import { Menu, Transition } from "@headlessui/react";
import { ChevronRightIcon, EllipsisHorizontalIcon, PlusCircleIcon } from "@heroicons/react/20/solid";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import AppContext, { NotificationType } from "AppContext";
import CompanyService from "api/CompanyService";
import Avatar from "components/Avatar/Avatar";
import { UserCompany, UserOwnershipFilter } from "types/Company.types";
import { BG_COLORS_AVATAR, NOTIFICATION_DISPLAY_TIME } from "utils/constants";
import useDebounce from "hooks/useDebounce";

export const CompaniesPage: React.FC = () => {
  const [companies, setCompanies] = useState<Array<UserCompany>>([]);
  const [searchKey, setSearchKey] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [ownershipFilter, setOwnershipFilter] = useState<UserOwnershipFilter>(UserOwnershipFilter.ALL);
  const debouncedSearchValue = useDebounce<string>(searchKey);
  const {
    session: { loggedUser },
    notification: { showNotification },
  } = useContext(AppContext);

  useEffect(() => {
    const fetchCompanies = async (): Promise<void> => {
      setIsLoading(true);
      const { data, error } = await CompanyService.listUserCompanies(loggedUser.id, {
        name: debouncedSearchValue,
        ownership: ownershipFilter,
      });
      setIsLoading(false);
      if (data) {
        setCompanies(data);
      } else {
        showNotification({
          notificationType: NotificationType.Error,
          title: "Error fetching companies.",
          message: error.message,
          displayTime: NOTIFICATION_DISPLAY_TIME,
        });
      }
    };
    fetchCompanies();
  }, [loggedUser.id, showNotification, debouncedSearchValue, ownershipFilter, setIsLoading]);

  const ownershipFilters = [
    { id: UserOwnershipFilter.ALL, title: "All" },
    { id: UserOwnershipFilter.OWNED, title: "My Companies" },
    { id: UserOwnershipFilter.INVITED, title: "Invited Companies" },
  ];

  return (
    <div className="lg:full flex flex-1 flex-col px-4 py-8 sm:px-6 lg:px-20 xl:px-24">
      <Tooltip id="coming-soon" className="z-50" />
      <div className="mx-4 mb-2 flex w-full flex-col md:mx-24 md:mb-8 md:w-1/2">
        <p className="mb-2 hidden text-base text-varla-violet-100/80 md:block">Let's get started!</p>
        <h1 className="mb-4 text-5xl font-bold text-gray-900">Your Companies</h1>
        <p className="mb-2 text-gray-500">
          This is where you create and manage your companies with ease. We're excited to help you streamline your business operations and
          achieve success. Let's get started!
        </p>
        <Link
          to="/companies/create-profile"
          className="m-auto inline-flex w-80 justify-center gap-x-1.5 rounded-md bg-varla-violet-100/90 px-3 py-2 text-sm text-white ring-1 ring-inset ring-gray-300 hover:bg-varla-violet-100/80 md:m-1 md:w-fit"
        >
          Create a Company
          <PlusCircleIcon className="h-5 w-5" aria-hidden="true" />
        </Link>
      </div>
      <div className="mb-8 flex">
        <div className="relative w-1/2">
          <label htmlFor="name" className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">
            Company Filter
          </label>
          <input
            type="text"
            name="companyNameFilter"
            id="companyNameFilter"
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            placeholder="Company Name"
            onChange={(event) => {
              setSearchKey(event.target.value.toLowerCase());
            }}
          />
        </div>
        <div className="flex w-1/2 items-center justify-center space-x-4 space-y-2 sm:space-y-0">
          {ownershipFilters.map((ownershipFilter) => (
            <div key={ownershipFilter.id} className="flex items-center">
              <input
                id={ownershipFilter.id}
                name="ownership-filter"
                type="radio"
                defaultChecked={ownershipFilter.id === UserOwnershipFilter.ALL}
                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                onChange={() => setOwnershipFilter(ownershipFilter.id)}
              />
              <label htmlFor={ownershipFilter.id} className="ml-2 block text-sm font-medium leading-6 text-gray-900">
                {ownershipFilter.title}
              </label>
            </div>
          ))}
        </div>
      </div>

      {isLoading && (
        <div className="mt-6 flex justify-center text-gray-500">
          <svg
            aria-hidden="true"
            className="mr-2 mt-1 h-4 w-4 animate-spin fill-violet-600 text-gray-200"
            viewBox="0 0 100 100"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
              fill="currentColor"
            />
            <path
              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
              fill="currentFill"
            />
          </svg>
          <span>Fetching your companies</span>
        </div>
      )}

      {!isLoading && companies.length === 0 && (
        <div className="mt-6 flex justify-center text-gray-500">
          <span>You don’t own or form part of any company.</span>
        </div>
      )}

      {!isLoading && companies.length > 0 && (
        <ul className="grid grid-cols-1 gap-x-6 gap-y-8 lg:grid-cols-3 xl:gap-x-8">
          {companies.map((company) => (
            <li key={company.id} className="overflow-hidden rounded-xl border border-gray-200">
              <div className="flex items-center gap-x-4 border-b border-gray-900/5 bg-gray-50 p-6">
                <Avatar
                  nameParts={company.name.split(" ")}
                  imageUrl={company.logoUrl ?? ""}
                  textClasses="flex h-screen justify-center items-center font-bold leading-none text-white"
                  sizeClasses="h-12 w-12 rounded-full ring-4 ring-white sm:h-12 sm:w-12"
                  bgClasses={BG_COLORS_AVATAR}
                />
                <div className="text-sm font-medium capitalize leading-6 text-gray-900">{company.name}</div>
                <Menu as="div" className="relative ml-auto">
                  <Menu.Button className="-m-2.5 block p-2.5 text-gray-400 hover:text-gray-500">
                    <span className="sr-only">Open options</span>
                    <EllipsisHorizontalIcon className="h-5 w-5" aria-hidden="true" />
                  </Menu.Button>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-10 mt-0.5 w-32 origin-top-right rounded-md bg-white py-2 ring-1 ring-gray-900/5 focus:outline-none">
                      <Menu.Item>
                        <Link to={`/companies/${company.id}`} className="block px-3 py-1 text-sm leading-6 text-gray-900">
                          Dashboard
                        </Link>
                      </Menu.Item>
                      {!company.accessLevel && (
                        <Menu.Item>
                          <button
                            data-tooltip-id="coming-soon"
                            data-tooltip-content="Coming Soon"
                            className="block px-3 py-1 text-sm leading-6 text-gray-900"
                          >
                            Verify
                          </button>
                        </Menu.Item>
                      )}
                      <Menu.Item>
                        <Link to={`/companies/${company.id}/edit-profile`} className="block px-3 py-1 text-sm leading-6 text-gray-900">
                          Edit
                        </Link>
                      </Menu.Item>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
              <dl className="-my-3 divide-y divide-gray-100 px-6 py-4 text-sm leading-6">
                <div className="flex justify-between gap-x-4 py-3">
                  <dt className="text-gray-500">Role</dt>
                  <dd className="flex items-start gap-x-2">
                    {company.accessLevel?.length > 0 ? (
                      <div>
                        {company.accessLevel
                          .map((element) => {
                            return element.charAt(0).toUpperCase() + element.slice(1);
                          })
                          .join(", ")}
                      </div>
                    ) : (
                      <div className="font-medium capitalize text-gray-900">Owner</div>
                    )}
                  </dd>
                </div>
                <div className="flex justify-center gap-x-4 py-3">
                  <div>
                    <Link
                      to={`/companies/${company.id}`}
                      className="m-auto inline-flex w-80 justify-center gap-x-1.5 rounded-md bg-varla-violet-100/90 px-3 py-2 text-sm text-white ring-1 ring-inset ring-gray-300 hover:bg-varla-violet-100/80 md:m-1 md:w-fit"
                    >
                      Go To Dashboard
                      <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
                    </Link>
                  </div>
                </div>
              </dl>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default CompaniesPage;
