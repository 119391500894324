import { InferType, array, number, object, string } from "yup";

export const JobPostingGeneratorFormSchema = object({
  roleId: string(),
  role: string().strict().required(),
  companyId: string().required(),
  locations: array().min(1, "At least one location must be selected"),
  country: string().when("locations", {
    is: (val: any) => val.includes("on-site"),
    then: (schema) => schema.required(),
  }),
  minSalary: number().strict().required(),
  maxSalary: number().strict().required(),
  expirationDate: string().strict().required(),
  hiringManagerId: string().required("Hiring manager is a required field"),
  requirements: string().strict(),
});

export type JobPostingGeneratorForm = InferType<typeof JobPostingGeneratorFormSchema> & {
  company: {
    name: string;
    logoUrl: string | null;
    industry: string;
  };
  hiringManager: {
    profilePictureUrl: string | null;
    firstName: string | null;
    lastName: string | null;
  };
};

export type JobPosting = {
  roleId: string;
  role: string;
  location: string;
  companyId: string;
  minSalary: number;
  maxSalary: number;
  requirements: string;
  hiringManagerId: string;
  expirationDate: string;
};

export type JobPostingListItem = {
  id: number;
  companyId: number;
  createdBy: number;
  createdOn: string;
  creatorName: string;
  expirationDuration: string;
  expiresOn: string;
  hiringCompanyId: string;
  hiringManagerId: number;
  hiringManagerName: string;
  location: string;
  maxSalary: number;
  minSalary: number;
  officeLocation: string[];
  roleName: string;
  companyName: string;
  industry: string;
  companyLogoUrl: string;
  profilePictureUrl: string;
  overview: string;
  responsibilities: string;
  qualifications: string;
};

export type JobPostingListAPI = {
  totalCount: number;
  jobPostings: Array<JobPostingListItem>;
};
