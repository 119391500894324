import React, { useState } from "react";
import { PLACEHOLDER } from "utils/constants";
import { charAtCoalescing } from "utils/helpers";
export type AvatarProps = {
  /**
   *for users = [firstName, lastName]
   *for companies = companyName.split(" ")
   */
  nameParts: Array<string>;

  /* image Url */
  imageUrl: string | null;
  /**
   * size of the container. ex: 'h-8 w-8 rounded-full'
   */
  sizeClasses: string;
  /**
   * size of the initials. ex: 'text-sm'
   */
  textClasses: string;
  bgClasses?: Array<string>;
};

export const Avatar: React.FC<AvatarProps> = ({ nameParts, imageUrl, sizeClasses, textClasses, bgClasses = ["bg-gray-500"] }) => {
  const [bgClass] = useState(bgClasses[Math.floor(Math.random() * bgClasses.length)]);

  if (Boolean(imageUrl)) {
    return <img className={sizeClasses} src={imageUrl!} alt={nameParts[0]} />;
  }

  let initials = "";
  if (nameParts.length === 1) {
    const [onlyPart] = nameParts;
    initials = `${charAtCoalescing(onlyPart)}${charAtCoalescing(onlyPart, { charIndex: 1 })}`;
  } else {
    const [firstPart, secondPart] = nameParts;
    initials = `${charAtCoalescing(firstPart)}${charAtCoalescing(secondPart)}`;
  }

  if (initials.length === 0) {
    return <img className={sizeClasses} src={PLACEHOLDER} alt="placeholder" />;
  }

  return (
    <span data-testid="avatarContainer" className={`${sizeClasses} inline-flex items-center justify-center ${bgClass}`}>
      <span className={`${textClasses} font-medium uppercase leading-none text-white`}>{initials}</span>
    </span>
  );
};

export default Avatar;
