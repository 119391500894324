import RoleService from "api/RoleService";
import { extractSkillsFromContent } from "api/TeamGeneratorService";
import { rateSkill } from "api/UserSkillsService";
import { AxiosResponse } from "axios";
import { ENV_VARS } from "env";
import { useLoader } from "hooks/useLoader";
import { useCallback, useEffect, useState } from "react";
import { NpcRolesSkills } from "types/Role.types";
import { UserSkillType } from "types/UserSkill.types";
import { fullName } from "utils/full-name";

export const AddNpcSkillsPage = () => {
  const { applyLoader } = useLoader();
  const [currentNpcs, setCurrentNpcs] = useState<Array<NpcRolesSkills>>([]);
  const [uniqueRoles, setUniqueRoles] = useState<Array<string>>([]);
  const [loadingMessage, setLoadingMessage] = useState<string | null>(null);

  useEffect(() => {
    const controller = new AbortController();
    const fetchRoles = async (): Promise<void> => {
      const { data } = await applyLoader(RoleService.listNpcRoles(controller.signal));
      if (data) {
        const uniqueNpcRoles = new Set<string>();
        for (const npc of data) {
          const roles = npc.roles.length ? npc.roles : [npc.title];
          for (const role of roles) {
            uniqueNpcRoles.add(role);
          }
        }
        setCurrentNpcs(data);
        setUniqueRoles(Array.from(uniqueNpcRoles));
      }
    };
    fetchRoles();
    return () => controller.abort();
  }, [applyLoader]);

  const getAndRateSkills = useCallback(async () => {
    const smoothLoadingWrapper = async () => {
      setLoadingMessage(`Fetching skills for ${uniqueRoles.length} roles...`);

      /* istanbul ignore next */
      const streamSkills = async (role: string): Promise<{ role: string; skills: Array<string> }> => {
        const lambdaStreamUrl = ENV_VARS.StreamRoleSkillSuggestions;
        const { body: streamResponse } = await fetch(lambdaStreamUrl, {
          method: "POST",
          body: JSON.stringify({ role }),
        });
        if (!streamResponse) {
          return { role, skills: [] };
        }
        const reader = streamResponse.getReader();
        const decoder = new TextDecoder();
        let done = false;
        let content = "";
        const skills: Array<string> = [];
        while (!done) {
          const { value, done: doneReading } = await reader.read();
          done = doneReading;
          const chunkValue = decoder.decode(value);
          content += chunkValue;
          const { completedSkills: completed, incompleteContent: incomplete } = extractSkillsFromContent(content);
          if (completed.length > 0) {
            skills.push(...completed);
          }
          content = incomplete;
        }
        if (content.trim()) {
          skills.push(content.replace(/\d+\.\s?/, "").trim());
        }
        return { role, skills };
      };

      console.log("streaming skills for roles:", uniqueRoles);
      const roleSkillsAIRequests = uniqueRoles.map((role) => streamSkills(role));
      const aiRoleSkills = await Promise.all(roleSkillsAIRequests);

      const roles: { [role: string]: { skills: Array<string> } } = {};
      for (const { role, skills } of aiRoleSkills) {
        roles[role] = { skills };
      }

      const newSkills = currentNpcs
        .map((npc) => {
          const currentNpcSkills = new Set<string>(npc.skills);
          const newNpcSkills = new Set<string>();
          const npcRoles = npc.roles.length ? npc.roles : [npc.title];
          for (const npcRole of npcRoles) {
            if (roles[npcRole]) {
              for (const skill of roles[npcRole].skills) {
                const sanitizedSkills = skill.length > 128 ? skill.split("\n") : [skill];
                for (const sanitized of sanitizedSkills) {
                  if (sanitized.length <= 128 && !currentNpcSkills.has(sanitized)) {
                    newNpcSkills.add(sanitized);
                  }
                }
              }
            }
          }
          return { npc, skills: Array.from(newNpcSkills) };
        })
        .filter(({ skills }) => skills.length > 0);

      const numberOfParallelRatingCalls = 5;
      const chunks: Array<() => Promise<Array<AxiosResponse<UserSkillType>>>> = [];
      for (const { npc, skills } of newSkills) {
        for (let i = 0; i < skills.length; i += numberOfParallelRatingCalls) {
          const newSkillsChunk = skills.slice(i, i + numberOfParallelRatingCalls);
          const npcFullName = fullName(npc);
          const skillsToRate = newSkillsChunk.map((skill) => `<span>- "${skill}"</span>`).join("");
          const chunkLoadingMessage = [
            '<div className="flex flex-col">',
            `  <span>NPC: ${npcFullName}</span>`,
            `  ${skillsToRate}`,
            "</div>",
          ].join("");
          chunks.push(async () =>
            Promise.all(
              newSkillsChunk.map((skillName) => {
                setLoadingMessage(chunkLoadingMessage);
                return rateSkill({ rate: 3, ratedBy: npc.userId, skillName }, npc.userId);
              })
            )
          );
        }
      }

      for (let i = 0; i < chunks.length; i++) {
        await chunks[i]();
      }
      setLoadingMessage(null);
    };

    return await applyLoader(smoothLoadingWrapper());
  }, [uniqueRoles, currentNpcs, applyLoader]);

  return (
    <div className="flex h-screen flex-col items-center justify-center">
      <div className="pb-4">{loadingMessage}</div>
      <button
        disabled={currentNpcs.length === 0 || loadingMessage !== null}
        onClick={getAndRateSkills}
        className="inline-flex items-center justify-center rounded-md bg-violet-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-violet-500"
      >
        Get & Rate skills for {uniqueRoles.length} roles
      </button>
    </div>
  );
};
