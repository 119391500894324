type Env = "dev" | "prod";
type EnvVariables = {
  ApiUrl: string;
  StreamRoleSkillSuggestions: string;
  StreamTeamRolesSuggestions: string;
};
type EnvVariableMap<T> = { [env in Env]: T };
type EnvVariablesMap = { [EnvVar in keyof EnvVariables]: EnvVariableMap<EnvVariables[EnvVar]> };

const envVars: EnvVariablesMap = {
  ApiUrl: {
    dev: "https://k7hetsxkw9.execute-api.us-west-1.amazonaws.com/dev/",
    prod: "https://ykfwtswh6h.execute-api.us-west-1.amazonaws.com/prod/",
  },
  StreamRoleSkillSuggestions: {
    dev: "https://ryq6thtk37pinp7iv7oiyyqmrq0fryiy.lambda-url.us-west-1.on.aws/",
    prod: "https://ajdqxiqlomezsazvthr5kgyrly0twxep.lambda-url.us-west-1.on.aws/",
  },
  StreamTeamRolesSuggestions: {
    dev: "https://vfttkct7qisbmkxmmiedmfv6ku0yxcfk.lambda-url.us-west-1.on.aws/",
    prod: "https://uinueu7vw4d6mlrlyt6qlh3n540ekilr.lambda-url.us-west-1.on.aws/",
  },
};

const currentEnv = process.env.REACT_APP_ENV as Env;
export const ENV_VARS: EnvVariables = {
  ApiUrl: envVars.ApiUrl[currentEnv],
  StreamRoleSkillSuggestions: envVars.StreamRoleSkillSuggestions[currentEnv],
  StreamTeamRolesSuggestions: envVars.StreamTeamRolesSuggestions[currentEnv],
};
