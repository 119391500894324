import { Fragment, useEffect, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { classNames } from "utils/helpers";
import { FieldError } from "react-hook-form";
import Avatar from "components/Avatar/Avatar";
import { fullName } from "utils/full-name";
import { Employee } from "types/Company.types";

type Props = {
  options: Employee[];
  selected?: string;
  fieldKey: string;
  onChange: Function;
  error?: FieldError;
};

const UserDropdown = (props: Props): JSX.Element => {
  const { selected, options, onChange, error } = props;
  const [selectedEmployee, setSelectedEmployee] = useState<Employee>();
  useEffect(() => {
    setSelectedEmployee(options.find((option) => option.id.toString() === selected));
  }, [selected, options]);

  return (
    <Listbox value={selected} onChange={(value) => onChange(value, props.fieldKey)}>
      {({ open }) => (
        <>
          <div className="relative mt-2 w-full">
            <Listbox.Button
              role="combobox"
              className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-violet-500 sm:text-sm sm:leading-6"
            >
              <span className="flex items-center">
                <span className="inline-flex h-8 w-8 items-center justify-center rounded-full">
                  {selectedEmployee && (
                    <Avatar
                      imageUrl={selectedEmployee.profilePictureUrl}
                      nameParts={[selectedEmployee.firstName!, selectedEmployee.lastName!]}
                      sizeClasses="h-8 w-8 rounded-full"
                      textClasses="text-sm"
                    />
                  )}
                </span>
                <span className="ml-3 block truncate">{selectedEmployee && fullName(selectedEmployee)}</span>
              </span>
              <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
              </span>
            </Listbox.Button>

            <Transition show={open} as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
              <Listbox.Options className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                <Listbox.Option
                  className={({ active }) =>
                    classNames(active ? "bg-violet-600 text-white" : "text-gray-900", "relative cursor-default select-none py-2 pl-3 pr-9")
                  }
                  value=""
                >
                  <div className="flex items-center">
                    <span className="inline-flex h-8 w-8 items-center justify-center rounded-full">
                      <span className="text-sm font-medium leading-none text-white"></span>
                    </span>
                    <span className="ml-3 block truncate"></span>
                  </div>
                </Listbox.Option>
                {props.options.map((employee) => (
                  <Listbox.Option
                    key={employee.id}
                    className={({ active }) =>
                      classNames(
                        active ? "bg-violet-600 text-white" : "text-gray-900",
                        "relative cursor-default select-none py-2 pl-3 pr-9"
                      )
                    }
                    value={employee.id}
                  >
                    {({ selected, disabled }) => (
                      <div className="flex items-center gap-2">
                        <span
                          className={classNames(
                            disabled ? "opacity-50" : "",
                            "inline-flex h-8 w-8 items-center justify-center rounded-full"
                          )}
                        >
                          <Avatar
                            imageUrl={employee.profilePictureUrl}
                            nameParts={[employee.firstName!, employee.lastName!]}
                            sizeClasses="h-8 w-8 rounded-full"
                            textClasses="text-sm"
                          />
                        </span>
                        <div className={classNames(selected ? "font-semibold" : "font-normal", disabled ? "opacity-50" : "", "truncate")}>
                          {fullName(employee)}
                        </div>
                      </div>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
            {error && <div className="mt-2 block w-full py-1 text-sm text-red-700">{error.message}</div>}
          </div>
        </>
      )}
    </Listbox>
  );
};

export default UserDropdown;
