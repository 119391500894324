import { instance } from "./base.api";
import { JobDescriptionFromGPT, JobDescriptionGeneratorForm, JobDescription } from "types/JobDescription.types";
import { AxiosResponse } from "axios";

export const lambdaStreamUrl = "https://ims45ftmvvigbr4efbmu7tv53a0vjqdv.lambda-url.us-west-1.on.aws/";
export const generateJobDescription = async (formData: JobDescriptionGeneratorForm): Promise<JobDescriptionFromGPT | undefined> => {
  return await instance.post(lambdaStreamUrl, formData, { responseType: "stream" }).then((response) => {
    let responseString = response.data;
    if (responseString[0] !== "{") {
      responseString = "{" + responseString;
    }

    return JSON.parse(responseString);
  });
};

export const insertJobDescription = async (companyId: number, userId: number, params: JobDescription): Promise<AxiosResponse> => {
  return instance.post(`companies/${companyId}/job-descriptions`, {
    ...params,
    userId,
  });
};

export const getJobDescription = async (jobDescriptionId: number): Promise<any> => {
  return instance.get(`job-descriptions/${jobDescriptionId}`);
};

const JobGeneratorService = {
  generateJobDescription,
  insertJobDescription,
  getJobDescription,
};

export default JobGeneratorService;
