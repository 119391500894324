import { UserGroupIcon, UserIcon, UserPlusIcon, BriefcaseIcon, QuestionMarkCircleIcon } from "@heroicons/react/20/solid";
import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import InviteUserModal from "../Modal/InviteUserModal";
import { classNames } from "utils/helpers";

const SideBarNavigation = (): JSX.Element => {
  const [showInviteUser, setShowInviteUser] = useState(false);
  const location = useLocation();

  return (
    <nav aria-label="Sidebar" className="hidden md:block md:flex-shrink-0 md:overflow-y-auto md:bg-gray-800">
      <div className="relative flex h-full w-20 flex-col space-y-3 p-3">
        <Link
          key="Directory"
          to="/directory"
          className={classNames(
            "/directory" === location.pathname ? "bg-gray-900 text-white" : "text-gray-400 hover:bg-gray-700",
            "inline-flex hidden h-14 w-14 flex-shrink-0 items-center justify-center rounded-lg"
          )}
        >
          <span className="sr-only">Directory</span>
          <UserGroupIcon className="h-6 w-6" aria-hidden="true" />
        </Link>
        <Link
          key="Connections"
          to="/connections"
          className={classNames(
            "/connections" === location.pathname ? "bg-gray-900 text-white" : "text-gray-400 hover:bg-gray-700",
            "inline-flex h-14 w-14 flex-shrink-0 items-center justify-center rounded-lg"
          )}
        >
          <span className="sr-only">Connections</span>
          <UserGroupIcon className="h-6 w-6" aria-hidden="true" />
        </Link>
        <Link
          key="Profile"
          to="/profile"
          className={classNames(
            "/profile" === location.pathname ? "bg-gray-900 text-white" : "text-gray-400 hover:bg-gray-700",
            "inline-flex h-14 w-14 flex-shrink-0 items-center justify-center rounded-lg"
          )}
        >
          <span className="sr-only">Profile</span>
          <UserIcon className="h-6 w-6" aria-hidden="true" />
        </Link>
        <Link
          key="Companies"
          to="/companies"
          className={classNames(
            "/companies" === location.pathname ? "bg-gray-900 text-white" : "text-gray-400 hover:bg-gray-700",
            "inline-flex h-14 w-14 flex-shrink-0 items-center justify-center rounded-lg"
          )}
        >
          <span className="sr-only">Companies</span>
          <BriefcaseIcon className="h-6 w-6" aria-hidden="true" />
        </Link>
        <button
          key="InviteUser"
          onClick={() => setShowInviteUser(true)}
          className="inline-flex h-14 w-14 flex-shrink-0 items-center justify-center rounded-lg text-gray-400 hover:bg-gray-700"
        >
          <span className="sr-only">Invite user</span>
          <UserPlusIcon className="h-6 w-6" aria-hidden="true" />
        </button>
        <InviteUserModal show={showInviteUser} setOpen={setShowInviteUser} />
        <Link
          key="Guide"
          to="/guide"
          className={classNames(
            "/guide" === location.pathname ? "bg-gray-900 text-white" : "text-gray-400 hover:bg-gray-700",
            "absolute bottom-6 inline-flex h-14 w-14 flex-shrink-0 items-center justify-center rounded-lg"
          )}
        >
          <span className="sr-only">Guide</span>
          <QuestionMarkCircleIcon className="h-6 w-6" aria-hidden="true" />
        </Link>
      </div>
    </nav>
  );
};

export default SideBarNavigation;
