import { useCallback, useEffect, useState } from "react";
import Autocomplete from "react-autocomplete";
import { NotificationType, useAppContext } from "AppContext";
import { useLoader } from "hooks/useLoader";
import { MatchingEmployee, MatchingSkills } from "types/User.types";
import { fullName } from "utils/full-name";
import Avatar from "../Avatar/Avatar";
import Modal, { ModalAction, ModalOnCloseFunction } from "./Modal";
import { Company } from "types/Company.types";
import CompanyService from "api/CompanyService";

export type AddTeamEmployeeResult = {
  selectedEmployee: MatchingEmployee | null;
  role: string;
};

export type AddTeamEmployeeModalProps = {
  show: boolean;
  onClose: ModalOnCloseFunction<AddTeamEmployeeResult>;
  matchingRoleEmployee: string;
  generatedSkills: string[];
  company: Company;
};

const AddTeamEmployeeModal: React.FC<AddTeamEmployeeModalProps> = (props) => {
  const [employees, setEmployees] = useState<Array<MatchingEmployee>>([]);
  const [selectedEmployee, setSelectedEmployee] = useState<MatchingEmployee | null>(null);
  const [searchValue, setSearchValue] = useState<string>("");
  const [openDropDown, setOpenDropDown] = useState(true);
  const { applyLoader } = useLoader();
  const {
    notification: { showNotification },
  } = useAppContext();
  const resetModal = useCallback(() => {
    setEmployees([]);
    setSelectedEmployee(null);
    setSearchValue("");
    setOpenDropDown(true);
  }, []);

  useEffect(() => {
    const controller = new AbortController();
    if (props.show) {
      const fetchEmployees = async () => {
        try {
          const { data } = await applyLoader(CompanyService.companyEmployees(props.company.id, controller.signal));
          const matchingSkills: MatchingSkills[] = props.generatedSkills.map((generatedSkill) => ({ name: generatedSkill, rate: 1 }));
          const fullEmployees: MatchingEmployee[] = data.map((employee) => ({
            ...employee,
            id: employee.employeeId,
            skills: matchingSkills,
            hasRole: true,
          }));
          const namedEmployees = fullEmployees.filter((employee) => employee.firstName !== null || employee.lastName !== null);
          setEmployees(namedEmployees);
        } catch (error) {
          showNotification({ notificationType: NotificationType.Error, message: error.message, title: "Error fetching employees" });
        }
      };
      fetchEmployees();
    } else {
      controller.abort();
      resetModal();
    }
    return () => controller.abort();
  }, [props.show, applyLoader, showNotification, resetModal, props.generatedSkills, props.company.id]);

  const nameMatches = (employee: MatchingEmployee, searchValue: string) => {
    return fullName(employee).toLowerCase().includes(searchValue.toLowerCase());
  };

  return (
    <>
      <Modal show={props.show} onClose={props.onClose} title="Select an employee">
        <div>
          Select an employee from your company for the role of <strong>{props.matchingRoleEmployee}</strong>
        </div>
        <div className="autocomplete-wrapper">
          <Autocomplete
            value={searchValue}
            items={employees}
            getItemValue={(employee) => fullName(employee)}
            shouldItemRender={nameMatches}
            renderMenu={(employeesNodes) => <div className="dropdown">{employeesNodes}</div>}
            renderItem={(employee: MatchingEmployee, isHighlighted) => (
              <div
                className={`item ${isHighlighted ? "selected-item" : ""}`}
                key={employee.id}
                data-testid={`EmployeeDropdownRecord.${employee.id}`}
              >
                <div className="flex items-center gap-2">
                  <Avatar
                    imageUrl={employee.profilePictureUrl}
                    nameParts={[employee.firstName!, employee.lastName!]}
                    sizeClasses="h-8 w-8 rounded-full"
                    textClasses="text-sm"
                  ></Avatar>
                  {fullName(employee)}
                </div>
              </div>
            )}
            onChange={(event, input) => {
              setOpenDropDown(true);
              setSelectedEmployee(null);
              setSearchValue(input);
            }}
            onSelect={(fullName, employee) => {
              setOpenDropDown(false);
              setSelectedEmployee(employee);
              setSearchValue(fullName);
            }}
            open={openDropDown}
          />
        </div>
        <div className="flex h-fit min-h-full justify-end space-x-1 pt-8">
          <button
            type="button"
            className="mr-3 rounded-md border-2 px-3 py-2 text-sm font-semibold text-black shadow-sm hover:bg-gray-200"
            onClick={() => props.onClose()}
          >
            Cancel
          </button>
          <button
            data-testid="SubmitButton"
            className="mr-3 inline-flex items-center justify-center rounded-md bg-violet-600 px-3 py-2 text-sm font-semibold text-white shadow-sm enabled:hover:bg-violet-500 disabled:cursor-not-allowed disabled:bg-slate-400"
            disabled={selectedEmployee === null}
            onClick={() => props.onClose({ action: ModalAction.Add, data: { selectedEmployee, role: props.matchingRoleEmployee } })}
          >
            Add
          </button>
        </div>
      </Modal>
    </>
  );
};

export default AddTeamEmployeeModal;
