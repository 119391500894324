import { ArrowRightCircleIcon, TrashIcon, UserPlusIcon } from "@heroicons/react/24/solid";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { Link, useOutletContext } from "react-router-dom";
import { useAppContext } from "AppContext";
import { extractSkillsFromContent, filterEmployees } from "api/TeamGeneratorService";
import { Avatar } from "components";
import AddTeamEmployeeModal, { AddTeamEmployeeResult } from "components/Modal/AddTeamEmployeeModal";
import { ModalAction, ModalOnCloseFunction, handleModalOnCloseResult } from "components/Modal/Modal";
import { ENV_VARS } from "../../../../../../env";
import { useLoader } from "hooks/useLoader";
import { MatchingEmployeesQuery, RoleSkillsQuery } from "../../../../../../queries/teamGeneratorQueries";
import { RoleWithSkillsType } from "types/Role.types";
import { MatchingRoleEmployee } from "types/User.types";
import { LIST_NUMBER_REGEX, NO_PROFILE_PIC } from "utils/constants";
import { fullName } from "utils/full-name";
import ConfirmationAlertModal from "components/Modal/ConfirmationAlertModal";
import { CompanyContainerOutletContextType } from "../../../CompanyContainerPage";
import { employeesFilteredByRoleSkills } from "api/CompanyService";
import { Disclosure } from "@headlessui/react";
import { ChevronUpIcon } from "@heroicons/react/20/solid";

export const StepTeamMembersPage: React.FC = () => {
  const {
    paginator: { stepData, nextStep },
  } = useAppContext();
  const { applyLoader } = useLoader();
  const [showAddEmployeeModal, setShowAddEmployeeModal] = useState(false);
  const [generatedSkills, setGeneratedSkills] = useState<RoleWithSkillsType[]>([]);
  const [roleGeneratedSkills, setRoleGeneratedSkills] = useState<string[]>([]);
  const [employeeRole, setEmployeeRole] = useState<string>("");
  const [matchingEmployees, setMatchingEmployees] = useState<MatchingRoleEmployee[]>([]);
  const { company } = useOutletContext<CompanyContainerOutletContextType>();

  /*istanbul ignore next*/
  const fetchRoleSkills = async () => {
    const streamSkills = async (role: string): Promise<Array<string>> => {
      const skills: Array<string> = [];
      const lambdaStreamUrl = ENV_VARS.StreamRoleSkillSuggestions;
      const { body: streamResponse } = await fetch(lambdaStreamUrl, {
        method: "POST",
        body: JSON.stringify({ role }),
      });
      if (!streamResponse) {
        return [];
      }
      const reader = streamResponse.getReader();
      const decoder = new TextDecoder();
      let done = false;
      let content = "";
      while (!done) {
        const { value, done: doneReading } = await reader.read();
        done = doneReading;
        const chunkValue = decoder.decode(value);
        content += chunkValue;
        const { completedSkills, incompleteContent: incomplete } = extractSkillsFromContent(content);
        for (const newSkill of completedSkills) {
          const skill = newSkill.replace(LIST_NUMBER_REGEX, "").trim();
          skills.push(skill);
        }
        content = incomplete;
      }
      if (content.trim()) {
        const skill = content.replace(LIST_NUMBER_REGEX, "").trim();
        skills.push(skill);
      }

      return skills;
    };

    const addGeneratedSkills = async (role: string) => {
      const skills = await applyLoader(streamSkills(role));

      return { roleName: role, roleSkills: skills };
    };

    const rolesWithSkills = await Promise.all(stepData.teamGeneratorData!.roles.map((role) => addGeneratedSkills(role.roleName)));
    return rolesWithSkills;
  };

  /*istanbul ignore next*/
  const fetchMatchingEmployees = async (rolesWithSkills: RoleWithSkillsType[]) => {
    setGeneratedSkills(rolesWithSkills);
    const { data } = await applyLoader(employeesFilteredByRoleSkills(company.id, rolesWithSkills));
    const matchingEmployees = filterEmployees(data, rolesWithSkills);
    matchingEmployees.sort((a, b) => a.roleName.localeCompare(b.roleName));
    return matchingEmployees;
  };

  const { data: roleSkillsData, isSuccess: roleSkillsIsSuccess } = RoleSkillsQuery(
    fetchRoleSkills,
    stepData.teamGeneratorData?.employees.length === 0
  );

  const { data: matchingEmployeesData, isSuccess: matchingEmployeesIsSuccess } = MatchingEmployeesQuery(
    (data: RoleWithSkillsType[] | undefined) => fetchMatchingEmployees(data ?? []),
    roleSkillsIsSuccess,
    roleSkillsData
  );

  useEffect(() => {
    if (matchingEmployeesIsSuccess) {
      setMatchingEmployees(matchingEmployeesData);
    } else {
      setMatchingEmployees(stepData.teamGeneratorData!.employees);
    }
  }, [matchingEmployees, matchingEmployeesData, matchingEmployeesIsSuccess, stepData]);

  /*istanbul ignore next*/
  const matchingGeneratedSkills = (employeeRoleName: string) => {
    const matchingSkill = generatedSkills.find(
      (generatedSkill) => generatedSkill.roleName.toLowerCase() === employeeRoleName.toLowerCase()
    );
    return matchingSkill ? matchingSkill.roleSkills : [];
  };

  const employeesAmountByRole = (roleName: string) => {
    return stepData.teamGeneratorData?.roles.find((role) => role.roleName === roleName)?.amount || 0;
  };

  const addEmployee = useCallback<ModalOnCloseFunction<AddTeamEmployeeResult>>(
    (onCloseResult) => {
      handleModalOnCloseResult(onCloseResult, {
        [ModalAction.Add]: (addTeamEmployeeResult) => {
          const updatedEmployees = matchingEmployees.map((data) => {
            if (data.roleName !== addTeamEmployeeResult.role) return data;

            data.roleSkillsEmployees.push({
              ...addTeamEmployeeResult.selectedEmployee!,
              checked: true,
              manuallyAdded: true,
            });
            return data;
          });

          setMatchingEmployees(updatedEmployees);
        },
      });

      setShowAddEmployeeModal(false);
    },
    [matchingEmployees]
  );

  const removeEmployee = (employeeId: number, roleName: string) => {
    const remainingEmployees = matchingEmployees.map((data) => {
      if (data.roleName !== roleName) return data;

      data.roleSkillsEmployees = data.roleSkillsEmployees.filter((employee) => {
        return employee.id !== employeeId;
      });

      return data;
    });

    setMatchingEmployees(remainingEmployees);
  };

  const updateMatchingEmployee = (event: ChangeEvent<HTMLInputElement>, index: number, roleName: string) => {
    const employees = matchingEmployees.map((data) => {
      if (data.roleName === roleName) {
        data.roleSkillsEmployees[index].checked = event.currentTarget.checked;
      }

      return data;
    });

    setMatchingEmployees(employees);
  };

  const handleClick = (employeeRoleName: string) => {
    setEmployeeRole(employeeRoleName);
    setRoleGeneratedSkills(matchingGeneratedSkills(employeeRoleName));
    setShowAddEmployeeModal(true);
  };

  const handleRemoveEmployee = (employeeId: number, roleName: string) => {
    ConfirmationAlertModal({
      title: "Remove Employee from Team",
      message: `Are you sure you want to remove this employee?`,
      action: "Remove",
      onAction: () => removeEmployee(employeeId, roleName),
    });
  };

  const next = () => {
    if (matchingEmployees.length === 0) return;
    stepData.teamGeneratorData!.employees = matchingEmployees;
    nextStep(3, stepData);
  };

  const toggleAllMatch = (event: ChangeEvent<HTMLInputElement>, roleName: string) => {
    matchingEmployees
      .find((role) => role.roleName === roleName)
      ?.roleSkillsEmployees.filter((employee) => !employee.manuallyAdded)
      .forEach((employee) => (employee.checked = event.currentTarget.checked));
    setMatchingEmployees(JSON.parse(JSON.stringify(matchingEmployees)));
  };

  const matchingEmployeeList = matchingEmployees.map(({ roleName, roleSkillsEmployees }) => (
    <div key={roleName} className="h-full overflow-y-auto">
      <Disclosure defaultOpen={true}>
        {({ open }) => (
          <>
            <Disclosure.Button className="flex w-full justify-between rounded-lg bg-gray-50 p-4 text-left text-sm font-medium text-gray-900 hover:bg-gray-200 focus:outline-none focus-visible:ring focus-visible:ring-gray-500/75">
              <h3 className="capitalize">
                {roleName} ({roleSkillsEmployees.filter((employee) => !employee.manuallyAdded).length} match found)
              </h3>
              <ChevronUpIcon className={`${open ? "rotate-180 transform" : ""} h-5 w-5 text-purple-500`} />
            </Disclosure.Button>
            <Disclosure.Panel className="px-4 pb-2 pt-4 text-sm text-gray-500">
              <ul className="divide-y divide-gray-100">
                {roleSkillsEmployees.some((employee) => !employee.manuallyAdded) && (
                  <li className="flex flex-col items-end">
                    <div className="flex items-center p-3">
                      <label htmlFor={`select_all_${roleName}`}>Select all</label>
                      <input
                        type="checkbox"
                        className="mx-3 h-4 w-4 rounded border-gray-300 text-violet-600 focus:ring-violet-600"
                        checked={roleSkillsEmployees.every((employee) => employee.checked)}
                        onChange={(event) => toggleAllMatch(event, roleName)}
                      />
                    </div>
                  </li>
                )}
                {roleSkillsEmployees.map((employee, index) => (
                  <li key={employee.id} data-testid={`ManualEmployee.${employee.id}`} className="flex gap-x-4 px-3 py-5">
                    <Avatar
                      imageUrl={employee.profilePictureUrl}
                      nameParts={[employee.firstName!, employee.lastName!]}
                      sizeClasses="h-8 w-8 rounded-full"
                      textClasses="text-sm"
                    ></Avatar>
                    <div className="flex w-full min-w-0 flex-row justify-between text-sm font-semibold leading-6 text-gray-900">
                      <Link to="#">
                        <span className="capitalize">{fullName(employee)}</span>
                      </Link>
                      {employee.manuallyAdded ? (
                        <button
                          data-testid={`remove-${employee.id}`}
                          type="button"
                          onClick={() => handleRemoveEmployee(employee.id, roleName)}
                        >
                          <TrashIcon className="mx-3 -ml-0.5 h-5 w-5 cursor-pointer text-slate-300 hover:text-red-400" aria-hidden="true" />
                        </button>
                      ) : (
                        <input
                          type="checkbox"
                          checked={employee.checked}
                          className="mx-3 h-4 w-4 rounded border-gray-300 text-violet-600 focus:ring-violet-600"
                          onChange={(event) => updateMatchingEmployee(event, index, roleName)}
                        />
                      )}
                    </div>
                  </li>
                ))}
                {roleSkillsEmployees.length < employeesAmountByRole(roleName) &&
                  Array.from({ length: employeesAmountByRole(roleName) - roleSkillsEmployees.length }, (_, index) => (
                    <li key={index} className="flex gap-x-4 px-3 py-5">
                      <img className="h-8 w-8 rounded-full bg-gray-50" src={NO_PROFILE_PIC} alt="" />
                      <div className="flex w-full min-w-0 flex-row justify-between text-sm font-semibold leading-6 text-gray-900">
                        <span className="text-sm text-gray-500">No match found for this role.</span>
                        <button
                          data-role-name={roleName}
                          type="button"
                          className="inline-flex w-1/3 items-center justify-center gap-x-2 rounded-md bg-gray-200 px-3.5 py-2.5 text-sm font-semibold text-slate-500 shadow-sm hover:bg-gray-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-slate-400"
                          onClick={() => handleClick(roleName)}
                        >
                          <UserPlusIcon className="-ml-0.5 h-5 w-5 text-slate-500" aria-hidden="true" />
                          Select an Employee
                        </button>
                      </div>
                    </li>
                  ))}
              </ul>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </div>
  ));

  return (
    <>
      <div className="mt-2">
        <div className="mx-auto text-center">
          <div className="mx-auto">
            <p className="text-md leading-8 text-gray-600">
              These are the individuals who best suit your project needs based on their skill sets.
            </p>
          </div>
        </div>
      </div>
      <AddTeamEmployeeModal
        show={showAddEmployeeModal}
        onClose={addEmployee}
        matchingRoleEmployee={employeeRole}
        generatedSkills={roleGeneratedSkills}
        company={company}
      />
      <div className="mt-2 flex flex-col gap-2">{matchingEmployeeList}</div>
      <div className="mt-8 flex flex-row-reverse items-end justify-between space-x-3 border-t border-gray-200 px-2 py-2 sm:px-3">
        <button
          data-testid="next-step"
          className="flex rounded-md bg-violet-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-violet-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-violet-500 disabled:bg-violet-400"
          onClick={() => next()}
        >
          <span className="flex">
            Next step
            <ArrowRightCircleIcon className="ml-2 h-5 w-5" aria-hidden="true" />
          </span>
        </button>
      </div>
    </>
  );
};

export default StepTeamMembersPage;
