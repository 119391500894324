import { AxiosResponse } from "axios";
import { APIResult } from "types/Api.types";
import { NpcRolesSkills, Role } from "types/Role.types";
import { instance } from "./base.api";

export const listRoles = async (): Promise<APIResult<Array<Role>>> => {
  try {
    let { data } = await instance.get("roles");
    data = data.map((role: Role) => {
      return {
        ...role,
        addedOn: new Date(role.addedOn),
      };
    });

    return { data, error: null };
  } catch (error) {
    const status = error.response?.status;
    let errorMessage = "Something went wrong. please try again";
    return { data: null, error: { message: errorMessage, code: status } };
  }
};

export const insertRole = async (roleName: string): Promise<AxiosResponse> => {
  return instance.post(`roles`, { name: roleName });
};

export const listNpcRoles = async (signal: AbortSignal): Promise<APIResult<Array<NpcRolesSkills>>> => {
  try {
    let { data } = await instance.get("npcs/list-roles-skills", { signal });
    return { data, error: null };
  } catch (error) {
    const status = error.response?.status;
    let errorMessage = "Something went wrong. please try again";
    return { data: null, error: { message: errorMessage, code: status } };
  }
};

const RoleService = {
  listRoles,
  insertRole,
  listNpcRoles,
};

export default RoleService;
