import * as DOMPurify from "dompurify";

export const classNames = (...classes: string[]) => classes.filter(Boolean).join(" ");

export const charAtCoalescing = (text: string | null, options?: { charIndex?: number; coalesce?: string }) =>
  text?.charAt(options?.charIndex ?? 0) ?? options?.coalesce ?? "";

export const numberToCurrency = (number: number, locale: string, currency: string) => {
  return number.toLocaleString(locale, {
    style: "currency",
    currency,
    minimumFractionDigits: 0,
  });
};

export const formatAndSanitize = (htmlString: string) => {
  const formatted = htmlString.replace("<ul>", '<ul class="list-disc">');
  return DOMPurify.sanitize(formatted);
};
