import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAppContext } from "AppContext";
import Step from "components/Steps/Step";
import Steps from "components/Steps/Steps";
import StepDescriptionPage from "./steps/StepDescriptionPage";
import StepPreviewPage from "./steps/StepPreviewPage";
import StepRolesPage from "./steps/StepRolesPage";

export const JobGeneratorPage: React.FC<{}> = () => {
  const {
    paginator: { nextStep, stepData },
  } = useAppContext();
  const { jobDescriptionId } = useParams();

  useEffect(() => {
    if (jobDescriptionId) {
      nextStep(2, stepData);
    } else {
      nextStep(0, stepData);
    }
  }, [jobDescriptionId, nextStep, stepData]);

  return (
    <div className="lg:full flex flex-1 flex-col px-4 py-2 sm:px-6 lg:px-20 xl:px-24">
      <Steps>
        <Step title="Roles" isDisabled={!!jobDescriptionId}>
          <StepRolesPage />
        </Step>
        <Step title="Description" isDisabled={!!jobDescriptionId || !stepData.jobDescription?.overview}>
          <StepDescriptionPage />
        </Step>
        <Step title="Preview" isDisabled={!!jobDescriptionId || !stepData.jobDescription?.qualifications}>
          <StepPreviewPage />
        </Step>
      </Steps>
    </div>
  );
};

export default JobGeneratorPage;
