import { CheckCircleIcon } from "@heroicons/react/24/solid";
import { useEffect, useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import { NotificationType, useAppContext } from "AppContext";
import CompanyService from "api/CompanyService";
import { Avatar } from "components";
import { useLoader } from "hooks/useLoader";
import { TeamGeneratorDataRequest } from "types/Company.types";
import { CollectiveSkillsByName, CollectiveSkillsByRate } from "types/UserSkill.types";
import { NOTIFICATION_DISPLAY_TIME } from "utils/constants";
import { errorMessage } from "utils/errors";
import { fullName } from "utils/full-name";
import { skillTranslate } from "utils/skill-rating-translate";
import { CompanyContainerOutletContextType } from "../../../CompanyContainerPage";
import { MatchingRoleEmployee, MatchingSkills } from "types/User.types";
import { ENV_VARS } from "env";
import { extractSkillsFromContent } from "api/TeamGeneratorService";

export const StepPreviewPage: React.FC<{}> = () => {
  const { applyLoader } = useLoader();
  const {
    session: { loggedUser },
    paginator: { stepData, nextStep },
    notification: { showNotification },
  } = useAppContext();
  const [collectiveSkills, setCollectiveSkills] = useState<CollectiveSkillsByRate>({});
  const initialItemsToShow = 6;
  const [itemsToShow, setItemsToShow] = useState(initialItemsToShow);
  const { company } = useOutletContext<CompanyContainerOutletContextType>();
  const navigate = useNavigate();
  const teamListRoute = `/companies/${company.id}/dashboard/teams`;
  const streamSkills = async (role: string) => {
    const roleSkills: Array<string> = [];
    const lambdaStreamUrl = ENV_VARS.StreamRoleSkillSuggestions;
    const { body: streamResponse } = await fetch(lambdaStreamUrl, { method: "POST", body: JSON.stringify({ role }) });
    if (!streamResponse) {
      return;
    }
    const reader = streamResponse.getReader();
    const decoder = new TextDecoder();
    let done = false;
    let content = "";

    while (!done) {
      const { value, done: doneReading } = await reader.read();
      done = doneReading;
      const chunkValue = decoder.decode(value);
      content += chunkValue;
      const { completedSkills: completed, incompleteContent: incomplete } = extractSkillsFromContent(content);
      if (completed.length > 0) {
        roleSkills.push(...completed);
      }
      content = incomplete;
    }
    if (content.trim()) {
      roleSkills.push(content.replace(/\d+\.\s?/, "").trim());
    }

    return roleSkills;
  };

  const hasSelectedEmployees = stepData.teamGeneratorData!.employees.some(
    (roleEmployee: MatchingRoleEmployee) => roleEmployee.roleSkillsEmployees.filter((employee) => employee.checked).length > 0
  );
  const [teamCollectiveSkills, setTeamCollectiveSkills] = useState<MatchingSkills[]>([]);
  useEffect(() => {
    let teamSkills: MatchingSkills[] = [];
    const fetchAISkills = async () => {
      const apiCalls = stepData.teamGeneratorData!.employees.map(({ roleName }) => streamSkills(roleName));
      Promise.all(apiCalls).then((results) => {
        results.forEach((result) => result?.map((skillName) => teamSkills.push({ name: skillName, rate: 0 })));
        setTeamCollectiveSkills(teamSkills);
      });
    };

    if (hasSelectedEmployees) {
      stepData.teamGeneratorData!.employees.forEach(({ roleSkillsEmployees }) => {
        roleSkillsEmployees.filter((employee) => employee.checked).forEach(({ skills }) => teamSkills.push(...skills));
      });
      setTeamCollectiveSkills(teamSkills);
    } else {
      fetchAISkills();
    }
  }, [stepData, hasSelectedEmployees]);

  useEffect(() => {
    const skillsByName = teamCollectiveSkills.reduce<CollectiveSkillsByName>((acc, skill) => {
      const sanitizedSkillName = skill.name.toLowerCase().trim();
      if (!acc[sanitizedSkillName]) {
        acc[sanitizedSkillName] = { maxRate: skill.rate, skillName: skill.name };
      }
      acc[sanitizedSkillName].maxRate = Math.max(acc[sanitizedSkillName].maxRate, skill.rate);

      return acc;
    }, {});

    const skillsByRate = Object.values(skillsByName).reduce<CollectiveSkillsByRate>((acc, { maxRate, skillName }) => {
      if (!acc[maxRate]) {
        acc[maxRate] = { skills: [] };
      }
      acc[maxRate].skills.push(skillName);
      return acc;
    }, {});

    setCollectiveSkills(skillsByRate);
  }, [teamCollectiveSkills]);

  const handleLoadMore = () => {
    setItemsToShow(itemsToShow + 6);
  };

  const save = async () => {
    try {
      const newTeamGeneratorData: TeamGeneratorDataRequest = {
        teamName: stepData.teamGeneratorData!.teamName,
        clientName: stepData.teamGeneratorData!.clientName,
        projectDescription: stepData.teamGeneratorData!.projectDescription,
        minNumOfRoles: stepData.teamGeneratorData!.minNumOfMembers,
        roles: stepData.teamGeneratorData!.roles,
        createdBy: loggedUser.id,
      };

      for (const role of newTeamGeneratorData.roles) {
        const employeeRole = stepData.teamGeneratorData?.employees.find((employee) => employee.roleName === role.roleName)!;
        role.employeesIds = employeeRole.roleSkillsEmployees.filter((employee) => employee.checked).map((employee) => employee.id);
      }

      await applyLoader(CompanyService.insertTeamGenerator(company.id, newTeamGeneratorData));
      showNotification({
        notificationType: NotificationType.Success,
        title: "Success",
        message: "Team generator created successfully!",
        displayTime: NOTIFICATION_DISPLAY_TIME,
      });

      stepData.teamGeneratorData = {
        teamName: "",
        clientName: "",
        projectDescription: "",
        minNumOfMembers: 0,
        roles: [],
        employees: [],
      };

      navigate(teamListRoute);
      nextStep(0, stepData);
    } catch (error) {
      showNotification({
        notificationType: NotificationType.Error,
        title: "Failed to create team generator",
        message: errorMessage(error),
        displayTime: NOTIFICATION_DISPLAY_TIME,
      });
    }
  };

  return (
    <>
      <div className="mt-2">
        <div className="mx-auto text-left ">
          <div className="mx-auto">
            <h2 className="text-2xl font-bold tracking-tight text-black sm:text-xl">Meet Your Team</h2>
            <p className="text-lg leading-8 text-gray-600">
              <b>{stepData.teamGeneratorData?.teamName}</b>: These are the individuals you've selected and that best suit the needs
              {stepData.teamGeneratorData?.clientName !== "" && (
                <>
                  {" "}
                  for <b>{stepData.teamGeneratorData?.clientName}</b>'s needs
                </>
              )}
              , based on their skill sets and compatibility as a team.
            </p>
          </div>
          <div className="mt-2">
            {hasSelectedEmployees && (
              <div className="px-4 sm:px-6 lg:px-8">
                <div className="flow-root">
                  <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                      <table className="min-w-full divide-y divide-gray-300">
                        <thead>
                          <tr>
                            <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                              Name
                            </th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                              Title
                            </th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                              Elephant
                            </th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                              Raven
                            </th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                              Owl
                            </th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                              Eagle
                            </th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                              Horse
                            </th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                              Wolverine
                            </th>
                          </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200 bg-white">
                          {stepData.teamGeneratorData?.employees.map((employee) =>
                            employee.roleSkillsEmployees
                              .filter((employee) => employee.checked)
                              .map((row) => (
                                <tr key={row.id}>
                                  <td className="whitespace-'no'wrap  text-sm sm:pl-0">
                                    <div className="flex items-center">
                                      <div className="h-6 w-6 flex-shrink-0">
                                        <Avatar
                                          imageUrl={row.profilePictureUrl}
                                          nameParts={[row.firstName!, row.lastName!]}
                                          sizeClasses="h-6 w-6 rounded-full"
                                          textClasses="text-sm"
                                        ></Avatar>
                                      </div>
                                      <div className="ml-3">
                                        <div className="font-medium capitalize text-gray-900">{fullName(row)}</div>
                                      </div>
                                    </div>
                                  </td>
                                  <td className="whitespace-'no'wrap px-3 py-5 text-sm text-gray-500">
                                    <div className="capitalize text-gray-900">{employee.roleName}</div>
                                  </td>
                                  <td className="text-center text-sm leading-6">
                                    {row.personality && row.personality["Elephant"] ? (
                                      <div className="flex items-center justify-center gap-x-2">
                                        <div
                                          className={`text-varla-working-${row.personality[
                                            "Elephant"
                                          ].toLowerCase()} flex-none rounded-full p-1`}
                                        >
                                          <div className="h-1.5 w-1.5 rounded-full bg-current" />
                                        </div>
                                      </div>
                                    ) : (
                                      "-"
                                    )}
                                  </td>
                                  <td className="text-center text-sm leading-6">
                                    {row.personality && row.personality["Raven"] ? (
                                      <div className="flex items-center justify-center gap-x-2">
                                        <div
                                          className={`text-varla-working-${row.personality[
                                            "Raven"
                                          ].toLowerCase()} flex-none rounded-full p-1`}
                                        >
                                          <div className="h-1.5 w-1.5 rounded-full bg-current" />
                                        </div>
                                      </div>
                                    ) : (
                                      "-"
                                    )}
                                  </td>
                                  <td className="text-center text-sm leading-6">
                                    {row.personality && row.personality["Owl"] ? (
                                      <div className="flex items-center justify-center gap-x-2">
                                        <div
                                          className={`text-varla-working-${row.personality[
                                            "Owl"
                                          ].toLowerCase()} flex-none rounded-full p-1`}
                                        >
                                          <div className="h-1.5 w-1.5 rounded-full bg-current" />
                                        </div>
                                      </div>
                                    ) : (
                                      "-"
                                    )}
                                  </td>
                                  <td className="text-center text-sm leading-6">
                                    {row.personality && row.personality["Eagle"] ? (
                                      <div className="flex items-center justify-center gap-x-2">
                                        <div
                                          className={`text-varla-working-${row.personality[
                                            "Eagle"
                                          ].toLowerCase()} flex-none rounded-full p-1`}
                                        >
                                          <div className="h-1.5 w-1.5 rounded-full bg-current" />
                                        </div>
                                      </div>
                                    ) : (
                                      "-"
                                    )}
                                  </td>
                                  <td className="text-center text-sm leading-6">
                                    {row.personality && row.personality["Horse"] ? (
                                      <div className="flex items-center justify-center gap-x-2">
                                        <div
                                          className={`text-varla-working-${row.personality[
                                            "Horse"
                                          ].toLowerCase()} flex-none rounded-full p-1`}
                                        >
                                          <div className="h-1.5 w-1.5 rounded-full bg-current" />
                                        </div>
                                      </div>
                                    ) : (
                                      "-"
                                    )}
                                  </td>
                                  <td className="text-center text-sm leading-6">
                                    {row.personality && row.personality["Wolverine"] ? (
                                      <div className="flex items-center justify-center gap-x-2">
                                        <div
                                          className={`text-varla-working-${row.personality[
                                            "Wolverine"
                                          ].toLowerCase()} flex-none rounded-full p-1`}
                                        >
                                          <div className="h-1.5 w-1.5 rounded-full bg-current" />
                                        </div>
                                      </div>
                                    ) : (
                                      "-"
                                    )}
                                  </td>
                                </tr>
                              ))
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {Object.keys(collectiveSkills).length > 0 && (
              <div className="mt-2 text-center">
                <h4 className="text-2xl font-bold tracking-tight text-black">Collective Skills</h4>
                <div>
                  <div className="mt-2 flow-root">
                    <div className="isolate -mt-16 grid max-w-sm grid-cols-1 gap-y-16 divide-y divide-gray-100 sm:mx-auto lg:-mx-8 lg:mt-0 lg:max-w-none lg:grid-cols-3 lg:divide-x lg:divide-y-0 xl:-mx-4">
                      {Object.keys(collectiveSkills).map((rate, index) => (
                        <div key={index} className="pt-16 lg:px-8 lg:pt-0 xl:px-14">
                          <h3 className="text-base font-semibold leading-7 text-gray-900">
                            {skillTranslate.numericToName(parseInt(rate))}
                          </h3>
                          <ul data-testid="rate" className="mt-6 space-y-3 text-left text-sm leading-6 text-gray-600">
                            {collectiveSkills[parseInt(rate, 10)].skills.map(
                              (skill, index) =>
                                index < itemsToShow && (
                                  <li key={index} className="flex gap-x-3">
                                    <CheckCircleIcon className="h-6 w-5 flex-none text-violet-600" aria-hidden="true" />
                                    <span className="text-sm capitalize">{skill}</span>
                                  </li>
                                )
                            )}
                          </ul>
                        </div>
                      ))}
                    </div>
                  </div>
                  <br />
                  <button
                    type="button"
                    data-testid="load-more-button"
                    onClick={handleLoadMore}
                    className="mt-5 rounded-md bg-gray-300 px-3 py-2 text-sm font-semibold text-slate-600 shadow-sm hover:bg-slate-400 hover:text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-violet-500"
                  >
                    Load More...
                  </button>
                </div>
              </div>
            )}

            <div className={`flex h-fit min-h-full justify-end space-x-4`}>
              <div className="mt-2">
                <button
                  data-testid="save-button"
                  type="button"
                  className="inline-flex items-center justify-center rounded-md bg-violet-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-violet-500"
                  onClick={save}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StepPreviewPage;
