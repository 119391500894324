import { yupResolver } from "@hookform/resolvers/yup";
import { NotificationType, useAppContext } from "AppContext";
import UserSkillsService from "api/UserSkillsService";
import { useLoader } from "hooks/useLoader";
import { useUserProfileContext } from "pages/home/user-profile/UserProfileContext";
import { useEffect } from "react";
import { confirmAlert } from "react-confirm-alert";
import { useForm } from "react-hook-form";
import { CrudModalProps } from "types/CrudModalProps.types";
import { UserSkillFormType, UserSkillType } from "types/UserSkill.types";
import { NOTIFICATION_DISPLAY_TIME } from "utils/constants";
import { errorMessage } from "utils/errors";
import { skillTranslate } from "utils/skill-rating-translate";
import { sameStrings } from "utils/string-compare";
import { UserSkillValidate } from "utils/validateForm";
import Modal, { ModalAction } from "./Modal";

export type UserSkillModalProps = CrudModalProps<UserSkillType> & {
  profile: {
    userId: number;
    fullName: string;
  };
};

const UserSkillModal = ({ show, onClose, profile, interaction: { data, action } }: UserSkillModalProps): JSX.Element => {
  const {
    session: {
      loggedUser: { id: loggedUserId },
    },
    notification: { showNotification },
  } = useAppContext();
  const { userSkills } = useUserProfileContext();
  const USER_SKILL_EDITED_SUCCESSFULLY = "User Skill Edited Successfully";
  const USER_SKILL_ADDED_SUCCESSFULLY = "User Skill Added Successfully";
  const USER_SKILL_REMOVED_SUCCESSFULLY = "User Skill Removed Successfully";
  const CONFIRMATION_TITLE = "Remove User Skill";
  const CONFIRMATION_MESSAGE = "Are you sure you want to remove this user skill?";
  const CONFIRMATION_ACTION = "Remove";
  const { dataSkill, dataRate, ratingLevel, modalTitle, modalBody, skillNameClassNames, actionButtonsContainerClassNames } = data
    ? {
        dataSkill: data.skill,
        dataRate: action === ModalAction.Add ? "" : skillTranslate.numericToInteger(data.rate),
        ratingLevel: action === ModalAction.Add ? "Select A Rating" : skillTranslate.numericToName(data.rate),
        modalTitle: `${action === ModalAction.Add ? "Add" : "Edit"} skill rate`,
        modalBody: "Please select a new rate for the selected skill",
        skillNameClassNames: "disabled bg-gray-200 opacity-50",
        actionButtonsContainerClassNames: action === ModalAction.Add ? "justify-end" : "justify-between",
      }
    : {
        dataSkill: undefined,
        dataRate: "",
        ratingLevel: "Select A Rating",
        modalTitle: "Add a new skill",
        modalBody: `Add a new skill to ${profile.userId === loggedUserId ? "your" : `${profile.fullName}'s`} profile!`,
        skillNameClassNames: "focus:ring-2 focus:ring-inset focus:ring-violet-600",
        actionButtonsContainerClassNames: "justify-end",
      };

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
  } = useForm<UserSkillFormType>({
    resolver: yupResolver(UserSkillValidate),
  });

  const { applyLoader } = useLoader();

  useEffect(() => {
    reset({ ratedBy: loggedUserId });
  }, [loggedUserId, show, reset]);

  const onSubmit = handleSubmit(async (formData) => {
    try {
      const formUserSkill: UserSkillFormType = { ...formData, rate: Number(formData["rate"]), userSkillId: data?.userSkillId };
      if (action === ModalAction.Add && profile.userId === loggedUserId) {
        const existingSkill = userSkills.find((skill) => sameStrings(skill.skill, formUserSkill.skillName));
        if (existingSkill !== undefined) {
          showNotification({
            notificationType: NotificationType.Error,
            title: "Error",
            message: `You’ve already added that skill to your profile.`,
            displayTime: NOTIFICATION_DISPLAY_TIME,
          });
          return;
        }
      }

      const { successMessage, userSkill } =
        action === ModalAction.Add
          ? {
              successMessage: USER_SKILL_ADDED_SUCCESSFULLY,
              userSkill: (await applyLoader(UserSkillsService.rateSkill(formUserSkill, profile.userId))).data,
            }
          : {
              successMessage: USER_SKILL_EDITED_SUCCESSFULLY,
              userSkill: (await applyLoader(UserSkillsService.updateSkill(formUserSkill, profile.userId))).data,
            };
      showNotification({
        notificationType: NotificationType.Success,
        title: "Success",
        message: successMessage,
        displayTime: NOTIFICATION_DISPLAY_TIME,
      });
      onClose({ action, data: userSkill });
    } catch (error) {
      showNotification({
        notificationType: NotificationType.Error,
        title: "Error",
        message: errorMessage(error),
        displayTime: NOTIFICATION_DISPLAY_TIME,
      });
      onClose();
    }
  });

  const deleteUserSkill = async (userSkill: UserSkillType) => {
    try {
      await applyLoader(UserSkillsService.removeSkill(userSkill.userSkillId, profile.userId));
      showNotification({
        notificationType: NotificationType.Success,
        title: "Success",
        message: USER_SKILL_REMOVED_SUCCESSFULLY,
        displayTime: NOTIFICATION_DISPLAY_TIME,
      });
      onClose({ action: ModalAction.Remove, data: userSkill });
    } catch (error) {
      showNotification({
        notificationType: NotificationType.Error,
        title: "Error",
        message: errorMessage(error),
        displayTime: NOTIFICATION_DISPLAY_TIME,
      });
      onClose();
    }
  };

  const handleRemove = (userSkill: UserSkillType) => {
    confirmAlert({
      customUI: ({ onClose: closeConfirmationDialog }) => {
        return (
          <div className="bg-white shadow sm:rounded-lg">
            <div className="px-4 py-5 sm:p-6">
              <h3 className="text-base font-semibold leading-6 text-gray-900">{CONFIRMATION_TITLE}</h3>
              <div className="mt-2 max-w-xl text-sm text-gray-500">
                <p>{CONFIRMATION_MESSAGE}</p>
              </div>
              <div className="flex h-fit min-h-full justify-end space-x-4 px-3 pt-6">
                <button
                  data-testid="remove-confirmation"
                  onClick={() => {
                    deleteUserSkill(userSkill);
                    closeConfirmationDialog();
                  }}
                  type="button"
                  className="inline-flex items-center rounded-md bg-violet-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-violet-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-violet-500"
                >
                  {CONFIRMATION_ACTION}
                </button>
                <button
                  onClick={closeConfirmationDialog}
                  type="button"
                  className="mr-3 rounded-md border-2 px-3 py-2 text-sm font-semibold text-black shadow-sm hover:bg-gray-200"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        );
      },
      overlayClassName: "react-confirm-alert-overlay",
    });
  };

  const RemoveButton = () =>
    action === ModalAction.Edit ? (
      <button
        type="button"
        onClick={() => handleRemove(data!)}
        className="inline-flex items-center justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500"
      >
        Remove
      </button>
    ) : null;

  return (
    <Modal show={show} onClose={onClose} title={modalTitle}>
      <div className="mt-2">
        <p className="block text-sm text-gray-500 ">{modalBody}</p>
        <form onSubmit={onSubmit} className="space-y-2">
          <div className="w-full py-4">
            <label htmlFor="skillName" className="block text-sm font-medium leading-6 text-gray-900">
              Skill
            </label>
            <input
              autoComplete="off"
              id="skillName"
              {...register("skillName")}
              type="text"
              value={dataSkill}
              className={`${skillNameClassNames} block w-full rounded-md border-0 py-1.5 capitalize text-gray-900 ring-1 ring-inset ring-gray-300 sm:text-sm sm:leading-6`}
            />
            {errors?.skillName && <div className="mt-2 block w-full py-1 text-sm text-red-700">{errors.skillName.message}</div>}
          </div>
          <div className="w-full">
            <label htmlFor="rate" className="block text-sm font-medium leading-6 text-gray-900">
              Proficiency Rating
            </label>
            <select
              id="rate"
              {...register("rate")}
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-violet-600 sm:text-sm sm:leading-6"
            >
              <option value={dataRate}>{ratingLevel}</option>
              {skillTranslate.nameIntegerValues.map(({ name, value }) => (
                <option key={value} value={value}>
                  {name}
                </option>
              ))}
            </select>
            {errors?.rate && <div className="mt-2 block w-full py-1 text-sm text-red-700">{errors.rate.message}</div>}
          </div>
          <div className={`flex h-fit min-h-full ${actionButtonsContainerClassNames} space-x-4`}>
            <RemoveButton />
            <div className="mt-2">
              <button
                type="button"
                className="mr-3 rounded-md border-2 px-3 py-2 text-sm font-semibold text-black shadow-sm hover:bg-gray-200"
                onClick={() => onClose()}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="inline-flex items-center justify-center rounded-md bg-violet-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-violet-500"
                disabled={isSubmitting}
              >
                Save
              </button>
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default UserSkillModal;
