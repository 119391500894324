import { Disclosure } from "@headlessui/react";
import { MinusSmallIcon, PlusSmallIcon } from "@heroicons/react/20/solid";

const faqs = [
  {
    question: `How to Access the Team Generator feautre.`,
    answer: `<ol>
        <li><b>Access Your Companies List:</b> Start by logging into your account and navigating to your companies list. This is typically found in the navigation menu or a dedicated section for company management.
        </li><li><b>Select the Company:</b> From your list of companies, choose the specific company for which you want to generate a team. Click on the company's name to access its dashboard.
        </li><li><b>Find the "Team Generator" Link:</b> Once you are on the desired company's dashboard, look for the "Team Generator" link or button. This link is usually placed in a prominent location within the company's dashboard, such as in the navigation menu or as a featured option.
        </li><li><b>Click on "Team Generator":</b> Click on the "Team Generator" link. This action will redirect you to the team generator page, where you can start creating your team.
        </li></ol>`,
  },
  {
    question: `How to generate a New Team.`,
    answer: `  <p class="text-violet-500"><strong>Step 1: Team Details</strong></p>
        <ol>
          <li>
            <p>
              <strong>Access the Team Generator Page</strong>: Start by going to the
              Team Generator Page within the platform or application.
            </p>
          </li>
          <li>
            <p>
              <strong>Enter Team Details</strong>: On the Team Generator Page, you'll
              first be prompted to provide basic information for your team:
            </p>
            <ul>
              <li>
                <strong>Team Name</strong>: Enter a name for your team (e.g., "Project
                X Team").
              </li>
              <li>
                <strong>Client's Name</strong>: Specify the name of the client or
                project this team will be working with.
              </li>
              <li>
                <strong>Brief Description</strong>: Provide a brief description of
                what the team will focus on. For example, "This team will focus on
                developing a marketing campaign for Client ABC."
              </li>
              <li>
                <strong>Select Number of Roles</strong>: Choose the number of roles
                you want to have in this team (e.g., 5 roles).
              </li>
            </ul>
          </li>
        </ol>
        
        <p class="pt-4 text-violet-500">
          <strong>Step 2: Team Composition</strong>
          </p> 
          <ol start="3"><li>
          <strong>AI-Powered Role Suggestions</strong>: After entering the basic team
          information, the platform will use AI to suggest a list of roles required to
          form this team based on the provided details.</li>
          <li>
            <strong>Manually Add Roles (Optional)</strong>: If you wish to add
            additional roles beyond the AI suggestions, you can do so by clicking the
            "Add Roles" button. This allows you to customize the team's composition
            further.
          </li>
        </ol>
        <p class="pt-4 text-violet-500">
          <strong>Step 3: Team Members</strong></p>
          <ol start="5">
          <li><strong>AI-Generated Role Matches</strong>: Once you've selected the roles
          for your team, the platform's powerful algorithm will suggest the best match
          for each role from your company's database. These suggestions are based on
          the skills and qualifications of existing employees or members.
        </li></ol>
        <p class="pt-4 text-violet-500">
          <strong>Step 4: Preview</strong></p>
          <ol start="6"><li><strong>Review the Team</strong>:
          Take a close look at the team that has been generated. You'll see a summary
          of team members, including their names, roles, and any additional
          information that is available.
        </li>
        
          <li>
            <p>
              <strong>Personality Types</strong>: The platform may also provide
              insights into the personality types of team members, giving you an idea
              of the team's overall personality dynamics.
            </p>
          </li>
          <li>
            <p>
              <strong>Collective Skills</strong>: Additionally, you'll have access to
              information about the collective skills of the team, helping you
              understand the strengths and capabilities of the team as a whole.
            </p>
          </li>
          <li>
            <p>
              <strong>Finalize the Team</strong>: If you're satisfied with the
              generated team and its details, you can proceed to finalize and save the
              team configuration.
            </p>
          </li>
        </ol>
        <br/>
        <p>
          By following these steps, you'll be able to create a well-defined and
          balanced team for your specific project or client, leveraging AI-powered
          suggestions and insights to optimize team composition and performance.
        </p>`,
  },
];

export const GuideTeamGeneratorPage: React.FC = () => {
  return (
    <>
      {faqs.map((faq) => (
        <Disclosure as="div" key={faq.question} className="pb-6">
          {({ open }) => (
            <>
              <dt>
                <Disclosure.Button className="flex w-full items-start justify-between rounded-md p-2 text-left text-gray-900 hover:bg-violet-50">
                  <span className="text-base font-semibold leading-7">{faq.question}</span>
                  <span className="ml-6 flex h-7 items-center">
                    {open ? (
                      <MinusSmallIcon className="h-6 w-6" aria-hidden="true" />
                    ) : (
                      <PlusSmallIcon className="h-6 w-6" aria-hidden="true" />
                    )}
                  </span>
                </Disclosure.Button>
              </dt>
              <Disclosure.Panel as="dd" className="mt-2 p-2 pl-12 pr-12">
                <p className="text-base leading-7 text-gray-600" dangerouslySetInnerHTML={{ __html: faq.answer }}></p>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      ))}
    </>
  );
};

export default GuideTeamGeneratorPage;
