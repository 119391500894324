import { FieldError } from "react-hook-form";
import { Combobox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { Fragment, useState } from "react";
import { classNames } from "utils/helpers";

type NewOptionProps = {
  query: string;
  options: Array<any>;
  disableCreation: boolean;
};

const NewOption = (props: NewOptionProps) => {
  if (props.query.length === 0) {
    return <></>;
  }

  if (!props.query.match(/^[A-Za-z0-9\s]+$/)) {
    return <div className="relative cursor-default select-none px-4 py-2 text-gray-700">Should not contain special characters</div>;
  }

  if (props.options.length === 0 && !props.disableCreation) {
    return (
      <Combobox.Option value={props.query}>
        <div className="relative cursor-default select-none px-4 py-2 text-gray-700">Create "{props.query}"</div>
      </Combobox.Option>
    );
  }

  return <></>;
};

export type Option = {
  id: string;
  value: string;
};

type Props = {
  options: Option[];
  selected?: string;
  fieldKey: string;
  onChange: Function;
  error?: FieldError;
  disableCreation?: boolean;
};

const AutoCompleteCombobox = (props: Props): JSX.Element => {
  const { selected, onChange, error, disableCreation = false } = props;
  const [query, setQuery] = useState("");

  const filteredOptions =
    query === ""
      ? props.options
      : props.options.filter((option: Option) => {
          return option.value.toLowerCase().replace(/\s+/g, "").includes(query.toLowerCase().replace(/\s+/g, ""));
        });

  return (
    <Combobox value={selected} onChange={(option) => onChange(option, props.fieldKey)}>
      <div className="relative mt-1">
        <div className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-violet-600 sm:text-sm sm:leading-6">
          <Combobox.Input
            autoComplete="off"
            className="w-full border-none py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0"
            displayValue={(option: string) => option}
            onChange={(event) => {
              setQuery(event.target.value);
              onChange(event.target.value, props.fieldKey);
            }}
          />
          <Combobox.Button aria-label="dropdown-button" className="absolute inset-y-0 right-0 flex items-center pr-2">
            <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
          </Combobox.Button>
        </div>
        {error && <div className="mt-2 block w-full py-1 text-sm text-red-700">{error.message}</div>}
        <Transition as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
          <Combobox.Options className="absolute z-20 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            <NewOption query={query} options={filteredOptions} disableCreation={disableCreation} />
            {filteredOptions?.map((option) => (
              <Combobox.Option
                key={option.id}
                className={({ active }) =>
                  classNames(active ? "bg-violet-600 text-white" : "text-gray-900", "relative cursor-default select-none py-2 pl-10 pr-4")
                }
                value={option.value}
              >
                {({ selected, active }) => (
                  <>
                    <span className={`block truncate ${selected ? "font-medium" : "font-normal"}`}>{option.value}</span>
                    {selected ? (
                      <span className={`absolute inset-y-0 left-0 flex items-center pl-3 ${active ? "text-white" : "text-violet-600"}`}>
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    ) : null}
                  </>
                )}
              </Combobox.Option>
            ))}
          </Combobox.Options>
        </Transition>
      </div>
    </Combobox>
  );
};

export default AutoCompleteCombobox;
