import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AppContext from "./AppContext";
import { AppRouter } from "./AppRouter";
import { Notification } from "./components/Notification/Notification";
import { instance } from "api/base.api";
import { Loader } from "./components";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const App: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const {
    session: { setLoggedUser },
  } = useContext(AppContext);
  const queryClient = new QueryClient();

  useEffect(() => {
    const varlaLoginToken = localStorage.getItem("varlaLoginToken");
    if (varlaLoginToken) {
      const fetchRefreshToken = async (): Promise<void> => {
        try {
          const response = await instance.post("auth/token-login", {}, { headers: { varlaLoginToken } });
          if (response) {
            localStorage.setItem("varlaLoginToken", response.headers.varlalogintoken);
            setLoggedUser(response.data);
          }
          setLoading(false);
        } catch (error) {
          navigate("/login");
          setLoading(false);
        }
      };
      fetchRefreshToken();
    } else {
      setLoading(false);
    }
  }, [setLoggedUser, setLoading, navigate]);

  if (loading) {
    return <div>Loading...</div>;
  }
  return (
    <>
      <Loader />
      <QueryClientProvider client={queryClient}>
        <AppRouter />
      </QueryClientProvider>
      <Notification />
    </>
  );
};

export default App;
