import { Disclosure } from "@headlessui/react";
import { MinusSmallIcon, PlusSmallIcon } from "@heroicons/react/20/solid";

const faqs = [
  {
    question: "How to Access the Job Description Generator feautre.",
    answer: `<ol>
            <li><b>Access Your Companies List:</b> Start by logging into your account and navigating to your companies list. This is typically found in the navigation menu or a dedicated section for company management.
            </li><li><b>Select the Company:</b> From your list of companies, choose the specific company for which you want to generate a team. Click on the company's name to access its dashboard.
            </li><li><b>Find the "Job Description" Link:</b> Once you are on the desired company's dashboard, look for the "Job Description Generator" link or button. This link is usually placed in a prominent location within the company's dashboard, such as in the navigation menu or as a featured option.
            </li><li><b>Click on "Job Description":</b> Click on the "Job Description" link. This action will redirect you to the Job Description page, where you can find a button that will redirect you to a page where start creating your Job Description.
            </li></ol>`,
  },
  {
    question: `How to Generate a Job Description`,
    answer: `
    <p class="text-violet-500"><strong>Step 1: Define the Job Role and Skills</strong></p>
    <ol>
      <li>
        <p>
          <strong>Access the Job Description Generator</strong>: Start by
          accessing the Job Description Generator tool within the platform or
          application.
        </p>
      </li>
      <li>
        <p>
          <strong>Select or Input the Job Role</strong>: Begin by selecting the
          job role from a dropdown menu or start typing to find and select it. For
          example, you might choose "Software Developer."
        </p>
      </li>
      <li>
        <p>
          <strong>List Required Skills</strong>: In the designated field, enter a
          list of skills required for this role, separated by commas. These skills
          should be relevant to the job. For example:
        </p>
        <ul>
          <li>Programming languages: Java, Python, C++</li>
          <li>Web development: HTML, CSS, JavaScript</li>
          <li>Database management: SQL, MongoDB</li>
        </ul>
      </li>
    </ol>
    <p class="text-violet-500"><strong>Step 2: Generate Job Description</strong></p>
    <ol start="4">
      <li>
        <p>
          <strong>AI-Generated Job Description</strong>: Once you've specified the
          job role and skills, the platform will harness the power of AI to
          generate a complete job description for you.
        </p>
      </li>
      <li>
        <p>
          <strong>Modify and Format</strong>: You will have the opportunity to
          modify and format the generated job description using rich text boxes.
          Tailor the description to fit your organization's specific needs and
          requirements. You can add details about the company, location, benefits,
          and any other relevant information.
        </p>
      </li>
    </ol>
    <p class="text-violet-500"><strong>Step 3: Review and Save</strong></p>
    <ol start="6">
      <li>
        <p>
          <strong>Preview Job Description</strong>: Review the generated job
          description carefully to ensure it accurately reflects the role and
          requirements. Make any necessary adjustments to align it with your
          company's preferences.
        </p>
      </li>
      <li>
        <p>
          <strong>Store Job Description</strong>: If you are satisfied with the
          job description, you can choose to save it within the platform for
          future use. This allows you to easily access and reuse job descriptions
          when needed.
        </p>
      </li>
    </ol>
    <p>
      By following these steps, you can efficiently generate, customize, and store
      job descriptions, streamlining the hiring process and ensuring that each job
      posting accurately reflects your organization's needs and expectations.
    </p>`,
  },
];

export const GuideJobDescriptionPage: React.FC = () => {
  return (
    <>
      {faqs.map((faq) => (
        <Disclosure as="div" key={faq.question} className="pb-6">
          {({ open }) => (
            <>
              <dt>
                <Disclosure.Button className="flex w-full items-start justify-between rounded-md p-2 text-left text-gray-900 hover:bg-violet-50">
                  <span className="text-base font-semibold leading-7">{faq.question}</span>
                  <span className="ml-6 flex h-7 items-center">
                    {open ? (
                      <MinusSmallIcon className="h-6 w-6" aria-hidden="true" />
                    ) : (
                      <PlusSmallIcon className="h-6 w-6" aria-hidden="true" />
                    )}
                  </span>
                </Disclosure.Button>
              </dt>
              <Disclosure.Panel as="dd" className="mt-2 p-2 pl-12 pr-12">
                <p className="text-base leading-7 text-gray-600" dangerouslySetInnerHTML={{ __html: faq.answer }}></p>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      ))}
    </>
  );
};

export default GuideJobDescriptionPage;
