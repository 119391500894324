import { useEffect, useState } from "react";

type UseMaxLengthProps = {
  inputRef: React.RefObject<HTMLInputElement | HTMLTextAreaElement>;
  maxLength: number;
};

export const useMaxLength = ({ inputRef, maxLength }: UseMaxLengthProps) => {
  const [maxReached, setMaxReached] = useState(false);

  useEffect(() => {
    const element = inputRef.current;
    if (element instanceof HTMLInputElement || element instanceof HTMLTextAreaElement) {
      const handleInput = () => {
        const { value } = element;
        setMaxReached(value.length === maxLength);
      };

      element.addEventListener("input", handleInput);

      return () => {
        element.removeEventListener("input", handleInput);
      };
    }
  }, [inputRef, maxLength]);

  return maxReached ? `Must not exceed ${maxLength} characters.` : "";
};
