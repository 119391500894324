import { ArrowRightCircleIcon, ChevronLeftIcon, ChevronRightIcon, TrashIcon, UserPlusIcon } from "@heroicons/react/24/solid";
import { useCallback, useEffect, useState } from "react";
import { useAppContext } from "AppContext";
import { extractRolesFromContent, teamSuggestedRoleFromContent } from "api/TeamGeneratorService";
import AddRoleModal from "components/Modal/AddRoleModal";
import { ModalAction, ModalOnCloseFunction, handleModalOnCloseResult } from "components/Modal/Modal";
import { ENV_VARS } from "../../../../../../env";
import { useLoader } from "hooks/useLoader";
import { LIST_NUMBER_REGEX } from "utils/constants";
import { TeamRole } from "utils/form-validations/team-generator/teamDetailsForm";
import { sort } from "pages/shared/TableFunctions";

export const StepTeamCompositionPage: React.FC<{}> = () => {
  const { applyLoader } = useLoader();
  const {
    paginator: { nextStep, stepData },
  } = useAppContext();
  const [showAddRole, setShowAddRole] = useState(false);
  const currentRoles = JSON.parse(JSON.stringify(stepData.teamGeneratorData?.roles));
  const [roleList, setRoleList] = useState<Array<TeamRole>>(currentRoles);

  useEffect(() => {
    const controller = new AbortController();
    /* istanbul ignore next */
    const streamSuggestedTeamRoles = async (minNumOfMembers: number, projectDescription: string) => {
      const lambdaStreamUrl = ENV_VARS.StreamTeamRolesSuggestions;
      const { body: streamResponse } = await fetch(lambdaStreamUrl, {
        method: "POST",
        body: JSON.stringify({ minNumOfMembers, projectDescription }),
        signal: controller.signal,
      });
      if (!streamResponse) {
        return;
      }
      const reader = streamResponse.getReader();
      const decoder = new TextDecoder();
      let done = false;
      let content = "";
      while (!done) {
        const { value, done: doneReading } = await reader.read();
        done = doneReading;
        const chunkValue = decoder.decode(value);
        content += chunkValue;
        const { completedRoles, incompleteContent: incomplete } = extractRolesFromContent(content);
        for (let suggestedRole of completedRoles) {
          suggestedRole = suggestedRole.replace(LIST_NUMBER_REGEX, "").trim();
          const newRole = teamSuggestedRoleFromContent(suggestedRole);
          setRoleList((current) => sort([...current, newRole], "roleName", 1));
          stepData.teamGeneratorData?.roles.push(newRole);
        }
        content = incomplete;
      }
      if (content.trim()) {
        content = content.replace(LIST_NUMBER_REGEX, "").trim();
        const newRole = teamSuggestedRoleFromContent(content);
        setRoleList((current) => sort([...current, newRole], "roleName", 1));
      }
    };
    if (!stepData.teamGeneratorData?.roles.length) {
      stepData.teamGeneratorData!.roles = [];
      applyLoader(streamSuggestedTeamRoles(stepData.teamGeneratorData!.minNumOfMembers, stepData.teamGeneratorData!.projectDescription));
    }
    return () => controller.abort();
  }, [stepData, applyLoader]);

  const decreaseButton = (index: number) => {
    if (roleList[index].amount === 1) return;
    const newRoles = [...roleList];
    newRoles[index].amount = newRoles[index].amount - 1;
    setRoleList(newRoles);
  };

  const increaseButton = (index: number) => {
    const newRoles = [...roleList];
    newRoles[index].amount = newRoles[index].amount + 1;
    setRoleList(newRoles);
  };

  const removeButton = (removeRole: TeamRole) => {
    const newRoles = roleList.filter((role) => role.roleName !== removeRole.roleName);
    setRoleList(newRoles);
  };

  const next = () => {
    if (roleList.length === 0) return;
    // TODO: move to step employees
    stepData.teamGeneratorData!.employees = [];
    stepData.teamGeneratorData!.roles = roleList;
    nextStep(2, stepData);
  };

  const addRole = useCallback<ModalOnCloseFunction<TeamRole>>((onCloseResult) => {
    handleModalOnCloseResult(onCloseResult, {
      [ModalAction.Add]: (newRole) => {
        setRoleList((current) => sort([...current, newRole], "roleName", 1));
      },
    });
    setShowAddRole(false);
  }, []);

  return (
    <>
      <div>
        <div className="mx-auto text-center ">
          <div className="mx-auto">
            <p className="text-md mt-2 leading-8 text-gray-600">
              To Create the team <b className="capitalize">{stepData.teamGeneratorData?.teamName}</b>
              {stepData.teamGeneratorData!.clientName.length > 0 && (
                <div className="inline-flex">
                  &nbsp;that will work with&nbsp;<b className="capitalize">{stepData.teamGeneratorData?.clientName}</b>
                </div>
              )}
              , I suggest the following roles and recomended number of people for each:
            </p>
          </div>
        </div>
      </div>
      <div className="mt-2">
        <div className="divide-y divide-gray-100 overflow-hidden bg-white ring-1 ring-gray-900/5 sm:rounded-xl">
          {roleList.map((role, index) => (
            <li key={role.roleName} className="relative flex justify-between gap-x-6 px-4 py-5 hover:bg-gray-50 sm:px-6">
              <div className="flex gap-x-4">
                <div className="flex-auto">
                  <span className="inline-flex h-14 w-14 items-center justify-center rounded-full bg-violet-500">
                    <span className="text-xl font-medium capitalize leading-none text-white">{role.roleName.charAt(0)}</span>
                  </span>
                </div>
                <div className="min-w-0 flex-auto">
                  <p className="text-sm font-semibold capitalize leading-6 text-gray-900">{role.roleName}</p>
                  <p className="mt-1 flex text-xs leading-5 text-gray-500">{role.description}</p>
                </div>
              </div>
              <div className="flex items-center">
                <div className="flex flex-row sm:items-end">
                  <button
                    data-testid={`decrease-button-${index}`}
                    className="items-center border-t-2 border-transparent text-sm font-medium text-gray-500"
                    onClick={() => decreaseButton(index)}
                  >
                    <ChevronLeftIcon className="h-5 w-5 flex-none text-gray-400" aria-hidden="true" />
                  </button>

                  <div className="mt-1 flex items-center gap-x-1.5">
                    <span className="inline-flex items-center gap-x-1.5 rounded-full bg-green-100 px-1.5 py-0.5 text-xs font-medium text-green-700">
                      <span className="text-green-500">x</span>
                      {role.amount}
                    </span>
                  </div>
                  <button
                    data-testid={`increase-button-${index}`}
                    className="items-center border-t-2 border-transparent text-sm font-medium text-gray-500"
                    onClick={() => increaseButton(index)}
                  >
                    <ChevronRightIcon className="h-5 w-5 flex-none text-gray-400" aria-hidden="true" />
                  </button>
                  <button
                    data-testid={`remove-button-${index}`}
                    className="items-center border-t-2 border-transparent text-sm font-medium text-gray-500"
                    onClick={() => removeButton(role)}
                  >
                    <TrashIcon className="ml-3 h-5 w-5 text-red-400" aria-hidden="true" />
                  </button>
                </div>
              </div>
            </li>
          ))}
          <li className="relative flex justify-center gap-x-6 px-4 py-5 hover:bg-gray-50 sm:px-6">
            <div className="flex gap-x-4">
              <button
                type="button"
                className="flex w-48 gap-x-2 rounded-md bg-lime-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-lime-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-lime-600"
                onClick={() => {
                  setShowAddRole(true);
                }}
              >
                <UserPlusIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
                Add Another Role
              </button>
              <AddRoleModal show={showAddRole} onClose={addRole} />
            </div>
          </li>
        </div>
        <div className="mt-8 flex flex-row-reverse items-end justify-between space-x-3 border-t border-gray-200 px-2 py-2 sm:px-3">
          <button
            data-testid="next-step"
            className="flex rounded-md bg-violet-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-violet-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-violet-500"
            onClick={() => next()}
          >
            <span className="flex">
              Next step
              <ArrowRightCircleIcon className="ml-2 h-5 w-5" aria-hidden="true" />
            </span>
          </button>
        </div>
      </div>
    </>
  );
};

export default StepTeamCompositionPage;
