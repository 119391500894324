import { yupResolver } from "@hookform/resolvers/yup";
import { useContext } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import AppContext, { NotificationType } from "AppContext";
import AuthService from "api/AuthService";
import { useLoader } from "hooks/useLoader";
import { ResetPasswordRequestType } from "types/ResetPassword.types";
import { NOTIFICATION_DISPLAY_TIME, NOTIFICATION_DISPLAY_TIME_LONG } from "utils/constants";
import { PasswordResetRequestValidate } from "utils/validateForm";

const Logo = "/images/v2.png";

export const ResetPasswordRequestPage: React.FC<{}> = () => {
  const navigate = useNavigate();
  const {
    notification: { showNotification },
  } = useContext(AppContext);
  const { applyLoader } = useLoader();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ResetPasswordRequestType>({
    resolver: yupResolver(PasswordResetRequestValidate),
  });

  const onSubmit = handleSubmit(async (formData) => {
    try {
      await applyLoader(AuthService.resetPasswordRequest(formData.email));
      showNotification({
        notificationType: NotificationType.Success,
        title: "Reset password email sent.",
        message: `If a Varla account exists for ${formData.email}, we'll send an email with instructions on how to reset your password.`,
        displayTime: NOTIFICATION_DISPLAY_TIME_LONG,
      });
      navigate("/login");
    } catch (error) {
      showNotification({
        notificationType: NotificationType.Error,
        title: "Error sending reset password email.",
        message: error.message,
        displayTime: NOTIFICATION_DISPLAY_TIME,
      });
    }
  });

  return (
    <div className="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <img className="mx-auto h-12 w-auto" src={Logo} alt="Varla" />
        <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">Reset your password</h2>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white px-4 py-8 shadow sm:rounded-lg sm:px-10">
          <form onSubmit={onSubmit} className="space-y-6">
            <div>
              <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                Enter your email address below and we'll send you a link with instructions.
              </label>
              <div className="mt-2">
                <input
                  id="email"
                  {...register("email")}
                  type="text"
                  className="block w-full rounded-md border-0 py-1.5 lowercase text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-violet-600 sm:text-sm sm:leading-6"
                />
                {errors?.email && <div className="mt-2 block w-full py-1 text-sm text-red-700">{errors.email.message}</div>}
              </div>
            </div>
            <div>
              <button
                type="submit"
                className="flex w-full justify-center rounded-md bg-violet-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-violet-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-violet-600"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ResetPasswordRequestPage;
