import { APIListFilters, APIResult } from "types/Api.types";
import { instance } from "./base.api";
import {
  Company,
  CompanyApi,
  Employee,
  CompanyEmployeeFormType,
  UserCompany,
  TeamGeneratorDataRequest,
  ListUserCompanyFilters,
} from "types/Company.types";
import { AxiosResponse } from "axios";
import { HttpStatusCode } from "axios";
import { CompanyProfileForm } from "utils/form-validations/companyProfileForm";
import { formPatchDocument } from "utils/form-patch-document";
import { JobDescriptionListAPI } from "types/JobDescription.types";
import { Team } from "types/Team.types";
import { RoleWithSkillsType } from "types/Role.types";
import { CompanyEmployee, MatchingRoleEmployee } from "types/User.types";
import { JobPostingListAPI } from "types/JobPosting.types";

export const listUserCompanies = async (userId: Number, params: ListUserCompanyFilters): Promise<APIResult<Array<UserCompany>>> => {
  try {
    let { data } = await instance.get(`users/${userId}/companies`, {
      params,
    });
    // TODO: remove when backend is ready
    data = data.reduce((result: any, item: any) => {
      const existingItem = result.find((i: any) => i.id === item.id);
      if (existingItem) {
        if (item.accessLevel) {
          existingItem.accessLevel.push(item.accessLevel);
        }
      } else {
        result.push({
          ...item,
          accessLevel: item.accessLevel ? [item.accessLevel] : [],
        });
      }

      return result;
    }, []);
    data = data.map(_formatCompanyApi);
    return { data, error: null };
  } catch (error) {
    const status = error.response?.status;
    let errorMessage = "Something went wrong. please try again";
    return { data: null, error: { message: errorMessage, code: status } };
  }
};

export const listCompanies = async (): Promise<APIResult<Array<Company>>> => {
  try {
    let { data } = await instance.get("companies");
    data = data.map(_formatCompanyApi);
    return { data, error: null };
  } catch (error) {
    const status = error.response?.status;
    let errorMessage = "Something went wrong. please try again";
    return { data: null, error: { message: errorMessage, code: status } };
  }
};

export const listTeams = async (companyId: Number): Promise<AxiosResponse<Array<Team>>> => {
  return instance.get(`companies/${companyId}/teams`);
};

export const removeEmployee = async (companyId: number, employeeId: number): Promise<AxiosResponse> => {
  return instance.delete(`companies/${companyId}/employees/${employeeId}`);
};

const _formatCompanyApi = (company: CompanyApi) => {
  return {
    ...company,
    createdAt: new Date(company.createdAt),
    updatedAt: new Date(company.updatedAt),
  };
};

export const insertProfile = async (userId: Number, params: CompanyProfileForm): Promise<AxiosResponse> => {
  const patchDocument = {
    userId: userId,
    name: params.name,
    website: params.website,
    industryId: params.industryId,
    about: params.about,
    logoUrl: params.logoUrl,
    bannerUrl: params.bannerUrl,
    publicUrl: params.publicUrl,
  };
  return instance.post("companies", { patchDocument });
};

export const getCompanyDashboard = async (companyId: Number): Promise<APIResult<Company>> => {
  try {
    const { data } = await instance.get(`companies/${companyId}/dashboard`);
    return { data, error: null };
  } catch (error) {
    const status = error.response?.status;
    let errorMessage = "Something went wrong. please try again";
    if (status === HttpStatusCode.NotFound) {
      errorMessage = "Company does not exist";
    }

    return { data: null, error: { message: errorMessage, code: status } };
  }
};

export const insertCompanyEmployee = async (params: CompanyEmployeeFormType): Promise<AxiosResponse> => {
  return instance.post(`companies/${params.companyId}/employees`, params);
};

export const getCompanyEmployees = async (companyId: Number): Promise<APIResult<Array<Employee>>> => {
  try {
    const { data } = await instance.get(`companies/${companyId}/employees`);
    return { data, error: null };
  } catch (error) {
    const status = error.response?.status;
    let errorMessage = "Something went wrong. please try again";
    return { data: null, error: { message: errorMessage, code: status } };
  }
};

export const updateCompanyProfile = (companyId: number, params: CompanyProfileForm): Promise<AxiosResponse> => {
  const patchDocument = formPatchDocument({
    name: params.name,
    website: params.website,
    about: params.about,
    industryId: params.industryId,
    logoUrl: params.logoUrl,
    bannerUrl: params.bannerUrl,
  });

  return instance.patch(`companies/${companyId}`, {
    patchDocument,
  });
};

export const listJobDescriptions = async (
  companyId: string | undefined,
  filters: APIListFilters
): Promise<APIResult<JobDescriptionListAPI>> => {
  try {
    let { data } = await instance.get(`companies/${companyId}/job-descriptions`, {
      params: filters,
    });
    return { data, error: null };
  } catch (error) {
    const status = error.response?.status;
    let errorMessage = "Something went wrong. please try again";
    return { data: null, error: { message: errorMessage, code: status } };
  }
};

export const insertTeamGenerator = async (companyId: number, teamGeneratorData: TeamGeneratorDataRequest): Promise<AxiosResponse> => {
  return instance.post(`companies/${companyId}/teams`, teamGeneratorData);
};

export const employeesFilteredByRoleSkills = async (
  companyId: number,
  params: Array<RoleWithSkillsType>
): Promise<AxiosResponse<MatchingRoleEmployee[]>> => {
  return instance.post(`companies/${companyId}/employees-matching-roles-skills`, params);
};

export const companyEmployees = async (companyId: number, signal: AbortSignal): Promise<AxiosResponse<Array<CompanyEmployee>>> => {
  return instance.get(`companies/${companyId}/employees`, { signal });
};

export const listJobPostings = async (companyId: string | undefined, filters: APIListFilters): Promise<APIResult<JobPostingListAPI>> => {
  try {
    let { data } = await instance.get(`companies/${companyId}/job-postings`, {
      params: filters,
    });
    return { data, error: null };
  } catch (error) {
    const status = error.response?.status;
    let errorMessage = "Something went wrong. please try again";
    return { data: null, error: { message: errorMessage, code: status } };
  }
};

const CompanyService = {
  listUserCompanies,
  insertProfile,
  getCompanyDashboard,
  insertCompanyEmployee,
  getCompanyEmployees,
  listCompanies,
  updateCompanyProfile,
  listJobDescriptions,
  insertTeamGenerator,
  listTeams,
  employeesFilteredByRoleSkills,
  companyEmployees,
  removeEmployee,
  listJobPostings,
};

export default CompanyService;
