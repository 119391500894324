import React, { useCallback, useContext, useEffect, useState } from "react";
import { MatchingRoleEmployee, User } from "./types/User.types";
import { TeamRole } from "./utils/form-validations/team-generator/teamDetailsForm";
import { JobDescription } from "types/JobDescription.types";
import { JobPostingGeneratorForm } from "types/JobPosting.types";

export const emptyUser: User = {
  id: 0,
  email: "",
  firstName: "",
  lastName: "",
  title: "",
  profilePictureUrl: "",
  bannerUrl: "",
  prettyUrl: "",
  location: "",
  aboutMe: "",
};

export type SessionContextType = {
  loggedUser: User;
  setLoggedUser: (user: User) => void;
  authenticated: () => boolean;
  profileCompleted: () => boolean;
};

export const enum NotificationType {
  Success = "success",
  Error = "error",
}

export type NotificationArguments = {
  notificationType: NotificationType;
  title: string;
  message: string;
  displayTime?: number;
};

export type LoaderArguments = {
  requestCount: number;
};

// INFO: This is the data that is passed through each step.
export type TeamGeneratorData = {
  teamName: string;
  clientName: string;
  projectDescription: string;
  minNumOfMembers: number;
  // TODO: employees should be inside roles
  roles: Array<TeamRole>;
  employees: Array<MatchingRoleEmployee>;
};

export type NotificationContextType = {
  showNotification: (args: NotificationArguments) => void;
  closeNotification: () => void;
  arguments: NotificationArguments;
  setArguments: (notificationArguments: NotificationArguments) => void;
  visible: boolean;
};

export type LoaderContextType = {
  loadingCount: number;
  incrementLoadingCount: () => void;
  decrementLoadingCount: () => void;
};

export type StepDataType = {
  teamGeneratorData: TeamGeneratorData | undefined;
  jobDescription: JobDescription | undefined;
  jobPosting: JobPostingGeneratorForm | undefined;
};

export type PaginatorContextType = {
  stepNumber: number;
  stepData: StepDataType;
  nextStep: (step: number, stepData: StepDataType) => void;
};

export type AppContextType = {
  session: SessionContextType;
  notification: NotificationContextType;
  loader: LoaderContextType;
  paginator: PaginatorContextType;
};

const initialSession: SessionContextType = {
  loggedUser: emptyUser,
  setLoggedUser: /* istanbul ignore next */ () => {},
  authenticated: /* istanbul ignore next */ () => false,
  profileCompleted: /* istanbul ignore next */ () => false,
};

const initialNotification: NotificationContextType = {
  arguments: {
    message: "",
    notificationType: NotificationType.Error,
    title: "",
  },
  setArguments: /* istanbul ignore next */ () => {},
  visible: false,
  closeNotification: /* istanbul ignore next */ () => {},
  showNotification: /* istanbul ignore next */ () => {},
};

const initialLoader: LoaderContextType = {
  incrementLoadingCount: /* istanbul ignore next */ () => {},
  decrementLoadingCount: /* istanbul ignore next */ () => {},
  loadingCount: 0,
};

const initialPaginator: PaginatorContextType = {
  stepNumber: 0,
  stepData: {
    teamGeneratorData: {
      teamName: "",
      clientName: "",
      projectDescription: "",
      minNumOfMembers: 0,
      roles: [],
      employees: [],
    },
    jobDescription: {
      overview: "",
      responsibilities: "",
      qualifications: "",
      roleId: 0,
      role: "",
      responsibilityList: [],
      qualificationList: [],
      skills: [],
    },
    jobPosting: undefined,
  },
  nextStep: /* istanbul ignore next */ () => {},
};

const initialContext: AppContextType = {
  session: initialSession,
  notification: initialNotification,
  loader: initialLoader,
  paginator: initialPaginator,
};

const AppContext = React.createContext(initialContext);

export const AppContextProvider: React.FC<{ children: JSX.Element }> = ({ children }) => {
  const [loggedUser, setLoggedUser] = useState(initialSession.loggedUser);
  const [notificationArguments, setNotificationArguments] = useState(initialNotification.arguments);
  const [notificationVisible, setNotificationVisible] = useState(initialNotification.visible);
  const [loadingCount, setLoadingCount] = useState(0);
  const [stepNumber, setStepNumber] = useState(0);
  // const [teamGeneratorData, setTeamGeneratorData] = useState(initialPaginator.stepData.teamGeneratorData);
  const [stepData, setStepData] = useState(initialPaginator.stepData);

  useEffect(() => {
    const visibleTimer = setTimeout(() => {
      setNotificationVisible(false);
    }, notificationArguments.displayTime);

    return () => clearTimeout(visibleTimer);
  }, [notificationArguments, setNotificationVisible]);

  const closeNotification = useCallback(() => {
    setNotificationVisible(false);
  }, []);

  const showNotification = useCallback((args: NotificationArguments) => {
    setNotificationArguments(args);
    setNotificationVisible(true);
  }, []);

  const incrementLoadingCount = useCallback(() => {
    setLoadingCount((prevCount) => prevCount + 1);
  }, []);

  const decrementLoadingCount = useCallback(() => {
    setLoadingCount((prevCount) => Math.max(prevCount - 1, 0));
  }, []);

  const nextStep = useCallback((step: number, stepData: StepDataType) => {
    // setTeamGeneratorData(teamGeneratorData);
    setStepData(stepData);
    setStepNumber(step);
  }, []);

  const providerValue: AppContextType = {
    session: {
      loggedUser,
      setLoggedUser,
      authenticated: /* istanbul ignore next */ () => authenticated(loggedUser),
      profileCompleted: /* istanbul ignore next */ () => profileCompleted(loggedUser),
    },
    notification: {
      arguments: notificationArguments,
      setArguments: setNotificationArguments,
      visible: notificationVisible,
      closeNotification,
      showNotification,
    },
    loader: {
      incrementLoadingCount,
      decrementLoadingCount,
      loadingCount,
    },
    paginator: {
      stepNumber,
      stepData,
      nextStep,
    },
  };

  return <AppContext.Provider value={providerValue}>{children}</AppContext.Provider>;
};

export const authenticated = (user: User) => user.id > 0;

export const profileCompleted = (user: User) => {
  const userProperties = [user.firstName, user.lastName, user.prettyUrl, user.location, user.aboutMe, user.title];
  return !userProperties.some((value) => value == null || value === "");
};

export const useAppContext = () => useContext(AppContext);
export default AppContext;
