import { ReactElement, useState } from "react";
import StepTitle, { Props as StepTitleProps } from "./StepTitle";

type Props = {
  children: ReactElement<StepTitleProps>[];
};

const Steps = (props: Props): JSX.Element => {
  const { children } = props;
  const [selectedStepIndex, setSelectedStepIndex] = useState<number>(0);
  const numberOfSteps = children.length - 1;

  return (
    <div>
      <ol className="divide-y divide-gray-300 rounded-md border border-gray-300 md:flex md:divide-y-0">
        {children.map((item, index) => (
          <StepTitle
            key={item.props.title}
            title={item.props.title}
            index={index}
            status={index === selectedStepIndex ? "current" : index < selectedStepIndex ? "complete" : "upcoming"}
            setSelectedStep={setSelectedStepIndex}
            numberOfSteps={numberOfSteps}
            isDisabled={item.props.isDisabled}
          />
        ))}
      </ol>
      <div className="mx-auto max-w-5xl px-4 sm:px-6 lg:px-8">{children[selectedStepIndex]}</div>
    </div>
  );
};

export default Steps;
