import { AxiosResponse, HttpStatusCode } from "axios";
import { APIResult } from "types/Api.types";
import { InviteUserFormType, InvitedUser, InvitedUserApi } from "types/InviteUser.types";
import { UserProfile, WorkingGenius } from "types/User.types";
import { WorkExperienceType } from "types/WorkExperience.types";
import { UserProfileForm } from "utils/form-validations/profileForm";
import { instance } from "./base.api";
import { formPatchDocument } from "utils/form-patch-document";
import { JobPreferenceForm } from "utils/form-validations/jobPreference";

export const getProfile = async (prettyUrl: string): Promise<APIResult<UserProfile>> => {
  try {
    let { data } = await instance.get(`profile/${prettyUrl}`);

    if (data.workExperience.length > 0) {
      const workExperienceParsedDate = data.workExperience.map((workExperience: WorkExperienceType) => {
        return {
          ...workExperience,
          startDate: new Date(workExperience.startDate),
          endDate: workExperience.endDate ? new Date(workExperience.endDate) : null,
        };
      });

      data = {
        ...data,
        workExperience: workExperienceParsedDate,
      };

      const { past, current } = splitToPastAndCurrent(data.workExperience);
      past.sort(startDateDescendingOrderer);
      current.sort(startDateDescendingOrderer);
      data.workExperience = [...current, ...past];
    }

    data = {
      ...data,
      workingGenius: data.workingGenius.map((workingGenius: WorkingGenius) => {
        return {
          ...workingGenius,
          createdOn: workingGenius.createdOn ? new Date(workingGenius.createdOn) : null,
        };
      }),
    };

    return { data, error: null };
  } catch (error) {
    const status = error.response?.status;
    let errorMessage = "Something went wrong. please try again";
    if (status === HttpStatusCode.NotFound) {
      errorMessage = "User does not exist";
    }

    return { data: null, error: { message: errorMessage, code: status } };
  }
};

export const splitToPastAndCurrent = (workExperience: WorkExperienceType[]) => {
  const current: Array<WorkExperienceType> = [];
  const past: Array<WorkExperienceType> = [];

  for (const work of workExperience) {
    if (work.endDate === null) {
      current.push(work);
    } else {
      past.push(work);
    }
  }

  return {
    past,
    current,
  };
};

export const startDateDescendingOrderer = (a: WorkExperienceType, b: WorkExperienceType) => {
  const aStart = a.startDate.getTime();
  const bStart = b.startDate.getTime();
  return bStart - aStart;
};

export const updateProfile = (userId: number, user: UserProfileForm): Promise<AxiosResponse> => {
  const patchDocument = formPatchDocument({
    firstName: user.firstName,
    lastName: user.lastName,
    prettyUrl: user.prettyUrl,
    location: user.location,
    aboutMe: user.aboutMe,
    title: "user",
    profilePictureUrl: user.profilePictureUrl,
    bannerUrl: user.bannerUrl,
    resume: user.resume,
  });

  return instance.patch(`users/${userId}`, {
    patchDocument,
  });
};

export const inviteUser = async (params: InviteUserFormType): Promise<AxiosResponse> => {
  return instance.post(`users/${params.invitedBy}/invitations`, params);
};

export const deleteUserInvitation = async (userId: number, invitationId: number): Promise<AxiosResponse> => {
  return instance.delete(`users/${userId}/invitations/${invitationId}`);
};

export const listInvitedUsers = async (userId: Number): Promise<APIResult<Array<InvitedUser>>> => {
  try {
    let { data } = await instance.get(`users/${userId}/invitations`);
    data = data.map(_formatInvitedUserApi);
    return { data, error: null };
  } catch (error) {
    const status = error.response?.status;
    let errorMessage = "Something went wrong. please try again";
    return { data: null, error: { message: errorMessage, code: status } };
  }
};

const _formatInvitedUserApi = (invitedUser: InvitedUserApi) => {
  return {
    ...invitedUser,
    invitedOn: new Date(invitedUser.invitedOn),
    acceptedOn: invitedUser.acceptedOn ? new Date(invitedUser.acceptedOn) : null,
  };
};

export const suggestPrettyUrl = (email: string): Promise<AxiosResponse> => {
  return instance.post(`users/suggest-prettyurl`, {
    email,
  });
};

export const updateResume = (userId: number, resume: string): Promise<AxiosResponse> => {
  const patchDocument = formPatchDocument({ resume });

  return instance.patch(`users/${userId}`, {
    patchDocument,
  });
};

export const upsertJobPreference = async (params: JobPreferenceForm): Promise<AxiosResponse> => {
  return instance.post(`users/${params.userId}/job-preference`, params);
};

export const getJobPreference = async (userId: number): Promise<AxiosResponse> => {
  return instance.get(`users/${userId}/job-preference`);
};

export const deleteResume = async (userId: number): Promise<AxiosResponse> => {
  return instance.delete(`users/${userId}/resume`);
};

const UserService = {
  getProfile,
  inviteUser,
  updateProfile,
  listInvitedUsers,
  suggestPrettyUrl,
  deleteUserInvitation,
  splitToPastAndCurrent,
  startDateDescendingOrderer,
  updateResume,
  upsertJobPreference,
  getJobPreference,
  deleteResume,
};

export default UserService;
