import { useCallback, useEffect, useState, useRef } from "react";
import { classNames, numberToCurrency } from "utils/helpers";

type Props = {
  min: number;
  max: number;
  minValue: number;
  maxValue: number;
  onMinChange: Function;
  onMaxChange: Function;
};

const MultiRangeSlider = (props: Props) => {
  const [minVal, setMinVal] = useState(props.minValue);
  const [maxVal, setMaxVal] = useState(props.maxValue);
  const range = useRef<HTMLInputElement>(null);

  const getPercent = useCallback(
    (value: number) => Math.round(((value - props.min) / (props.max - props.min)) * 100),
    [props.min, props.max]
  );

  useEffect(() => {
    setMinVal(props.minValue);
    setMaxVal(props.maxValue);
  }, [props.minValue, props.maxValue]);

  useEffect(() => {
    const minPercent = getPercent(minVal);
    const maxPercent = getPercent(props.maxValue);

    if (range.current) {
      range.current.style.left = `${minPercent}%`;
      range.current.style.width = `${maxPercent - minPercent}%`;
    }
  }, [minVal, props.maxValue, getPercent]);

  useEffect(() => {
    const minPercent = getPercent(props.minValue);
    const maxPercent = getPercent(maxVal);
    if (range.current) {
      range.current.style.width = `${maxPercent - minPercent}%`;
    }
  }, [maxVal, props.minValue, getPercent]);

  return (
    <div className="relative flex h-10 items-center justify-center">
      <input
        type="range"
        min={props.min}
        max={props.max}
        value={minVal}
        onChange={(event) => {
          const value = Math.min(Number(event.target.value), maxVal - 1);
          setMinVal(value);
          props.onMinChange(value);
        }}
        className={classNames(
          minVal > props.max - 100 ? "z-10" : "",
          "thumb pointer-events-none absolute z-[3] mx-auto h-0 w-full outline-none"
        )}
        data-testid="minSlider"
      />
      <input
        type="range"
        min={props.min}
        max={props.max}
        value={maxVal}
        onChange={(event) => {
          const value = Math.max(Number(event.target.value), minVal + 1);
          setMaxVal(value);
          props.onMaxChange(value);
        }}
        className="thumb pointer-events-none absolute z-[4] mx-auto h-0 w-full outline-none"
        data-testid="maxSlider"
      />

      <div className="relative mx-auto w-full">
        <div className="absolute z-[1] mx-auto h-1.5 w-full rounded bg-violet-400" />
        <div ref={range} className="absolute z-[2] h-1.5 rounded bg-violet-600" />
        <div className="absolute -left-2 mt-5 text-xs text-black">{numberToCurrency(minVal, "en-US", "USD")}</div>
        <div className="absolute -right-4 mt-5 text-xs text-black">{numberToCurrency(maxVal, "en-US", "USD")}</div>
      </div>
    </div>
  );
};

export default MultiRangeSlider;
