import { Dialog } from "@headlessui/react";
import { CloudArrowUpIcon, UserCircleIcon, PhotoIcon } from "@heroicons/react/20/solid";
import "cropperjs/dist/cropper.css";
import { ChangeEvent, Dispatch, SetStateAction, createRef, useRef, useState } from "react";
import Cropper, { ReactCropperElement } from "react-cropper";
import Modal, { ModalAction, ModalOnCloseFunction } from "./Modal";
import { ImageCropper } from "utils/form-validations/companyProfileForm";

export type ImageCropperModalProps = {
  show: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  modalTitle: string;
  modalDescription: string;
  cropDescription: string;
  aspectRatio: number;
  classNameImagePreview: string;
  onClose: ModalOnCloseFunction<ImageCropper>;
};

const ImageCropperModal = (imageCropperModalProps: ImageCropperModalProps): JSX.Element => {
  const { show, setOpen, modalTitle, modalDescription, cropDescription, aspectRatio, classNameImagePreview } = imageCropperModalProps;
  const inputRef = useRef<HTMLInputElement>(null);
  const [image, setImage] = useState("");
  const cropperRef = createRef<ReactCropperElement>();
  const [error, setError] = useState("");

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    setError("");
    if (event.target.files && event.target.files.length > 0 && event.target.files[0].size > 0) {
      const files = event.target.files;
      if (files[0].size > 10e6) {
        setError("Please upload a file smaller than 10 MB");
        return;
      }
      const reader = new FileReader();
      reader.onload = () => {
        setImage(reader.result as string);
      };
      reader.readAsDataURL(files[0]);
    }
  };

  const getCropData = () => {
    if (typeof cropperRef.current?.cropper !== "undefined") {
      const imageBase64 = cropperRef.current?.cropper.getCroppedCanvas().toDataURL();
      const data = { imageBase64, modalTitle };
      imageCropperModalProps.onClose({ action: ModalAction.Add, data });
      setImage("");
      setError("");
      setOpen(false);
    }
  };

  const handleClick = () => {
    inputRef.current!.click();
  };

  const closeClick = () => {
    setOpen(false);
    setError("");
  };

  const initialImage = modalTitle.includes("Profile") ? (
    <UserCircleIcon className="h-24 w-24 text-gray-300" aria-hidden="true" />
  ) : (
    <PhotoIcon className="mx-auto h-24 w-24 text-gray-300" aria-hidden="true" />
  );

  return (
    <Modal show={show} onClose={imageCropperModalProps.onClose} title={modalTitle}>
      <Dialog.Description className="mt-1 text-sm leading-6 text-gray-600">{modalDescription}</Dialog.Description>

      <div className="mt-1 sm:flex sm:min-h-0 sm:flex-1 sm:items-center sm:justify-center sm:space-x-6">
        <div className="mt-2 grid grid-rows-1 justify-items-center gap-x-6 gap-y-1 sm:grid-rows-1">
          <div className="sm:row-span-1">
            {image === "" ? initialImage : <div id="img-preview" className={classNameImagePreview}></div>}
          </div>
          <div className="py-1 sm:row-span-3">
            <input
              data-testid="fileInputCropperModal"
              type="file"
              onChange={onChange}
              accept="image/png, image/jpeg, image/jpg"
              max-total-size="10485760"
              ref={inputRef}
              className="hidden"
            />
            <button
              type="button"
              onClick={handleClick}
              className="inline-flex justify-center gap-x-1.5 rounded-md bg-varla-violet-100/90 px-3 py-2 text-sm text-white shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-varla-violet-100/80"
            >
              Select an Image
              <CloudArrowUpIcon className="text-white-400 -ml-0.5 h-5 w-5" aria-hidden="true" />
            </button>
          </div>
          <div className="sm:row-span-1">{error && <div className="block w-full py-1 text-sm text-red-700">{error}</div>}</div>
        </div>
      </div>

      <div className="border-b border-gray-200"></div>
      {image !== "" ? (
        <div>
          <p className="leading-2 mt-2 text-sm text-gray-600">{cropDescription}</p>
          <div className="flex w-fit min-w-full py-3 sm:items-center sm:justify-center">
            <Cropper
              data-testid="cropperId"
              ref={cropperRef}
              className="h-64 w-64"
              zoomTo={0.5}
              initialAspectRatio={aspectRatio}
              aspectRatio={aspectRatio}
              preview="#img-preview"
              src={image}
              viewMode={1}
              minCropBoxHeight={10}
              minCropBoxWidth={10}
              background={false}
              responsive={true}
              autoCropArea={1}
              checkOrientation={false}
              guides={true}
            />
          </div>
        </div>
      ) : (
        <div></div>
      )}
      <div className="flex h-fit min-h-full justify-end py-2">
        <button
          type="button"
          className="rounded-md border-2 px-3 py-2 text-sm font-semibold text-black shadow-sm hover:bg-gray-200"
          onClick={closeClick}
        >
          Cancel
        </button>
        <button
          data-testid="saveImageCropper"
          onClick={getCropData}
          className="mt-3 inline-flex w-full items-center justify-center rounded-md bg-violet-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-violet-500 disabled:opacity-25 sm:ml-3 sm:mt-0 sm:w-auto"
        >
          Save
        </button>
      </div>
    </Modal>
  );
};

export default ImageCropperModal;
