import { WorkExperienceFormType } from "types/WorkExperience.types";

export type WorkExperienceValidationErrors = {
  companyName?: string;
  roleName?: string;
  startDate?: string;
  endDate?: string;
};

export const formValidation = async (values: WorkExperienceFormType): Promise<WorkExperienceValidationErrors> => {
  const errors: WorkExperienceValidationErrors = {};

  if (!values.companyName) {
    errors.companyName = "Company name is required";
  }

  if (!values.roleName) {
    errors.roleName = "Role name is required";
  }

  if (!values.startDate) {
    errors.startDate = "Start Date is required";
  }

  if (values.startDate && values.endDate && values.startDate.getTime() > values.endDate.getTime()) {
    errors.endDate = "End date must be greater than start date";
  }

  return errors;
};

const WorkExperience = {
  formValidation,
};

export default WorkExperience;
