import { BookmarkIcon } from "@heroicons/react/24/solid";
import { useRef } from "react";
import { NotificationType, useAppContext } from "AppContext";
import { Avatar } from "components";
import { ChevronRightIcon } from "@heroicons/react/20/solid";
import { fullName } from "utils/full-name";
import { formatAndSanitize, numberToCurrency } from "utils/helpers";
import { add, format } from "date-fns";
import JobGeneratorService from "api/JobGeneratorService";
import { useNavigate, useParams } from "react-router-dom";
import { NOTIFICATION_DISPLAY_TIME } from "utils/constants";
import JobPostingService from "api/JobPostingService";
import { useLoader } from "hooks/useLoader";

export const calculateExpirationDate = (expirationDate: string) => {
  const [value, duration] = expirationDate.split(" ");
  const addValue = {
    months: 0,
    weeks: 0,
  };
  if (duration === "months" || duration === "month") {
    addValue.months = Number(value);
  } else {
    addValue.weeks = Number(value);
  }
  return add(new Date(), addValue);
};

export const StepPreviewPage: React.FC<{}> = () => {
  const { applyLoader } = useLoader();
  const previewRef = useRef<any>();
  const {
    session: {
      loggedUser: { id: loggedUserId },
    },
    paginator: { nextStep, stepData },
    notification: { showNotification },
  } = useAppContext();
  const jobPosting = stepData.jobPosting!;
  const { companyId } = useParams();
  const navigate = useNavigate();
  const jobPostingsRoute = `/companies/${companyId}/dashboard/job-postings`;

  const onSubmit = async () => {
    try {
      const { data } = await applyLoader(
        JobGeneratorService.insertJobDescription(Number(companyId), loggedUserId, stepData.jobDescription!)
      );
      const jobDescriptionId = data.id;
      await applyLoader(JobPostingService.insertJobPosting(Number(companyId), loggedUserId, jobDescriptionId, stepData.jobPosting!));
      showNotification({
        notificationType: NotificationType.Success,
        title: "Success",
        message: "Successfully created job posting.",
        displayTime: NOTIFICATION_DISPLAY_TIME,
      });

      stepData.jobPosting = {
        roleId: "",
        role: "",
        companyId: "",
        locations: [],
        country: "",
        minSalary: 0,
        maxSalary: 0,
        expirationDate: "",
        hiringManagerId: "",
        requirements: "",
        company: {
          name: "",
          logoUrl: "",
          industry: "",
        },
        hiringManager: {
          profilePictureUrl: "",
          firstName: "",
          lastName: "",
        },
      };
      stepData.jobDescription = {
        overview: "",
        responsibilities: "",
        qualifications: "",
        roleId: 0,
        role: "",
        responsibilityList: [],
        qualificationList: [],
        skills: [],
      };
      navigate(jobPostingsRoute);
      nextStep(0, stepData);
    } catch (error) {
      showNotification({
        notificationType: NotificationType.Error,
        title: "Failed to create job posting",
        message: error.message,
        displayTime: NOTIFICATION_DISPLAY_TIME,
      });
    }
  };

  return (
    <div className="flex flex-1 flex-col px-4 sm:px-6 lg:px-20 xl:px-24">
      <div ref={previewRef} className="m-auto flex w-full flex-1 flex-col gap-4 px-4 pt-4">
        <div className="m-auto flex w-full items-center gap-2 text-2xl">
          <label htmlFor="teamName" className="font-medium text-varla-violet-100">
            Role:
          </label>
          <h2 className="font-bold capitalize text-gray-900">{jobPosting.role}</h2>
        </div>
        <div>
          <h3 className="text-xl font-bold capitalize text-gray-900">Details</h3>
          <div className="flex flex-col gap-4 p-4">
            <div className="w-full text-sm sm:grid sm:grid-cols-2">
              <label>Company</label>
              <div className="flex gap-2">
                <Avatar
                  nameParts={jobPosting.company.name.split(" ") ?? [""]}
                  imageUrl={jobPosting.company.logoUrl ?? ""}
                  textClasses="flex justify-center items-center font-bold leading-none text-white"
                  sizeClasses="h-6 w-6 rounded-full"
                />
                <div className="truncate">{jobPosting.company.name}</div>
                <ChevronRightIcon className="h-4 w-4" />
                <div className="truncate text-gray-400">{jobPosting.company.industry}</div>
              </div>
            </div>
            <div className="w-full text-sm sm:grid sm:grid-cols-2">
              <label>Hiring Manager</label>
              <div className="flex gap-2">
                <Avatar
                  imageUrl={jobPosting.hiringManager.profilePictureUrl}
                  nameParts={[jobPosting.hiringManager.firstName!, jobPosting.hiringManager.lastName!]}
                  sizeClasses="h-6 w-6 rounded-full"
                  textClasses="text-sm"
                />
                <div className="truncate">{fullName(jobPosting.hiringManager)}</div>
              </div>
            </div>
            <div className="w-full text-sm sm:grid sm:grid-cols-2">
              <label>Location</label>
              <div className="flex gap-2">
                {jobPosting.locations?.join(" / ")}
                {jobPosting.country && <span>&#x2022; {jobPosting.country}</span>}
              </div>
            </div>
            <div className="w-full text-sm sm:grid sm:grid-cols-2">
              <label>Salary Range</label>
              <div>
                {numberToCurrency(jobPosting.minSalary, "en-US", "USD")} - {numberToCurrency(jobPosting.maxSalary, "en-US", "USD")}
              </div>
            </div>
            <div className="w-full text-sm sm:grid sm:grid-cols-2">
              <label>Posting expiration</label>
              <div>{format(calculateExpirationDate(jobPosting.expirationDate), "PP")}</div>
            </div>
          </div>
        </div>
        <div>
          <h2 className="text-xl font-bold capitalize text-gray-900">Overview</h2>
          <div>{stepData.jobDescription?.overview}</div>
        </div>
        <div>
          <h2 className="text-xl font-bold capitalize text-gray-900">Responsibilities</h2>
          <div
            data-testid="responsibilitiesContainer"
            dangerouslySetInnerHTML={{ __html: formatAndSanitize(stepData.jobDescription!.responsibilities) }}
            className="pl-16"
          />
        </div>
        <div>
          <h2 className="text-xl font-bold capitalize text-gray-900">Qualifications</h2>
          <div
            data-testid="qualificationsContainer"
            dangerouslySetInnerHTML={{ __html: formatAndSanitize(stepData.jobDescription!.qualifications) }}
            className="pl-16"
          />
        </div>
        <div>
          <h2 className="text-xl font-bold capitalize text-gray-900">Skills</h2>
          <div className="mt-4 flex flex-wrap gap-x-3 pb-6">
            {stepData.jobDescription!.skills.map((skill) => (
              <span
                key={skill}
                className="mt-4 inline-flex items-center gap-x-0.5 rounded-full bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10"
              >
                {skill}
              </span>
            ))}
          </div>
        </div>
      </div>
      <div className="flex flex-row-reverse items-end justify-between space-x-3 border-t border-gray-200 px-2 py-2 sm:px-3">
        <button
          type="button"
          className="flex rounded-md bg-violet-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-violet-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-violet-500"
          onClick={onSubmit}
        >
          Save
          <BookmarkIcon className="ml-2 h-5 w-5" aria-hidden="true" />
        </button>
      </div>
    </div>
  );
};

export default StepPreviewPage;
