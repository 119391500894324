import { useParams } from "react-router-dom";
import { useAppContext } from "AppContext";
import Step from "components/Steps/Step";
import Steps from "components/Steps/Steps";
import StepJobPostingPage from "./steps/StepJobPostingPage";
import StepPreviewPage from "./steps/StepPreviewPage";
import StepDescriptionPage from "../job-description-generator/steps/StepDescriptionPage";

export const JobPostingGeneratorPage: React.FC<{}> = () => {
  const {
    paginator: { stepData },
  } = useAppContext();

  const { jobPostingId } = useParams();

  return (
    <div className="lg:full flex flex-1 flex-col px-4 py-2 sm:px-6 lg:px-20 xl:px-24">
      <Steps>
        <Step title="Description" isDisabled={!!jobPostingId}>
          <StepJobPostingPage />
        </Step>
        <Step title="Job Description" isDisabled={!!jobPostingId || !stepData.jobPosting?.companyId}>
          <StepDescriptionPage />
        </Step>
        <Step title="Preview" isDisabled={!!jobPostingId || !stepData.jobDescription?.qualifications}>
          <StepPreviewPage />
        </Step>
      </Steps>
    </div>
  );
};

export default JobPostingGeneratorPage;
