import { Popover, Transition } from "@headlessui/react";
import { CheckBadgeIcon } from "@heroicons/react/20/solid";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import React, { Fragment, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Avatar from "components/Avatar/Avatar";
import { Company } from "types/Company.types";
import { BG_COLORS_AVATAR } from "utils/constants";
import { classNames } from "utils/helpers";

type CompanyNavigationPage = {
  label: string;
  route: string;
  target: React.HTMLAttributeAnchorTarget;
  current: boolean;
};
type CompanyDashboardHeaderProps = { company: Company };

export const CompanyDashboardHeaderPage: React.FC<CompanyDashboardHeaderProps> = ({ company }) => {
  const location = useLocation();
  const [companyRoute] = useState(`/companies/${company.id}`);
  const [navigation, setNavigation] = useState<Array<CompanyNavigationPage>>([
    { label: "Company Directory", route: `${companyRoute}/dashboard/directory`, target: "_self", current: false },
    { label: "Team Generator", route: `${companyRoute}/dashboard/team-generator`, target: "_self", current: false },
    { label: "Company Teams", route: `${companyRoute}/dashboard/teams`, target: "_self", current: false },
    { label: "Job Descriptions", route: `${companyRoute}/dashboard/job-descriptions`, target: "_self", current: false },
    { label: "Profile", route: `${companyRoute}/profile`, target: "_blank", current: false },
    { label: "Edit Profile", route: `${companyRoute}/edit-profile`, target: "_blank", current: false },
    { label: "Job Posting", route: `${companyRoute}/dashboard/job-postings`, target: "_self", current: false },
  ]);

  useEffect(() => {
    setNavigation((currentNavigation) => currentNavigation.map((page) => ({ ...page, current: location.pathname === page.route })));
  }, [location.pathname]);

  const MobileMenuButton = (menuOpened: boolean): JSX.Element => {
    const MenuIcon = menuOpened ? XMarkIcon : Bars3Icon;
    return (
      <Popover.Button className="inline-flex items-center justify-center rounded-md bg-transparent text-violet-500 hover:bg-violet-800 hover:bg-opacity-10 focus:outline-none focus:ring-2 focus:ring-white">
        <span className="sr-only">Open main menu</span>
        <MenuIcon className="block h-8 w-8" aria-hidden />
      </Popover.Button>
    );
  };

  const MobileMenu: React.FC = () => (
    <Transition.Root as={Fragment}>
      <div className="lg:hidden">
        <Transition.Child
          as={Fragment}
          enter="duration-150 ease-out"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="duration-150 ease-in"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Popover.Overlay className="fixed inset-0 z-20 bg-black bg-opacity-25" />
        </Transition.Child>
        <Transition.Child
          as={Fragment}
          enter="duration-150 ease-out"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="duration-150 ease-in"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <Popover.Panel focus className="absolute inset-x-0 top-0 z-30 mx-auto w-full max-w-3xl origin-top transform p-2 transition">
            <div className="divide-y divide-gray-200 rounded-lg bg-white ring-1 ring-black ring-opacity-5">
              <div className="pb-2 pt-3">
                <div className="flex items-center justify-end px-4">
                  <div className="-mr-2">
                    <Popover.Button className="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-cyan-500">
                      <span className="sr-only">Close menu</span>
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </Popover.Button>
                  </div>
                </div>
                <div className="mt-3 space-y-1 px-2">
                  {navigation.map((page) => (
                    <Link
                      key={page.label}
                      to={page.route}
                      target={page.target}
                      className="block rounded-md px-3 py-2 text-base font-medium hover:bg-gray-100 hover:text-gray-800"
                    >
                      {page.label}
                    </Link>
                  ))}
                </div>
              </div>
            </div>
          </Popover.Panel>
        </Transition.Child>
      </div>
    </Transition.Root>
  );

  const CompanyGreeting = (MobileMenuButton: JSX.Element): JSX.Element => (
    <div className="flex items-center justify-between overflow-hidden rounded-lg bg-white bg-opacity-90 px-8 py-2 shadow sm:space-x-5 md:p-4">
      <div className="flex items-center">
        <div className="flex-shrink-0">
          <Avatar
            nameParts={company.name.split(" ") ?? [""]}
            imageUrl={company.logoUrl ?? ""}
            textClasses="flex h-screen justify-center items-center font-bold leading-none text-white"
            sizeClasses={company.verified ? "h-16 w-16 rounded-full ring-2 ring-amber-400" : "h-16 w-16 rounded-full"}
            bgClasses={BG_COLORS_AVATAR}
          />
        </div>
        <div className="pl-2 text-left sm:pt-1 sm:text-left md:ml-0">
          <p className="text-sm font-medium text-gray-600">Welcome back,</p>
          <p className="text-xl font-bold capitalize text-gray-900 sm:text-2xl">{company.name}</p>
          <p className="hidden text-sm font-medium text-gray-600 md:flex ">
            {company.industry}
            {company.verified && (
              <div className="flex">
                <CheckBadgeIcon className="ml-1.5 mr-1.5 h-5 w-5 flex-shrink-0 text-amber-400" aria-hidden="true" />{" "}
                <label className="flex text-sm text-gray-400 ">Verified account</label>
              </div>
            )}
          </p>
        </div>
      </div>
      <div className="flex flex-shrink-0 items-center lg:hidden">{MobileMenuButton}</div>
    </div>
  );

  const Navigation: React.FC = () => {
    const displayPageNav = (page: CompanyNavigationPage) => (
      <Link
        key={page.label}
        data-testid={`NavigationLink.${page.label}.${page.current}`}
        to={page.route}
        target={page.target}
        className={classNames(page.current ? "border-b-2  text-white" : "text-violet-100", "whitespace-nowrap p-1 text-sm font-medium")}
        aria-current={page.current ? "page" : undefined}
      >
        {page.label}
      </Link>
    );
    return (
      <div className="flex justify-center px-2 py-1">
        <nav className="lg:hidden">{navigation.filter((page) => page.current).map(displayPageNav)}</nav>
        <nav className="hidden lg:flex lg:items-center lg:gap-4">{navigation.map(displayPageNav)}</nav>
      </div>
    );
  };

  return (
    <Popover as="header" className="bg-gradient-to-r from-violet-500 to-purple-400 p-6 pb-1">
      {({ open }) => (
        <>
          {CompanyGreeting(MobileMenuButton(open))}
          <Navigation />
          <MobileMenu />
        </>
      )}
    </Popover>
  );
};

export default CompanyDashboardHeaderPage;
