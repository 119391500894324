import { confirmAlert } from "react-confirm-alert";

export type ConfirmationAlertModalProps = {
  title: string;
  message: string;
  action: string;
  onAction: Function;
};

export const ConfirmationAlertModal = (props: ConfirmationAlertModalProps) => {
  confirmAlert({
    customUI: ({ onClose }) => (
      <div className="bg-white shadow sm:rounded-lg">
        <div className="px-4 py-5 sm:p-6">
          <h3 data-testid="DialogTitle" className="text-base font-semibold leading-6 text-gray-900">
            {props.title}
          </h3>
          <div className="mt-2 max-w-xl text-sm text-gray-500">
            <p>{props.message}</p>
          </div>
          <div className="flex h-fit min-h-full justify-end space-x-4 px-3 pt-6">
            <button
              data-testid="remove-confirmation"
              onClick={async () => {
                await props.onAction();
                onClose();
              }}
              type="button"
              className="inline-flex items-center rounded-md bg-violet-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-violet-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-violet-500"
            >
              {props.action}
            </button>
            <button
              onClick={() => onClose()}
              type="button"
              className="mr-3 rounded-md border-2 px-3 py-2 text-sm font-semibold text-black shadow-sm hover:bg-gray-200"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    ),
    overlayClassName: "react-confirm-alert-overlay",
  });
};

export default ConfirmationAlertModal;
