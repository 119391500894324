import { AxiosResponse } from "axios";
import { instance } from "./base.api";

export const industries = async (): Promise<AxiosResponse> => {
  return instance.get("industries");
};

const IndustryService = {
  industries,
};

export default IndustryService;
