import { InferType, array, object, string, number, boolean } from "yup";

export const officeLocation = ["Hybrid", "Remote", "On-site"];
export const dateRangeFilter = ["Last Week", "Last Month", "Last Quarter"];

export const jobPreferenceSchema = object().shape({
  userId: number().strict().required(),
  available: boolean().strict().required(),
  minimumSalary: number().min(0).required(),
  officeLocation: array(string().oneOf(officeLocation)).strict().required().min(1),
  dateRangeFilter: string().oneOf(dateRangeFilter).strict().required(),
});
export type JobPreferenceForm = InferType<typeof jobPreferenceSchema>;
