import { BookmarkIcon, CheckCircleIcon, ClipboardIcon } from "@heroicons/react/24/solid";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { NotificationType, useAppContext } from "AppContext";
import JobGeneratorService from "api/JobGeneratorService";
import { useLoader } from "hooks/useLoader";
import { NOTIFICATION_DISPLAY_TIME } from "utils/constants";
import { formatAndSanitize } from "utils/helpers";

export const StepPreviewPage: React.FC<{}> = () => {
  const { applyLoader } = useLoader();
  const { companyId, jobDescriptionId } = useParams();
  const [isCopied, setIsCopied] = useState<boolean>(false);
  const previewRef = useRef<any>();
  const {
    session: {
      loggedUser: { id: loggedUserId },
    },
    paginator: { nextStep, stepData },
    notification: { showNotification },
  } = useAppContext();
  const navigate = useNavigate();
  const jobDescriptionRoute = `/companies/${companyId}/dashboard/job-descriptions`;

  useEffect(() => {
    const fetchJobDescription = async (): Promise<void> => {
      const { data } = await applyLoader(JobGeneratorService.getJobDescription(Number(jobDescriptionId)));
      stepData.jobDescription!.overview = data.overview;
      stepData.jobDescription!.qualifications = data.qualifications;
      stepData.jobDescription!.responsibilities = data.responsibilities;
      stepData.jobDescription!.roleId = data.roleId;
      stepData.jobDescription!.role = data.role;
    };

    if (jobDescriptionId) {
      fetchJobDescription();
    }
  }, [applyLoader, jobDescriptionId, stepData]);

  const onSubmit = async () => {
    try {
      await applyLoader(JobGeneratorService.insertJobDescription(Number(companyId), loggedUserId, stepData.jobDescription!));

      showNotification({
        notificationType: NotificationType.Success,
        title: "Success",
        message: "Successfully created job description.",
        displayTime: NOTIFICATION_DISPLAY_TIME,
      });

      stepData.jobDescription = {
        overview: "",
        responsibilities: "",
        qualifications: "",
        roleId: 0,
        role: "",
        responsibilityList: [],
        qualificationList: [],
        skills: [],
      };

      navigate(jobDescriptionRoute);
      nextStep(0, stepData);
    } catch (error) {
      showNotification({
        notificationType: NotificationType.Error,
        title: "Failed to create job description",
        message: error.message,
        displayTime: NOTIFICATION_DISPLAY_TIME,
      });
    }
  };

  const copyToClipboard = () => {
    navigator.clipboard.write([
      new ClipboardItem({
        "text/html": new Blob([previewRef.current.innerHTML], { type: "text/html" }),
      }),
    ]);
    setIsCopied(true);
  };

  return (
    <div className="flex flex-1 flex-col px-4 sm:px-6 lg:px-20 xl:px-24">
      <div ref={previewRef} className="m-auto flex flex-1 flex-col px-4 pt-4 sm:px-6 lg:px-20 xl:px-24">
        <div className="m-auto mb-5 flex w-full items-center gap-2 text-2xl">
          <label htmlFor="teamName" className="font-medium text-varla-violet-100">
            Role:
          </label>
          <h2 className="font-bold capitalize text-gray-900">{stepData.jobDescription!.role}</h2>
        </div>
        <div className="m-auto w-full">
          <p className="mb-2 text-xl font-bold text-gray-900">Overview</p>
          <div dangerouslySetInnerHTML={{ __html: formatAndSanitize(stepData.jobDescription!.overview) }} className="pl-10" />
        </div>
        <div className="m-auto w-full">
          <p className="mb-2 mt-6 text-xl font-bold text-gray-900">Responsibilities</p>
          <div
            data-testid="responsibilitiesContainer"
            dangerouslySetInnerHTML={{ __html: formatAndSanitize(stepData.jobDescription!.responsibilities) }}
            className="pl-16"
          />
        </div>
        <div className="m-auto w-full">
          <p className="mb-2 mt-6 text-xl font-bold text-gray-900">Qualifications</p>
          <div
            data-testid="qualificationsContainer"
            dangerouslySetInnerHTML={{ __html: formatAndSanitize(stepData.jobDescription!.qualifications) }}
            className="pl-16"
          />
        </div>
      </div>
      <div className="flex flex-row-reverse items-end justify-between space-x-3 border-t border-gray-200 px-2 py-2 sm:px-3">
        {!jobDescriptionId ? (
          <button
            type="button"
            className="flex rounded-md bg-violet-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-violet-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-violet-500"
            onClick={onSubmit}
          >
            Save
            <BookmarkIcon className="ml-2 h-5 w-5" aria-hidden="true" />
          </button>
        ) : (
          <button
            type="button"
            className="flex rounded-md bg-violet-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-violet-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-violet-500"
            onClick={copyToClipboard}
          >
            {isCopied && "Text copied"}
            {!isCopied && "Copy to Clipboard"}
            {isCopied && <CheckCircleIcon className="ml-2 h-5 w-5" aria-hidden="true" />}
            {!isCopied && <ClipboardIcon className="ml-2 h-5 w-5" aria-hidden="true" />}
          </button>
        )}
      </div>
    </div>
  );
};

export default StepPreviewPage;
