import { UseQueryResult, useQuery } from "@tanstack/react-query";
import { MatchingRoleEmployee } from "types/User.types";
import { RoleWithSkillsType } from "./../types/Role.types";

export const RoleSkillsQuery = (queryFn: () => Promise<RoleWithSkillsType[]>, enabled: boolean): UseQueryResult<RoleWithSkillsType[]> => {
  const results = useQuery({
    queryKey: ["rolesWithSkills"],
    queryFn,
    enabled,
  });
  return results;
};

export const MatchingEmployeesQuery = (
  queryFn: (data: RoleWithSkillsType[] | undefined) => Promise<MatchingRoleEmployee[]>,
  enabled: boolean,
  roleWithSkillsData: RoleWithSkillsType[] | undefined
): UseQueryResult<MatchingRoleEmployee[]> => {
  const results = useQuery({
    queryKey: ["matchingEmployees"],
    queryFn: () => queryFn(roleWithSkillsData),
    enabled,
  });
  return results;
};

const TeamGeneratorQueries = {
  RoleSkillsQuery,
  MatchingEmployeesQuery,
};

export default TeamGeneratorQueries;
