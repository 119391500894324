import { yupResolver } from "@hookform/resolvers/yup";
import { Dispatch, SetStateAction, useContext } from "react";
import { useForm } from "react-hook-form";
import AppContext, { NotificationType } from "AppContext";
import UserConnectionService from "api/UserConnectionService";
import { useLoader } from "hooks/useLoader";
import { UserProfile } from "types/User.types";
import { ConnectionType, UserConnectionFormType, userConnectionFormSchema } from "types/UserConnection.types";
import { NOTIFICATION_DISPLAY_TIME, STATUS_CODE_CREATED } from "utils/constants";
import Modal from "./Modal";

type UserConnectionProps = {
  show: boolean;
  data?: UserProfile;
  setOpen: Dispatch<SetStateAction<boolean>>;
  setIsConnected: Dispatch<SetStateAction<boolean>>;
};

const UserConnectionModal = (props: UserConnectionProps): JSX.Element => {
  const { show, setOpen, setIsConnected, data } = props;
  const context = useContext(AppContext);

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<UserConnectionFormType>({
    resolver: yupResolver(userConnectionFormSchema),
  });

  const { applyLoader } = useLoader();

  const onSubmit = handleSubmit(async (formData) => {
    try {
      const response = await applyLoader(UserConnectionService.connectUser(context.session.loggedUser.id, formData));
      const message =
        response.status === STATUS_CODE_CREATED ? "User connected successfully" : "Connection already exists. Check your connections";
      context.notification.showNotification({
        notificationType: NotificationType.Success,
        title: "Success",
        message,
        displayTime: NOTIFICATION_DISPLAY_TIME,
      });
      setIsConnected(true);
    } catch (error) {
      context.notification.showNotification({
        notificationType: NotificationType.Error,
        title: "Error",
        message: error.response ? "Failed to connect to the user" : error.message,
        displayTime: NOTIFICATION_DISPLAY_TIME,
      });
      setIsConnected(false);
    } finally {
      setOpen(false);
    }
  });

  return (
    <Modal show={show} onClose={() => setOpen(false)} title={`Connect with ${data?.firstName}`}>
      <div className="mt-2">
        <p className="block text-sm text-gray-500 ">Add {data?.firstName} as a connection</p>
        <form onSubmit={onSubmit} className="space-y-2">
          <div className="w-full py-4">
            <input type="hidden" {...register("connectedUser")} value={data?.id} />
            <label htmlFor="connectionType" className="block pb-2 text-sm font-medium leading-6 text-gray-900">
              How do you know each other?
            </label>
            <select
              className="w-full rounded-md border-violet-600 text-base font-medium text-gray-900 focus:ring-1 focus:ring-violet-600"
              {...register("connectionType")}
            >
              <option value="">Select an option</option>
              {Object.values(ConnectionType).map((types) => (
                <option key={types}>{types}</option>
              ))}
            </select>
            {errors?.connectionType && <div className="mt-2 w-full py-1 text-sm text-red-700">{errors.connectionType.message}</div>}
          </div>
          <div className="flex justify-end">
            <button
              type="button"
              className="mr-3 rounded-md border-2 px-3 py-2 text-sm font-semibold text-black shadow-sm hover:bg-gray-200"
              onClick={() => setOpen(false)}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="inline-flex items-center justify-center rounded-md bg-violet-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-violet-500"
              disabled={isSubmitting}
            >
              Connect
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default UserConnectionModal;
