import { yupResolver } from "@hookform/resolvers/yup";
import React, { useContext } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import AppContext, { NotificationType } from "AppContext";
import AuthService from "api/AuthService";
import { AuthCredentialsType } from "types/UserLogin.types";
import { UserRegistrationFormType } from "types/UserRegistration.types";
import { NOTIFICATION_DISPLAY_TIME } from "utils/constants";
import { RegistrationValidate } from "utils/validateForm";
import { useLoader } from "hooks/useLoader";

const ImageBackground = "/images/rect2021-5.png";
const Logo = "/images/v2.png";

export const RegistrationPage: React.FC<{}> = () => {
  const {
    notification: { showNotification },
  } = useContext(AppContext);
  const navigate = useNavigate();

  const { applyLoader } = useLoader();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<UserRegistrationFormType>({
    resolver: yupResolver(RegistrationValidate),
  });

  const onSubmit = handleSubmit(async (formData) => {
    const { email, password } = formData;
    const user: AuthCredentialsType = { authCredentials: { email, password } };

    const { error } = await applyLoader(AuthService.registration(user));

    if (error) {
      showNotification({
        notificationType: NotificationType.Error,
        title: "Failed to register user",
        message: error.message,
        displayTime: NOTIFICATION_DISPLAY_TIME,
      });
    } else {
      const message = "An email will been sent shortly to the registered email. Please check your inbox to verify your account. Thank you!";
      showNotification({
        notificationType: NotificationType.Success,
        title: "Success",
        message,
        displayTime: NOTIFICATION_DISPLAY_TIME,
      });
      navigate("/login");
    }
  });

  return (
    <div className="flex min-h-screen">
      <div className="flex flex-1 flex-col justify-center px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
        <div className="mx-auto w-full max-w-sm lg:w-96">
          <div>
            <img className="h-12 w-auto" src={Logo} alt="Your Company" />
            <h2 className="mt-6 text-3xl font-bold tracking-tight text-gray-900">Register your account</h2>
          </div>

          <div className="mt-8">
            <div className="mt-6">
              <form onSubmit={onSubmit} className="space-y-6">
                <div>
                  <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                    Email address
                  </label>
                  <div className="mt-2">
                    <input
                      id="email"
                      {...register("email")}
                      type="text"
                      className="block w-full rounded-md border-0 py-1.5 lowercase shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-violet-600 sm:text-sm sm:leading-6"
                    />
                    {errors?.email && <div className="mt-2 block w-full py-1 text-sm text-red-700">{errors.email.message}</div>}
                    {errors?.email?.type === "alreadyExists" && (
                      <Link to="/login" className="pl-1 font-medium text-violet-600 hover:text-violet-500">
                        Log in
                      </Link>
                    )}
                  </div>
                </div>

                <div className="space-y-1">
                  <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                    Password
                  </label>
                  <div className="mt-2">
                    <input
                      id="password"
                      {...register("password")}
                      type="password"
                      className="placeholder: block w-full rounded-md border-0 py-1.5 text-gray-500 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-violet-600 sm:text-sm sm:leading-6"
                    />
                    {errors?.password && <div className="mt-2 block w-full py-1 text-sm text-red-700">{errors.password.message}</div>}
                  </div>
                </div>
                <div className="space-y-1">
                  <label htmlFor="confirmPassword" className="block text-sm font-medium leading-6 text-gray-900">
                    Confirm Password
                  </label>
                  <div className="mt-2">
                    <input
                      id="confirmPassword"
                      {...register("confirmPassword")}
                      type="password"
                      className="placeholder: block w-full rounded-md border-0 py-1.5 text-gray-500 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-violet-600 sm:text-sm sm:leading-6"
                    />
                    {errors?.confirmPassword && (
                      <div className="mt-2 block w-full py-1 text-sm text-red-700">{errors.confirmPassword.message}</div>
                    )}
                  </div>
                </div>

                <div>
                  <button
                    type="submit"
                    className="flex w-full justify-center rounded-md bg-violet-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-violet-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-violet-600"
                  >
                    Register
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="relative hidden w-0 flex-1 lg:block">
        <img className="absolute inset-0 h-full w-full object-cover" src={ImageBackground} alt="" />
      </div>
    </div>
  );
};

export default RegistrationPage;
