import { Disclosure } from "@headlessui/react";
import { MinusSmallIcon, PlusSmallIcon } from "@heroicons/react/20/solid";

const faqs = [
  {
    question: `How to Invite an Employee`,
    answer: `
        <ol>
          <li>
            <p>
              <strong>Access the Company Dashboard</strong>: Start by logging into
              your company's account and accessing the company dashboard. This is
              typically done by clicking on your company's name or logo or selecting
              the company from a list of companies associated with your account.
            </p>
          </li>
          <li>
            <p>
              <strong>Click on 'Invite Employee' Link</strong>: Within the company
              dashboard, look for and click on the "Invite Employee" link or button.
              This action will initiate the employee invitation process.
            </p>
          </li>
          <li>
            <p>
              <strong>Enter Valid Email Address</strong>: A pop-up window with an
              input field will appear. In this field, enter the valid email address of
              the user you want to invite. It's important to note that this email
              address should belong to a user who is already registered in the
              application.
            </p>
          </li>
          <li>
            <p>
              <strong>Verify Email Address</strong>: Double-check to ensure that the
              entered email address is correct to avoid any delivery issues.
            </p>
          </li>
          <li>
            <p>
              <strong>Select User Role</strong>: Choose a role for the user you are
              inviting within the company. This role typically defines their
              permissions and responsibilities within the company. For example, you
              might select "Employee" or "Admin" based on their position.
            </p>
          </li>
          <li>
            <p>
              <strong>Click 'Invite'</strong>: After entering the email address and
              selecting the role, click the "Invite" button or a similar option within
              the pop-up window. This action will send an invitation to the specified
              user.
            </p>
          </li>
          <li>
            <p>
              <strong>User Notification</strong>: The user you invited will receive an
              email notification informing them of the invitation. They will be
              provided with instructions on how to accept the invitation and join the
              company.
            </p>
          </li>
          <li>
            <p>
              <strong>Display in Company Directory</strong>: Once the invited user
              accepts the invitation, they will be displayed in the company's
              directory or list of members, indicating that they have successfully
              joined the company.
            </p>
          </li>
        </ol>
        <p>
          By following these steps, you can easily invite employees or administrators
          to your company within the application, ensuring smooth onboarding and
          collaboration within your organization.
        </p>
      `,
  },
];

export const GuideCompaniesPage: React.FC = () => {
  return (
    <>
      {faqs.map((faq) => (
        <Disclosure as="div" key={faq.question} className="pb-6">
          {({ open }) => (
            <>
              <dt>
                <Disclosure.Button className="flex w-full items-start justify-between rounded-md p-2 text-left text-gray-900 hover:bg-violet-50">
                  <span className="text-base font-semibold leading-7">{faq.question}</span>
                  <span className="ml-6 flex h-7 items-center">
                    {open ? (
                      <MinusSmallIcon className="h-6 w-6" aria-hidden="true" />
                    ) : (
                      <PlusSmallIcon className="h-6 w-6" aria-hidden="true" />
                    )}
                  </span>
                </Disclosure.Button>
              </dt>
              <Disclosure.Panel as="dd" className="mt-2 p-2 pl-12 pr-12">
                <p className="text-base leading-7 text-gray-600" dangerouslySetInnerHTML={{ __html: faq.answer }}></p>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      ))}
    </>
  );
};

export default GuideCompaniesPage;
