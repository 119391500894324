export enum SortOrder {
  ascending = 1,
  descending = -1,
}

const tablePage = <T>(data: Array<T>, pageNumber: number, itemsPerPage: number) => {
  const startIndex = (pageNumber - 1) * itemsPerPage;
  const endIndex = Math.min(startIndex + itemsPerPage, data.length);
  return data.slice(startIndex, endIndex);
};

export const sort = <T>(data: Array<T>, sortKey: keyof T, sortOrder: SortOrder) => {
  const collator = new Intl.Collator(undefined, {
    numeric: true,
    sensitivity: "base",
  });
  return data.sort((left: T, right: T) => {
    const leftValue = String(left[sortKey]);
    const rightValue = String(right[sortKey]);
    return collator.compare(leftValue, rightValue) * sortOrder;
  });
};

const totalPages = (dataLength: number, itemsPerPage: number) => {
  return Math.ceil(dataLength / itemsPerPage);
};

const pageNumbers = (totalPages: number) => {
  return Array.from(Array(totalPages).keys()).map((pageNumber) => pageNumber + 1);
};

const tableFunctions = {
  tablePage,
  sort,
  pageNumbers,
  totalPages,
};

export default tableFunctions;
