import { Menu, Transition } from "@headlessui/react";
import { EllipsisVerticalIcon, PlusCircleIcon } from "@heroicons/react/20/solid";
import { format, parseJSON } from "date-fns";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import CompanyService from "api/CompanyService";
import Pagination from "components/Pagination/Pagination";
import useDebounce from "hooks/useDebounce";
import { useLoader } from "hooks/useLoader";
import { JobDescriptionListAPI } from "types/JobDescription.types";

const ITEMS_PER_PAGE = 10;

export const JobDescriptionListPage: React.FC<{}> = () => {
  const { applyLoader } = useLoader();
  const params = useParams();
  const companyId = params.companyId;
  const [currentPage, setCurrentPage] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [jobDescriptionList, setJobDescriptionList] = useState<JobDescriptionListAPI | null>();
  const debouncedSearchValue = useDebounce<string>(searchValue);

  const fetchJobDescriptions = useCallback(
    async (currentPage: number) => {
      const { data } = await applyLoader(
        CompanyService.listJobDescriptions(companyId, {
          limit: ITEMS_PER_PAGE,
          offset: ITEMS_PER_PAGE * (currentPage - 1),
          filter: debouncedSearchValue,
        })
      );

      setJobDescriptionList(data);
    },
    [debouncedSearchValue, companyId, applyLoader]
  );

  useEffect(() => {
    setCurrentPage(1);
  }, [debouncedSearchValue]);

  useEffect(() => {
    fetchJobDescriptions(currentPage);
  }, [currentPage, fetchJobDescriptions]);

  return (
    <div className="lg:full xl:px-18 mt-4 flex flex-1 flex-col px-4 sm:px-6 lg:px-12">
      <div className="mx-auto flex w-3/4 flex-col-reverse items-center gap-4 lg:flex-row lg:items-start">
        <div className="relative w-full flex-grow lg:w-fit">
          <label
            htmlFor="jobDescriptionFilter"
            className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900"
          >
            Job Description Filter
          </label>
          <input
            type="text"
            name="jobDescriptionFilter"
            id="jobDescriptionFilter"
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            placeholder="Role"
            onChange={(event) => {
              setSearchValue(event.target.value.toLowerCase());
            }}
          />
        </div>
        <Link
          to={`/companies/${companyId}/dashboard/job-description-generator`}
          className="inline-flex w-fit justify-center gap-x-1.5 whitespace-nowrap rounded-md bg-varla-violet-100/90 px-3 py-2 text-sm text-white shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-varla-violet-100/80"
        >
          Generate a new Job Description
          <PlusCircleIcon className="h-5 w-5" aria-hidden="true" />
        </Link>
      </div>
      <div className="mx-auto w-3/4">
        {jobDescriptionList && jobDescriptionList.totalCount > 0 ? (
          <>
            <ul className="divide-y divide-gray-100">
              {jobDescriptionList.jobDescriptions.map((jobDescription) => (
                <li key={jobDescription.id} className="flex items-center justify-between gap-x-6 py-5">
                  <div className="min-w-0">
                    <div className="flex items-start gap-x-3">
                      <p className="text-sm font-semibold capitalize leading-6 text-gray-900">{jobDescription.roleName}</p>
                    </div>
                    <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
                      <p className="truncate capitalize">
                        By <b>{jobDescription.fullName}</b>
                      </p>
                      <svg viewBox="0 0 2 2" className="h-0.5 w-0.5 fill-current">
                        <circle cx={1} cy={1} r={1} />
                      </svg>
                      <p className="whitespace-nowrap">
                        on <time dateTime={jobDescription.createdOn}>{format(parseJSON(jobDescription.createdOn), "PPP")}</time>
                      </p>
                    </div>
                  </div>
                  <div className="flex flex-none items-center gap-x-4">
                    <Link
                      to={`/companies/${companyId}/dashboard/job-description-generator/${jobDescription.id}`}
                      className="hidden rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:block"
                    >
                      View<span className="sr-only">, {jobDescription.roleName}</span>
                    </Link>
                    <Menu as="div" className="relative flex-none">
                      <Menu.Button className="-m-2.5 block p-2.5 text-gray-500 hover:text-gray-900">
                        <span className="sr-only">Open options</span>
                        <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
                      </Menu.Button>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="absolute right-0 z-10 mt-2 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                          <Menu.Item>
                            <Link
                              to={`/companies/${companyId}/dashboard/job-description-generator/${jobDescription.id}`}
                              className="block px-3 py-1 text-sm leading-6 text-gray-900 sm:hidden"
                            >
                              View<span className="sr-only">, {jobDescription.roleName}</span>
                            </Link>
                          </Menu.Item>
                          <Menu.Item>
                            <Link to="#" className="block px-3 py-1 text-sm leading-6 text-gray-900">
                              Edit<span className="sr-only">, {jobDescription.roleName}</span>
                            </Link>
                          </Menu.Item>
                          <Menu.Item>
                            <Link to="#" className="block px-3 py-1 text-sm leading-6 text-gray-900">
                              Duplicate<span className="sr-only">, {jobDescription.roleName}</span>
                            </Link>
                          </Menu.Item>
                          <Menu.Item>
                            <Link to="#" className="block px-3 py-1 text-sm font-semibold leading-6 text-red-500">
                              Delete<span className="sr-only">, {jobDescription.roleName}</span>
                            </Link>
                          </Menu.Item>
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  </div>
                </li>
              ))}
            </ul>
            <Pagination
              itemsLength={jobDescriptionList.totalCount}
              itemsPerPage={ITEMS_PER_PAGE}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          </>
        ) : (
          <p className="text-sm font-semibold leading-6 text-gray-900">There are currently no Job Descriptions for your company</p>
        )}
      </div>
    </div>
  );
};

export default JobDescriptionListPage;
