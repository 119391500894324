import { instance } from "./base.api";
import { AxiosResponse } from "axios";
import { JobPostingGeneratorForm } from "types/JobPosting.types";

export const insertJobPosting = async (
  companyId: number,
  userId: number,
  jobDescriptionId: number,
  params: JobPostingGeneratorForm
): Promise<AxiosResponse> => {
  return instance.post(`companies/${companyId}/job-postings`, {
    ...params,
    jobDescriptionId,
    hiringManagerId: Number(params.hiringManagerId),
    expirationDuration: params.expirationDate,
    officeLocation: params.locations,
    location: params.country,
    createdBy: userId,
  });
};

const JobPostingService = {
  insertJobPosting,
};

export default JobPostingService;
