import { InferType, object, string } from "yup";

export const JobDescriptionGeneratorFormSchema = object({
  role: string().strict().required(),
});
export type JobDescriptionGeneratorForm = InferType<typeof JobDescriptionGeneratorFormSchema>;

export const JobDescriptionFormSchema = object({
  overview: string().strict().required(),
  responsibilities: string(),
  qualifications: string(),
});
export type JobDescriptionForm = InferType<typeof JobDescriptionFormSchema>;

export type JobDescriptionFromGPT = {
  overview: string;
  responsibilities: Array<string>;
  qualifications: Array<string>;
  skills: Array<string>;
};

export type JobDescription = {
  overview: string;
  responsibilities: string;
  qualifications: string;
  roleId: number;
  role: string;
  responsibilityList: Array<string>;
  qualificationList: Array<string>;
  skills: Array<string>;
};

export type JobDescriptionListItem = {
  id: number;
  createdOn: string;
  roleName: string;
  fullName: string;
};

export type JobDescriptionListAPI = {
  totalCount: number;
  jobDescriptions: Array<JobDescriptionListItem>;
};
