import { decode } from "base64-arraybuffer";
import { DateTime } from "luxon";
import { instance } from "./base.api";
import { AxiosResponse } from "axios";

export const getFileName = (imageBase64: string) => {
  const date = new Date();
  const dateTime = DateTime.fromISO(date.toISOString(), { zone: "utc" });
  const formattedDate = dateTime.toFormat("yyyy-MM-dd_hh-mm-ss");
  const imageExtension = _getImageType(imageBase64);
  return `${formattedDate}.${imageExtension}`;
};

export const presignCompanyAvatar = async (fileName: string): Promise<AxiosResponse> => {
  return instance.post("s3/presign-url/companies", { fileName });
};

const _getImageType = (imageBase64: string) => {
  const regex = /^data:image\/([^;]+)/;
  const b64match = imageBase64.match(regex);
  if (b64match !== null) {
    const [, imageType] = b64match;
    return imageType;
  }
  return "";
};

export const upload = async (presignUrl: string, imageBase64: string) => {
  if (!presignUrl) {
    return;
  }
  const regex = /data:([^;]+);base64,(.+)/;
  const b64match = imageBase64.match(regex);
  if (b64match !== null) {
    const [, imageExtension, encodedImage] = b64match;
    const arrayBuffer = decode(encodedImage);
    const requestOptions = {
      method: "PUT",
      headers: { "Content-Type": imageExtension },
      body: arrayBuffer,
    };
    return await fetch(presignUrl, requestOptions);
  }
};

export const getUrl = (presignUrl: string) => {
  const regex = /^([^?]+)/;
  const urlMatch = presignUrl.match(regex);
  if (urlMatch !== null) {
    const [imageUrl] = urlMatch;
    return imageUrl;
  }
  return "";
};

export const presignedUserProfileImage = async (fileName: string): Promise<AxiosResponse<{ url: string }>> => {
  return instance.post("s3/presign-url/users", { fileName });
};

export const presignedUserResume = async (fileName: string): Promise<AxiosResponse<{ url: string }>> => {
  return instance.post("s3/presign-url/resume", { fileName });
};

const CompanyService = {
  getFileName,
  presignCompanyAvatar,
  upload,
  getUrl,
  presignedUserProfileImage,
  presignedUserResume,
};

export default CompanyService;
