import { TeamRoleRequest } from "utils/form-validations/team-generator/teamDetailsForm";

export type Company = {
  id: number;
  userId: number;
  name: string;
  publicUrl: string | null;
  website: string | null;
  industryId: number;
  industry: string;
  logoUrl: string | null;
  bannerUrl: string | null;
  about: string | null;
  verified: boolean;
  active: boolean;
  createdAt: Date;
  updatedAt: Date;
  employees: Array<Employee>;
  openings: Array<Opening>;
  interviewTokens: Array<InterviewToken>;
};

export type UserCompany = Company & {
  accessLevel: Array<AccessLevel>;
  ownerFirstName: string;
  ownerLastName: string;
};

export type CompanyApi = {
  id: number;
  userId: number;
  name: string;
  publicUrl: string | null;
  website: string | null;
  industryId: number;
  industry: string;
  logoUrl: string | null;
  bannerUrl: string | null;
  about: string | null;
  verified: boolean;
  active: boolean;
  createdAt: string;
  updatedAt: string;
};

export enum AccessLevel {
  employee = "employee",
  admin = "admin",
}

export type Employee = {
  firstName: string;
  lastName: string;
  accessLevel: AccessLevel;
  profilePictureUrl: string;
  id: number;
  prettyUrl: string;
  title: string | null;
};

export type CompanyEmployeeFormType = {
  companyId: number;
  userEmail: string;
  accessLevel: AccessLevel;
  addedBy: number;
  active: boolean;
};

export type TeamGeneratorDataRequest = {
  teamName: string;
  clientName: string;
  projectDescription: string;
  minNumOfRoles: number;
  roles: Array<TeamRoleRequest>;
  createdBy: number;
};

// TODO: change types once they are defined
// TODO: move to separate files if needed when types are defined
export type Opening = any;
export type InterviewToken = any;

export enum UserOwnershipFilter {
  ALL = "all",
  OWNED = "owned",
  INVITED = "invited",
}

export type ListUserCompanyFilters = {
  name?: string;
  ownership?: UserOwnershipFilter;
};
