import { GlobeAltIcon } from "@heroicons/react/24/outline";
import { CheckBadgeIcon, PencilSquareIcon } from "@heroicons/react/24/solid";
import { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import AppContext from "AppContext";
import CompanyService from "api/CompanyService";
import Avatar from "components/Avatar/Avatar";
import { useLoader } from "hooks/useLoader";
import { Company, Employee } from "types/Company.types";
import { BG_COLORS_AVATAR } from "utils/constants";
import { createCompleteUrl } from "utils/composeUrl";

const tabs = [
  { name: "Profile", href: "#", current: true },
  { name: "Jobs", href: "#", current: false },
];

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

export const CompanyProfilePage: React.FC = () => {
  const {
    session: { loggedUser },
  } = useContext(AppContext);
  const { applyLoader } = useLoader();
  const params = useParams();
  const companyId = params.companyId || "";
  const [companyDetails, setCompanyDetails] = useState<Company>();
  const [companyEmployees, setCompanyEmployees] = useState<Array<Employee>>([]);
  const navigate = useNavigate();
  useEffect(() => {
    const fetchCompanyDetails = async (): Promise<void> => {
      const { data } = await applyLoader(CompanyService.getCompanyDashboard(Number(companyId)));
      if (data) {
        setCompanyDetails(data);
      } else {
        navigate("/companies");
      }
    };

    const fetchCompanyEmployees = async (): Promise<void> => {
      const { data } = await applyLoader(CompanyService.getCompanyEmployees(Number(companyId)));
      setCompanyEmployees(data || []);
    };

    fetchCompanyDetails();
    fetchCompanyEmployees();
  }, [navigate, companyId, applyLoader]);

  const canEdit = () => {
    return companyDetails?.userId === loggedUser.id || companyEmployees.some((obj) => obj.id === loggedUser.id);
  };

  return (
    <article>
      {/* Profile header */}
      <div>
        <div>
          <img className="h-32 w-full object-cover lg:h-48" src={companyDetails?.bannerUrl || "/images/default_banner.png"} alt="" />
        </div>
        <div className="mx-auto max-w-5xl px-4 sm:px-6 lg:px-8">
          <div className="-mt-12 sm:-mt-16 sm:flex sm:items-end sm:space-x-5">
            <div className="flex">
              <Avatar
                nameParts={companyDetails?.name.split(" ") ?? [""]}
                imageUrl={companyDetails?.logoUrl ?? ""}
                textClasses="flex text-4xl font-bold leading-none text-white uppercase"
                sizeClasses={companyDetails?.verified ? "h-24 w-24 rounded-full ring-4 ring-amber-400" : "h-24 w-24 rounded-full"}
                bgClasses={["bg-orange-500", "bg-lime-500", "bg-emerald-500", "bg-sky-500", "bg-violet-500"]}
              />
            </div>
            <div className="-mb-8 sm:flex sm:min-w-0 sm:flex-1 sm:items-center sm:justify-end sm:space-x-6 sm:pb-3">
              <div className="flex min-w-0 flex-1 2xl:block">
                <h1 className="mb-5 inline-flex truncate text-2xl font-bold capitalize text-gray-900 sm:items-center">
                  {companyDetails?.verified ? (
                    <CheckBadgeIcon className="-ml-0.5 h-10 w-10 text-amber-400 drop-shadow-md" aria-hidden="true" />
                  ) : (
                    <div></div>
                  )}
                  {companyDetails?.name}
                </h1>
              </div>

              <div className="mt-6 flex flex-col justify-stretch space-y-3 sm:flex-row sm:space-x-4 sm:space-y-0">
                {canEdit() && (
                  <Link
                    to={`/companies/${companyDetails?.id}/edit-profile`}
                    className="inline-flex justify-center gap-x-1.5 rounded-md bg-varla-violet-100/90 px-3 py-2 text-sm text-white shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-varla-violet-100/80"
                  >
                    Edit Company Profile
                    <PencilSquareIcon className="h-5 w-5" aria-hidden="true" />
                  </Link>
                )}
                {companyDetails?.website && (
                  <a
                    href={createCompleteUrl(companyDetails.website)}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="inline-flex justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                  >
                    <GlobeAltIcon className="-ml-0.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                    Website
                  </a>
                )}
              </div>
            </div>
          </div>
          <div className="mt-6 hidden min-w-0 flex-1 sm:block 2xl:hidden">
            <h1 className="truncate text-2xl font-bold text-gray-900">{companyDetails?.name}</h1>
          </div>
        </div>
      </div>

      {/* Tabs */}
      <div className="mt-6 sm:mt-2 2xl:mt-5">
        <div className="border-b border-gray-200">
          <div className="mx-auto max-w-5xl px-4 sm:px-6 lg:px-8">
            <nav className="-mb-px flex space-x-8" aria-label="Tabs">
              {tabs.map((tab) => (
                <a
                  key={tab.name}
                  href={tab.href}
                  className={classNames(
                    tab.current
                      ? "border-violet-500 text-gray-900"
                      : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                    "whitespace-nowrap border-b-2 px-1 py-4 text-sm font-medium"
                  )}
                  aria-current={tab.current ? "page" : undefined}
                >
                  {tab.name}
                </a>
              ))}
            </nav>
          </div>
        </div>
      </div>

      {/* Description list */}
      <div className="mx-auto mt-6 max-w-5xl px-4 sm:px-6 lg:px-8">
        <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">Industry</dt>
            <dd className="mt-1 text-sm text-gray-900">{companyDetails?.industry}</dd>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">Team Size</dt>
            <dd className="mt-1 text-sm text-gray-900">{companyDetails?.employees.length! > 0 ? companyDetails?.employees.length : "-"}</dd>
          </div>
          <div className="sm:col-span-2">
            <dt className="text-sm font-medium text-gray-500">About</dt>
            <dd className="mt-1 max-w-prose space-y-5 text-sm text-gray-900" dangerouslySetInnerHTML={{ __html: companyDetails?.about! }} />
          </div>
        </dl>
      </div>

      {/* Team member list */}
      {companyEmployees && companyEmployees.length > 0 ? (
        <div className="mx-auto mt-8 max-w-5xl px-4 pb-12 sm:px-6 lg:px-8">
          <h2 className="text-sm font-medium text-gray-500">Team Members</h2>
          <div className="mt-1 grid grid-cols-1 gap-4 sm:grid-cols-2">
            {companyEmployees.map((person) => (
              <div
                key={person.id}
                className="relative flex items-center space-x-3 rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm focus-within:ring-2 focus-within:ring-violet-500 focus-within:ring-offset-2 hover:border-gray-400"
              >
                <div className="flex-shrink-0">
                  <Avatar
                    nameParts={[person.firstName ?? "", person.lastName ?? ""]}
                    imageUrl={person.profilePictureUrl}
                    textClasses="text-1xl font-bold leading-none text-white"
                    sizeClasses="h-10 w-10 rounded-full ring-2 ring-white sm:h-10 sm:w-10"
                    bgClasses={BG_COLORS_AVATAR}
                  />
                </div>
                <div className="min-w-0 flex-1">
                  <Link to="#" className="focus:outline-none">
                    <span className="absolute inset-0" aria-hidden="true" />
                    <p className="text-sm font-medium capitalize text-gray-900">{`${person.firstName} ${person.lastName}`}</p>
                    <p className="truncate text-sm capitalize text-gray-500">{person.title}</p>
                  </Link>
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div></div>
      )}
    </article>
  );
};

export default CompanyProfilePage;
