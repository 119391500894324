import React, { useEffect, useState } from "react";
import { Link, useOutletContext } from "react-router-dom";
import { Team, TeamInformation } from "types/Team.types";
import CompanyService from "api/CompanyService";
import { useLoader } from "hooks/useLoader";
import { NotificationType, useAppContext } from "AppContext";
import { NOTIFICATION_DISPLAY_TIME } from "utils/constants";
import tableFunctions, { SortOrder } from "../../../../shared/TableFunctions";
import { fullName } from "utils/full-name";
import { ArrowLongLeftIcon, ArrowLongRightIcon, ArrowsUpDownIcon, PlusCircleIcon } from "@heroicons/react/20/solid";
import Avatar from "components/Avatar/Avatar";
import useDebounce from "hooks/useDebounce";
import { CompanyContainerOutletContextType } from "../../CompanyContainerPage";
import TeamInfoModal from "components/Modal/TeamInfoModal";
import TeamService from "api/TeamService";

export const TEAMS_PER_PAGE = 10;

export const TeamListPage: React.FC<{}> = () => {
  const {
    notification: { showNotification },
  } = useAppContext();
  const { applyLoader } = useLoader();
  const [teams, setTeams] = useState<Array<Team>>([]);
  const [sortOrder, setSortOrder] = useState(SortOrder.ascending);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [pageNumbers, setPageNumbers] = useState([1]);
  const [searchValue, setSearchValue] = useState("");
  const [filteredTeams, setFilteredTeams] = useState<Array<Team>>([]);
  const debouncedSearchValue = useDebounce<string>(searchValue);
  const { company } = useOutletContext<CompanyContainerOutletContextType>();
  const [showTeamInfoModal, setShowTeamInfoModal] = useState(false);
  const [teamInfo, setTeamInfo] = useState<TeamInformation>();

  useEffect(() => {
    setFilteredTeams(
      teams.filter((team: Team) => {
        return team.name.toLowerCase().includes(debouncedSearchValue);
      })
    );
    setCurrentPage(1);
  }, [debouncedSearchValue, teams]);

  const handleSort = (event: React.MouseEvent<HTMLButtonElement>) => {
    const element = event.target as HTMLElement;
    const sortKey = element.dataset.value as keyof Team;
    if (sortKey) {
      setSortOrder(sortOrder === SortOrder.ascending ? SortOrder.descending : SortOrder.ascending);
      setFilteredTeams(tableFunctions.sort(filteredTeams, sortKey, sortOrder));
    }
  };

  const handlePageChange = (pageNumber: number) => {
    if (pageNumber < 1 || pageNumber > totalPages) return;
    setCurrentPage(pageNumber);
  };

  const getTeamInfo = async (teamId: number) => {
    const { data } = await applyLoader(TeamService.getTeam(company.id, teamId));
    setTeamInfo(data);
    setShowTeamInfoModal(true);
  };

  useEffect(() => {
    setTotalPages(tableFunctions.totalPages(filteredTeams.length, TEAMS_PER_PAGE));
  }, [filteredTeams]);

  useEffect(() => {
    setPageNumbers(tableFunctions.pageNumbers(totalPages));
    if (currentPage > totalPages) {
      setCurrentPage(1);
    }
  }, [totalPages, currentPage]);

  useEffect(() => {
    const fetchTeamsData = async (companyId: number) => {
      try {
        const { data } = await applyLoader(CompanyService.listTeams(companyId));
        setTeams(data);
      } catch (error) {
        showNotification({
          notificationType: NotificationType.Error,
          title: "Unable to fetch company teams data",
          message: error.message,
          displayTime: NOTIFICATION_DISPLAY_TIME,
        });
      }
    };
    fetchTeamsData(company.id);
  }, [applyLoader, company, showNotification]);

  const TeamsTableRows = (): JSX.Element => {
    const teamsRows: Team[] = tableFunctions.tablePage(filteredTeams, currentPage, TEAMS_PER_PAGE);
    if (teamsRows.length === 0) {
      return (
        <tr className="border-t border-gray-200">
          <td className="whitespace-nowrap px-6 py-4 text-center text-sm text-gray-500" colSpan={5}>
            No teams found
          </td>
        </tr>
      );
    }

    return (
      <>
        {teamsRows.map((teamsRow) => (
          <tr key={teamsRow.name}>
            <td className="whitespace-nowrap py-5 pl-4 pr-3 text-sm sm:pl-0">
              <div className="flex items-center">
                <div className="h-11 w-11 flex-shrink-0">
                  <Avatar
                    nameParts={teamsRow.name.split(" ") ?? [""]}
                    imageUrl={""}
                    textClasses="flex text-1xl font-bold leading-none text-white uppercase"
                    sizeClasses={"h-11 w-11 rounded-full"}
                    bgClasses={["bg-orange-500", "bg-lime-500", "bg-emerald-500", "bg-sky-500", "bg-violet-500"]}
                  />
                </div>
                <div className="ml-4">
                  <div className="font-medium capitalize text-gray-900">{teamsRow.name}</div>
                </div>
              </div>
            </td>
            <td className="whitespace-nowrap py-5 pl-4 pr-3 text-sm sm:pl-0">
              <div className="px-2 py-1 text-xs font-medium capitalize text-gray-900">{teamsRow.clientName}</div>
            </td>
            <td className="whitespace-nowrap py-5 pl-4 pr-3 text-sm sm:pl-0">
              <div className="px-2 py-1 text-xs font-medium capitalize text-gray-900">{fullName(teamsRow.createdBy)}</div>
            </td>
            <td className="whitespace-nowrap py-5 pl-4 pr-3 text-sm sm:pl-0">
              <div className="flex items-center">
                {teamsRow.employees.slice(0, 5).map((member) => (
                  <Avatar
                    nameParts={[member.firstName!, member.lastName!]}
                    imageUrl={member.profilePictureUrl}
                    textClasses="flex text-4 font-bold leading-none text-white uppercase"
                    sizeClasses={"h-6 w-6 rounded-full ring-2 ring-white hover:z-20 hover:motion-safe:animate-bounce cursor-pointer"}
                    bgClasses={["bg-orange-500", "bg-lime-500", "bg-emerald-500", "bg-sky-500", "bg-violet-500"]}
                  />
                ))}
                {teamsRow.employees.length > 5 ? (
                  <span
                    data-testid="avatarContainer"
                    className="inline-flex h-6 w-6 cursor-pointer items-center justify-center rounded-full bg-slate-500 ring-2 ring-white hover:z-20 hover:motion-safe:animate-bounce"
                  >
                    <span className="text-4 flex font-bold font-medium uppercase uppercase leading-none leading-none text-white text-white">
                      ...
                    </span>
                  </span>
                ) : (
                  ""
                )}
              </div>
            </td>
            <td className="relative whitespace-nowrap py-5 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
              <button
                data-testid={`View.${teamsRow.id}`}
                className="text-violet-600 hover:text-violet-900"
                onClick={() => getTeamInfo(teamsRow.id)}
              >
                View
              </button>
            </td>
          </tr>
        ))}
      </>
    );
  };

  return (
    <div className="lg:full flex flex-1 flex-col px-4 py-8 sm:px-6 lg:px-20 xl:px-24">
      <div className="min-h-screen">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <div className="mx-auto flex w-3/4 flex-col-reverse items-center gap-4 lg:flex-row lg:items-start">
              <div className="relative w-full flex-grow lg:w-fit">
                <label htmlFor="teamFilter" className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">
                  Team Filter
                </label>
                <input
                  type="text"
                  name="teamFilter"
                  id="teamFilter"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  placeholder="Team name"
                  onChange={(event) => {
                    setSearchValue(event.target.value.toLowerCase());
                  }}
                />
              </div>
              <Link
                to={`/companies/${company.id}/dashboard/team-generator`}
                className="inline-flex w-fit justify-center gap-x-1.5 whitespace-nowrap rounded-md bg-varla-violet-100/90 px-3 py-2 text-sm text-white shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-varla-violet-100/80"
              >
                Generate a new Team
                <PlusCircleIcon className="h-5 w-5" aria-hidden="true" />
              </Link>
            </div>
            <table className="min-w-full divide-y divide-gray-300">
              <thead>
                <tr>
                  <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                    <div className="sm:flex sm:items-center">
                      <p className="mr-10">Team Name</p>
                      <button onClick={handleSort} data-testid="team-sort">
                        <ArrowsUpDownIcon className="text-white-400 -ml-0.5 mr-auto h-5 w-5" aria-hidden="true" data-value="name" />
                      </button>
                    </div>
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    <div className="sm:flex sm:items-center">
                      <p className="mr-10">Client</p>
                      <button onClick={handleSort}>
                        <ArrowsUpDownIcon
                          className="text-white-400 -ml-0.5 mr-auto h-5 w-5 cursor-pointer"
                          aria-hidden="true"
                          data-value="clientName"
                        />
                      </button>
                    </div>
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    <div className="sm:flex sm:items-center">
                      <p className="mr-10">Created By</p>
                      <button onClick={handleSort}>
                        <ArrowsUpDownIcon
                          className="text-white-400 -ml-0.5 mr-auto h-5 w-5 cursor-pointer"
                          aria-hidden="true"
                          data-value="clientName"
                        />
                      </button>
                    </div>
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Members
                  </th>
                  <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
                    <span className="sr-only">View</span>
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                <TeamsTableRows />
              </tbody>
            </table>
            <nav className="flex items-center justify-between border-t border-gray-200 px-4 sm:px-0">
              <div className="-mt-px flex w-0 flex-1">
                <button
                  className="inline-flex items-center border-t-2 border-transparent pr-1 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
                  onClick={() => handlePageChange(currentPage - 1)}
                >
                  <ArrowLongLeftIcon className="mr-3 h-5 w-5 text-gray-400" aria-hidden="true" />
                  Previous
                </button>
              </div>
              <div className="hidden md:-mt-px md:flex">
                {pageNumbers.map((pageNumber) => (
                  <button
                    className={`inline-flex items-center border-t-2 border-transparent px-4 pt-4 text-sm font-medium ${
                      currentPage === pageNumber
                        ? "border-violet-500 text-violet-600"
                        : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700"
                    }`}
                    key={pageNumber}
                    aria-current={currentPage === pageNumber ? "page" : undefined}
                    onClick={() => handlePageChange(pageNumber)}
                  >
                    {pageNumber}
                    <span className="inline-flex items-center border-r-2 text-sm font-medium text-gray-500"></span>
                  </button>
                ))}
              </div>
              <div className="-mt-px flex w-0 flex-1 justify-end">
                <button
                  className="inline-flex items-center border-t-2 border-transparent pl-1 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
                  data-testid="nextPage"
                  onClick={() => handlePageChange(currentPage + 1)}
                >
                  Next
                  <ArrowLongRightIcon className="ml-3 h-5 w-5 text-gray-400" aria-hidden="true" />
                </button>
              </div>
            </nav>

            {showTeamInfoModal && <TeamInfoModal show={showTeamInfoModal} setOpen={setShowTeamInfoModal} data={teamInfo!} />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeamListPage;
