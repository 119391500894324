import { Dispatch, SetStateAction, useEffect, useState } from "react";
import Modal from "./Modal";
import Avatar from "components/Avatar/Avatar";
import { fullName } from "utils/full-name";
import { TeamInformation } from "types/Team.types";
import { CollectiveSkillsByName, CollectiveSkillsByRate } from "types/UserSkill.types";
import { skillTranslate } from "utils/skill-rating-translate";
import { CheckCircleIcon } from "@heroicons/react/24/solid";
import { MatchingSkills } from "types/User.types";

export type TeamInfoProps = {
  show: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  data: TeamInformation;
};

const TeamInfoModal = (teamInfoModalProps: TeamInfoProps): JSX.Element => {
  const { show, setOpen, data } = teamInfoModalProps;
  const modalTitle = "Team Information";
  const [teamCollectiveSkills, setTeamCollectiveSkills] = useState<MatchingSkills[]>([]);
  const [collectiveSkills, setCollectiveSkills] = useState<CollectiveSkillsByRate>({});
  const initialItemsToShow = 6;
  const [itemsToShow, setItemsToShow] = useState(initialItemsToShow);

  useEffect(() => {
    let teamSkills: MatchingSkills[] = [];
    data.employees.forEach(({ roleSkillsEmployees }) => {
      roleSkillsEmployees.forEach(({ skills }) => teamSkills.push(...skills));
    });
    setTeamCollectiveSkills(teamSkills);
  }, [data]);

  useEffect(() => {
    const skillsByName = teamCollectiveSkills.reduce<CollectiveSkillsByName>((acc, skill) => {
      const sanitizedSkillName = skill.name.toLowerCase().trim();
      if (!acc[sanitizedSkillName]) {
        acc[sanitizedSkillName] = { maxRate: skill.rate, skillName: skill.name };
      }
      acc[sanitizedSkillName].maxRate = Math.max(acc[sanitizedSkillName].maxRate, skill.rate);

      return acc;
    }, {});

    const skillsByRate = Object.values(skillsByName).reduce<CollectiveSkillsByRate>((acc, { maxRate, skillName }) => {
      if (!acc[maxRate]) {
        acc[maxRate] = { skills: [] };
      }
      acc[maxRate].skills.push(skillName);
      return acc;
    }, {});

    setCollectiveSkills(skillsByRate);
  }, [teamCollectiveSkills]);

  const handleLoadMore = () => {
    setItemsToShow(itemsToShow + 6);
  };

  return (
    <>
      <Modal show={show} onClose={() => setOpen(false)} title={modalTitle}>
        <div className="mt-2">
          <p>
            <strong>{data.teamName}</strong>
          </p>
          <p>
            <strong>Client:</strong> {data.clientName}
          </p>
        </div>
        <div className="mt-2">
          <div className="px-4 sm:px-6 lg:px-8">
            <div className="flow-root">
              <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                  <table className="min-w-full divide-y divide-gray-300">
                    <thead>
                      <tr>
                        <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                          Name
                        </th>
                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                          Title
                        </th>
                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                          Elephant
                        </th>
                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                          Raven
                        </th>
                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                          Owl
                        </th>
                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                          Eagle
                        </th>
                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                          Horse
                        </th>
                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                          Wolverine
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white">
                      {data.employees.map((employee) =>
                        employee.roleSkillsEmployees.map((row) => (
                          <tr key={row.id}>
                            <td className="whitespace-'no'wrap  text-sm sm:pl-0">
                              <div className="flex items-center">
                                <div className="h-6 w-6 flex-shrink-0">
                                  <Avatar
                                    imageUrl={row.profilePictureUrl}
                                    nameParts={[row.firstName!, row.lastName!]}
                                    sizeClasses="h-6 w-6 rounded-full"
                                    textClasses="text-sm"
                                  ></Avatar>
                                </div>
                                <div className="ml-3">
                                  <div className="font-medium capitalize text-gray-900">{fullName(row)}</div>
                                </div>
                              </div>
                            </td>
                            <td className="whitespace-'no'wrap px-3 py-5 text-sm text-gray-500">
                              <div className="capitalize text-gray-900">{employee.roleName}</div>
                            </td>
                            <td className="text-center text-sm leading-6">
                              {row.personality && row.personality["Elephant"] ? (
                                <div className="flex items-center justify-center gap-x-2">
                                  <div
                                    className={`text-varla-working-${row.personality["Elephant"].toLowerCase()} flex-none rounded-full p-1`}
                                  >
                                    <div className="h-1.5 w-1.5 rounded-full bg-current" />
                                  </div>
                                </div>
                              ) : (
                                "-"
                              )}
                            </td>
                            <td className="text-center text-sm leading-6">
                              {row.personality && row.personality["Raven"] ? (
                                <div className="flex items-center justify-center gap-x-2">
                                  <div
                                    className={`text-varla-working-${row.personality["Raven"].toLowerCase()} flex-none rounded-full p-1`}
                                  >
                                    <div className="h-1.5 w-1.5 rounded-full bg-current" />
                                  </div>
                                </div>
                              ) : (
                                "-"
                              )}
                            </td>
                            <td className="text-center text-sm leading-6">
                              {row.personality && row.personality["Owl"] ? (
                                <div className="flex items-center justify-center gap-x-2">
                                  <div className={`text-varla-working-${row.personality["Owl"].toLowerCase()} flex-none rounded-full p-1`}>
                                    <div className="h-1.5 w-1.5 rounded-full bg-current" />
                                  </div>
                                </div>
                              ) : (
                                "-"
                              )}
                            </td>
                            <td className="text-center text-sm leading-6">
                              {row.personality && row.personality["Eagle"] ? (
                                <div className="flex items-center justify-center gap-x-2">
                                  <div
                                    className={`text-varla-working-${row.personality["Eagle"].toLowerCase()} flex-none rounded-full p-1`}
                                  >
                                    <div className="h-1.5 w-1.5 rounded-full bg-current" />
                                  </div>
                                </div>
                              ) : (
                                "-"
                              )}
                            </td>
                            <td className="text-center text-sm leading-6">
                              {row.personality && row.personality["Horse"] ? (
                                <div className="flex items-center justify-center gap-x-2">
                                  <div
                                    className={`text-varla-working-${row.personality["Horse"].toLowerCase()} flex-none rounded-full p-1`}
                                  >
                                    <div className="h-1.5 w-1.5 rounded-full bg-current" />
                                  </div>
                                </div>
                              ) : (
                                "-"
                              )}
                            </td>
                            <td className="text-center text-sm leading-6">
                              {row.personality && row.personality["Wolverine"] ? (
                                <div className="flex items-center justify-center gap-x-2">
                                  <div
                                    className={`text-varla-working-${row.personality[
                                      "Wolverine"
                                    ].toLowerCase()} flex-none rounded-full p-1`}
                                  >
                                    <div className="h-1.5 w-1.5 rounded-full bg-current" />
                                  </div>
                                </div>
                              ) : (
                                "-"
                              )}
                            </td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          {Object.keys(collectiveSkills).length > 0 && (
            <div className="mt-2 text-center">
              <h4 className="text-2xl font-bold tracking-tight text-black">Collective Skills</h4>
              <div>
                <div className="mt-2 flow-root">
                  <div className="isolate -mt-16 grid max-w-sm grid-cols-1 gap-y-16 divide-y divide-gray-100 sm:mx-auto lg:-mx-8 lg:mt-0 lg:max-w-none lg:grid-cols-4 lg:divide-x lg:divide-y-0 xl:-mx-4">
                    {Object.keys(collectiveSkills).map((rate, index) => (
                      <div key={index} className="pt-16 lg:px-8 lg:pt-0 xl:px-14">
                        <h3 className="text-base font-semibold leading-7 text-gray-900">{skillTranslate.numericToName(parseInt(rate))}</h3>
                        <ul data-testid="rate" className="mt-6 space-y-3 text-left text-sm leading-6 text-gray-600">
                          {collectiveSkills[parseInt(rate, 10)].skills.map(
                            (skill, index) =>
                              index < itemsToShow && (
                                <li key={index} className="flex gap-x-3">
                                  <CheckCircleIcon className="h-6 w-5 flex-none text-violet-600" aria-hidden="true" />
                                  <span className="text-sm capitalize">{skill}</span>
                                </li>
                              )
                          )}
                        </ul>
                      </div>
                    ))}
                  </div>
                </div>
                <br />
                <button
                  type="button"
                  data-testid="load-more-button"
                  onClick={handleLoadMore}
                  className="mt-5 rounded-md bg-gray-300 px-3 py-2 text-sm font-semibold text-slate-600 shadow-sm hover:bg-slate-400 hover:text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-violet-500"
                >
                  Load More...
                </button>
              </div>
            </div>
          )}
        </div>

        <div className="mt-5 grid justify-items-end sm:mt-6">
          <button
            type="button"
            className="rounded-md border-2 px-3 py-2 text-sm font-semibold text-black shadow-sm hover:bg-gray-200"
            onClick={() => setOpen(false)}
          >
            Cancel
          </button>
        </div>
      </Modal>
    </>
  );
};

export default TeamInfoModal;
