import { yupResolver } from "@hookform/resolvers/yup";
import { isAxiosError } from "axios";
import React, { useContext, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import AppContext, { emptyUser } from "AppContext";
import AuthService from "api/AuthService";
import { AuthCredentialsFormType, AuthCredentialsType } from "types/UserLogin.types";
import { LoginValidate } from "utils/validateForm";
import { useLoader } from "hooks/useLoader";

const ImageBackground = "/images/rect2021-5.png";
const Logo = "/images/v2.png";
const homeRoute = "/";

export const LoginPage: React.FC<{}> = () => {
  const navigate = useNavigate();
  const {
    session: { setLoggedUser },
  } = useContext(AppContext);

  const { applyLoader } = useLoader();

  useEffect(() => {
    localStorage.removeItem("varlaLoginToken");
    setLoggedUser(emptyUser);
  }, [setLoggedUser]);

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setError,
  } = useForm<AuthCredentialsFormType>({
    resolver: yupResolver(LoginValidate),
  });

  const onSubmit = handleSubmit(async (formData) => {
    const user: AuthCredentialsType = { authCredentials: { ...formData } };
    try {
      const loginResponse = await applyLoader(AuthService.login(user));
      setLoggedUser(loginResponse.data);
      navigate(homeRoute);
    } catch (error) {
      if (isAxiosError(error)) {
        setError("email", {
          type: "custom",
          message: error.response?.data.message,
        });
      }
    }
  });

  return (
    <div className="flex min-h-screen">
      <div className="flex flex-1 flex-col justify-center px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
        <div className="mx-auto w-full max-w-sm lg:w-96">
          <div>
            <img className="h-12 w-auto" src={Logo} alt="Varla" />
            <h2 className="mt-6 text-3xl font-bold tracking-tight text-gray-900">Welcome back, User</h2>
          </div>

          <div className="mt-8">
            <div className="mt-6">
              <form onSubmit={onSubmit} className="space-y-6">
                <div>
                  <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                    Email address
                  </label>
                  <div className="mt-2">
                    <input
                      id="email"
                      {...register("email")}
                      type="text"
                      className="block w-full rounded-md border-0 py-1.5 lowercase shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-violet-600 sm:text-sm sm:leading-6"
                    />
                    {errors?.email && <div className="mt-2 block w-full py-1 text-sm text-red-700">{errors.email.message}</div>}
                  </div>
                </div>

                <div className="space-y-1">
                  <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                    Password
                  </label>
                  <div className="mt-2">
                    <input
                      id="password"
                      {...register("password")}
                      type="password"
                      className="placeholder: block w-full rounded-md border-0 py-1.5 text-gray-500 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-violet-600 sm:text-sm sm:leading-6"
                    />
                    {errors?.password && <div className="mt-2 block w-full py-1 text-sm text-red-700">{errors.password.message}</div>}
                  </div>
                </div>
                <div>
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className="flex w-full justify-center rounded-md bg-violet-600 px-3 py-2 text-lg font-semibold text-white shadow-sm hover:bg-violet-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-violet-600 disabled:opacity-25"
                  >
                    Sign in
                  </button>
                </div>
                <div className="mt-2">
                  <span className="block text-sm font-medium leading-6 tracking-wide text-gray-900">
                    Don't have an account?{" "}
                    <Link to="/registration" className="pl-1 font-bold text-black hover:text-violet-600">
                      Signup for free.
                    </Link>
                  </span>
                </div>
                <div className="mt-2">
                  <span className="block text-sm font-medium leading-6 tracking-wide text-gray-900">
                    <Link to="/reset-password-request" className="font-bold text-black hover:text-violet-600">
                      Forgot your password?
                    </Link>
                  </span>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="relative hidden w-0 flex-1 lg:block">
        <img className="absolute inset-0 h-full w-full object-cover" src={ImageBackground} alt="" />
      </div>
    </div>
  );
};

export default LoginPage;
