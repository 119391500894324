import { Menu, Transition } from "@headlessui/react";
import { ArrowsUpDownIcon, UserMinusIcon } from "@heroicons/react/20/solid";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { NotificationType, useAppContext } from "AppContext";
import UserConnectionService from "api/UserConnectionService";
import { Avatar } from "components";
import ConfirmationAlertModal from "components/Modal/ConfirmationAlertModal";
import useDebounce from "hooks/useDebounce";
import { useLoader } from "hooks/useLoader";
import { UserConnectionListItem } from "types/UserConnection.types";
import { NOTIFICATION_DISPLAY_TIME } from "utils/constants";
import { fullName } from "utils/full-name";
import { classNames } from "utils/helpers";
import { SortOrder } from "../../shared/TableFunctions";

type GroupedUserConnections = { [key: string]: UserConnectionListItem[] };

export const ConnectionsPage: React.FC = () => {
  const [userConnections, setUserConnections] = useState<GroupedUserConnections>({});
  const [rawUserConnections, setRawUserConnections] = useState<UserConnectionListItem[]>([]);
  const [searchKey, setSearchKey] = useState<string>("");
  const debouncedSearchValue = useDebounce<string>(searchKey);

  const {
    session: { loggedUser },
    notification: { showNotification },
  } = useAppContext();
  const { applyLoader } = useLoader();

  const sortByFullName = useCallback((userConnections: Array<UserConnectionListItem>, sortOrder: SortOrder) => {
    userConnections.sort((connectionA, connectionB) => {
      const fullNameA = fullName(connectionA).toLowerCase();
      const fullNameB = fullName(connectionB).toLowerCase();
      return sortOrder * fullNameA.localeCompare(fullNameB, undefined, { ignorePunctuation: true, sensitivity: "base" });
    });
  }, []);

  const groupByFirstName = useCallback(
    (userConnections: Array<UserConnectionListItem>): GroupedUserConnections =>
      userConnections.reduce<GroupedUserConnections>((groupedConnections, connection) => {
        const firstLetter = connection.firstName.charAt(0).toUpperCase();
        if (!groupedConnections[firstLetter]) {
          groupedConnections[firstLetter] = [];
        }
        groupedConnections[firstLetter].push(connection);
        return groupedConnections;
      }, {}),
    []
  );

  const filterAndSort = useCallback(
    (userConnections: Array<UserConnectionListItem>, sortOrder: SortOrder): GroupedUserConnections => {
      const filteredConnections = userConnections.filter((connection) => fullName(connection).toLowerCase().includes(debouncedSearchValue));
      sortByFullName(filteredConnections, sortOrder);
      return groupByFirstName(filteredConnections);
    },
    [sortByFullName, groupByFirstName, debouncedSearchValue]
  );

  useEffect(() => {
    const fetchUserConnection = async (): Promise<void> => {
      const { data } = await applyLoader(UserConnectionService.listUserConnections(Number(loggedUser.id)));
      if (data) {
        setRawUserConnections(data);
      }
    };
    fetchUserConnection();
  }, [loggedUser, applyLoader]);

  useEffect(() => {
    setUserConnections(filterAndSort(rawUserConnections, SortOrder.ascending));
  }, [rawUserConnections, filterAndSort]);

  const handleDeleteConnection = async (connectedUser: number, createdBy: number) => {
    ConfirmationAlertModal({
      title: "Disconnect",
      message: `Are you sure you want to disconnect?`,
      action: "Confirm",
      onAction: async () => await deleteUserConnection(connectedUser, createdBy),
    });
  };

  const deleteUserConnection = async (connectedUser: number, createdBy: number) => {
    try {
      await applyLoader(UserConnectionService.deleteUserConnection(createdBy, connectedUser));
      showNotification({
        notificationType: NotificationType.Success,
        title: "Success",
        message: "Disconnected successfully",
        displayTime: NOTIFICATION_DISPLAY_TIME,
      });
      const newRawUserConnections = rawUserConnections.filter((x) => !(x.connectedUser === connectedUser && x.createdBy === createdBy));
      setRawUserConnections(newRawUserConnections);
    } catch (error) {
      showNotification({
        notificationType: NotificationType.Error,
        title: "Error",
        message: error.response ? "Failed to disconnect from user" : error.message,
        displayTime: NOTIFICATION_DISPLAY_TIME,
      });
    }
  };

  return (
    <div className="lg:full flex flex-1 flex-col px-4 py-8 sm:px-6 lg:px-20 xl:px-24">
      <h1 className="mb-4 text-5xl font-bold text-gray-900">Your Connections</h1>
      <p className="mb-2 text-gray-500">This is the spot where you can see all of your connections!</p>
      <nav className="h-full overflow-y-auto">
        <div className="flex p-6">
          <div className="relative m-auto w-1/2">
            <label htmlFor="name" className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">
              Name
            </label>
            <input
              type="text"
              name="userConnectionFilter"
              id="userConnectionFilter"
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              placeholder="Jane Smith"
              onChange={(event) => {
                setSearchKey(event.target.value.toLowerCase());
              }}
            />
          </div>

          <Menu as="div" className="relative inline-block text-left">
            <div>
              <Menu.Button
                data-testid="sort-dropdown"
                className="inline-flex w-full justify-center gap-x-1.5 rounded-full bg-white p-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
              >
                <ArrowsUpDownIcon className="h-5 w-5" aria-hidden="true" />
              </Menu.Button>
            </div>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute right-0 z-20 mt-2 w-28 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div className="py-1">
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        onClick={() => setUserConnections(filterAndSort(rawUserConnections, SortOrder.ascending))}
                        className={classNames(active ? "text-violet-600" : "text-gray-700", "block w-full px-4 py-2 text-left text-sm")}
                      >
                        A - Z
                      </button>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        onClick={() => setUserConnections(filterAndSort(rawUserConnections, SortOrder.descending))}
                        className={classNames(active ? "text-violet-600" : "text-gray-700", "block w-full px-4 py-2 text-left text-sm")}
                      >
                        Z - A
                      </button>
                    )}
                  </Menu.Item>
                </div>
              </Menu.Items>
            </Transition>
          </Menu>
        </div>
        <div className="mx-auto mt-6 w-full lg:w-2/3">
          {Object.keys(userConnections).map((letter) => (
            <div key={letter} className="relative">
              <div className="sticky top-0 z-10 border-y border-b-gray-200 border-t-gray-100 bg-gray-50 px-3 py-1.5 text-sm font-semibold leading-6 text-gray-900">
                <h3>{letter}</h3>
              </div>
              <ul className="divide-y divide-gray-100">
                {userConnections[letter].map((connection) => (
                  <li key={connection.id} className="flex gap-x-4 px-3 py-5">
                    <Avatar
                      imageUrl={connection.profilePictureUrl}
                      nameParts={[connection.firstName, connection.lastName]}
                      sizeClasses="h-12 w-12 rounded-full"
                      textClasses="text-sm"
                    />
                    <div className="min-w-0">
                      <Link
                        to={`/profile/${connection.prettyUrl}`}
                        className="text-sm font-semibold leading-6 text-gray-900 hover:text-violet-600"
                      >
                        {fullName(connection)}
                      </Link>
                      <p className="mt-1 truncate text-xs leading-5 text-gray-500"></p>
                      <span className="inline-flex items-center rounded-full bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                        {connection.connectionType}
                      </span>
                    </div>

                    <button
                      data-testid={`disconnect-btn-${connection.id}`}
                      className="ml-auto"
                      onClick={() => handleDeleteConnection(connection.connectedUser, connection.createdBy)}
                    >
                      <UserMinusIcon className="pointer-events-none h-5 w-5 text-gray-400" aria-hidden="true" />
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </nav>
    </div>
  );
};

export default ConnectionsPage;
