import { InferType, object, string } from "yup";

export enum ConnectionType {
  COWORKER = "Coworker",
  CLIENT = "Client",
  PARTNER = "Partner",
  OTHER = "Other",
}

export const userConnectionFormSchema = object({
  connectedUser: string().strict().required(),
  connectionType: string().strict().required().oneOf(Object.values(ConnectionType)),
});
export type UserConnectionFormType = InferType<typeof userConnectionFormSchema>;

export interface UserConnectionListItem {
  id: number;
  createdBy: number;
  connectedUser: number;
  connectionType: ConnectionType;
  createdAt: Date;
  firstName: string;
  lastName: string;
  prettyUrl: string;
  profilePictureUrl: string | null;
}
