import { PhotoIcon, UserCircleIcon } from "@heroicons/react/24/solid";
import { yupResolver } from "@hookform/resolvers/yup";
import AppContext, { NotificationType } from "AppContext";
import S3Service from "api/S3Service";
import UserService from "api/UserService";
import LocationDropdown from "components/Dropdown/LocationDropdown";
import ImageCropperModal from "components/Modal/ImageCropperModal";
import { ModalAction, ModalOnCloseFunction, handleModalOnCloseResult } from "components/Modal/Modal";
import { useMaxLength } from "hooks/InputValidations";
import { useLoader } from "hooks/useLoader";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { UserProfile } from "types/User.types";
import { MAX_LENGTH_25, MAX_LENGTH_50, MAX_LENGTH_500, NOTIFICATION_DISPLAY_TIME } from "utils/constants";
import { ImageCropper } from "utils/form-validations/companyProfileForm";
import { UserProfileForm, userProfileSchema } from "utils/form-validations/profileForm";
import { Country } from "country-state-city";

const isUuid = (input: string) => {
  const uuidv4Regex = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-4[0-9a-fA-F]{3}-[89abAB][0-9a-fA-F]{3}-[0-9a-fA-F]{12}$/;
  return uuidv4Regex.test(input);
};

export const EditUserProfilePage: React.FC = () => {
  const {
    session: { loggedUser, setLoggedUser },
    notification: { showNotification },
  } = useContext(AppContext);
  const navigate = useNavigate();
  const profileRoute = "/profile";
  const message = "User profile updated successfully!";
  const [showImageCropper, setShowImageCropper] = useState(false);
  const [userDetails, setUserDetails] = useState<UserProfile>();
  const [modalTitle, setModalTitle] = useState("");
  const [modalDescription, setModalDescription] = useState("");
  const [cropDescription, setCropDescription] = useState("");
  const [aspectRadioImage, setAspectRadioImage] = useState(0);
  const [classNameImagePreview, setClassNameImagePreview] = useState("");
  const { applyLoader } = useLoader();
  const [profileImage, setProfileImage] = useState("");
  const [profileUrl, setProfileUrl] = useState("");
  const [bannerImage, setBannerImage] = useState("");
  const [bannerUrl, setBannerUrl] = useState("");
  const [showSuggestedPrettyUrl, setShowSuggestedPrettyUrl] = useState(false);
  const [suggestedPrettyUrl, setSuggestedPrettyUrl] = useState("");
  let countryData = Country.getAllCountries();
  countryData.sort((a, b) => a.name.localeCompare(b.name));
  const [userCountry, setUserCountry] = useState(countryData[0]);

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
  } = useForm<UserProfileForm>({ resolver: yupResolver(userProfileSchema) });

  useEffect(() => {
    const fetchUserDetails = async (): Promise<void> => {
      const { data } = await applyLoader(UserService.getProfile(loggedUser.prettyUrl));
      if (data) {
        setUserDetails(data);
        setUserCountry(
          countryData.find((country) => country.name.trim().toLowerCase() === data.location!.trim().toLowerCase()) || countryData[0]
        );
        setProfileImage(data.profilePictureUrl!);
        setBannerImage(data.bannerUrl!);
      }
    };
    const fetchSuggestedPrettyUrl = async (): Promise<void> => {
      const { data } = await applyLoader(UserService.suggestPrettyUrl(loggedUser.email));
      if (data) {
        setSuggestedPrettyUrl(data.suggestedPrettyUrl);
      }
    };
    fetchUserDetails();
    if (isUuid(loggedUser.prettyUrl)) {
      setShowSuggestedPrettyUrl(true);
      fetchSuggestedPrettyUrl();
    }
  }, [loggedUser, applyLoader, countryData]);

  useEffect(() => {
    const prettyUrl = showSuggestedPrettyUrl ? suggestedPrettyUrl : userDetails?.prettyUrl;
    reset({ ...userDetails, prettyUrl });
  }, [userDetails, reset, showSuggestedPrettyUrl, suggestedPrettyUrl]);

  const inputRefFirstName = useRef<HTMLInputElement | null>(null);
  const inputRefLastName = useRef<HTMLInputElement | null>(null);
  const inputRefPrettyUrl = useRef<HTMLInputElement | null>(null);
  const inputRefAboutMe = useRef<HTMLTextAreaElement | null>(null);

  const { ref: refFirstName, ...restFirstName } = register("firstName");
  const { ref: refLastName, ...restLastName } = register("lastName");
  const { ref: refPrettyUrl, ...restPrettyUrl } = register("prettyUrl");
  const { ref: refAboutMe, ...restAboutMe } = register("aboutMe");

  const maxLengthFirstName = useMaxLength({
    inputRef: inputRefFirstName,
    maxLength: MAX_LENGTH_50,
  });

  const maxLengthLastName = useMaxLength({
    inputRef: inputRefLastName,
    maxLength: MAX_LENGTH_50,
  });

  const maxLengthPrettyUrl = useMaxLength({
    inputRef: inputRefPrettyUrl,
    maxLength: MAX_LENGTH_25,
  });

  const maxLengthAboutMe = useMaxLength({
    inputRef: inputRefAboutMe,
    maxLength: MAX_LENGTH_500,
  });

  const onSubmit = handleSubmit(async (formData) => {
    try {
      const apiCalls = [];
      if (profileUrl !== "") {
        apiCalls.push(S3Service.upload(profileUrl, profileImage));
        formData.profilePictureUrl = profileUrl;
      }

      if (bannerUrl !== "") {
        apiCalls.push(S3Service.upload(bannerUrl, bannerImage));
        formData.bannerUrl = bannerUrl;
      }

      formData.location = userCountry.name;

      apiCalls.push(UserService.updateProfile(loggedUser.id, formData));

      await applyLoader(Promise.all(apiCalls));

      showNotification({
        notificationType: NotificationType.Success,
        title: "Success",
        message,
        displayTime: NOTIFICATION_DISPLAY_TIME,
      });
      setLoggedUser({
        ...loggedUser,
        ...formData,
        title: "user",
      });

      navigate(profileRoute);
    } catch (error) {
      const errorMessage = error.response ? error.response.data.message : error.message;
      showNotification({
        notificationType: NotificationType.Error,
        title: "Failed to update user profile",
        message: errorMessage,
        displayTime: NOTIFICATION_DISPLAY_TIME,
      });
    }
  });

  const onCloseModal = useCallback<ModalOnCloseFunction<ImageCropper>>(
    (onCloseResult) => {
      handleModalOnCloseResult(onCloseResult, {
        [ModalAction.Add]: async (imageCropper) => {
          const fileName = S3Service.getFileName(imageCropper.imageBase64);
          const { data } = await applyLoader(S3Service.presignedUserProfileImage(fileName));
          if (imageCropper.modalTitle.includes("Profile")) {
            setProfileImage(imageCropper.imageBase64);
            setProfileUrl(S3Service.getUrl(data.url));
          }

          if (imageCropper.modalTitle.includes("Banner")) {
            setBannerImage(imageCropper.imageBase64);
            setBannerUrl(S3Service.getUrl(data.url));
          }
        },
      });
      setShowImageCropper(false);
    },
    [applyLoader]
  );

  const setProfileModalProps = () => {
    setShowImageCropper(true);
    setModalTitle("Profile Image");
    setModalDescription("Upload your profile picture to personalize your account.");
    setCropDescription("Please crop your profile picture to ensure it fits perfectly within the designated area.");
    setAspectRadioImage(1);
    setClassNameImagePreview("h-24 w-24 rounded-full ring-4 ring-white sm:h-24 sm:w-24 overflow-hidden");
  };

  const setBannerModalProps = () => {
    setShowImageCropper(true);
    setModalTitle("Banner Image");
    setModalDescription("Upload your banner picture to personalize your account.");
    setCropDescription("Please crop your banner picture to ensure it fits perfectly within the designated area.");
    setAspectRadioImage(16 / 9);
    setClassNameImagePreview("h-14 w-28 ring-4 ring-white sm:h-14 sm:w-28 overflow-hidden");
  };

  return (
    <div className="flex min-h-screen ">
      <div className="lg:full flex flex-1 flex-col justify-center px-4 py-12 sm:px-6 lg:px-20 xl:px-24">
        <div className="mx-auto w-full lg:w-full">
          <form onSubmit={onSubmit}>
            <div className="space-y-12">
              <div className="border-b border-gray-900/10 pb-32">
                <div className="grid grid-cols-1 gap-x-6 gap-y-8 pb-6 sm:grid-cols-6">
                  <div className="col-span-full sm:col-span-3">
                    <label htmlFor="profilePictureUrl" className="block text-sm font-medium leading-6 text-gray-900">
                      Photo
                    </label>

                    <div className="mt-2 flex flex-col items-center gap-4 rounded-lg px-6 py-10">
                      {profileImage === "" ? (
                        <UserCircleIcon className="h-32 w-32 text-gray-300" aria-hidden="true" />
                      ) : (
                        <img className="h-32 w-32 rounded-full ring-4 ring-white" src={profileImage} alt="profileImage" />
                      )}
                      <button
                        type="button"
                        className="mt-1 rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                        onClick={setProfileModalProps}
                      >
                        Change
                      </button>
                    </div>
                  </div>

                  <div className="col-span-full sm:col-span-3">
                    <label htmlFor="cover-photo" className="block text-sm font-medium leading-6 text-gray-900">
                      Cover photo
                    </label>

                    <div className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
                      <div className="justify-center text-center">
                        {bannerImage === "" ? (
                          <PhotoIcon className="mx-auto h-32 w-32 text-gray-300" aria-hidden="true" />
                        ) : (
                          <img className="full inline h-32 w-64 ring-4 ring-white" src={bannerImage} alt="bannerImage" />
                        )}

                        <div className="mt-4 flex justify-center text-sm leading-6 text-gray-600">
                          <label htmlFor="file-upload" className="relative cursor-default rounded-md bg-white font-semibold ">
                            <button
                              className="m-0 cursor-pointer appearance-none p-0 text-inherit text-violet-600 outline-none focus-within:outline-none focus-within:ring-2 focus-within:ring-violet-600 focus-within:ring-offset-2 hover:bg-transparent hover:text-violet-500 hover:ring-0 focus:bg-transparent focus:ring-0 active:bg-transparent active:ring-0 active:ring-opacity-0"
                              onClick={setBannerModalProps}
                              type="button"
                            >
                              Upload a file
                            </button>
                            <input id="file-upload" name="file-upload" type="file" className="sr-only" disabled />
                          </label>
                        </div>
                        <p className="text-xs leading-5 text-gray-600">PNG, JPG, GIF up to 10MB</p>
                      </div>
                    </div>
                  </div>

                  <ImageCropperModal
                    show={showImageCropper}
                    setOpen={setShowImageCropper}
                    modalTitle={modalTitle}
                    modalDescription={modalDescription}
                    cropDescription={cropDescription}
                    aspectRatio={aspectRadioImage}
                    classNameImagePreview={classNameImagePreview}
                    onClose={onCloseModal}
                  />
                </div>
                <h2 className="text-base font-semibold leading-7 text-gray-900">Profile</h2>
                <p className="mt-1 text-sm leading-6 text-gray-600">
                  This information will be displayed publicly so be careful what you share.
                </p>
                <div data-testid="abcd" className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                  <div className="sm:col-span-3">
                    <label htmlFor="firstName" className="block text-sm font-medium leading-6 text-gray-900">
                      First name
                    </label>
                    <div className="mt-2">
                      <input
                        type="text"
                        id="firstName"
                        {...restFirstName}
                        ref={(e) => {
                          refFirstName(e);
                          inputRefFirstName.current = e;
                        }}
                        maxLength={MAX_LENGTH_50}
                        className="block w-full rounded-md border-0 py-1.5 capitalize text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-violet-600 sm:text-sm sm:leading-6"
                      />
                      {errors?.firstName && <div className="mt-2 block w-full py-1 text-sm text-red-700">{errors.firstName.message}</div>}
                      {maxLengthFirstName && <div className="mt-2 block w-full py-1 text-sm text-orange-400">{maxLengthFirstName}</div>}
                    </div>
                  </div>

                  <div className="sm:col-span-3">
                    <label htmlFor="lastName" className="block text-sm font-medium leading-6 text-gray-900">
                      Last name
                    </label>
                    <div className="mt-2">
                      <input
                        type="text"
                        id="lastName"
                        {...restLastName}
                        ref={(e) => {
                          refLastName(e);
                          inputRefLastName.current = e;
                        }}
                        maxLength={MAX_LENGTH_50}
                        className="block w-full rounded-md border-0 py-1.5 capitalize text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-violet-600 sm:text-sm sm:leading-6"
                      />
                      {errors?.lastName && <div className="mt-2 block w-full py-1 text-sm text-red-700">{errors.lastName.message}</div>}
                      {maxLengthLastName && <div className="mt-2 block w-full py-1 text-sm text-orange-400">{maxLengthLastName}</div>}
                    </div>
                  </div>

                  <div className="sm:col-span-3">
                    <label htmlFor="userName" className="block text-sm font-medium leading-6 text-gray-900">
                      Username
                    </label>
                    <div className="mt-2">
                      <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-violet-600 sm:max-w-md">
                        <span className="flex select-none items-center pl-3 text-gray-500 sm:text-sm">varla.app.com/</span>
                        <input
                          type="text"
                          id="userName"
                          {...restPrettyUrl}
                          ref={(e) => {
                            refPrettyUrl(e);
                            inputRefPrettyUrl.current = e;
                          }}
                          maxLength={MAX_LENGTH_25}
                          className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                          placeholder="john-doe"
                        />
                      </div>
                      {errors?.prettyUrl && <div className="mt-2 block w-full py-1 text-sm text-red-700">{errors.prettyUrl.message}</div>}
                      {maxLengthPrettyUrl && <div className="mt-2 block w-full py-1 text-sm text-orange-400">{maxLengthPrettyUrl}</div>}
                      {showSuggestedPrettyUrl && (
                        <div className="ml-auto mt-2 w-full py-1 text-sm text-orange-400">(Suggested username)</div>
                      )}
                    </div>
                  </div>

                  <div className="sm:col-span-3">
                    <label htmlFor="location" className="block text-sm font-medium leading-6 text-gray-900">
                      Location
                    </label>
                    <div className="mt-2">
                      <LocationDropdown data={countryData} selected={userCountry} setSelected={setUserCountry} />
                    </div>
                  </div>

                  <div className="col-span-full">
                    <label htmlFor="aboutMe" className="block text-sm font-medium leading-6 text-gray-900">
                      About
                    </label>
                    <div className="mt-2">
                      <textarea
                        id="aboutMe"
                        rows={4}
                        {...restAboutMe}
                        ref={(e) => {
                          refAboutMe(e);
                          inputRefAboutMe.current = e;
                        }}
                        maxLength={MAX_LENGTH_500}
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-violet-600 sm:text-sm sm:leading-6"
                      />
                      {errors?.aboutMe && <div className="mt-2 block w-full py-1 text-sm text-red-700">{errors.aboutMe.message}</div>}
                      {maxLengthAboutMe && <div className="mt-2 block w-full py-1 text-sm text-orange-400">{maxLengthAboutMe}</div>}
                    </div>
                    <p className="mt-3 text-sm leading-6 text-gray-600">Write a few sentences about yourself.</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-6 flex items-center justify-end gap-x-6">
              <Link
                to="/profile"
                className="rounded-md bg-red-700 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-700"
              >
                Cancel
              </Link>
              <button
                type="submit"
                className="rounded-md bg-violet-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-violet-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-violet-600 disabled:opacity-25"
                disabled={isSubmitting}
              >
                Save
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditUserProfilePage;
