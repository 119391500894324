import { InferType, object, string } from "yup";
import { ALPHA_NUMERIC_REGEX, MAX_LENGTH_25, MAX_LENGTH_50, MAX_LENGTH_500, MIN_LENGTH_4 } from "../constants";

export const userProfileSchema = object().shape({
  firstName: string().strict().required("First name is required").max(MAX_LENGTH_50).nullable(),
  lastName: string().strict().required("Last name is required").max(MAX_LENGTH_50).nullable(),
  prettyUrl: string()
    .strict()
    .required("Username is required")
    .min(MIN_LENGTH_4, "Username should be at least 4 characters long")
    .max(MAX_LENGTH_25)
    .matches(ALPHA_NUMERIC_REGEX, "Username should only consist in letters and numbers"),
  location: string().strict().required("Location is required").nullable(),
  aboutMe: string().strict().required("About is required").max(MAX_LENGTH_500).nullable(),
  profilePictureUrl: string().strict().nullable(),
  bannerUrl: string().strict().nullable(),
  resume: string().strict().nullable(),
});
export type UserProfileForm = InferType<typeof userProfileSchema>;
