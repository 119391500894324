import { RoleWithSkillsType } from "types/Role.types";
import { MatchingRoleEmployee } from "types/User.types";
import { COMPLETED_ROLE_REGEX, COMPLETED_SKILL_REGEX, LIST_NUMBER_REGEX, REQUIRED_PERCENT_SKILLS } from "utils/constants";
import { TeamRole } from "utils/form-validations/team-generator/teamDetailsForm";

type RolesFromContent = {
  completedRoles: Array<string>;
  incompleteContent: string;
};

type SkillsFromContent = {
  completedSkills: Array<string>;
  incompleteContent: string;
};

export const extractRolesFromContent = (
  content: string,
  completedRoles: Array<string> = [],
  mightBeComplete: string = ""
): RolesFromContent => {
  const completedMatch = content.match(COMPLETED_ROLE_REGEX);
  if (completedMatch) {
    const [, contentToProcess] = completedMatch;
    const contentToProcessIndex = content.indexOf(contentToProcess);
    const role = content.substring(contentToProcessIndex + contentToProcess.length);
    if (!mightBeComplete) {
      return extractRolesFromContent(contentToProcess, completedRoles, role);
    }
    completedRoles.push(role.replace(LIST_NUMBER_REGEX, "").trim());
    return extractRolesFromContent(contentToProcess, completedRoles, mightBeComplete);
  }
  if (mightBeComplete) {
    completedRoles.push(content.replace(LIST_NUMBER_REGEX, "").trim());
    return {
      completedRoles: completedRoles,
      incompleteContent: mightBeComplete,
    };
  }
  return {
    completedRoles: completedRoles,
    incompleteContent: content,
  };
};

export const teamSuggestedRoleFromContent = (content: string): TeamRole => {
  const splitRegex = /^(.*):(.*)/;
  const contentMatch = content.match(splitRegex);

  if (contentMatch === null) {
    throw new Error(`Invalid generated role: ${content}`);
  }

  const [, roleName, description] = contentMatch;

  return {
    roleName,
    description,
    amount: 1,
  };
};

/**
 * Recursively searches content from right to left for completed skills.
 * @param content accum content from stream chunks
 * @param completedSkills recursive variable. array of verified completed skills
 * @param mightBeComplete recursive variable. possible completed skill
 * @returns {SkillsFromContent} {@link SkillsFromContent}
 */
export const extractSkillsFromContent = (
  content: string,
  completedSkills: Array<string> = [],
  mightBeComplete: string = ""
): SkillsFromContent => {
  const completedMatch = content.match(COMPLETED_SKILL_REGEX);
  if (completedMatch) {
    const [, contentToProcess] = completedMatch;
    const contentToProcessIndex = content.indexOf(contentToProcess);
    const skill = content.substring(contentToProcessIndex + contentToProcess.length);
    if (!mightBeComplete) {
      return extractSkillsFromContent(contentToProcess, completedSkills, skill);
    }
    completedSkills.push(skill.replace(LIST_NUMBER_REGEX, "").trim());
    return extractSkillsFromContent(contentToProcess, completedSkills, mightBeComplete);
  }
  if (mightBeComplete) {
    completedSkills.push(content.replace(LIST_NUMBER_REGEX, "").trim());
    return {
      completedSkills: completedSkills,
      incompleteContent: mightBeComplete,
    };
  }
  return {
    completedSkills: completedSkills,
    incompleteContent: content,
  };
};

const requiredPercentOfSkills = (employeeMatchingSkills: string[], suggestedSkills: string[]): boolean => {
  const matchingSkillsCount = employeeMatchingSkills.filter((userMatchingSkill) => suggestedSkills.includes(userMatchingSkill)).length;
  const requiredMatchingSkillsCount = Math.ceil(suggestedSkills.length * REQUIRED_PERCENT_SKILLS);
  return matchingSkillsCount >= requiredMatchingSkillsCount;
};

export const filterEmployees = (
  suggestedRoleEmployees: MatchingRoleEmployee[],
  rolesWithSkills: RoleWithSkillsType[]
): MatchingRoleEmployee[] => {
  return suggestedRoleEmployees.map((suggestedRoleEmployee) => {
    const matchingRole = rolesWithSkills.find((role) => role.roleName.toLowerCase() === suggestedRoleEmployee.roleName.toLowerCase());
    if (matchingRole) {
      const matchedEmployees = suggestedRoleEmployee.roleSkillsEmployees.filter((roleSkillsEmployee) => {
        const employeeSkills = roleSkillsEmployee.skills.map((skill) => skill.name.toLowerCase());
        const suggestedSkills = matchingRole.roleSkills.map((skill) => skill.toLowerCase());
        return requiredPercentOfSkills(employeeSkills, suggestedSkills);
      });
      // priority to employees within the maximum value of skill rate
      matchedEmployees.sort(
        (a, b) => b.skills.reduce((sum, skill) => sum + skill.rate, 0) - a.skills.reduce((sum, skill) => sum + skill.rate, 0)
      );
      // priority to employees within the desired role
      matchedEmployees.sort((a, b) => (b.hasRole ? 1 : -1) - (a.hasRole ? 1 : -1));
      matchedEmployees.map((employee) => (employee.checked = true));
      return {
        ...suggestedRoleEmployee,
        roleSkillsEmployees: matchedEmployees,
      };
    }
    return suggestedRoleEmployee;
  });
};

const TeamGeneratorService = {
  teamSuggestedRoleFromContent,
  extractRolesFromContent,
  filterEmployees,
};

export default TeamGeneratorService;
