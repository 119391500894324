import { AddRoleFormType } from "types/Role.types";

export type AddRoleErrors = {
  roleName?: string;
  numOfMembers?: string;
};

export const formValidation = async (values: AddRoleFormType): Promise<AddRoleErrors> => {
  const errors: AddRoleErrors = {};

  if (!values.roleName) {
    errors.roleName = "Role name is required";
  }

  if (values.numOfMembers < 1) {
    errors.numOfMembers = "Team member number must be greater than 0";
  }

  if (values.numOfMembers > 10) {
    errors.numOfMembers = "Team member number must be less than 10";
  }

  return errors;
};

const AddRole = {
  formValidation,
};

export default AddRole;
