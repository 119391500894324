import { useCallback } from "react";
import { useAppContext } from "AppContext";

export function useLoader() {
  const {
    loader: { incrementLoadingCount, decrementLoadingCount },
  } = useAppContext();

  const applyLoader = useCallback(
    async <T extends unknown>(loaderRequester: Promise<T>) => {
      incrementLoadingCount();
      try {
        const response = await loaderRequester;
        decrementLoadingCount();
        return response;
      } catch (error) {
        decrementLoadingCount();
        throw error;
      }
    },
    [incrementLoadingCount, decrementLoadingCount]
  );
  return { applyLoader };
}
