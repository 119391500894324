import { Dispatch, Fragment, SetStateAction, useState } from "react";
import { Combobox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { ICountry } from "country-state-city";
import Flag from "react-world-flags";

type Props = {
  data: ICountry[];
  selected: ICountry;
  setSelected: Dispatch<SetStateAction<ICountry>>;
};

export default function LocationDropdown(props: Props) {
  const [query, setQuery] = useState("");
  const { data, selected, setSelected } = props;

  const filteredData =
    query === ""
      ? data
      : data.filter((item) => item.name.toLowerCase().replace(/\s+/g, "").includes(query.toLowerCase().replace(/\s+/g, "")));

  return (
    <div className="w-full">
      <Combobox value={selected} onChange={setSelected}>
        <div className="relative mt-1">
          <div className="flex w-full cursor-default overflow-hidden rounded-lg bg-white text-left align-middle shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm">
            <Flag code={selected.isoCode} className="relative left-3 flex items-center align-middle" height={20} width={20} />
            <Combobox.Input
              key={selected.isoCode}
              data-testid="country-input"
              className="ml-3 w-full border-none py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 outline-none focus:ring-0"
              displayValue={(item: ICountry) => item.name}
              onChange={(event) => setQuery(event.target.value)}
            />
            <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2" data-testid="open-dropdown">
              <ChevronUpDownIcon className="h-5 w-5 text-gray-400 hover:text-gray-500" aria-hidden="true" />
            </Combobox.Button>
          </div>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            afterLeave={() => setQuery("")}
          >
            <Combobox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-gray-100 py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {filteredData.length === 0 && query !== "" ? (
                <div key={"NotFound"} className="relative cursor-default select-none px-4 py-2 text-gray-700">
                  Nothing found.
                </div>
              ) : (
                filteredData.map((item: ICountry) => (
                  <div key={item.isoCode}>
                    <Flag code={item.isoCode} className="relative inset-y-6 left-3" height={20} width={20} />
                    <Combobox.Option
                      key={item.isoCode}
                      className={({ active }) =>
                        `relative cursor-default select-none py-2 pl-12 ${active ? "bg-violet-300 text-white opacity-80" : "text-gray-900"}`
                      }
                      value={item}
                    >
                      {({ selected, active }) => (
                        <>
                          <span className={`block truncate ${selected ? "font-medium" : "font-normal"}`}>{item.name}</span>
                          {selected ? (
                            <span
                              className={`absolute inset-y-0 right-5 flex items-center pl-1 ${active ? "text-white" : "text-violet-600"}`}
                            >
                              <CheckIcon className="h-5 w-5" aria-hidden="true" />
                            </span>
                          ) : null}
                        </>
                      )}
                    </Combobox.Option>
                  </div>
                ))
              )}
            </Combobox.Options>
          </Transition>
        </div>
      </Combobox>
    </div>
  );
}
