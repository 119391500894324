import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import AuthService from "api/AuthService";

const VerificationFailed = () => (
  <div className="bg-white">
    <div className="px-6 py-24 sm:px-6 sm:py-32 lg:px-8">
      <div className="mx-auto max-w-2xl text-center">
        <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Email Verification Failed!</h2>
        <p className="mx-auto mt-6 max-w-xl text-lg leading-8 text-gray-600">
          The verification link is either invalid or has expired.
          <br />
          Please contact Tech Support.
        </p>
      </div>
    </div>
  </div>
);

const VerificationSuccess = () => (
  <div className="bg-white">
    <div className="px-6 py-24 sm:px-6 sm:py-32 lg:px-8">
      <div className="mx-auto max-w-2xl text-center">
        <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Verification Completed!</h2>
        <p className="mx-auto mt-6 max-w-xl text-lg leading-8 text-gray-600">
          Thank you, your email has been verified. Your account is now active.
          <br />
          Please use the link below to login into your account.
        </p>
        <div className="mt-10 flex items-center justify-center gap-x-6">
          <Link
            to="/login"
            className="rounded-md bg-violet-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-violet-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-violet-600"
          >
            Log in
          </Link>
        </div>
      </div>
    </div>
  </div>
);

export const VerificationPage: React.FC<{}> = () => {
  const emailRegex = /email=([^&]+)/;
  const codeRegex = /code=([^&]+)/;
  const url = new URL(window.location.href);

  const params = url.search;
  const emailMatch = params.match(emailRegex);
  const codeMatch = params.match(codeRegex);
  const email = emailMatch ? emailMatch[1] : "";
  const code = codeMatch ? codeMatch[1] : "";
  const requestRef = useRef(false);
  const [verified, setVerified] = useState<boolean | null>(null);

  const verifyEmail = async (email: string, code: string) => {
    try {
      await AuthService.verification(email, code);
      setVerified(true);
    } catch (error) {
      setVerified(false);
    }
  };

  useEffect(() => {
    if (requestRef.current) return;
    requestRef.current = true;
    if (!email || !code) {
      setVerified(false);
      return;
    }
    setVerified(null);
    verifyEmail(email, code);
  }, [email, code]);

  // Wait for verification result
  if (verified === null) {
    return <div>Verifying...</div>;
  }
  return verified ? <VerificationSuccess /> : <VerificationFailed />;
};

export default VerificationPage;
