import { Popover, Transition } from "@headlessui/react";
import { PlusCircleIcon } from "@heroicons/react/20/solid";
import { ChevronDownIcon, Cog6ToothIcon, UserCircleIcon } from "@heroicons/react/24/outline";
import { NotificationType, useAppContext } from "AppContext";
import CompanyService from "api/CompanyService";
import { Avatar } from "components";
import ConfirmationAlertModal from "components/Modal/ConfirmationAlertModal";
import InsertCompanyEmployeeModal from "components/Modal/InsertCompanyEmployeeModal";
import Pagination from "components/Pagination/Pagination";
import { useDebounce } from "hooks/useDebounce";
import { useLoader } from "hooks/useLoader";
import { Fragment, useEffect, useState } from "react";
import { Link, useOutletContext } from "react-router-dom";
import { Employee } from "types/Company.types";
import { BG_COLORS_AVATAR, NOTIFICATION_DISPLAY_TIME } from "utils/constants";
import { errorMessage } from "utils/errors";
import { fullName } from "utils/full-name";
import { CompanyContainerOutletContextType } from "../../CompanyContainerPage";

const ITEMS_PER_PAGE = 10;

export const CompanyDirectoryPage: React.FC = () => {
  const { company } = useOutletContext<CompanyContainerOutletContextType>();
  const [currentPage, setCurrentPage] = useState(1);
  const [employeeSearchValue, setSearchValue] = useState("");
  const [displayedEmployees, setDisplayedEmployees] = useState<Array<Employee>>([]);
  const [filteredEmployees, setFilteredEmployees] = useState<Array<Employee>>([]);
  const debouncedEmployeeSearchValue = useDebounce<string>(employeeSearchValue);
  const [showInviteEmployeeModal, setShowInviteEmployeeModal] = useState(false);

  const {
    notification: { showNotification },
  } = useAppContext();
  const { applyLoader } = useLoader();

  useEffect(() => {
    setFilteredEmployees(
      company.employees.filter((employee: Employee) => {
        return (
          fullName(employee).toLowerCase().includes(debouncedEmployeeSearchValue) ||
          employee.title?.toLowerCase().includes(debouncedEmployeeSearchValue)
        );
      })
    );
    setCurrentPage(1);
  }, [debouncedEmployeeSearchValue, company.employees]);

  useEffect(() => {
    const indexOfLastItem = currentPage * ITEMS_PER_PAGE;
    const indexOfFirstItem = indexOfLastItem - ITEMS_PER_PAGE;
    setDisplayedEmployees(filteredEmployees.slice(indexOfFirstItem, indexOfLastItem));
  }, [currentPage, filteredEmployees]);

  const deleteInvitation = async (employeeId: number) => {
    try {
      await applyLoader(CompanyService.removeEmployee(company.id, employeeId));
      showNotification({
        notificationType: NotificationType.Success,
        title: "Success",
        message: "Employee Removed Successfully",
        displayTime: NOTIFICATION_DISPLAY_TIME,
      });
      const removedIndex = company.employees.findIndex((employee) => employee.id === employeeId);
      company.employees.splice(removedIndex, 1);
      company.employees = [...company.employees];
    } catch (error) {
      showNotification({
        notificationType: NotificationType.Error,
        title: "Error",
        message: errorMessage(error),
        displayTime: NOTIFICATION_DISPLAY_TIME,
      });
    }
  };

  const handleRemove = (employeeId: number) => () => {
    ConfirmationAlertModal({
      title: "Remove Employee",
      message: "Are you sure you want to remove this employee?",
      action: "Remove",
      onAction: () => deleteInvitation(employeeId),
    });
  };

  return (
    <>
      <InsertCompanyEmployeeModal companyId={company.id} setOpen={setShowInviteEmployeeModal} show={showInviteEmployeeModal} />
      <main className="pb-8">
        <div className="mx-auto max-w-3xl px-4 sm:px-6 lg:max-w-7xl lg:px-8">
          <div className="grid grid-cols-1 items-start gap-4 lg:grid-cols-2 lg:gap-8">
            <div className="grid grid-cols-1 gap-4 lg:col-span-2">
              <section>
                <div className="my-5 flex flex-col-reverse items-center gap-4 md:flex-row">
                  <div className="relative w-full md:flex-grow">
                    <label
                      htmlFor="employeeFilter"
                      className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900"
                    >
                      Employee Filter
                    </label>
                    <input
                      type="text"
                      name="employeeFilter"
                      id="employeeFilter"
                      className="block w-full rounded-md border-0 py-1.5 capitalize text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-violet-600 sm:text-sm sm:leading-6"
                      placeholder="Name"
                      onChange={(event) => {
                        setSearchValue(event.target.value.toLowerCase());
                      }}
                    />
                  </div>
                  <button
                    type="button"
                    className="inline-flex w-fit justify-center gap-x-1.5 whitespace-nowrap rounded-md bg-varla-violet-100/90 px-3 py-2 text-sm text-white shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-varla-violet-100/80"
                    onClick={() => setShowInviteEmployeeModal(true)}
                  >
                    Invite Employee
                    <PlusCircleIcon className="h-5 w-5" aria-hidden="true" />
                  </button>
                </div>
                <ul className="grid grid-cols-1 gap-6 pb-4 sm:grid-cols-2 lg:grid-cols-3">
                  {displayedEmployees.map((employee) => (
                    <li key={employee.id} className="col-span-1 divide-y divide-gray-200 rounded-lg bg-white shadow">
                      <div className="flex w-full items-center justify-between space-x-6 p-6">
                        <div className="flex-1 truncate">
                          <div className="flex items-center space-x-3">
                            <h3 className="truncate text-sm font-medium capitalize text-gray-900">{fullName(employee)}</h3>
                            <span className="inline-flex flex-shrink-0 items-center rounded-full bg-green-50 px-1.5 py-0.5 text-xs font-medium capitalize text-green-700 ring-1 ring-inset ring-green-600/20">
                              {employee.accessLevel}
                            </span>
                          </div>
                          <p className="mt-1 truncate text-sm capitalize text-gray-500">{employee.title}</p>
                        </div>
                        <Avatar
                          nameParts={[employee.firstName, employee.lastName]}
                          imageUrl={employee.profilePictureUrl}
                          textClasses="font-bold leading-none text-white"
                          sizeClasses="h-10 w-10 rounded-full ring-4 ring-white"
                          bgClasses={BG_COLORS_AVATAR}
                        />
                      </div>
                      <div>
                        <div className="-mt-px flex divide-x divide-gray-200">
                          <div className="flex w-0 flex-1">
                            <Link
                              to={`/profile/${employee.prettyUrl}`}
                              className="relative -mr-px inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-bl-lg border border-transparent py-4 text-sm font-semibold text-gray-900"
                            >
                              <UserCircleIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                              Profile
                            </Link>
                          </div>
                          <div className="-ml-px flex w-0 flex-1">
                            <div className="relative -mr-px inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-bl-lg border border-transparent py-4 text-sm font-semibold text-gray-900">
                              <Popover className="relative">
                                <Popover.Button
                                  data-testid={`${employee.id}.open-options`}
                                  className="inline-flex items-center gap-x-1 text-sm font-semibold leading-6 text-gray-900"
                                >
                                  <Cog6ToothIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                  <span>Options</span>
                                  <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
                                </Popover.Button>
                                <Transition
                                  as={Fragment}
                                  enter="transition ease-out duration-200"
                                  enterFrom="opacity-0 translate-y-1"
                                  enterTo="opacity-100 translate-y-0"
                                  leave="transition ease-in duration-150"
                                  leaveFrom="opacity-100 translate-y-0"
                                  leaveTo="opacity-0 translate-y-1"
                                >
                                  <Popover.Panel className="absolute left-1/2 z-10 mt-5 flex w-screen max-w-max -translate-x-1/2 px-4">
                                    <div className="w-screen max-w-sm flex-auto rounded-3xl bg-white p-4 text-sm leading-6 shadow-lg ring-1 ring-gray-900/5">
                                      <div className="relative rounded-lg p-4 hover:bg-gray-50">
                                        <button
                                          data-testid="remove-employee"
                                          onClick={handleRemove(employee.id)}
                                          className={`font-semibold text-gray-900 text-red-700`}
                                        >
                                          Remove
                                          <span className="absolute inset-0" />
                                        </button>
                                        <p className="mt-1 text-gray-600">Remove access from this company</p>
                                      </div>
                                    </div>
                                  </Popover.Panel>
                                </Transition>
                              </Popover>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
                <Pagination
                  itemsLength={filteredEmployees.length}
                  itemsPerPage={ITEMS_PER_PAGE}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                />
              </section>
            </div>
          </div>
        </div>
      </main>
      <footer>
        <div className="mx-auto max-w-3xl px-4 sm:px-6 lg:max-w-7xl lg:px-8">
          <div className="border-t border-gray-200 py-8 text-center text-sm text-gray-500 sm:text-left">
            <span className="block sm:inline">&copy; 2023 Varla, Inc.</span> <span className="block sm:inline">All rights reserved.</span>
          </div>
        </div>
      </footer>
    </>
  );
};

export default CompanyDirectoryPage;
