import { GuideFaqPage } from "./GuideFaq";
import { GuideProfilePage } from "./GuideProfilePage";
import { GuideTeamGeneratorPage } from "./GuideTeamGenerator";
import { GuideJobDescriptionPage } from "./GuideJobDesciptionPage";
import { GuideCompaniesPage } from "./GuideCompaniesPage";
import "./Guide.css";
import TabPane from "components/Tabs/TabPane";
import Tabs from "components/Tabs/Tabs";

export const GuidePage: React.FC = () => {
  return (
    <>
      <div className="bg-white py-4 sm:py-2">
        <div className="px-6 lg:px-8">
          <div className="">
            <p className="text-base font-semibold leading-7 text-violet-600">Get the help you need</p>
            <h2 className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">Guide center</h2>
            <p className="mt-6 text-lg leading-8 text-gray-600">
              Discover Varla's Guide Page: Your roadmap to mastering our web application. Start your journey today!{" "}
            </p>
          </div>
        </div>
      </div>
      <Tabs classNames="-mb-px flex space-x-8 tabs">
        <TabPane title="FAQs">
          <GuideFaqPage></GuideFaqPage>
        </TabPane>
        <TabPane title="Profile">
          <GuideProfilePage></GuideProfilePage>
        </TabPane>
        <TabPane title="Companies">
          <GuideCompaniesPage></GuideCompaniesPage>
        </TabPane>
        <TabPane title="Team Generator">
          <GuideTeamGeneratorPage></GuideTeamGeneratorPage>
        </TabPane>
        <TabPane title="Job Description">
          <GuideJobDescriptionPage></GuideJobDescriptionPage>
        </TabPane>
      </Tabs>
    </>
  );
};

export default GuidePage;
