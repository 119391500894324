import { ArrowsUpDownIcon, ChevronRightIcon, PlusCircleIcon } from "@heroicons/react/20/solid";
import { format, parseISO } from "date-fns";
import React, { useCallback, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import CompanyService from "api/CompanyService";
import Pagination from "components/Pagination/Pagination";
import useDebounce from "hooks/useDebounce";
import { useLoader } from "hooks/useLoader";
import { JobPostingListAPI, JobPostingListItem } from "types/JobPosting.types";
import tableFunctions, { SortOrder } from "pages/shared/TableFunctions";
import { Avatar } from "components";
import { Tooltip } from "react-tooltip";
import Modal from "components/Modal/Modal";
import { formatAndSanitize, numberToCurrency } from "utils/helpers";

const ITEMS_PER_PAGE = 10;

export const JobPostingListPage: React.FC<{}> = () => {
  const { applyLoader } = useLoader();
  const params = useParams();
  const companyId = params.companyId;
  const [currentPage, setCurrentPage] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [selectedJobPosting, setSelectedJobPosting] = useState<JobPostingListItem | undefined>();
  const [jobPostingList, setJobPostingList] = useState<JobPostingListAPI | null>();
  const debouncedSearchValue = useDebounce<string>(searchValue);

  const fetchJobPostings = useCallback(
    async (currentPage: number) => {
      const { data } = await applyLoader(
        CompanyService.listJobPostings(companyId, {
          limit: ITEMS_PER_PAGE,
          offset: ITEMS_PER_PAGE * (currentPage - 1),
          filter: debouncedSearchValue,
        })
      );

      setJobPostingList(data);
    },
    [debouncedSearchValue, companyId, applyLoader]
  );

  useEffect(() => {
    setCurrentPage(1);
  }, [debouncedSearchValue]);

  useEffect(() => {
    fetchJobPostings(currentPage);
  }, [currentPage, fetchJobPostings]);
  const [sortOrder, setSortOrder] = useState(SortOrder.ascending);

  const handleSort = (event: React.MouseEvent<HTMLButtonElement>) => {
    const element = event.target as HTMLElement;
    const sortKey = element.dataset.value as keyof JobPostingListItem;
    setSortOrder(sortOrder === SortOrder.ascending ? SortOrder.descending : SortOrder.ascending);

    const sorted = tableFunctions.sort(jobPostingList?.jobPostings!, sortKey, sortOrder);
    setJobPostingList({
      totalCount: jobPostingList?.totalCount!,
      jobPostings: sorted,
    });
  };

  const getStatus = (row: JobPostingListItem) => {
    if (row.hiringCompanyId) {
      return <span className="rounded-md bg-purple-200 p-1.5 text-purple-800">Hired</span>;
    } else if (parseISO(row.expiresOn) < new Date()) {
      return <span className="rounded-md bg-red-200 p-1.5 text-red-800">Expired</span>;
    }

    return <span className="rounded-md bg-lime-200 p-1.5 text-lime-800">Active</span>;
  };

  const JobPostingTableRows = (): JSX.Element => {
    return (
      <>
        {jobPostingList?.jobPostings.map((jobPostingRow, index) => (
          <tr key={index}>
            <td className="whitespace-nowrap py-5 pl-4 pr-3 text-sm sm:pl-0">
              <div className="px-2 py-1 text-xs font-medium capitalize text-gray-900">{jobPostingRow.roleName}</div>
            </td>
            <td className="whitespace-nowrap py-5 pl-4 pr-3 text-sm sm:pl-0">
              <div className="flex items-center">
                <Avatar
                  nameParts={jobPostingRow.hiringManagerName.split(" ")}
                  imageUrl={""}
                  textClasses="flex text-1xl font-bold leading-none text-white uppercase"
                  sizeClasses={"h-8 w-8 rounded-full"}
                />
                <div className="ml-4">
                  <div className="font-medium capitalize text-gray-900">{jobPostingRow.hiringManagerName}</div>
                </div>
              </div>
            </td>
            <td className="whitespace-nowrap py-5 pl-4 pr-3 text-sm sm:pl-0">
              <div className="flex items-center">
                <Avatar
                  nameParts={jobPostingRow.creatorName.split(" ")}
                  imageUrl={""}
                  textClasses="flex text-1xl font-bold leading-none text-white uppercase"
                  sizeClasses={"h-8 w-8 rounded-full"}
                />
                <div className="ml-4">
                  <div className="font-medium capitalize text-gray-900">{jobPostingRow.creatorName}</div>
                </div>
              </div>
            </td>
            <td className="whitespace-nowrap py-5 pl-4 pr-3 text-sm sm:pl-0">
              <div className="px-2 py-1 text-xs font-medium capitalize text-gray-900">
                {format(parseISO(jobPostingRow.createdOn), "PP")}
              </div>
            </td>
            <td className="whitespace-nowrap py-5 pl-4 pr-3 text-sm sm:pl-0">
              <div className="px-2 py-1 text-xs font-medium capitalize text-gray-900">
                {format(parseISO(jobPostingRow.expiresOn), "PP")}
              </div>
            </td>
            <td className="whitespace-nowrap py-5 pl-4 pr-3 text-sm sm:pl-0">
              <div className="px-2 py-1 text-xs font-medium capitalize text-gray-900">{getStatus(jobPostingRow)}</div>
            </td>
            <td className="relative whitespace-nowrap py-5 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
              <button
                data-testid={`View.${jobPostingRow.id}`}
                className="text-violet-600 hover:text-violet-900"
                onClick={() => setSelectedJobPosting(jobPostingRow)}
              >
                View
              </button>
              <Tooltip id="actions-tooltip" />
            </td>
          </tr>
        ))}
      </>
    );
  };

  const JobPostingViewModal = (jobPosting: JobPostingListItem): JSX.Element => {
    return (
      <Modal show={true} onClose={() => setSelectedJobPosting(undefined)}>
        <div className="flex max-w-6xl flex-1 flex-col px-4 sm:px-6 lg:px-20 xl:px-24">
          <div className="m-auto flex w-full flex-1 flex-col gap-4 px-4 pt-4">
            <div className="m-auto flex w-full items-center gap-2 text-2xl">
              <label htmlFor="teamName" className="font-medium text-varla-violet-100">
                Role:
              </label>
              <h2 className="font-bold capitalize text-gray-900">{jobPosting.roleName}</h2>
            </div>
            <div>
              <h3 className="text-xl font-bold capitalize text-gray-900">Details</h3>
              <div className="flex flex-col gap-4 p-4">
                <div className="w-full text-sm sm:grid sm:grid-cols-2">
                  <label>Company</label>
                  <div className="flex gap-2">
                    <Avatar
                      nameParts={jobPosting.companyName.split(" ")}
                      imageUrl={jobPosting.companyLogoUrl}
                      textClasses="flex justify-center items-center font-bold leading-none text-white"
                      sizeClasses="h-6 w-6 rounded-full"
                    />
                    <div className="truncate">{jobPosting.companyName}</div>
                    <ChevronRightIcon className="h-4 w-4" />
                    <div className="truncate text-gray-400">{jobPosting.industry}</div>
                  </div>
                </div>
                <div className="w-full text-sm sm:grid sm:grid-cols-2">
                  <label>Hiring Manager</label>
                  <div className="flex gap-2">
                    <Avatar
                      imageUrl={jobPosting.profilePictureUrl}
                      nameParts={jobPosting.hiringManagerName.split(" ")}
                      sizeClasses="h-6 w-6 rounded-full"
                      textClasses="text-sm"
                    />
                    <div className="truncate">{jobPosting.hiringManagerName}</div>
                  </div>
                </div>
                <div className="w-full text-sm sm:grid sm:grid-cols-2">
                  <label>Location</label>
                  <div className="flex gap-2">
                    {jobPosting.officeLocation.join(" / ")}
                    {jobPosting.location && <span>&#x2022; {jobPosting.location}</span>}
                  </div>
                </div>
                <div className="w-full text-sm sm:grid sm:grid-cols-2">
                  <label>Salary Range</label>
                  <div>
                    {numberToCurrency(jobPosting.minSalary, "en-US", "USD")} - {numberToCurrency(jobPosting.maxSalary, "en-US", "USD")}
                  </div>
                </div>
                <div className="w-full text-sm sm:grid sm:grid-cols-2">
                  <label>Posting expiration</label>
                  <div>{format(new Date(jobPosting.expiresOn), "PP")}</div>
                </div>
              </div>
            </div>
            <div>
              <h2 className="text-xl font-bold capitalize text-gray-900">Overview</h2>
              <div>{jobPosting.overview}</div>
            </div>
            <div>
              <h2 className="text-xl font-bold capitalize text-gray-900">Responsibilities</h2>
              <div
                data-testid="responsibilitiesContainer"
                dangerouslySetInnerHTML={{ __html: formatAndSanitize(jobPosting.responsibilities) }}
                className="pl-16"
              />
            </div>
            <div>
              <h2 className="text-xl font-bold capitalize text-gray-900">Qualifications</h2>
              <div
                data-testid="qualificationsContainer"
                dangerouslySetInnerHTML={{ __html: formatAndSanitize(jobPosting.qualifications) }}
                className="pl-16"
              />
            </div>
          </div>
        </div>
        <div className="flex flex-row-reverse items-end justify-between space-x-3 border-t border-gray-200 px-2 py-2 sm:px-3">
          <button
            type="button"
            className="flex rounded-md bg-violet-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-violet-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-violet-500"
            onClick={() => setSelectedJobPosting(undefined)}
          >
            Close
          </button>
        </div>
      </Modal>
    );
  };

  return (
    <div className="lg:full xl:px-18 mt-4 flex flex-1 flex-col px-4 sm:px-6 lg:px-12">
      {selectedJobPosting && JobPostingViewModal(selectedJobPosting)}
      <div className="mx-auto flex w-3/4 flex-col-reverse items-center gap-4 lg:flex-row lg:items-start">
        <div className="relative w-full flex-grow lg:w-fit">
          <label htmlFor="jobPostingFilter" className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">
            Job Postings Filter
          </label>
          <input
            type="text"
            name="jobPostingFilter"
            id="jobPostingFilter"
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            placeholder="Role"
            onChange={(event) => {
              setSearchValue(event.target.value.toLowerCase());
            }}
          />
        </div>
        <Link
          to={`/companies/${companyId}/dashboard/job-posting-generator`}
          className="inline-flex w-fit justify-center gap-x-1.5 whitespace-nowrap rounded-md bg-varla-violet-100/90 px-3 py-2 text-sm text-white shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-varla-violet-100/80"
        >
          Generate a new Job Posting
          <PlusCircleIcon className="h-5 w-5" aria-hidden="true" />
        </Link>
      </div>
      <div className="mx-auto w-3/4">
        {jobPostingList && jobPostingList.totalCount > 0 ? (
          <>
            <table className="min-w-full divide-y divide-gray-300">
              <thead>
                <tr>
                  <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                    <div className="sm:flex sm:items-center">
                      <p className="mr-10">Role</p>
                      <button onClick={handleSort} data-testid="roleName-sort">
                        <ArrowsUpDownIcon className="text-white-400 -ml-0.5 mr-auto h-5 w-5" aria-hidden="true" data-value="roleName" />
                      </button>
                    </div>
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    <div className="sm:flex sm:items-center">
                      <p className="mr-10">Hiring Manager</p>
                      <button onClick={handleSort}>
                        <ArrowsUpDownIcon
                          className="text-white-400 -ml-0.5 mr-auto h-5 w-5 cursor-pointer"
                          aria-hidden="true"
                          data-value="hiringManagerName"
                        />
                      </button>
                    </div>
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    <div className="sm:flex sm:items-center">
                      <p className="mr-10">Created By</p>
                      <button onClick={handleSort}>
                        <ArrowsUpDownIcon
                          className="text-white-400 -ml-0.5 mr-auto h-5 w-5 cursor-pointer"
                          aria-hidden="true"
                          data-value="creatorName"
                        />
                      </button>
                    </div>
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Posted On
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Expires On
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Status
                  </th>
                  <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
                    <span className="sr-only">View</span>
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                <JobPostingTableRows />
              </tbody>
            </table>
            <Pagination
              itemsLength={jobPostingList.totalCount}
              itemsPerPage={ITEMS_PER_PAGE}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          </>
        ) : (
          <p className="text-sm font-semibold leading-6 text-gray-900">There are currently no Job Postings for your company</p>
        )}
      </div>
    </div>
  );
};

export default JobPostingListPage;
