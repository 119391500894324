import React, { useEffect, useReducer } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { NotificationType, useAppContext } from "AppContext";
import { NOTIFICATION_DISPLAY_TIME } from "utils/constants";

export type PrivateRouteCondition = {
  check: () => boolean;
  onFailedCheck: {
    redirect: string;
    errorMessage: string;
    errorTitle: string;
  };
};

export type PrivateRouteProps = {
  conditions: Array<PrivateRouteCondition>;
  outlet: JSX.Element;
};

export const PrivateRoute: React.FC<PrivateRouteProps> = ({ conditions, outlet }) => {
  const {
    notification: { showNotification },
  } = useAppContext();
  const location = useLocation();
  const [privateRoute, dispatchPrivateRoute] = useReducer(() => {
    const unmetCondition = conditions.find((condition) => !condition.check());
    if (!unmetCondition) {
      return outlet;
    }
    const { redirect, errorMessage, errorTitle } = unmetCondition.onFailedCheck;
    showNotification({
      notificationType: NotificationType.Error,
      message: errorMessage,
      title: errorTitle,
      displayTime: NOTIFICATION_DISPLAY_TIME,
    });
    return <Navigate to={{ pathname: redirect }} />;
  }, <></>);

  useEffect(() => {
    dispatchPrivateRoute();
  }, [location]);

  return privateRoute;
};
