import { ReactElement, useState } from "react";

import TabTitle from "./TabTitle";
import { Props as TabPaneProps } from "./TabPane";

type Props = {
  classNames: string;
  children: ReactElement<TabPaneProps>[];
};

const Tabs = (props: Props): JSX.Element => {
  const { classNames, children } = props;
  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(0);

  return (
    <div className="mt-6 sm:mt-2 2xl:mt-5">
      <div className="border-b border-gray-200">
        <div className="mx-auto max-w-5xl px-4 sm:px-6 lg:px-8">
          <ul className={classNames}>
            {children
              .filter(({ props: { hide } }) => !hide)
              .map((item, index) => (
                <TabTitle
                  key={item.props.title}
                  title={item.props.title}
                  index={index}
                  isActive={index === selectedTabIndex}
                  setSelectedTab={setSelectedTabIndex}
                />
              ))}
          </ul>
        </div>
      </div>

      <div className="mx-auto mt-6 max-w-5xl px-4 sm:px-6 lg:px-8">{children[selectedTabIndex]}</div>
    </div>
  );
};

export default Tabs;
