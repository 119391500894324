import React, { Dispatch, SetStateAction, useContext, useState } from "react";
import { UserSkillAveragesType } from "types/UserSkill.types";

export type UserProfileContextType = {
  userSkills: Array<UserSkillAveragesType>;
  setUserSkills: Dispatch<SetStateAction<Array<UserSkillAveragesType>>>;
};

const initialUserProfileContext: UserProfileContextType = {
  userSkills: [],
  setUserSkills: /* istanbul ignore next */ () => {},
};

const UserProfileContext = React.createContext(initialUserProfileContext);

export const UserProfileContextProvider: React.FC<{ children: JSX.Element }> = ({ children }) => {
  const [userSkills, setUserSkills] = useState<Array<UserSkillAveragesType>>([]);

  const providerValue: UserProfileContextType = {
    userSkills,
    setUserSkills,
  };

  return <UserProfileContext.Provider value={providerValue}>{children}</UserProfileContext.Provider>;
};

export const useUserProfileContext = () => useContext(UserProfileContext);
