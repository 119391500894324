import { Dialog, Transition } from "@headlessui/react";
import { Fragment, ReactElement } from "react";

export const enum ModalAction {
  Add = "Add",
  Edit = "Edit",
  Remove = "Remove",
}
export type ModalOnCloseResult<T> = {
  action: ModalAction;
  data: T;
};
export type ModalOnCloseFunction<T> = (onCloseResult?: ModalOnCloseResult<T>) => void;

export const handleModalOnCloseResult = <T extends unknown>(
  onCloseResult: ModalOnCloseResult<T> | undefined,
  actionHandlers: { [action in ModalAction]?: (data: T) => void }
): void => {
  if (onCloseResult === undefined) return;
  const actionHandler = actionHandlers[onCloseResult.action];
  if (actionHandler === undefined) return;
  actionHandler(onCloseResult.data);
};

type ModalProps<T> = {
  show: boolean;
  onClose: ModalOnCloseFunction<T>;
  title?: string;
  children: ReactElement | ReactElement[];
};

export const Modal = <T extends unknown>(props: ModalProps<T>): JSX.Element => {
  const { show, onClose, children, title } = props;

  return (
    <Transition.Root show={show} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => onClose()}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>
        <div className="fixed inset-0 z-40 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative min-w-[32rem] max-w-fit transform rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:p-6">
                {title && (
                  <Dialog.Title data-testid="DialogTitle" as="h3" className="text-base font-semibold leading-6 text-gray-900">
                    {title}
                  </Dialog.Title>
                )}
                {children}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default Modal;
