import { Dialog, Menu, Transition } from "@headlessui/react";
import { ChevronRightIcon, MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import { Bars3Icon, BellIcon, XMarkIcon } from "@heroicons/react/24/outline";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, Outlet } from "react-router-dom";
import AppContext, { NotificationType } from "AppContext";
import SearchService from "api/SearchService";
import { Avatar } from "components";
import SideBarNavigation from "components/SideBarNavigation/SideBarNavigation";
import { ResultItem } from "types/Search.types";
import { NOTIFICATION_DISPLAY_TIME } from "utils/constants";
import { classNames } from "utils/helpers";
import { LoadingIcon } from "components/Icons/Icons";
import JobPreferenceModal from "components/Modal/JobPreferenceModal";

const Logo = "/images/v2.png";
const WhiteLogo = "/images/logo-alt.png";

type Navigation = {
  name: string;
  href: string;
};

type MenuNavigation = Navigation;

const navigation: Array<MenuNavigation> = [
  { name: "Home", href: "/" },
  { name: "Companies", href: "/companies" },
  { name: "User Guide", href: "/guide" },
];

const userNavigation: Array<Navigation> = [
  { name: "Your Profile", href: "/profile" },
  { name: "Sign out", href: "/login" },
];

export const HomePage: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const {
    notification: { showNotification },
    session: { loggedUser },
  } = useContext(AppContext);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [searchResults, setSearchResults] = useState<ResultItem[]>([]);
  const [searchResultsVisible, setSearchResultsVisible] = useState(false);
  const {
    handleSubmit,
    reset,
    formState: { isSubmitted },
  } = useForm();
  const [searchValue, setSearchValue] = useState("");
  const [jobPreferenceModalOpen, setJobPreferenceModalOpen] = useState(false);

  const onSubmit = handleSubmit(async () => {
    setIsLoading(true);
    const { data, error } = await SearchService.search({ filter: searchValue });
    if (data) {
      setSearchResults(data.results);
      setSearchResultsVisible(true);
    } else {
      showNotification({
        notificationType: NotificationType.Error,
        title: "Error searching user/company",
        message: error.message,
        displayTime: NOTIFICATION_DISPLAY_TIME,
      });
      setSearchResultsVisible(false);
    }
    setIsLoading(false);
  });

  useEffect(() => {
    if (searchValue && !isSubmitted) {
      const timer = setTimeout(() => onSubmit(), 1000);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [searchValue, isSubmitted, onSubmit]);

  type SearchResultsContainerProps = {
    results: ResultItem[];
  };

  const SearchResultsContainer = ({ results }: SearchResultsContainerProps) => {
    const searchResultsContent =
      results.length === 0 ? (
        <span className="p-4">No results found.</span>
      ) : (
        results.map((result, idx) => (
          <Link
            to={result.type === "Company" ? `/companies/${result.prettyUrl}/profile` : `/profile/${result.prettyUrl}`}
            className="flex flex-row items-center gap-4 p-4 text-gray-500 last:rounded-b hover:bg-violet-600 hover:text-white focus:outline-none"
            onClick={() => setSearchResultsVisible(false)}
            key={idx.toString()}
          >
            <Avatar
              nameParts={result.name.split(" ")}
              imageUrl={result.avatarUrl}
              textClasses="text-sm"
              sizeClasses="h-8 w-8 rounded-full"
            />
            <span className="text-lg font-medium">
              {result.name}
              <ChevronRightIcon className="inline-block h-6 w-6" aria-hidden="true" />
            </span>
            <span>{result.type}</span>
          </Link>
        ))
      );

    return (
      <Transition
        as={Fragment}
        enter="transition-opacity ease-linear duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity ease-linear duration-300"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        show={searchResultsVisible}
      >
        <Dialog as="div" onClose={setSearchResultsVisible} open={searchResultsVisible}>
          <Dialog.Panel className="absolute left-0 top-14 z-40 flex w-full flex-col rounded-b border border-gray-200 bg-white shadow-lg md:left-20 md:w-[45rem]">
            {searchResultsContent}
          </Dialog.Panel>
        </Dialog>
      </Transition>
    );
  };

  return (
    <div className="flex min-h-screen flex-col">
      {/* Top nav*/}
      <header className="relative flex h-16 flex-shrink-0 items-center bg-white">
        <JobPreferenceModal show={jobPreferenceModalOpen} setOpen={setJobPreferenceModalOpen} />
        {/* Logo area */}
        <div className="inset-y-0 left-0 md:static md:flex-shrink-0">
          <Link
            to="/"
            className="flex h-16 w-16 items-center justify-center bg-violet-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-violet-600 md:w-20"
          >
            <img className="h-5 w-auto" src={WhiteLogo} alt="Varla" />
          </Link>
        </div>

        {/* Search area */}
        <div className="min-w-0 flex-1">
          <div className="relative max-w-2xl text-gray-400 focus-within:text-gray-500">
            <label htmlFor="desktop-search" className="sr-only">
              Search
            </label>
            <form onSubmit={onSubmit} onChange={() => reset({ keepValues: true })}>
              <input
                id="desktop-search"
                placeholder="Search"
                className="block w-full border-transparent pl-12 capitalize focus:border-transparent focus:ring-0 sm:text-sm"
                onChange={(e) => setSearchValue(e.target.value)}
                autoComplete="off"
              />
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                <LoadingIcon class="h-5 w-5" isVisible={isLoading} />
              </div>
              <button name="SubmitSearch" type="submit" className="absolute inset-y-0 left-0 flex items-center justify-center pl-4">
                <MagnifyingGlassIcon className="h-5 w-5 cursor-pointer" aria-hidden="true" />
              </button>
            </form>
            <SearchResultsContainer results={searchResults} />
          </div>
        </div>
        {/* Menu button area */}
        <div className="inset-y-0 right-0 flex items-center pr-4 sm:pr-6 md:hidden">
          {/* Mobile menu button */}
          <button
            type="button"
            className="-mr-2 inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-violet-600"
            onClick={() => setMobileMenuOpen(true)}
          >
            <span className="sr-only">Open main menu</span>
            <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
          </button>
        </div>

        {/* Desktop nav area */}
        <div className="hidden md:flex md:min-w-0 md:flex-1 md:items-center md:justify-end">
          <div className="ml-10 flex flex-shrink-0 items-center space-x-10 pr-4">
            <nav aria-label="Global" className="flex hidden space-x-10">
              <Link to="#" className="text-sm font-medium text-gray-900">
                Settings
              </Link>
            </nav>
            <div className="flex items-center space-x-8">
              <span className="inline-flex hidden">
                <Link to="#" className="-mx-1 rounded-full bg-white p-1 text-gray-400 hover:text-gray-500">
                  <span className="sr-only">View notifications</span>
                  <BellIcon className="h-6 w-6" aria-hidden="true" />
                </Link>
              </span>

              <Menu as="div" className="relative inline-block text-left">
                <Menu.Button className="flex rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-violet-600 focus:ring-offset-2">
                  <span className="sr-only">Open user menu</span>
                  <Avatar
                    nameParts={[loggedUser.firstName ?? "", loggedUser.lastName ?? ""]}
                    imageUrl={loggedUser.profilePictureUrl}
                    textClasses="text-sm"
                    sizeClasses="h-8 w-8 rounded-full"
                  />
                </Menu.Button>

                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="py-1">
                      <Menu.Item key={"Job Preferences"}>
                        {({ active }) => (
                          <button
                            onClick={() => setJobPreferenceModalOpen(true)}
                            className={classNames(active ? "bg-gray-100" : "", "block w-full px-4 py-2 text-left text-sm text-gray-700")}
                          >
                            Job Preferences
                          </button>
                        )}
                      </Menu.Item>
                      {userNavigation.map((navigation) => (
                        <Menu.Item key={navigation.name}>
                          {({ active }) => (
                            <Link
                              to={navigation.href}
                              className={classNames(active ? "bg-gray-100" : "", "block px-4 py-2 text-sm text-gray-700")}
                            >
                              {navigation.name}
                            </Link>
                          )}
                        </Menu.Item>
                      ))}
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
          </div>
        </div>

        {/* Mobile menu, show/hide this `div` based on menu open/closed state */}
        <Transition.Root show={mobileMenuOpen} as={Fragment}>
          <Dialog as="div" className="relative z-40 md:hidden" onClose={setMobileMenuOpen}>
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="hidden sm:fixed sm:inset-0 sm:block sm:bg-gray-600 sm:bg-opacity-75" />
            </Transition.Child>

            <div className="fixed inset-0 z-40">
              <Transition.Child
                as={Fragment}
                enter="transition ease-out duration-150 sm:ease-in-out sm:duration-300"
                enterFrom="transform opacity-0 scale-110 sm:translate-x-full sm:scale-100 sm:opacity-100"
                enterTo="transform opacity-100 scale-100 sm:translate-x-0 sm:scale-100 sm:opacity-100"
                leave="transition ease-in duration-150 sm:ease-in-out sm:duration-300"
                leaveFrom="transform opacity-100 scale-100 sm:translate-x-0 sm:scale-100 sm:opacity-100"
                leaveTo="transform opacity-0 scale-110 sm:translate-x-full sm:scale-100 sm:opacity-100"
              >
                <Dialog.Panel
                  className="fixed inset-0 z-40 h-full w-full bg-white sm:inset-y-0 sm:left-auto sm:right-0 sm:w-full sm:max-w-sm sm:shadow-lg"
                  aria-label="Global"
                >
                  <div className="flex h-16 items-center justify-between px-4 sm:px-6">
                    <Link to="/">
                      <img className="block h-8 w-auto" src={Logo} alt="Varla" />
                    </Link>
                    <button
                      type="button"
                      className="-mr-2 inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-violet-600"
                      onClick={() => setMobileMenuOpen(false)}
                    >
                      <span className="sr-only">Close main menu</span>
                      <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                  <div className="max-w-8xl mx-auto px-2 py-3 sm:px-4">
                    {navigation.map((item) => (
                      <Fragment key={item.name}>
                        <Link
                          to={item.href}
                          onClick={() => setMobileMenuOpen(false)}
                          className="block rounded-md px-3 py-2 text-base font-medium text-gray-900 hover:bg-gray-100"
                        >
                          {item.name}
                        </Link>
                      </Fragment>
                    ))}
                  </div>
                  <div className="border-t border-gray-200 pb-3 pt-4">
                    <div className="max-w-8xl mx-auto flex items-center px-4 sm:px-6">
                      <div className="flex-shrink-0">
                        <Avatar
                          nameParts={[loggedUser.firstName ?? "", loggedUser.lastName ?? ""]}
                          imageUrl={loggedUser.profilePictureUrl}
                          textClasses=""
                          sizeClasses="h-10 w-10 rounded-full"
                        />
                      </div>
                      <div className="ml-3 min-w-0 flex-1">
                        <div className="truncate text-base font-medium text-gray-800">{loggedUser.firstName}</div>
                        <div className="truncate text-sm font-medium text-gray-500">{loggedUser.email}</div>
                      </div>
                      <Link to="#" className="ml-auto hidden flex-shrink-0 bg-white p-2 text-gray-400 hover:text-gray-500">
                        <span className="sr-only">View notifications</span>
                        <BellIcon className="h-6 w-6" aria-hidden="true" />
                      </Link>
                    </div>
                    <div className="max-w-8xl mx-auto mt-3 space-y-1 px-2 sm:px-4">
                      {userNavigation.map((item) => (
                        <Link
                          key={item.name}
                          to={item.href}
                          onClick={() => setMobileMenuOpen(false)}
                          className="block rounded-md px-3 py-2 text-base font-medium text-gray-900 hover:bg-gray-50"
                        >
                          {item.name}
                        </Link>
                      ))}
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>
      </header>

      {/* Bottom section */}
      <div className="flex min-h-0 flex-1">
        <SideBarNavigation />
        {/* Main area */}
        <main className="relative min-w-0 flex-1 border-t border-gray-200 lg:flex">
          {/* Primary column */}
          <section aria-labelledby="primary-heading" className="flex h-full min-w-0 flex-1 flex-col overflow-y-auto lg:order-last">
            <Outlet />
          </section>
        </main>
      </div>
    </div>
  );
};

export default HomePage;
