import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AppContext, { NotificationType } from "AppContext";
import { useLoader } from "hooks/useLoader";
import WorkingGeniusService from "api/WorkingGeniusService";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  InsertPersonalityRequest,
  PersonalityAnswer,
  PersonalityQuestion,
  QuestionForm,
  QuestionFormSchema,
  TimeSpent,
} from "../../../types/WorkingGenius.types";
import { NOTIFICATION_DISPLAY_TIME } from "utils/constants";
import { ArrowRightCircleIcon } from "@heroicons/react/20/solid";
import { UserProfile } from "types/User.types";

export type WorkingGeniusProps = {
  setUserDetails: Function;
  userDetails: UserProfile | undefined;
};

export const WorkingGeniusPage: React.FC<WorkingGeniusProps> = ({ setUserDetails, userDetails }) => {
  const { applyLoader } = useLoader();
  const navigate = useNavigate();
  const [questions, setQuestions] = useState<Array<PersonalityQuestion>>([]);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [answers, setAnswers] = useState<Array<PersonalityAnswer>>([]);
  const [categoryStartTime, setCategoryStartTime] = useState(new Date());
  const [timeSpent, setTimeSpent] = useState<Array<TimeSpent>>([]);
  const [lastQuestionIndex, setLastQuestionIndex] = useState(0);

  const {
    notification: { showNotification },
    session: { loggedUser },
  } = useContext(AppContext);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<QuestionForm>({
    resolver: yupResolver(QuestionFormSchema),
  });

  useEffect(() => {
    const fetchPersonalityQuestions = async (): Promise<void> => {
      const { data } = await applyLoader(WorkingGeniusService.getPersonalityQuestions());
      setLastQuestionIndex(data.length - 1);
      setQuestions(data);
    };

    fetchPersonalityQuestions();
  }, [applyLoader]);

  const calculateScores = (answers: Array<PersonalityAnswer>, timeSpent: Array<TimeSpent>): InsertPersonalityRequest => {
    let testAnswers: any = [];
    const types = ["Elephant", "Owl", "Raven", "Wolverine", "Eagle", "Horse"];
    types.forEach((type) => {
      let typeQuestions = questions.filter((q) => {
        return q.type === type;
      });
      let typeQuestionIds = typeQuestions.map((q) => q.id);
      let typeRawAnswers = answers.filter((a) => typeQuestionIds.includes(a.question_id));
      let typeAnswers = typeRawAnswers.map((a) => {
        return {
          question_id: a.question_id,
          answer: a.answer === "Agree",
        };
      });
      let typeScore = typeAnswers.reduce((acc, ta) => {
        if (ta.answer) {
          return acc + 1;
        } else {
          return acc;
        }
      }, 0);
      let typeDuration = timeSpent.filter((ts) => ts.type === type)[0].duration;
      testAnswers.push({
        answers: typeAnswers,
        score: typeScore,
        duration: typeDuration,
        type: type,
      });
    });
    return {
      userId: loggedUser.id,
      testAnswers: testAnswers,
    };
  };

  const onSubmit = handleSubmit(async (formData) => {
    if (questions[currentQuestion + 1] === undefined || questions[currentQuestion + 1].type !== questions[currentQuestion].type) {
      const currentDateTime = new Date();
      const duration = Math.abs(categoryStartTime.getTime() - currentDateTime.getTime());
      setTimeSpent([
        ...timeSpent,
        {
          type: questions[currentQuestion].type,
          duration: duration,
        },
      ]);
    }
    if (currentQuestion === lastQuestionIndex) {
      try {
        const currentDateTime = new Date();
        const duration = Math.abs(categoryStartTime.getTime() - currentDateTime.getTime());
        const calculatedScores = calculateScores(
          [
            ...answers,
            {
              question_id: currentQuestion,
              answer: formData.answer,
            },
          ],
          [
            ...timeSpent,
            {
              type: questions[currentQuestion].type,
              duration: duration,
            },
          ]
        );

        const { data } = await applyLoader(WorkingGeniusService.insertPersonality(calculatedScores));
        showNotification({
          notificationType: NotificationType.Success,
          title: "Success",
          message: "Working genius test completed!",
          displayTime: NOTIFICATION_DISPLAY_TIME,
        });
        navigate("/profile");
        setUserDetails({
          ...userDetails,
          ...data,
        });
      } catch (error) {
        const errorMessage = error.response ? error.response.data.message : error.message;
        showNotification({
          notificationType: NotificationType.Error,
          title: "Failed to update user profile",
          message: errorMessage,
          displayTime: NOTIFICATION_DISPLAY_TIME,
        });
      }
    } else {
      setAnswers([
        ...answers,
        {
          question_id: currentQuestion,
          answer: formData.answer,
        },
      ]);
      setCurrentQuestion((currentQuestion) => currentQuestion + 1);
      // reset timer on new question type
      setCategoryStartTime(new Date());
    }
  });

  return (
    <div className="flex flex-1 flex-col">
      <div className="flex items-center justify-center">
        <form className="w-full" onSubmit={onSubmit}>
          <div>
            <label className="text-sm text-gray-600">
              Answer the following test to know your{" "}
              <span className="text-base font-semibold leading-7  text-violet-500">personality types!</span>
            </label>
          </div>
          <div className="mt-10 flex flex-row items-center">
            <div className="mr-5 inline-flex h-10 w-10 items-center justify-center rounded-full bg-violet-700">
              <span className="font-bold font-medium uppercase leading-none leading-none text-white text-white">{currentQuestion + 1}</span>
            </div>
            <div>
              <label className="text-lg	font-semibold leading-7  text-gray-800">
                {questions[currentQuestion] && <span>{questions[currentQuestion].text}</span>}
              </label>
            </div>
          </div>
          <div className="ml-[74px] text-gray-600">
            <div className="mb-2 mt-4">Do you agree or disagree?</div>
            <fieldset>
              <div className="space-y-1">
                {["Agree", "Disagree"].map((answer) => (
                  <div key={answer} className="flex items-center">
                    <input
                      {...register("answer")}
                      value={answer}
                      id={answer}
                      type="radio"
                      className="h-4 w-4 border-gray-300 text-violet-600 focus:ring-violet-600"
                    />
                    <label htmlFor={answer} className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                      {answer}
                    </label>
                  </div>
                ))}
              </div>
            </fieldset>
            {errors?.answer && <div className="mt-2 block w-full py-1 text-sm text-red-600">{errors.answer.message}</div>}
          </div>
          <div className="mt-4 flex w-full items-center justify-center">
            <button
              type="submit"
              className="w-full rounded-md bg-violet-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-violet-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-violet-600 disabled:opacity-25"
            >
              {(currentQuestion === lastQuestionIndex && <span>Submit</span>) || (
                <span className="flex justify-center">
                  Next <ArrowRightCircleIcon className="ml-2 h-5 w-5" aria-hidden="true" />
                </span>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default WorkingGeniusPage;
