import { CheckCircleIcon } from "@heroicons/react/20/solid";
import { yupResolver } from "@hookform/resolvers/yup";
import { extractSkillsFromContent } from "api/TeamGeneratorService";
import UserSkillsService from "api/UserSkillsService";
import { useUserProfileContext } from "pages/home/user-profile/UserProfileContext";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { sameStrings } from "utils/string-compare";
import * as yup from "yup";
import { NotificationType, useAppContext } from "AppContext";
import { ENV_VARS } from "env";
import { useLoader } from "hooks/useLoader";
import { UserSkillFormType, UserSkillType } from "types/UserSkill.types";
import { LIST_NUMBER_REGEX, NOTIFICATION_DISPLAY_TIME } from "utils/constants";
import { skillTranslate } from "utils/skill-rating-translate";
import Modal, { ModalAction, ModalOnCloseFunction } from "./Modal";

const suggestedSkillSchema = yup.object({
  skill: yup.string().required(),
  level: yup.number().required(),
  selected: yup.boolean().defined().required(),
});
export type SuggestedSkillType = yup.InferType<typeof suggestedSkillSchema>;
const suggestRoleSkillsFormSchema = yup.object({
  suggestedSkills: yup.array(suggestedSkillSchema).defined().max(10),
});
export type SuggestRoleSkillsForm = yup.InferType<typeof suggestRoleSkillsFormSchema>;

export type SuggestRoleSkillsModalProps = {
  role: string;
  onClose: ModalOnCloseFunction<Array<UserSkillType>>;
  show: boolean;
  showSuccess?: boolean;
  profileUserId: number;
};
const SuggestRoleSkillsModal: React.FC<SuggestRoleSkillsModalProps> = (props) => {
  const {
    session: {
      loggedUser: { id: loggedUserId },
    },
    notification: { showNotification },
  } = useAppContext();
  const { applyLoader } = useLoader();
  const [loadingSkills, setLoadingSkills] = useState(true);
  const [isAllChecked, setIsAllChecked] = useState(false);
  const { userSkills } = useUserProfileContext();

  const {
    control: formControl,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<SuggestRoleSkillsForm>({ resolver: yupResolver(suggestRoleSkillsFormSchema) });
  const {
    fields: suggestedSkills,
    append: appendSuggestion,
    update: updateSuggestion,
    remove: clearSuggestions,
  } = useFieldArray({ name: "suggestedSkills", control: formControl });

  const appendIfNew = useCallback(
    (newSkill: string) => {
      const existingSkill = userSkills.find((skill) => sameStrings(skill.skill, newSkill));
      if (existingSkill === undefined)
        appendSuggestion({ level: 1, skill: newSkill.replace(LIST_NUMBER_REGEX, "").trim(), selected: false });
    },
    [appendSuggestion, userSkills]
  );

  useEffect(() => {
    const controller = new AbortController();
    if (props.show) {
      setLoadingSkills(true);
      /* istanbul ignore next */
      const streamSkills = async (role: string) => {
        const lambdaStreamUrl = ENV_VARS.StreamRoleSkillSuggestions;
        const { body: streamResponse } = await fetch(lambdaStreamUrl, {
          method: "POST",
          body: JSON.stringify({ role }),
          signal: controller.signal,
        });
        if (!streamResponse) {
          return;
        }
        const reader = streamResponse.getReader();
        const decoder = new TextDecoder();
        let done = false;
        let content = "";

        while (!done) {
          const { value, done: doneReading } = await reader.read();
          done = doneReading;
          const chunkValue = decoder.decode(value);
          content += chunkValue;
          const { completedSkills, incompleteContent: incomplete } = extractSkillsFromContent(content);
          for (const newSkill of completedSkills) {
            appendIfNew(newSkill);
          }
          content = incomplete;
        }
        if (content.trim()) {
          appendIfNew(content);
        }
        setLoadingSkills(false);
      };
      streamSkills(props.role);
    } else {
      clearSuggestions();
      controller.abort();
    }
    return () => controller.abort();
  }, [props.show, props.role, appendSuggestion, clearSuggestions, appendIfNew]);

  const handleLevelChange = (event: ChangeEvent<HTMLSelectElement>, suggestionIndex: number) => {
    const suggestion = suggestedSkills[suggestionIndex];
    updateSuggestion(suggestionIndex, { ...suggestion, level: Number(event.target.value) });
  };

  const handleSelectionChange = (event: ChangeEvent<HTMLInputElement>, suggestionIndex: number) => {
    const suggestion = suggestedSkills[suggestionIndex];
    updateSuggestion(suggestionIndex, { ...suggestion, selected: event.target.checked });
  };

  const submitSelectedSkills = handleSubmit(async (formData) => {
    try {
      const skillsToSubmit: Array<UserSkillFormType> = formData.suggestedSkills
        .filter((suggestion) => suggestion.selected)
        .map((suggestion) => ({ rate: suggestion.level, skillName: suggestion.skill, ratedBy: loggedUserId }));
      const apiCalls = skillsToSubmit.map((selectedSkill) => applyLoader(UserSkillsService.rateSkill(selectedSkill, props.profileUserId)));
      const addedSkills = (await Promise.all(apiCalls)).map((apiResponse) => apiResponse.data);
      showNotification({
        notificationType: NotificationType.Success,
        title: "Success",
        message: "Skills Added Successfully",
        displayTime: NOTIFICATION_DISPLAY_TIME,
      });
      props.onClose({ action: ModalAction.Add, data: addedSkills });
    } catch (error) {
      showNotification({
        notificationType: NotificationType.Error,
        title: "Error",
        message: error.response ? error.response.data.message : error.message,
        displayTime: NOTIFICATION_DISPLAY_TIME,
      });
      props.onClose();
    }
  });

  const loadedSkills = suggestedSkills.map((suggestion, index) => (
    <div key={suggestion.id} className="relative flex items-center gap-3 px-1 py-2">
      <div className="min-w-0 flex-1">
        <label htmlFor={`${suggestion.id}`} className="select-none font-medium text-gray-900">
          {suggestion.skill}
        </label>
      </div>
      <div>
        <select
          data-testid={`Skill.${suggestion.id}.Level`}
          className="block w-full rounded-md border-0 py-0.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-violet-600 sm:text-sm sm:leading-6"
          value={suggestion.level}
          onChange={(e) => handleLevelChange(e, index)}
        >
          {skillTranslate.nameIntegerValues.map(({ name, value }) => (
            <option key={value} value={value}>
              {name}
            </option>
          ))}
        </select>
      </div>
      <div className="flex items-center">
        <input
          id={`${suggestion.id}`}
          data-testid={`Skill.${suggestion.id}.Selected`}
          type="checkbox"
          checked={suggestion.selected}
          className="h-4 w-4 rounded border-gray-300 text-violet-600 focus:ring-violet-600"
          onChange={(e) => handleSelectionChange(e, index)}
        />
      </div>
    </div>
  ));

  const skillLoadingPlaceholder = loadingSkills && (
    <div className="min-h-8 flex animate-pulse-fast items-center gap-3 px-1 py-2">
      <div className="h-6 flex-1 rounded-md bg-slate-200"></div>
      <div className="h-8 w-28 rounded-md bg-slate-200"></div>
      <div className="h-4 w-4 rounded bg-slate-200"></div>
    </div>
  );

  const handleCheckAll = (e: ChangeEvent<HTMLInputElement>) => {
    suggestedSkills.forEach((_, index) => {
      handleSelectionChange(e, index);
    });
    setIsAllChecked(e.target.checked);
  };

  useEffect(() => {
    setIsAllChecked(suggestedSkills.length > 0 && suggestedSkills.every((skills) => skills.selected));
  }, [suggestedSkills]);

  return (
    <Modal show={props.show} onClose={() => props.onClose()}>
      <form data-testid="SuggestRoleSkillsModalForm" onSubmit={submitSelectedSkills}>
        {props.showSuccess && (
          <div className="border-b border-gray-200 pb-1">
            <div className="flex flex-row items-center gap-1">
              <CheckCircleIcon color="green" width="2em" />
              <span className="font-semibold">Success</span>
            </div>
            <div className="text-gray-500">
              <span className="font-semibold">{props.role}</span> has been added to your work experience.
            </div>
          </div>
        )}
        <div className="pt-1">
          <div className="font-semibold">Recommended Skills</div>
          <div className="text-gray-500">Here's a list of recommended skills. Check the ones you'd like to add to your profile.</div>
        </div>
        <div className="flex place-content-end items-center gap-2 px-[2.6rem] py-2">
          <label htmlFor="checkAll">Select all</label>
          <input
            id="checkAll"
            type="checkbox"
            checked={isAllChecked}
            className="h-4 w-4 rounded border-gray-300 text-violet-600 focus:ring-violet-600"
            onChange={(e) => handleCheckAll(e)}
          />
        </div>
        <div className="flex h-96 flex-col gap-2 overflow-y-scroll px-6">
          <fieldset>
            <div className="mt-2 divide-y divide-gray-200 border-b border-t border-gray-200">
              {loadedSkills}
              {skillLoadingPlaceholder}
            </div>
          </fieldset>
        </div>
        <div className="flex h-fit min-h-full justify-end space-x-1 pt-8">
          <button
            type="button"
            className="mr-3 rounded-md border-2 px-3 py-2 text-sm font-semibold text-black shadow-sm hover:bg-gray-200"
            onClick={() => props.onClose()}
          >
            Cancel
          </button>
          <button
            data-testid="SubmitButton"
            type="submit"
            className="mr-3 inline-flex items-center justify-center rounded-md bg-violet-600 px-3 py-2 text-sm font-semibold text-white shadow-sm enabled:hover:bg-violet-500 disabled:cursor-not-allowed disabled:bg-slate-400"
            disabled={isSubmitting || suggestedSkills.filter((suggestion) => suggestion.selected).length === 0}
          >
            Add
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default SuggestRoleSkillsModal;
