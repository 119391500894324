import * as yup from "yup";

const emailValidation = yup
  .string()
  .required()
  .matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, "Invalid email address.");
const passwordValidation = yup
  .string()
  .required()
  .matches(
    /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%^&*()_+}{"':;?/>.<,`\-=[\]\\|]).{12,}$/,
    "password must contain at least 12 characters, including one uppercase letter, one lowercase letter, one number, and one special character."
  );

export const RegistrationValidate = yup.object().shape({
  email: emailValidation,
  password: passwordValidation,
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), undefined], "passwords don't match.")
    .required("confirmation password is a required field"),
});

export const LoginValidate = yup.object().shape({
  email: emailValidation,
  password: yup.string().required(),
});

export const PasswordResetRequestValidate = yup.object().shape({
  email: emailValidation,
});

export const PasswordResetValidate = yup.object().shape({
  password: passwordValidation,
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), undefined], "passwords don't match.")
    .required("confirmation password is a required field"),
});

export const InviteUserValidate = yup.object().shape({
  invitedEmail: emailValidation,
});

export const UserSkillValidate = yup.object().shape({
  skillName: yup.string().required("skill is a required field"),
  rate: yup.string().required(),
});

export const CompanyEmployeeValidate = yup.object().shape({
  userEmail: emailValidation,
  accessLevel: yup.string().required(),
});
