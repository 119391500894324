import { InferType, number, object, string } from "yup";
import { MAX_LENGTH_120, MAX_LENGTH_255 } from "../constants";

export const companyProfileSchema = object().shape({
  name: string().strict().required("Company name is required").max(MAX_LENGTH_120),
  website: string().strict().nullable().max(MAX_LENGTH_255, "Webside must not exceed 255 characters"),
  about: string().strict().nullable().max(MAX_LENGTH_255, "About must not exceed 255 characters"),
  publicUrl: string().strict().nullable().max(MAX_LENGTH_255, "Public URL must not exceed 255 characters"),
  industryId: number().integer().typeError("Industry is required").required(),
  logoUrl: string().strict().nullable(),
  bannerUrl: string().strict().nullable(),
});
export type CompanyProfileForm = InferType<typeof companyProfileSchema>;

export const imageCropperSchema = object().shape({
  imageBase64: string().strict().required(),
  modalTitle: string().strict().required(),
});

export type ImageCropper = InferType<typeof imageCropperSchema>;
