import { CheckIcon } from "@heroicons/react/24/solid";
import AppContext from "AppContext";
import { useCallback, useContext, useEffect } from "react";
export type StepStatus = "complete" | "current" | "upcoming";

export type Props = {
  title: string;
  index: number;
  setSelectedStep: (index: number) => void;
  status?: StepStatus;
  numberOfSteps: number;
  isDisabled?: boolean;
};

const StepTitle = (props: Props): JSX.Element => {
  const {
    paginator: { stepNumber, stepData, nextStep },
  } = useContext(AppContext);
  const { title, setSelectedStep, index, status, numberOfSteps, isDisabled = false } = props;

  useEffect(() => {
    if (stepNumber === index) {
      setSelectedStep(index);
      nextStep(index, stepData);
    }
  }, [stepNumber, setSelectedStep, index, nextStep, stepData]);

  const handleOnClick = useCallback(() => {
    if (!isDisabled) {
      setSelectedStep(index);
      nextStep(index, stepData);
    }
  }, [index, isDisabled, setSelectedStep, nextStep, stepData]);

  return (
    <li key={title} className="relative md:flex md:flex-1" onClick={handleOnClick}>
      {status === "complete" ? (
        <span data-testid="completed" className={`group flex w-full items-center ${isDisabled ? "" : "cursor-pointer"}`}>
          <span className="flex items-center px-6 py-4 text-sm font-medium">
            <span
              className={`flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full ${
                isDisabled ? "bg-gray-300" : "bg-violet-600 group-hover:bg-violet-800"
              }`}
            >
              <CheckIcon className="h-6 w-6 text-white" aria-hidden="true" />
            </span>
            <span className="ml-4 text-sm font-medium text-gray-900">{title}</span>
          </span>
        </span>
      ) : status === "current" ? (
        <span className="flex cursor-pointer items-center px-6 py-4 text-sm font-medium" aria-current="step">
          <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-violet-600">
            <span className="text-violet-600">{`0${index + 1}`}</span>
          </span>
          <span className="ml-4 text-sm font-medium text-violet-600">{title}</span>
        </span>
      ) : (
        <span className="group flex cursor-pointer items-center">
          <span className="flex items-center px-6 py-4 text-sm font-medium">
            <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-gray-300 group-hover:border-gray-400">
              <span className="text-gray-500 group-hover:text-gray-900">{`0${index + 1}`}</span>
            </span>
            <span className="ml-4 text-sm font-medium text-gray-500 group-hover:text-gray-900">{title}</span>
          </span>
        </span>
      )}

      {index !== numberOfSteps ? (
        <>
          {/* Arrow separator for lg screens and up */}
          <div className="absolute right-0 top-0 hidden h-full w-5 md:block" aria-hidden="true">
            <svg className="h-full w-full text-gray-300" viewBox="0 0 22 80" fill="none" preserveAspectRatio="none">
              <path d="M0 -2L20 40L0 82" vectorEffect="non-scaling-stroke" stroke="currentcolor" strokeLinejoin="round" />
            </svg>
          </div>
        </>
      ) : null}
    </li>
  );
};

export default StepTitle;
