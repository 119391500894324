import { InferType, object, string } from "yup";

export const QuestionFormSchema = object({
  answer: string().strict().required(),
});

export type QuestionForm = InferType<typeof QuestionFormSchema>;

export interface PersonalityQuestion {
  id: number;
  text: string;
  type: string;
}

export interface PersonalityAnswer {
  question_id: number;
  answer: string;
}

export interface TimeSpent {
  type: string;
  duration: number;
}

export interface Answer {
  question_id: number;
  answer: boolean;
}

export interface TestAnswers {
  answers: Array<Answer>;
  score: number;
  duration: number;
  type: string;
}

export interface InsertPersonalityRequest {
  userId: number;
  testAnswers: Array<TestAnswers>;
}
