import { Outlet, useOutletContext } from "react-router";
import { CompanyContainerOutletContextType } from "../CompanyContainerPage";
import CompanyDashboardHeaderPage from "./header/CompanyDashboardHeaderPage";

export const CompanyDashboardContainerPage = () => {
  // forwarding context since useOutletContext only works on immediate children
  const companyContainerOutletContext = useOutletContext<CompanyContainerOutletContextType>();

  return (
    <>
      <div className="pb-2">
        <CompanyDashboardHeaderPage company={companyContainerOutletContext.company} />
      </div>
      <Outlet context={companyContainerOutletContext} />
    </>
  );
};

export default CompanyDashboardContainerPage;
