import { ArrowRightCircleIcon, BriefcaseIcon, HashtagIcon, UserGroupIcon } from "@heroicons/react/24/solid";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { useAppContext } from "AppContext";
import { useMaxLength } from "hooks/InputValidations";
import { MAX_LENGTH_125, MAX_LENGTH_255 } from "utils/constants";
import { TeamDetailsForm, teamDetailsSchema } from "utils/form-validations/team-generator/teamDetailsForm";

export const StepTeamDetailsPage: React.FC<{}> = () => {
  const {
    paginator: { nextStep, stepData },
  } = useAppContext();
  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<TeamDetailsForm>({ resolver: yupResolver(teamDetailsSchema) });

  const inputRefTeamName = useRef<HTMLInputElement | null>(null);
  const inputRefClientName = useRef<HTMLInputElement | null>(null);
  const inputRefProjectDescription = useRef<HTMLTextAreaElement | null>(null);

  const { ref: refTeamName, ...restTeamName } = register("teamName");
  const { ref: refClientName, ...restClientName } = register("clientName");
  const { ref: refProjectDescription, ...restProjectDescription } = register("projectDescription");
  const { ref: refMinNumOfMembers, ...restMinNumOfMembers } = register("minNumOfMembers");

  const maxLengthTeamName = useMaxLength({
    inputRef: inputRefTeamName,
    maxLength: MAX_LENGTH_125,
  });

  const maxLengthClientName = useMaxLength({
    inputRef: inputRefClientName,
    maxLength: MAX_LENGTH_125,
  });

  const maxLengthProjectDescription = useMaxLength({
    inputRef: inputRefProjectDescription,
    maxLength: MAX_LENGTH_255,
  });

  const onSubmit = handleSubmit(async (formData) => {
    stepData.teamGeneratorData!.teamName = formData.teamName;
    stepData.teamGeneratorData!.clientName = formData.clientName!;
    stepData.teamGeneratorData!.projectDescription = formData.projectDescription;
    stepData.teamGeneratorData!.minNumOfMembers = formData.minNumOfMembers;
    stepData.teamGeneratorData!.roles = [];
    stepData.teamGeneratorData!.employees = [];
    nextStep(1, stepData);
  });

  useEffect(() => {
    setValue("teamName", stepData.teamGeneratorData?.teamName ? stepData.teamGeneratorData?.teamName : "");
    setValue("clientName", stepData.teamGeneratorData?.clientName ? stepData.teamGeneratorData?.clientName : "");
    setValue("projectDescription", stepData.teamGeneratorData?.projectDescription ? stepData.teamGeneratorData?.projectDescription : "");
    setValue("minNumOfMembers", stepData.teamGeneratorData?.minNumOfMembers ? stepData.teamGeneratorData?.minNumOfMembers : 1);
  }, [setValue, stepData]);

  return (
    <div className="lg:full flex flex-1 flex-col px-4 sm:px-6 lg:px-20 xl:px-24">
      <form onSubmit={onSubmit} className="px-8 pt-2">
        <div>
          <label htmlFor="teamName" className="block text-sm font-medium leading-6 text-gray-900">
            Team Name
          </label>
          <div className="relative mt-2 rounded-md shadow-sm">
            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
              <UserGroupIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
            </div>
            <input
              type="text"
              id="teamName"
              autoComplete="off"
              {...restTeamName}
              ref={(e) => {
                refTeamName(e);
                inputRefTeamName.current = e;
              }}
              maxLength={MAX_LENGTH_125}
              className="block w-full rounded-md border-0 py-1.5 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-violet-600 sm:text-sm sm:leading-6"
              placeholder="Ex: MyTeam"
            />
          </div>
          {errors?.teamName && <div className="mt-2 block w-full py-1 text-sm text-red-700">{errors.teamName.message}</div>}
          {maxLengthTeamName && <div className="mt-2 block w-full py-1 text-sm text-orange-400">{maxLengthTeamName}</div>}
          <p className="mt-2 text-sm text-gray-500" id="email-description">
            Enter a name for your new team.
          </p>
        </div>
        <div className="mt-4">
          <label htmlFor="clientName" className="block text-sm font-medium leading-6 text-gray-900">
            Client's Name
          </label>
          <div className="relative mt-2 rounded-md shadow-sm">
            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
              <BriefcaseIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
            </div>
            <input
              type="text"
              autoComplete="off"
              id="clientName"
              {...restClientName}
              ref={(e) => {
                refClientName(e);
                inputRefClientName.current = e;
              }}
              maxLength={MAX_LENGTH_125}
              className="block w-full rounded-md border-0 py-1.5 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-violet-600 sm:text-sm sm:leading-6"
              placeholder="Ex: Company"
            />
          </div>
          {maxLengthClientName && <div className="mt-2 block w-full py-1 text-sm text-orange-400">{maxLengthClientName}</div>}
          <p className="mt-2 text-sm text-gray-500" id="email-description">
            Enter the name of your client.
          </p>
        </div>
        <div className="mt-4">
          <label htmlFor="projectDescription" className="block text-sm font-medium leading-6 text-gray-900">
            Project Description
          </label>
          <div className="relative mt-2 rounded-md shadow-sm">
            <textarea
              rows={4}
              id="projectDescription"
              {...restProjectDescription}
              ref={(e) => {
                refProjectDescription(e);
                inputRefProjectDescription.current = e;
              }}
              maxLength={MAX_LENGTH_255}
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-violet-600 sm:text-sm sm:leading-6"
              defaultValue={""}
            />
            {errors?.projectDescription && (
              <div className="mt-2 block w-full py-1 text-sm text-red-700">{errors.projectDescription.message}</div>
            )}
            {maxLengthProjectDescription && (
              <div className="mt-2 block w-full py-1 text-sm text-orange-400">{maxLengthProjectDescription}</div>
            )}
          </div>
          <p className="mt-2 text-sm text-gray-500" id="email-description">
            Enter a description of what your new team will be working on.
          </p>
        </div>
        <div className="mt-4">
          <label htmlFor="minNumOfMembers" className="block text-sm font-medium leading-6 text-gray-900">
            Number of Team Members
          </label>
          <div className="relative mt-2 rounded-md">
            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
              <HashtagIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
            </div>
            <input
              type="number"
              autoComplete="off"
              id="minNumOfMembers"
              {...restMinNumOfMembers}
              ref={(e) => {
                refMinNumOfMembers(e);
              }}
              className="block w-28 rounded-md border-0 py-1.5 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-violet-600 sm:text-sm sm:leading-6"
              placeholder="Ex: 6"
              min="1"
            />
          </div>
          {errors?.minNumOfMembers && <div className="mt-2 block w-full py-1 text-sm text-red-700">{errors.minNumOfMembers.message}</div>}
          <p className="mt-2 text-sm text-gray-500" id="email-description">
            Enter the number of team members for your new team.
          </p>
        </div>
        <div className="flex flex-row-reverse items-end justify-between space-x-3 border-t border-gray-200 px-2 py-2 sm:px-3">
          <button
            type="submit"
            data-testid="next-step"
            className="flex rounded-md bg-violet-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-violet-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-violet-500"
            disabled={isSubmitting}
          >
            <span className=" flex">
              Next step
              <ArrowRightCircleIcon className="ml-2 h-5 w-5" aria-hidden="true" />
            </span>
          </button>
        </div>
      </form>
    </div>
  );
};

export default StepTeamDetailsPage;
