import { AxiosResponse } from "axios";
import { UserConnectionFormType } from "types/UserConnection.types";
import { instance } from "./base.api";

export const connectUser = async (userId: number, params: UserConnectionFormType): Promise<AxiosResponse> => {
  return instance.post(`users/${userId}/connection`, params);
};

export const getUserConnection = async (userId: number, connectedUserId: number): Promise<AxiosResponse> => {
  return instance.get(`users/${userId}/connection/${connectedUserId}`);
};

export const listUserConnections = async (userId: number): Promise<AxiosResponse> => {
  return instance.get(`users/${userId}/connection`);
};

export const deleteUserConnection = async (userId: number, connectedUserId: number): Promise<AxiosResponse> => {
  return instance.delete(`users/${userId}/connection/${connectedUserId}`);
};

const UserConnectionService = {
  connectUser,
  getUserConnection,
  deleteUserConnection,
  listUserConnections,
};

export default UserConnectionService;
