import { AxiosResponse } from "axios";
import { UserSkillAveragesType, UserSkillFormType, UserSkillType } from "types/UserSkill.types";
import { instance } from "./base.api";

export const skillRatings = async (userId: number): Promise<AxiosResponse<Array<UserSkillAveragesType>>> => {
  return instance.get(`users/${userId}/skill-ratings`);
};

export const rateSkill = async (params: UserSkillFormType, userId: number): Promise<AxiosResponse<UserSkillType>> => {
  return instance.post(`users/${userId}/skill-ratings`, params);
};

export const updateSkill = async (params: UserSkillFormType, userId: number): Promise<AxiosResponse<UserSkillType>> => {
  const patchBody = [{ op: "replace", path: "rate", value: params.rate }];
  return instance.patch(`users/${userId}/skill-ratings/${params.userSkillId}`, patchBody);
};

export const removeSkill = async (userSkillId: number, userId: number): Promise<AxiosResponse> => {
  return instance.delete(`users/${userId}/skill-ratings/${userSkillId}`);
};

const UserSkillsService = {
  skillRatings,
  rateSkill,
  updateSkill,
  removeSkill,
};

export default UserSkillsService;
