import { APIListFilters, APIResult } from "types/Api.types";
import { SearchResults } from "types/Search.types";
import { instance } from "./base.api";

const search = async (filters: APIListFilters): Promise<APIResult<SearchResults>> => {
  try {
    let { data } = await instance.get("search", {
      params: filters,
    });
    return { data, error: null };
  } catch (error) {
    const status = error.response?.status;
    let errorMessage = "Something went wrong. please try again";
    return { data: null, error: { message: errorMessage, code: status } };
  }
};

const SearchService = {
  search,
};

export default SearchService;
