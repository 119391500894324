const skillRatings = [
  { name: "Additional", intValue: 0, inRange: (rate: number) => rate <= 0 },
  { name: "Learning", intValue: 1, inRange: (rate: number) => rate < 1.5 },
  { name: "Beginner", intValue: 2, inRange: (rate: number) => 1.5 <= rate && rate < 2.5 },
  { name: "Proficient", intValue: 3, inRange: (rate: number) => 2.5 <= rate && rate < 3.5 },
  { name: "Expert", intValue: 4, inRange: (rate: number) => 3.5 <= rate && rate < 4.5 },
  { name: "Ninja", intValue: 5, inRange: (rate: number) => 4.5 <= rate },
];

const numericToName = (rating: number): string => skillRatings.find((skillRating) => skillRating.inRange(rating))!.name;

const nameIntegerValues = skillRatings.map(({ name, intValue: value }) => ({ name, value }));

const numericToInteger = (rating: number): number => skillRatings.find((skillRating) => skillRating.inRange(rating))!.intValue;

export const skillTranslate = {
  numericToName,
  nameIntegerValues,
  numericToInteger,
};
