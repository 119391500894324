import {
  ArrowLongLeftIcon,
  ArrowLongRightIcon,
  ArrowUturnRightIcon,
  ArrowsUpDownIcon,
  BoltIcon,
  DocumentPlusIcon,
  PencilSquareIcon,
  PlusIcon,
  TrashIcon,
  UserMinusIcon,
  UserPlusIcon,
} from "@heroicons/react/20/solid";
import { NotificationType, useAppContext } from "AppContext";
import RoleService from "api/RoleService";
import S3Service from "api/S3Service";
import UserConnectionService from "api/UserConnectionService";
import UserService, { splitToPastAndCurrent, startDateDescendingOrderer } from "api/UserService";
import UserSkillsService from "api/UserSkillsService";
import { Avatar } from "components";
import AutoCompleteCombobox from "components/Dropdown/AutoCompleteCombobox";
import ConfirmationAlertModal from "components/Modal/ConfirmationAlertModal";
import Modal, { ModalAction, ModalOnCloseFunction, handleModalOnCloseResult } from "components/Modal/Modal";
import SuggestRoleSkillsModal from "components/Modal/SuggestRoleSkillsModal";
import UserConnectionModal from "components/Modal/UserConnectionModal";
import UserSkillModal from "components/Modal/UserSkillModal";
import WorkExperienceModal, { WorkExperienceModalResponse } from "components/Modal/WorkExperienceModal";
import TabPane from "components/Tabs/TabPane";
import Tabs from "components/Tabs/Tabs";
import { useDebounce } from "hooks/useDebounce";
import { useLoader } from "hooks/useLoader";
import { DateTime } from "luxon";
import React, { ChangeEvent, Fragment, useCallback, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate, useParams } from "react-router-dom";
import { CrudInteraction } from "types/CrudModalProps.types";
import { Role } from "types/Role.types";
import { User, UserProfile, WorkingType } from "types/User.types";
import { UserSkillAveragesType, UserSkillType } from "types/UserSkill.types";
import { WorkExperienceType } from "types/WorkExperience.types";
import { NOTIFICATION_DISPLAY_TIME, WG_EAGLE, WG_ELEPHANT, WG_HORSE, WG_OWL, WG_RAVEN, WG_WOLVERINE } from "utils/constants";
import { fullName } from "utils/full-name";
import { skillTranslate } from "utils/skill-rating-translate";
import tableFunctions, { SortOrder } from "../../shared/TableFunctions";
import { useUserProfileContext } from "./UserProfileContext";
import Flag from "react-world-flags";
import { Country } from "country-state-city";
import { WorkingGeniusPage } from "../working-genius/WorkingGeniusPage";
import { formatAndSanitize } from "utils/helpers";

export const SKILLS_PER_PAGE = 10;

type UserProfileActionProps = {
  loggedUser: User;
  userDetails?: UserProfile;
  isConnected: boolean;
};

export const UserProfilePage: React.FC<{}> = () => {
  const {
    session: { loggedUser },
    notification: { showNotification },
  } = useAppContext();
  const params = useParams();
  const navigate = useNavigate();
  const [showRateSkill, setShowRateSkill] = useState(false);
  const [showUserConnection, setShowUserConnection] = useState(false);
  const [showWorkExperience, setShowWorkExperience] = useState(false);
  const [showSelectRoleModal, setShowSelectRoleModal] = useState(false);
  const [showSuggestRoleSkillsModal, setShowSuggestRoleSkillsModal] = useState(false);
  const [isConnected, setIsConnected] = useState(false);
  const [userSkillModalInteraction, setUserSkillModalInteraction] = useState<CrudInteraction<UserSkillType>>({
    data: undefined,
    action: ModalAction.Add,
  });
  const [modalWorkExperienceData, setModalWorkExperienceData] = useState<WorkExperienceType | undefined>(undefined);
  const [userDetails, setUserDetails] = useState<UserProfile>();
  const prettyUrl = params.prettyUrl || loggedUser.prettyUrl;
  const [sortOrder, setSortOrder] = useState(SortOrder.ascending);
  const { userSkills, setUserSkills } = useUserProfileContext();
  const [filteredSkillAverages, setFilteredUserSkillAverages] = useState<UserSkillAveragesType[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [pageNumbers, setPageNumbers] = useState([1]);
  const [roles, setRoles] = useState<Array<Role>>([]);
  const [queryUserSkill, setQueryUserSkill] = useState("");
  let countryData = Country.getAllCountries();
  const defaultCountry = Country.getCountryByCode("US")!;
  const [userCountry, setUserCountry] = useState(defaultCountry);
  const debouncedQueryUserSkill = useDebounce<string>(queryUserSkill);

  const { applyLoader } = useLoader();
  const handleSort = (event: React.MouseEvent<HTMLButtonElement>) => {
    const element = event.target as HTMLElement;
    const sortKey = element.dataset.value as keyof UserSkillAveragesType;
    if (sortKey) {
      setSortOrder(sortOrder === SortOrder.ascending ? SortOrder.descending : SortOrder.ascending);
      setFilteredUserSkillAverages(tableFunctions.sort(filteredSkillAverages, sortKey, sortOrder));
    }
  };
  const recalculateAverage = useCallback(
    (skillAverage: UserSkillAveragesType) =>
      skillAverage.ratings.reduce((sum, rating) => sum + rating.rate, 0) / skillAverage.ratings.length,
    []
  );
  const addSkillRate = useCallback(
    (userSkill: UserSkillType) => {
      setUserSkills((currentAverages) => {
        const averageRecord = currentAverages.find((current) => current.skill.toLowerCase() === userSkill.skill.toLowerCase());
        if (averageRecord) {
          averageRecord.ratings.push(userSkill);
          averageRecord.average = recalculateAverage(averageRecord);
          return currentAverages;
        }
        const newAverageRecord = {
          average: userSkill.rate,
          skill: userSkill.skill,
          company: userSkill.company,
          title: userSkill.title,
          ratings: [{ rate: userSkill.rate, ratedBy: userSkill.ratedBy, userSkillId: userSkill.userSkillId }],
        };
        return [...currentAverages, newAverageRecord];
      });
    },
    [recalculateAverage, setUserSkills]
  );
  const editSkillRate = useCallback(
    (userSkill: UserSkillType) => {
      setUserSkills((currentAverages) => {
        const averageRecord = currentAverages.find((current) => current.skill.toLowerCase() === userSkill.skill.toLowerCase())!;
        averageRecord.ratings.find((rating) => rating.userSkillId === userSkill.userSkillId)!.rate = userSkill.rate;
        averageRecord.average = recalculateAverage(averageRecord);
        return currentAverages;
      });
    },
    [recalculateAverage, setUserSkills]
  );
  const removeSkillRate = useCallback(
    (userSkill: UserSkillType) => {
      setUserSkills((currentAverages) => {
        const averageIndex = currentAverages.findIndex((current) => current.skill.toLowerCase() === userSkill.skill.toLowerCase());
        if (averageIndex < 0) return currentAverages;

        const averageRecord = currentAverages[averageIndex];
        const ratingIndex = averageRecord.ratings.findIndex((rating) => rating.userSkillId === userSkill.userSkillId);
        if (ratingIndex < 0) return currentAverages;

        averageRecord.ratings.splice(ratingIndex, 1);
        averageRecord.ratings.length
          ? (averageRecord.average = recalculateAverage(averageRecord)) // average record still has ratings, recalc avg.
          : currentAverages.splice(averageIndex, 1); // average record has no ratings left, remove it.

        return [...currentAverages];
      });
    },
    [recalculateAverage, setUserSkills]
  );
  const onUserSkillModalClose = useCallback<ModalOnCloseFunction<UserSkillType>>(
    (onCloseResult) => {
      handleModalOnCloseResult(onCloseResult, {
        [ModalAction.Add]: addSkillRate,
        [ModalAction.Edit]: editSkillRate,
        [ModalAction.Remove]: removeSkillRate,
      });
      setShowRateSkill(false);
    },
    [addSkillRate, editSkillRate, removeSkillRate]
  );

  const onWorkExperienceModalClose = useCallback<ModalOnCloseFunction<WorkExperienceModalResponse>>(
    (onCloseResult) => {
      handleModalOnCloseResult(onCloseResult, {
        [ModalAction.Add]: ({ workExperience, skills }) => {
          skills.forEach((skill) => addSkillRate(skill));

          setUserDetails((currentDetails) => {
            if (currentDetails === undefined) return;
            const updatedWorkExperience = [...currentDetails.workExperience, workExperience];
            const sortedWorkExperience = sortWorkExperience(updatedWorkExperience);
            return {
              ...currentDetails,
              workExperience: sortedWorkExperience,
            };
          });
        },
        [ModalAction.Edit]: ({ workExperience, skills }) => {
          skills.forEach((skill) => editSkillRate(skill));
          setUserDetails((currentDetails) => {
            if (currentDetails === undefined) return;
            const updateIndex = currentDetails.workExperience.findIndex((wxp) => wxp.id === workExperience.id);
            if (updateIndex === -1) return currentDetails;
            currentDetails.workExperience.splice(updateIndex, 1, workExperience);
            const sortedWorkExperience = sortWorkExperience(currentDetails.workExperience);
            return { ...currentDetails, workExperience: sortedWorkExperience };
          });
        },
        [ModalAction.Remove]: ({ workExperience }) => {
          setUserDetails((currentDetails) => {
            if (currentDetails === undefined) return;
            const removeIndex = currentDetails.workExperience.findIndex((wxp) => wxp.id === workExperience.id);
            if (removeIndex === -1) return currentDetails;
            currentDetails.workExperience.splice(removeIndex, 1);
            return { ...currentDetails, workExperience: [...currentDetails.workExperience] };
          });
        },
      });
      setShowWorkExperience(false);
    },
    [addSkillRate, editSkillRate]
  );

  const sortWorkExperience = (workExperience: WorkExperienceType[]) => {
    const { past, current } = splitToPastAndCurrent(workExperience);
    past.sort(startDateDescendingOrderer);
    current.sort(startDateDescendingOrderer);
    return [...current, ...past];
  };

  const handlePageChange = (pageNumber: number) => {
    if (pageNumber < 1 || pageNumber > totalPages) return;
    setCurrentPage(pageNumber);
  };

  const deleteUserConnection = async () => {
    if (userDetails?.id) {
      try {
        await applyLoader(UserConnectionService.deleteUserConnection(loggedUser.id, userDetails?.id));
        showNotification({
          notificationType: NotificationType.Success,
          title: "Success",
          message: "Disconnected successfully",
          displayTime: NOTIFICATION_DISPLAY_TIME,
        });
        setIsConnected(false);
      } catch (error) {
        showNotification({
          notificationType: NotificationType.Error,
          title: "Error",
          message: error.response ? "Failed to disconnect from user" : error.message,
          displayTime: NOTIFICATION_DISPLAY_TIME,
        });
      }
    }
  };

  const handleDeleteConnection = async () => {
    ConfirmationAlertModal({
      title: "Disconnect",
      message: `Are you sure you want to disconnect?`,
      action: "Confirm",
      onAction: async () => await deleteUserConnection(),
    });
  };

  useEffect(() => {
    const fetchUserDetails = async (): Promise<void> => {
      const { data } = await applyLoader(UserService.getProfile(prettyUrl));
      if (data) {
        setUserDetails(data);
        setUserCountry(
          (countryData.find((country) => country.name.trim().toLowerCase() === data.location!.trim().toLowerCase()) || defaultCountry)!
        );
      } else {
        navigate("/");
      }
    };

    fetchUserDetails();
  }, [prettyUrl, navigate, showNotification, applyLoader, countryData, defaultCountry]);

  useEffect(() => {
    if (userDetails?.id === undefined) return;

    const fetchUserConnection = async (): Promise<void> => {
      if (userDetails.id === loggedUser.id) return;
      const { data } = await applyLoader(UserConnectionService.getUserConnection(loggedUser.id, userDetails.id));
      const connected = data?.id !== undefined;
      setIsConnected(connected);
    };

    const fetchUserSkills = async (): Promise<void> => {
      try {
        const skillAveragesResponse = await applyLoader(UserSkillsService.skillRatings(userDetails.id));
        setUserSkills(skillAveragesResponse.data);
      } catch (error) {
        showNotification({
          notificationType: NotificationType.Error,
          title: "Failed to load user skills",
          message: error.message,
          displayTime: NOTIFICATION_DISPLAY_TIME,
        });
      }
    };

    Promise.all([fetchUserConnection(), fetchUserSkills()]);
  }, [navigate, loggedUser.id, userDetails?.id, showNotification, applyLoader, setUserSkills]);

  useEffect(() => {
    setTotalPages(tableFunctions.totalPages(filteredSkillAverages.length, SKILLS_PER_PAGE));
  }, [filteredSkillAverages]);

  useEffect(() => {
    setPageNumbers(tableFunctions.pageNumbers(totalPages));
    if (currentPage > totalPages) {
      setCurrentPage(1);
    }
  }, [totalPages, currentPage]);

  const canEdit = useCallback(() => {
    return userDetails?.id === loggedUser.id;
  }, [userDetails, loggedUser.id]);

  const loggedUserRatedSkillEditButton = useCallback(
    (skillName: string) => {
      const averageRecord = filteredSkillAverages.find(
        (averageRecord) => averageRecord.skill.toLowerCase().trim() === skillName.toLowerCase().trim()
      )!;

      const existingRating = averageRecord.ratings.find((rating) => rating.ratedBy === loggedUser.id);

      const { testId, userSkill, label, action }: { testId: string; userSkill: UserSkillType; label: string; action: ModalAction } =
        existingRating
          ? {
              testId: `skill.${skillName}.edit-rate-button`,
              userSkill: {
                ...existingRating,
                company: averageRecord.company,
                skill: averageRecord.skill,
                title: averageRecord.title,
              },
              label: "Edit",
              action: ModalAction.Edit,
            }
          : {
              testId: `skill.${skillName}.add-rate-button`,
              userSkill: {
                company: averageRecord.company,
                skill: averageRecord.skill,
                title: averageRecord.title,
                rate: 0,
                ratedBy: loggedUser.id,
                userSkillId: 0,
              },
              label: "Rate",
              action: ModalAction.Add,
            };

      return (
        <button
          data-testid={testId}
          onClick={() => {
            setUserSkillModalInteraction({ data: userSkill, action: action });
            setShowRateSkill(true);
          }}
          className="text-violet-600 hover:text-violet-900"
        >
          {label}
          <span className="sr-only text-violet-600 hover:text-violet-900">{skillName}</span>
        </button>
      );
    },
    [filteredSkillAverages, loggedUser.id]
  );

  const SkillAveragesTableRows = (): JSX.Element => {
    const skillAverages: UserSkillAveragesType[] = tableFunctions.tablePage(filteredSkillAverages, currentPage, SKILLS_PER_PAGE);
    if (skillAverages.length === 0) {
      return (
        <tr className="border-t border-gray-200">
          <td className="whitespace-nowrap px-6 py-4 text-center text-sm text-gray-500" colSpan={5}>
            No skills found
          </td>
        </tr>
      );
    }

    return (
      <>
        {skillAverages.map((skillAverage) => (
          <tr key={skillAverage.skill}>
            <td className="whitespace-nowrap py-5 pl-4 pr-3 text-sm sm:pl-0">
              <div className="font-medium capitalize text-gray-900">{skillAverage.skill}</div>
            </td>
            <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
              <span
                data-testid={`skill.${skillAverage.skill}.average`}
                className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20"
              >
                {skillTranslate.numericToName(skillAverage.average)}
              </span>
            </td>
            <td className="whitespace-nowrap py-5 pl-4 pr-3 text-sm sm:pl-0">
              <div className="px-2 py-1 text-xs font-medium text-gray-900">{skillAverage.ratings.length}</div>
            </td>
            <td className="relative whitespace-nowrap py-5 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
              {loggedUserRatedSkillEditButton(skillAverage.skill)}
            </td>
          </tr>
        ))}
      </>
    );
  };

  const WorkingGeniusSummary = (): JSX.Element => {
    return (
      <>
        <div className="flex w-full flex-row">
          <div className="mx-48 mt-8 w-full text-xl">
            <div className="flex text-center">
              <div className="w-1/2 space-y-4">
                <img className="m-auto block h-24" alt="working-type" src={getWorkingGeniusImage(userDetails?.workingTypeA || "")} />
                <p>{userDetails?.workingTypeA}</p>
              </div>
              <div className="w-1/2 space-y-4">
                <img className="m-auto block h-24" alt="working-type" src={getWorkingGeniusImage(userDetails?.workingTypeB || "")} />
                <p>{userDetails?.workingTypeB}</p>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  useEffect(() => {
    const filtered = userSkills.filter((userSkills) => userSkills.skill.includes(debouncedQueryUserSkill.toLowerCase()));
    setFilteredUserSkillAverages(filtered);
  }, [userSkills, debouncedQueryUserSkill]);

  const WorkExperience = () => {
    const workExperience = userDetails?.workExperience ?? [];

    if (workExperience.length === 0)
      return <div className="whitespace-nowrap px-6 py-4 text-center text-sm text-gray-500">No Work Experience found</div>;

    const XpBorderLink = ({ xpIndex }: { xpIndex: number }) => {
      const lastXp = xpIndex === workExperience.length - 1;
      if (lastXp) return null;
      return <span className="absolute left-6 top-5 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true" />;
    };

    const formatDate = (date: Date) => {
      const dateTime = DateTime.fromISO(date.toISOString(), { zone: "utc" });
      const formattedDate = dateTime.toFormat("LLL yyyy");
      return formattedDate;
    };

    const XpDateRange = ({ xp }: { xp: WorkExperienceType }) => {
      const start = formatDate(xp.startDate);
      const end = xp.endDate ? ` - ${formatDate(xp.endDate)}` : "";
      return <>{`${start}${end}`}</>;
    };

    const EditXpButton = ({ xp }: { xp: WorkExperienceType }) =>
      canEdit() ? (
        <button
          type="button"
          data-testid="edit-work-experience"
          onClick={() => {
            setModalWorkExperienceData(xp);
            setShowWorkExperience(true);
          }}
          className="float-right rounded-full bg-white p-1 text-slate-300 shadow-sm hover:bg-violet-500 hover:text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-violet-600"
        >
          <PencilSquareIcon className="h-5 w-5" aria-hidden="true" />
        </button>
      ) : null;

    const CompanyProfileLink = ({ children, xp }: { xp: WorkExperienceType; children: React.ReactNode }) => {
      // TODO: change condition and route once publicUrls are implemented
      const hasPublicUrl = true;
      if (!hasPublicUrl) {
        return <>{children}</>;
      }
      const companyProfileRoute = `/companies/${xp.companyId}/profile`;
      return <Link to={companyProfileRoute}>{children}</Link>;
    };

    return (
      <ul className="-mb-8">
        {workExperience.map((xp, index) => (
          <li key={xp.id}>
            <div className="relative pb-8">
              <XpBorderLink xpIndex={index} />
              <div className="relative flex items-start space-x-3">
                <div className="relative">
                  <CompanyProfileLink xp={xp}>
                    <Avatar
                      imageUrl={xp.companyLogoUrl}
                      nameParts={xp.companyName.split(" ")}
                      sizeClasses="h-12 w-12 rounded-full"
                      textClasses="text-xl upeprcase"
                    />
                  </CompanyProfileLink>
                </div>
                <div className="min-w-0 flex-1">
                  <div>
                    <div className="text-sm">
                      <CompanyProfileLink xp={xp}>
                        <span className="font-medium capitalize text-gray-900">{xp.companyName}</span>
                      </CompanyProfileLink>
                      <span className="ml-2 mr-0.5">
                        <Fragment>
                          <span className="inline-flex items-center gap-x-1.5 rounded-full px-2 py-1 text-xs font-medium capitalize text-gray-900 ring-1 ring-inset ring-gray-200">
                            <svg className="h-1.5 w-1.5 fill-violet-500" viewBox="0 0 6 6" aria-hidden="true">
                              <circle cx={3} cy={3} r={3} />
                            </svg>
                            {xp.role}
                          </span>
                        </Fragment>
                      </span>
                      <EditXpButton xp={xp} />
                    </div>
                    <p className="mt-0.5 text-sm text-gray-500">
                      <XpDateRange xp={xp} />
                    </p>
                  </div>
                  <div className="mt-2 text-sm text-gray-700">
                    <p>{xp.about}</p>
                  </div>
                </div>
              </div>
            </div>
          </li>
        ))}
      </ul>
    );
  };

  const ProfileAction = (props: UserProfileActionProps) => {
    if (props.userDetails?.id === props.loggedUser.id) {
      return (
        <Link
          to="/edit-profile"
          className="inline-flex justify-center gap-x-1.5 rounded-md bg-varla-violet-100/90 px-3 py-2 text-sm text-white shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-varla-violet-100/80"
        >
          Edit Profile
          <PencilSquareIcon className="h-5 w-5" aria-hidden="true" />
        </Link>
      );
    }

    if (props.isConnected) {
      return (
        <button
          type="button"
          className="inline-flex justify-center gap-x-1.5 rounded-md bg-varla-violet-100/90 px-3 py-2 text-sm text-white shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-varla-violet-100/80"
          onClick={handleDeleteConnection}
        >
          Disconnect
          <UserMinusIcon className="text-white-400 -ml-0.5 h-5 w-5" aria-hidden="true" />
        </button>
      );
    } else {
      return (
        <button
          type="button"
          className="inline-flex justify-center gap-x-1.5 rounded-md bg-varla-violet-100/90 px-3 py-2 text-sm text-white shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-varla-violet-100/80"
          onClick={() => setShowUserConnection(true)}
        >
          Connect
          <UserPlusIcon className="text-white-400 -ml-0.5 h-5 w-5" aria-hidden="true" />
        </button>
      );
    }
  };

  useEffect(() => {
    const fetchRoles = async (): Promise<void> => {
      const { data, error } = await RoleService.listRoles();
      if (data) {
        setRoles(data);
      } else {
        showNotification({
          notificationType: NotificationType.Error,
          title: "Error fetching roles.",
          message: error.message,
          displayTime: NOTIFICATION_DISPLAY_TIME,
        });
      }
    };
    fetchRoles();
  }, [showNotification]);

  const {
    formState: { isSubmitting },
    getValues,
    setValue,
    watch,
    handleSubmit,
    reset,
  } = useForm({
    defaultValues: { role: "" },
  });

  const onChange = (value: string, key: "role") => {
    setValue(key, value);
  };

  const onSubmit = handleSubmit(async (formData) => {
    setShowSuggestRoleSkillsModal(true);
  });

  const suggestedSkillsOnClose = useCallback<ModalOnCloseFunction<Array<UserSkillType>>>(
    (onCloseResult) => {
      handleModalOnCloseResult(onCloseResult, {
        [ModalAction.Add]: (suggestedSkills) => {
          suggestedSkills.forEach((skill) => addSkillRate(skill));
        },
      });

      setShowSuggestRoleSkillsModal(false);
      setShowSelectRoleModal(false);
    },
    [addSkillRate]
  );

  useEffect(() => {
    reset();
  }, [showSelectRoleModal, reset]);

  const resumeInput = useRef<HTMLInputElement>(null);
  const deleteResume = async () => {
    try {
      await applyLoader(UserService.deleteResume(loggedUser.id));
      setUserDetails({ ...userDetails!, resume: "" });
      showNotification({
        notificationType: NotificationType.Success,
        title: "Success",
        message: "Resume removed successfully",
        displayTime: NOTIFICATION_DISPLAY_TIME,
      });
    } catch (error) {
      showNotification({
        notificationType: NotificationType.Error,
        title: "Error",
        message: "Failed to remove resume",
        displayTime: NOTIFICATION_DISPLAY_TIME,
      });
    }
  };

  const removeResumeBtnClick = (event: React.MouseEvent) => {
    ConfirmationAlertModal({
      title: "Remove Resume",
      message: "Are you sure you want to remove your resume?",
      action: "Remove",
      onAction: () => deleteResume(),
    });
  };

  const handleFileUpload = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const file = event.target.files[0];
      if (file.type !== "application/pdf") {
        showNotification({
          notificationType: NotificationType.Error,
          title: "Error",
          message: "Invalid Resume file type, please upload a .pdf file",
          displayTime: NOTIFICATION_DISPLAY_TIME,
        });
        resumeInput.current!.value = "";
      } else if (file.size > 2_621_440) {
        showNotification({
          notificationType: NotificationType.Error,
          title: "Error",
          message: "Resume file size exceeds 2.5MB.",
          displayTime: NOTIFICATION_DISPLAY_TIME,
        });
        resumeInput.current!.value = "";
      } else {
        handleFile(file);
      }
    }
  };

  const handleFile = async (file: File) => {
    const reader = new FileReader();
    let imageBase64 = "";
    reader.onload = () => {
      imageBase64 = reader.result as string;
    };
    reader.readAsDataURL(file);

    const fileExtension = file.name.split(".").pop();
    const { data } = await S3Service.presignedUserResume(`${loggedUser.id}.${fileExtension}`);
    const resumeUrl = S3Service.getUrl(data.url);
    await applyLoader(
      S3Service.upload(resumeUrl, imageBase64).then(async () => {
        try {
          await UserService.updateResume(loggedUser.id, resumeUrl);
          setUserDetails({ ...userDetails!, resume: resumeUrl });
          showNotification({
            notificationType: NotificationType.Success,
            title: "Success",
            message: "Resume successfully uploaded",
            displayTime: NOTIFICATION_DISPLAY_TIME,
          });
        } catch (error) {
          showNotification({
            notificationType: NotificationType.Error,
            title: "Error",
            message: "Failed to upload resume",
            displayTime: NOTIFICATION_DISPLAY_TIME,
          });
        }
      })
    );
  };

  const getWorkingGeniusImage = (workingGenius: string): string => {
    if (workingGenius === WorkingType.Eagle) {
      return WG_EAGLE;
    } else if (workingGenius === WorkingType.Elephant) {
      return WG_ELEPHANT;
    } else if (workingGenius === WorkingType.Horse) {
      return WG_HORSE;
    } else if (workingGenius === WorkingType.Owl) {
      return WG_OWL;
    } else if (workingGenius === WorkingType.Raven) {
      return WG_RAVEN;
    } else if (workingGenius === WorkingType.Wolverine) {
      return WG_WOLVERINE;
    } else {
      return "";
    }
  };

  return (
    <div className="flex h-full">
      <div className="flex min-w-0 flex-1 flex-col overflow-hidden">
        <div className="relative z-0 flex flex-1 overflow-hidden">
          <main className="relative z-0 flex-1 focus:outline-none xl:order-last">
            <article className="mb-4">
              <div>
                <div>
                  <img className="h-32 w-full object-cover lg:h-48" src={userDetails?.bannerUrl ?? "/images/default_banner.png"} alt="" />
                </div>
                <div className="mx-auto max-w-5xl px-4 sm:px-6 lg:px-8">
                  <div className="-mt-12 sm:-mt-16 sm:flex sm:items-end sm:space-x-5">
                    <div className="flex">
                      {userDetails && (
                        <Avatar
                          nameParts={[userDetails.firstName ?? "", userDetails.lastName ?? ""]}
                          imageUrl={userDetails.profilePictureUrl}
                          textClasses="text-4xl font-bold leading-none text-white"
                          sizeClasses="h-24 w-24 rounded-full ring-4 ring-white sm:h-32 sm:w-32"
                        />
                      )}
                    </div>
                    <div className="mt-6 sm:flex sm:min-w-0 sm:flex-1 sm:items-center sm:justify-end sm:space-x-6 sm:pb-1">
                      <div className="mt-6 min-w-0 flex-1 sm:hidden 2xl:block">
                        <h1 className="truncate text-2xl font-bold capitalize text-gray-900">
                          {userDetails?.firstName} {userDetails?.lastName}
                        </h1>
                      </div>
                      <div className="mt-6 flex flex-col justify-stretch space-y-3 sm:flex-row sm:space-x-4 sm:space-y-0">
                        <ProfileAction userDetails={userDetails} loggedUser={loggedUser} isConnected={isConnected} />
                        <UserConnectionModal
                          show={showUserConnection}
                          setOpen={setShowUserConnection}
                          data={userDetails}
                          setIsConnected={setIsConnected}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="mt-6 hidden min-w-0 flex-1 sm:block 2xl:hidden">
                    <h1 className="truncate text-2xl font-bold text-gray-900">
                      {userDetails?.firstName} {userDetails?.lastName}
                    </h1>
                  </div>
                </div>
              </div>
              <Tabs classNames="-mb-px flex space-x-8">
                <TabPane title="Profile">
                  <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                    <div key="email" className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500">Email</dt>
                      <dd className="mt-1 text-sm text-gray-900">{userDetails?.email}</dd>
                    </div>
                    <div key="location" className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500">Location</dt>
                      <div className="flex justify-start space-x-2 pt-1 align-middle">
                        {userDetails?.location && (
                          <>
                            <Flag code={userCountry.isoCode} className="relative flex items-center align-middle" height={20} width={20} />
                            <dd className="mt-1 pb-1 text-sm capitalize text-gray-900">{userDetails?.location}</dd>
                          </>
                        )}
                      </div>
                    </div>
                    <div className="sm:col-span-2">
                      <dt className="text-sm font-medium text-gray-500">About</dt>
                      <dd className="mt-1 max-w-prose space-y-5 text-sm text-gray-900">
                        <p className="normal-case">{userDetails?.aboutMe}</p>
                      </dd>
                    </div>
                  </dl>
                </TabPane>
                <TabPane title="Skills">
                  <div className="gap-x-2 sm:flex sm:items-center">
                    <p className="mr-auto text-sm text-gray-700">A list of all the skills that the user has added to their profile.</p>
                    {canEdit() && (
                      <>
                        <button
                          onClick={() => setShowSelectRoleModal(true)}
                          className="inline-flex justify-center gap-x-1.5 rounded-md bg-varla-violet-100/90 px-3 py-2 text-sm text-white ring-1 ring-inset ring-gray-300 hover:bg-varla-violet-100/80"
                        >
                          Suggest Skill
                          <BoltIcon className="text-white-400 -ml-0.5 h-5 w-5" aria-hidden="true" />
                        </button>
                        <Modal show={showSelectRoleModal} onClose={() => {}}>
                          <div className="space-y-2 pt-1">
                            <div className="font-semibold">Add Suggested Skills</div>
                            <div className="text-sm text-gray-500">
                              Enter or select a role to get a list of suggested skill based on the entered role.
                            </div>
                          </div>
                          <form onSubmit={onSubmit}>
                            <div className="w-full py-4">
                              <label htmlFor="roleName" className="block text-sm font-medium leading-6 text-gray-900">
                                Role
                              </label>
                              <AutoCompleteCombobox
                                options={
                                  roles?.map((role) => ({
                                    id: role.id.toString(),
                                    value: role.name,
                                  })) || []
                                }
                                selected={watch("role")}
                                fieldKey={"role"}
                                onChange={onChange}
                              />
                            </div>
                            <div className="flex h-fit min-h-full justify-end space-x-1 pt-8">
                              <button
                                type="button"
                                className="mr-3 rounded-md border-2 px-3 py-2 text-sm font-semibold text-black shadow-sm hover:bg-gray-200"
                                onClick={() => setShowSelectRoleModal(false)}
                              >
                                Cancel
                              </button>
                              <button
                                data-testid="SubmitButton"
                                type="submit"
                                className="mr-3 inline-flex items-center justify-center rounded-md bg-violet-600 px-3 py-2 text-sm font-semibold text-white shadow-sm enabled:hover:bg-violet-500 disabled:cursor-not-allowed disabled:bg-slate-400"
                                disabled={isSubmitting || !getValues("role")}
                              >
                                Suggest Skills
                              </button>
                            </div>
                          </form>
                        </Modal>
                        {userDetails?.id && (
                          <SuggestRoleSkillsModal
                            show={showSuggestRoleSkillsModal}
                            role={getValues("role")}
                            onClose={suggestedSkillsOnClose}
                            showSuccess={false}
                            profileUserId={userDetails.id}
                          />
                        )}

                        <button
                          onClick={() => {
                            setUserSkillModalInteraction({
                              data: undefined,
                              action: ModalAction.Add,
                            });
                            setShowRateSkill(true);
                          }}
                          className="inline-flex justify-center gap-x-1.5 rounded-md bg-varla-violet-100/90 px-3 py-2 text-sm text-white ring-1 ring-inset ring-gray-300 hover:bg-varla-violet-100/80"
                        >
                          Add Skill
                          <PlusIcon className="text-white-400 -ml-0.5 h-5 w-5" aria-hidden="true" />
                        </button>
                      </>
                    )}
                    {userDetails?.id && (
                      <UserSkillModal
                        show={showRateSkill}
                        onClose={onUserSkillModalClose}
                        interaction={userSkillModalInteraction}
                        profile={{
                          userId: userDetails.id,
                          fullName: fullName(userDetails),
                        }}
                      />
                    )}
                  </div>
                  <div className="min-h-screen">
                    <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                      <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                        <div className="relative mx-auto w-1/2 py-8">
                          <label
                            htmlFor="searchSkill"
                            className="absolute left-2 top-[1.6rem] inline-block bg-white px-1 text-xs font-medium text-gray-900"
                          >
                            Search for skills
                          </label>
                          <input
                            type="text"
                            name="searchSkill"
                            className="block w-full rounded-md border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            placeholder="skill"
                            onChange={(event) => setQueryUserSkill(event.target.value)}
                          />
                        </div>
                        <table className="min-w-full divide-y divide-gray-300">
                          <thead>
                            <tr>
                              <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                                <div className="sm:flex sm:items-center">
                                  <p className="mr-10">Skill</p>
                                  <button onClick={handleSort} data-testid="skill-sort">
                                    <ArrowsUpDownIcon
                                      className="text-white-400 -ml-0.5 mr-auto h-5 w-5"
                                      aria-hidden="true"
                                      data-value="skill"
                                    />
                                  </button>
                                </div>
                              </th>
                              <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                <div className="sm:flex sm:items-center">
                                  <p className="mr-10">Average Rating</p>
                                  <button onClick={handleSort}>
                                    <ArrowsUpDownIcon
                                      className="text-white-400 -ml-0.5 mr-auto h-5 w-5 cursor-pointer"
                                      aria-hidden="true"
                                      data-value="rate"
                                    />
                                  </button>
                                </div>
                              </th>
                              <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                <div className="sm:flex sm:items-center">
                                  <p className="mr-10"># of Ratings</p>
                                  <button onClick={handleSort}>
                                    <ArrowsUpDownIcon
                                      className="text-white-400 -ml-0.5 mr-auto h-5 w-5 cursor-pointer"
                                      aria-hidden="true"
                                      data-value="rate"
                                    />
                                  </button>
                                </div>
                              </th>
                              <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
                                <span className="sr-only">Edit</span>
                              </th>
                            </tr>
                          </thead>
                          <tbody className="divide-y divide-gray-200 bg-white">
                            <SkillAveragesTableRows />
                          </tbody>
                        </table>
                        <nav className="flex items-center justify-between border-t border-gray-200 px-4 sm:px-0">
                          <div className="-mt-px flex w-0 flex-1">
                            <button
                              className="inline-flex items-center border-t-2 border-transparent pr-1 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
                              onClick={() => handlePageChange(currentPage - 1)}
                            >
                              <ArrowLongLeftIcon className="mr-3 h-5 w-5 text-gray-400" aria-hidden="true" />
                              Previous
                            </button>
                          </div>
                          <div className="hidden md:-mt-px md:flex">
                            {pageNumbers.map((pageNumber) => (
                              <button
                                className={`inline-flex items-center border-t-2 border-transparent px-4 pt-4 text-sm font-medium ${
                                  currentPage === pageNumber
                                    ? "border-violet-500 text-violet-600"
                                    : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700"
                                }`}
                                key={pageNumber}
                                aria-current={currentPage === pageNumber ? "page" : undefined}
                                onClick={() => handlePageChange(pageNumber)}
                              >
                                {pageNumber}
                                <span className="inline-flex items-center border-r-2 text-sm font-medium text-gray-500"></span>
                              </button>
                            ))}
                          </div>
                          <div className="-mt-px flex w-0 flex-1 justify-end">
                            <button
                              className="inline-flex items-center border-t-2 border-transparent pl-1 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
                              data-testid="next-page"
                              onClick={() => handlePageChange(currentPage + 1)}
                            >
                              Next
                              <ArrowLongRightIcon className="ml-3 h-5 w-5 text-gray-400" aria-hidden="true" />
                            </button>
                          </div>
                        </nav>
                      </div>
                    </div>
                  </div>
                </TabPane>
                <TabPane title="Work Experience">
                  <div className="flex flex-col">
                    {canEdit() && (
                      <div className="w-full ">
                        <button
                          onClick={() => {
                            setModalWorkExperienceData(undefined);
                            setShowWorkExperience(true);
                          }}
                          className="float-right mb-4 inline-flex justify-center gap-x-1.5 rounded-md bg-varla-violet-100/90 px-3 py-2 text-sm text-white ring-1 ring-inset ring-gray-300 hover:bg-varla-violet-100/80"
                        >
                          Add Experience
                          <PlusIcon className="text-white-400 -ml-0.5 h-5 w-5" aria-hidden="true" />
                        </button>
                        <WorkExperienceModal
                          show={showWorkExperience}
                          onClose={onWorkExperienceModalClose}
                          data={modalWorkExperienceData}
                          profileUserId={loggedUser.id}
                        />
                      </div>
                    )}
                    <div className="flex flex-col lg:py-8">
                      <WorkExperience />
                    </div>
                  </div>
                </TabPane>
                <TabPane title="Personality" hide={!canEdit()}>
                  <div className="mt-6 flex items-center justify-center">
                    {(userDetails?.personalitySummary && (
                      <div>
                        <div className="mb-10 text-center text-3xl font-bold text-gray-900">Your Personality Types</div>
                        <WorkingGeniusSummary />
                        <p className="my-8 text-xl">Summary:</p>
                        <div dangerouslySetInnerHTML={{ __html: formatAndSanitize(userDetails!.personalitySummary) }} />
                      </div>
                    )) || (
                      <WorkingGeniusPage setUserDetails={setUserDetails} userDetails={userDetails}></WorkingGeniusPage>
                    )}
                  </div>
                </TabPane>
                <TabPane title="Resume" hide={!canEdit()}>
                  {userDetails?.resume ? (
                    <div className="flex flex-col">
                      <button
                        className="ml-auto inline-flex gap-x-1.5 rounded-md bg-varla-violet-100/90 px-3 py-2 text-sm text-white shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-varla-violet-100/80"
                        onClick={removeResumeBtnClick}
                      >
                        Remove Resume
                        <TrashIcon className="h-5 w-5" aria-hidden="true" />
                      </button>
                      <div className="align-center mt-8 flex flex-col justify-center space-x-0 space-y-1 rounded sm:flex-row sm:space-x-1 sm:space-y-0">
                        <a
                          className="flex flex-row justify-center gap-4 rounded-full bg-varla-violet-100/90 px-5 py-3 text-sm font-bold text-white shadow-sm hover:bg-varla-violet-100/80"
                          href={userDetails.resume}
                          target="_blank"
                          rel="noreferrer"
                        >
                          Open Resume in new tab
                          <ArrowUturnRightIcon className="h-5 w-5" aria-hidden="true" />
                        </a>
                      </div>
                    </div>
                  ) : (
                    <>
                      <button
                        className="float-right inline-flex gap-x-1.5 rounded-md bg-varla-violet-100/90 px-3 py-2 text-sm text-white shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-varla-violet-100/80"
                        onClick={() => resumeInput.current!.click()}
                      >
                        Upload Resume
                        <DocumentPlusIcon className="h-5 w-5" aria-hidden="true" />
                      </button>
                      <input
                        type="file"
                        data-testid="resumeInput"
                        onChange={handleFileUpload}
                        ref={resumeInput}
                        accept="application/pdf"
                        className="hidden"
                      />
                    </>
                  )}
                </TabPane>
              </Tabs>
            </article>
          </main>
        </div>
      </div>
    </div>
  );
};

export default UserProfilePage;
