import { yupResolver } from "@hookform/resolvers/yup";
import { Dispatch, SetStateAction, useContext, useEffect } from "react";
import { useForm } from "react-hook-form";
import AppContext, { NotificationType } from "AppContext";
import CompanyService from "api/CompanyService";
import { CompanyEmployeeFormType } from "types/Company.types";
import { NOTIFICATION_DISPLAY_TIME, NOTIFICATION_DISPLAY_TIME_LONG } from "utils/constants";
import { CompanyEmployeeValidate } from "utils/validateForm";
import Modal from "./Modal";
import { AxiosError, isAxiosError } from "axios";

type Props = {
  show: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  companyId: number;
};

const COMPANY_EMPLOYEE_ACCESS_LEVELS: { [accessLevel: string]: string } = {
  admin: "Admin",
  employee: "Employee",
};

const InsertCompanyEmployeeModal = (props: Props): JSX.Element => {
  const { show, setOpen, companyId } = props;
  const context = useContext(AppContext);

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
  } = useForm<CompanyEmployeeFormType>({
    resolver: yupResolver(CompanyEmployeeValidate),
  });

  useEffect(() => {
    reset({ addedBy: context.session.loggedUser.id, active: true, companyId: companyId });
  }, [context.session.loggedUser, companyId, show, reset]);

  const getErrMessage = (error: Error | AxiosError) => {
    if (!isAxiosError(error)) {
      return error.message;
    }

    if (error.response?.status === 409) {
      return "User has already been invited";
    }

    return error.response?.data.message;
  };

  const onSubmit = handleSubmit(async (formData) => {
    try {
      await CompanyService.insertCompanyEmployee(formData);
      context.notification.showNotification({
        notificationType: NotificationType.Success,
        title: "Successfully added employee to your company",
        message: `${formData.userEmail} is added as ${formData.accessLevel}.`,
        displayTime: NOTIFICATION_DISPLAY_TIME_LONG,
      });
    } catch (error) {
      // TODO: When inactive profile feature is available redirect 400 validation error (user not found) to that page
      context.notification.showNotification({
        notificationType: NotificationType.Error,
        title: "Error adding employee to your company",
        message: getErrMessage(error),
        displayTime: NOTIFICATION_DISPLAY_TIME,
      });
    }
    setOpen(false);
  });

  return (
    <Modal show={show} onClose={() => setOpen(false)} title={"Invite a user to your company"}>
      <div className="mt-2">
        <p className="text-sm text-gray-500">Enter an email of a user you would like to invite to your company and select their role.</p>
        <form onSubmit={onSubmit} className="mt-5 items-start sm:flex">
          <div className="flex w-full flex-col">
            <div>
              <label htmlFor="userEmail" className="sr-only">
                Email
              </label>
              <input
                id="userEmail"
                {...register("userEmail")}
                type="text"
                className="block w-full rounded-md border-0 py-1.5 lowercase text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-violet-600 sm:text-sm sm:leading-6"
              />
              {errors?.userEmail && <div className="mt-2 block w-full py-1 text-sm text-red-700">{errors.userEmail.message}</div>}

              <select
                data-testid="accessLevel"
                id="accessLevel"
                {...register("accessLevel")}
                className="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-violet-600 sm:text-sm sm:leading-6"
              >
                {Object.entries(COMPANY_EMPLOYEE_ACCESS_LEVELS).map(([accessLevel, accessLevelName]) => (
                  <option key={accessLevel} value={accessLevel}>
                    {accessLevelName}
                  </option>
                ))}
              </select>
              {errors?.accessLevel && <div className="mt-2 block w-full py-1 text-sm text-red-700">{errors.accessLevel.message}</div>}
            </div>
            <div className="mt-5 flex justify-items-end sm:mt-6">
              <button
                type="button"
                className="ml-auto mr-2 rounded-md border-2 px-3 py-2 text-sm font-semibold text-black shadow-sm hover:bg-gray-200"
                onClick={() => setOpen(false)}
              >
                Cancel
              </button>
              <button
                disabled={isSubmitting}
                type="submit"
                className="rounded-md border-2 bg-violet-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-violet-500"
              >
                Invite
              </button>
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default InsertCompanyEmployeeModal;
