import { Disclosure } from "@headlessui/react";
import { MinusSmallIcon, PlusSmallIcon } from "@heroicons/react/20/solid";

const faqs = [
  {
    question: `How to edit your Profile.`,
    answer: `<ol>
        <li><b>Access Your Profile:</b> Start by logging into your account and accessing your user profile. This is typically done by clicking on your profile picture or username, usually located at the top of the screen or in the navigation menu.</li><li><b>Locate 'Edit Profile' Button:</b> On your profile page, look for the "Edit Profile" button. This button is usually placed near your profile information or settings.</li><li><b>Click 'Edit Profile':</b> Click on the "Edit Profile" button. This action will redirect you to the profile editing form or page.</li><li><b>Make Desired Changes:</b> In the profile editing form, you'll have the opportunity to make changes to various aspects of your profile. You can update information such as your profile picture, banner image, name, location, bio, and any other details available for editing.</li><li><b>Save Changes:</b> After making the desired changes, don't forget to save your edits. This is often done by clicking a "Save" or "Update" button located at the bottom of the edit form.</li><li><b>Confirmation:</b> Once you've saved your changes, you should receive a confirmation message indicating that your profile has been updated successfully.</li></ol><br/><p>Your profile has now been edited with the changes you've made. Others will see the updated information when they visit your profil</p>`,
  },
  {
    question: `How to add your Skills.`,
    answer: `<ol>
        <li><b>Access Your Profile:</b> Start by logging into your account and accessing your user profile. This is typically done by clicking on your profile picture or username, usually located at the top of the screen or in the navigation menu.</li><li><b>Navigate to the Skills Tab:</b> Once you're on your profile page, look for a tab labeled "Skills" or something similar. Click on this tab to access your skills section.</li><li><b>Click 'Add Skill' Button:</b> On the skills page, locate and click the "Add Skill" button. This button is usually prominently displayed and is used to add new skills to your profile.</li><li><b>Enter Skill Details:</b> A pop-up window or form will appear. In this form, type in the name of the skill you want to add.</li><li><b>Select Proficiency Level:</b> Next, select your proficiency level for this skill. This typically ranges from options like "Learning" (indicating you're just starting with the skill) to "Ninja" (representing advanced proficiency).</li><li><b>Click 'Save':</b> After entering the skill name and selecting your proficiency level, click the "SAVE" button or a similar option to save your newly added skill to your profile.</li><li><b>Confirmation:</b> You should receive a confirmation message indicating that your skill has been successfully added to your profile.
        </li></ol><br/><p>Your profile has now been updated with the new skill, including your proficiency level. Others can see your skills and their respective proficiency levels when they visit your profile.</p>`,
  },
  {
    question: `How to edit and/or remove your Skills.`,
    answer: `<ol>
        <li><b>Access Your Profile:</b> Start by logging into your account and accessing your user profile. This is typically done by clicking on your profile picture or username, usually located at the top of the screen or in the navigation menu.</li><li><b>Navigate to the Skills Tab:</b> Once you're on your profile page, look for a tab labeled "Skills" or something similar. Click on this tab to access your skills section.</li><li><b>Select the Skill to Edit or Remove:</b> On the skills page, you will see a table or list of your skills. Locate the skill you want to edit or remove, and select it. This can often be done by clicking on the skill's name or a checkbox next to it.</li><li><b>Click 'Edit':</b> After selecting the skill, look for an "Edit" button or option. Click on this button to initiate the editing process.</li><li><b>Update Proficiency Level:</b> In the pop-up window that appears, you can now select or modify the proficiency level for this skill. Choose the appropriate level, ranging from "Learning" to "Ninja."
        </li><li><b>Remove the Skill (Optional):</b> If you wish to remove the skill entirely from your profile, you can often find a "Remove" or "Delete" button within the same pop-up window. Click on this button if you want to remove the skill.</li><li><b>Click 'Save':</b> After making your desired changes (either updating the proficiency level or removing the skill), click the "SAVE" button to save your edits.</li><li><b>Confirmation:</b> You should receive a confirmation message indicating that your skill has been successfully edited or removed from your profile.
        </li></ol><p>Your profile has now been updated with the edited skill information, including the new proficiency level, or the skill has been removed if you chose that option. Others can see the updated skill details when they visit your profile.</p>`,
  },
  {
    question: `How to add your Work Experience.`,
    answer: `<ol>
        <li><b>Access Your Profile:</b> Start by logging into your account and accessing your user profile. This is typically done by clicking on your profile picture or username, usually located at the top of the screen or in the navigation menu.</li><li><b>Navigate to the Work Experience Tab:</b> Once you're on your profile page, look for a tab labeled "Work Experience" or something similar. Click on this tab to access your work experience section.</li><li><b>Click 'Add Experience' Button:</b> On the work experience page, locate and click the "Add Experience" button. This button is usually prominently displayed and is used to add new work experiences to your profile.</li><li><b>Enter Experience Details:</b> A pop-up window or form will appear. In this form, you will be prompted to enter specific details about your work experience. Typically, you'll need to provide:
        <br/>
        • Company Name<br/>
        • Your Role in the Company<br/>
        • A brief description or "About" section of your role<br/>
        • Start and End Date for the role in the company<br/>
        </li><li><b>Complete the Form:</b> Fill in the requested information accurately for the work experience you want to add to your profile.</li><li><b>Click 'Save':</b> After entering all the necessary details, click the "Save" button or a similar option within the pop-up window to save your newly added work experience to your profile.</li><li><b>Confirmation:</b> You should receive a confirmation message indicating that your work experience has been successfully added to your profile.
        </li></ol><br/><p>Your profile has now been updated with the new work experience entry. Others can view your work history and details when they visit your profile.</p>`,
  },
  {
    question: `How to edit or remove your Work Experience.`,
    answer: `<b>Editing Work Experience:</b><br/>
        <ol>
        <li><b>Access Your Profile:</b> Start by logging into your account and accessing your user profile. This is typically done by clicking on your profile picture or username, usually located at the top of the screen or in the navigation menu.
        </li><li><b>Navigate to the Work Experience Tab:</b> Once you're on your profile page, look for a tab labeled "Work Experience" or something similar. Click on this tab to access your work experience section.
        </li><li><b>Select the Work Experience to Edit:</b> In the list of your work experience entries, locate the specific one you want to edit. Click on the pencil icon (or an edit icon) usually found to the right of that entry. This action will open an editing interface.
        </li><li><b>Edit Information:</b> In the pop-up window that appears, you can now make changes to the information associated with that work experience entry. You can typically edit details such as:
        <br/>• Company Name
        <br/>•Your Role in the Company
        <br/>•About section (a brief description of your role)
        <br/>•Start and End Date for that role in the company
        </li><li><b>Save Changes:</b> After making your desired edits, click the "Save" button or a similar option within the pop-up window to save the updated work experience information.
        <br/><br/>
        <b>Removing Work Experience:</b><br/>
        <ol>
        <li>Access Your Profile:</b> Begin by logging into your account and accessing your user profile as mentioned earlier.
        </li><li><b>Navigate to the Work Experience Tab:</b> Once on your profile page, click on the "Work Experience" tab to access your work history.
        </li><li><b>Select the Work Experience to Remove:</b> In the list of your work experience entries, locate the specific one you want to remove. Click on the remove or delete button usually associated with that entry.
        </li><li><b>Confirm Removal:</b> Some platforms may ask for confirmation before deleting. If prompted, confirm that you want to remove this work experience entry.
        </li><li><b>Save Changes:</b> If you confirmed the removal, click the "Save" button or a similar option to save the changes. The selected work experience entry will now be removed from your profile.
        </li></ol><br/>
        <p>That's it! You've successfully edited or removed work experience entries from your profile, depending on your preference. Others can view your updated profile information when they visit your profile.</p>`,
  },
  {
    question: `How to add your Working Genius types.`,
    answer: `<ol><li><b>Access Your Profile:</b> Begin by logging into your account and accessing your user profile. This is typically done by clicking on your profile picture or username, usually located at the top of the screen or in the navigation menu.</li><li><b>Edit Your Profile:</b> On your profile page, look for the "Edit Profile" button or a similar option. Click on this button to initiate the profile editing process.</li><li><b>Navigate to Personality Type Section:</b> In the profile editing form, scroll down to the bottom of the page. Look for the section where you can specify your personality type.</li><li><b>Select Your Personality Types:</b> Within this section, you will typically find a list of personality types or traits. You can select the personality types that best describe you or that you identify with. This might involve clicking checkboxes, radio buttons, or similar selection methods.</li><li><b>Save Changes:</b> After selecting your personality types, click the "Save" button or a similar option at the bottom of the profile editing form to save the changes to your profile.</li><li><b>Confirmation:</b> You should receive a confirmation message indicating that your personality type information has been successfully added to your profile.</li></ol>`,
  },
];

export const GuideProfilePage: React.FC = () => {
  return (
    <>
      {faqs.map((faq) => (
        <Disclosure as="div" key={faq.question} className="pb-6">
          {({ open }) => (
            <>
              <dt>
                <Disclosure.Button className="flex w-full items-start justify-between rounded-md p-2 text-left text-gray-900 hover:bg-violet-50">
                  <span className="text-base font-semibold leading-7">{faq.question}</span>
                  <span className="ml-6 flex h-7 items-center">
                    {open ? (
                      <MinusSmallIcon className="h-6 w-6" aria-hidden="true" />
                    ) : (
                      <PlusSmallIcon className="h-6 w-6" aria-hidden="true" />
                    )}
                  </span>
                </Disclosure.Button>
              </dt>
              <Disclosure.Panel as="dd" className="mt-2 p-2 pl-12 pr-12">
                <p className="text-base leading-7 text-gray-600" dangerouslySetInnerHTML={{ __html: faq.answer }}></p>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      ))}
    </>
  );
};

export default GuideProfilePage;
