import { instance } from "./base.api";
import { AxiosResponse } from "axios";
import { TeamInformation } from "types/Team.types";

export const getTeam = async (companyId: number, teamId: number): Promise<AxiosResponse<TeamInformation>> => {
  return instance.get(`companies/${companyId}/teams/${teamId}`);
};

const TeamService = {
  getTeam,
};

export default TeamService;
