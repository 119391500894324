import { ReactElement } from "react";

type Props = {
  title: string;
  isDisabled?: boolean;
  children: ReactElement | ReactElement[];
};

const Step = ({ children }: Props): JSX.Element => <div>{children}</div>;

export default Step;
