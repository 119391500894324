import { useEffect, useState } from "react";
import { Outlet, useNavigate, useParams } from "react-router";
import { NotificationType, useAppContext } from "AppContext";
import CompanyService from "api/CompanyService";
import { PrivateRoute, PrivateRouteCondition } from "components/PrivateRoute/PrivateRoute";
import { useLoader } from "hooks/useLoader";
import { Company } from "types/Company.types";
import { NOTIFICATION_DISPLAY_TIME } from "utils/constants";

export type CompanyContainerOutletContextType = {
  company: Company;
};

export const CompanyContainerPage = () => {
  const {
    session: {
      loggedUser: { id: loggedUserId },
    },
    notification: { showNotification },
  } = useAppContext();
  const [outlet, setOutlet] = useState(<></>);
  const { companyId } = useParams();
  const navigate = useNavigate();
  const { applyLoader } = useLoader();

  useEffect(() => {
    const fetchCompany = async () => {
      const { data: company, error } = await applyLoader(CompanyService.getCompanyDashboard(Number(companyId)));
      if (company) {
        const loggedIsOwnerOrEmployee =
          company.userId === loggedUserId || company.employees.find((employee) => employee.id === loggedUserId) !== undefined;
        const companyDashboardGuard: PrivateRouteCondition = {
          check: () => loggedIsOwnerOrEmployee,
          onFailedCheck: {
            redirect: "/companies",
            errorMessage: "You do not have permissions to view the page.",
            errorTitle: "Unauthorized",
          },
        };
        const context: CompanyContainerOutletContextType = { company };
        setOutlet(<PrivateRoute conditions={[companyDashboardGuard]} outlet={<Outlet context={context} />} />);
      } else {
        showNotification({
          notificationType: NotificationType.Error,
          title: "Unable to fetch company data",
          message: error.message,
          displayTime: NOTIFICATION_DISPLAY_TIME,
        });
        navigate("/companies");
      }
    };

    fetchCompany();
  }, [companyId, loggedUserId, applyLoader, showNotification, navigate]);

  return outlet;
};

export default CompanyContainerPage;
