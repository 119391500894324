import { ChevronRightIcon } from "@heroicons/react/20/solid";
import { useCallback, useEffect, useState } from "react";
import Autocomplete from "react-autocomplete";
import DatePicker from "react-datepicker";
import { useForm } from "react-hook-form";
import { NotificationType, useAppContext } from "AppContext";
import CompanyService from "api/CompanyService";
import RoleService from "api/RoleService";
import WorkExperienceService from "api/WorkExperienceService";
import { useLoader } from "hooks/useLoader";
import { Company } from "types/Company.types";
import { Role } from "types/Role.types";
import { UserSkillType } from "types/UserSkill.types";
import { WorkExperienceFormType, WorkExperienceType } from "types/WorkExperience.types";
import { NOTIFICATION_DISPLAY_TIME } from "utils/constants";
import { formPatchDocument } from "utils/form-patch-document";
import WorkExperience, { WorkExperienceValidationErrors } from "utils/form-validations/workExperience";
import Avatar from "../Avatar/Avatar";
import ConfirmationAlertModal from "./ConfirmationAlertModal";
import Modal, { ModalAction, ModalOnCloseFunction, ModalOnCloseResult, handleModalOnCloseResult } from "./Modal";
import SuggestRoleSkillsModal from "./SuggestRoleSkillsModal";

export type WorkExperienceModalResponse = {
  workExperience: WorkExperienceType;
  skills: Array<UserSkillType>;
};

type WorkExperienceProps = {
  show: boolean;
  data?: WorkExperienceType;
  onClose: ModalOnCloseFunction<WorkExperienceModalResponse>;
  profileUserId: number;
};

const WorkExperienceModal = (workExperienceProps: WorkExperienceProps): JSX.Element => {
  const { show, onClose, data } = workExperienceProps;
  const {
    session: { loggedUser },
    notification: { showNotification },
  } = useAppContext();
  const [companies, setCompanies] = useState<Array<Company>>([]);
  const [roles, setRoles] = useState<Array<Role>>([]);
  const modalTitle = "Work Experience";
  const modalBody = "Enter information of your work experience.";
  const WORK_EXPERIENCE_REMOVED_SUCCESSFULLY = "Work Experience Removed Successfully";
  const [selectedCompany, setSelectedCompany] = useState<{ name: string }>({ name: "" });
  const [selectedRole, setSelectedRole] = useState<{ name: string }>({ name: "" });
  const [startDate, setStartDate] = useState<Date | null>(new Date());
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [formErrors, setFormErrors] = useState<WorkExperienceValidationErrors>({});
  const [showSuggestRoleSkillsModal, setShowSuggestRoleSkillsModal] = useState(false);
  const { applyLoader } = useLoader();
  const actionButtonsContainerClassNames = data ? "justify-between" : "justify-end";
  const [modalOnCloseResult, setModalOnCloseResult] = useState<ModalOnCloseResult<WorkExperienceModalResponse> | null>(null);

  const {
    register,
    reset,
    handleSubmit,
    formState: { isSubmitting, dirtyFields },
  } = useForm<WorkExperienceFormType>({});

  useEffect(() => {
    reset({
      about: data?.about,
    });
    // form SHOULD USED instead of states
    setSelectedCompany({ name: data?.companyName ?? "" });
    setSelectedRole({ name: data?.role ?? "" });
    setStartDate(data?.startDate ?? new Date());
    setEndDate(data?.endDate ?? null);
  }, [reset, show, data]);

  useEffect(() => {
    const fetchCompanies = async (): Promise<void> => {
      const { data, error } = await CompanyService.listCompanies();
      if (data) {
        setCompanies(data);
      } else {
        showNotification({
          notificationType: NotificationType.Error,
          title: "Error fetching companies.",
          message: error.message,
          displayTime: NOTIFICATION_DISPLAY_TIME,
        });
      }
    };

    const fetchRoles = async (): Promise<void> => {
      const { data, error } = await RoleService.listRoles();
      if (data) {
        setRoles(data);
      } else {
        showNotification({
          notificationType: NotificationType.Error,
          title: "Error fetching roles.",
          message: error.message,
          displayTime: NOTIFICATION_DISPLAY_TIME,
        });
      }
    };
    fetchCompanies();
    fetchRoles();
  }, [showNotification]);

  const suggestedSkillsOnClose = useCallback<ModalOnCloseFunction<Array<UserSkillType>>>(
    (onCloseResult) => {
      let skills: Array<UserSkillType> = [];
      handleModalOnCloseResult(onCloseResult, {
        [ModalAction.Add]: (suggestedSkills) => {
          skills = suggestedSkills;
        },
      });
      setShowSuggestRoleSkillsModal(false);
      const {
        action,
        data: { workExperience },
      } = modalOnCloseResult!;
      onClose({ action, data: { skills, workExperience } });
    },
    [modalOnCloseResult, onClose]
  );

  const renderCompanyRow = useCallback((company: Company, highlighted: boolean) => {
    return (
      <div className={`item ${highlighted ? "selected-item" : ""}`} key={company.id}>
        <div className="flex max-w-fit items-center gap-2 px-1">
          <div>
            <Avatar
              nameParts={company.name.split(" ")}
              imageUrl={company.logoUrl}
              textClasses="text-sm"
              sizeClasses="h-8 w-8 rounded-full"
            />
          </div>
          <div className="flex min-w-0 items-baseline gap-2">
            <span className="text-md truncate font-medium">{company.name}</span>
            <span className="-mx-1 opacity-40">
              <ChevronRightIcon className="inline-block h-4 w-4" aria-hidden="true" />
            </span>
            <span className="whitespace-nowrap text-xs opacity-60">{company.industry}</span>
          </div>
        </div>
      </div>
    );
  }, []);

  const onSubmit = handleSubmit(async (formData) => {
    formData = { ...formData, companyName: selectedCompany.name, roleName: selectedRole.name, startDate: startDate!, endDate: endDate! };
    const validationErrors = await WorkExperience.formValidation(formData);

    if (Object.keys(validationErrors).length === 0) {
      try {
        const { action, workExperience } = await (async () => {
          if (!data) {
            return {
              action: ModalAction.Add,
              workExperience: (await applyLoader(WorkExperienceService.insertWorkExperience(formData, loggedUser.id))).data,
            };
          }
          // TODO fill patchfields with the dirtyFields from the form
          let changedValues: Partial<WorkExperienceFormType> = Object.entries(dirtyFields)
            .filter(([, dirty]) => dirty)
            .map(([fieldName]) => fieldName as keyof WorkExperienceFormType)
            .reduce((acc, fieldName) => {
              // @ts-ignore
              acc[fieldName] = formData[fieldName];
              return acc;
            }, {});
          // manually adding these fields since they are not bieng registered on the form
          // remove next line fields are being handled by the form hook
          changedValues = {
            ...changedValues,
            companyName: selectedCompany.name,
            roleName: selectedRole.name,
            startDate: startDate!,
            endDate: endDate,
          };
          // Call form path document function
          const patchDocument = formPatchDocument(changedValues);
          await applyLoader(WorkExperienceService.updateWorkExperience(patchDocument, data.id, loggedUser.id));
          // update data to return onClose
          const workExperience: WorkExperienceType = {
            ...data,
            ...changedValues,
            companyName: selectedCompany.name,
            role: selectedRole.name,
            startDate: startDate!,
            endDate: endDate,
          };
          return { action: ModalAction.Edit, workExperience };
        })();
        setModalOnCloseResult({ action, data: { workExperience, skills: [] } });
        setShowSuggestRoleSkillsModal(true);
      } catch (error) {
        showNotification({
          notificationType: NotificationType.Error,
          title: "Error",
          message: error.response ? error.response.data.message : error.message,
          displayTime: NOTIFICATION_DISPLAY_TIME,
        });
        onClose();
      }
    } else {
      setFormErrors(validationErrors);
    }
  });

  const matchValue = (object: Company | Role, value: string) => {
    return object.name.toLowerCase().indexOf(value.toLowerCase()) !== -1;
  };

  const deleteWorkExperience = async (workExperience: WorkExperienceType) => {
    try {
      await applyLoader(WorkExperienceService.removeWorkExperience(workExperience.id, loggedUser.id));
      showNotification({
        notificationType: NotificationType.Success,
        title: "Success",
        message: WORK_EXPERIENCE_REMOVED_SUCCESSFULLY,
        displayTime: NOTIFICATION_DISPLAY_TIME,
      });
      onClose({ action: ModalAction.Remove, data: { workExperience, skills: [] } });
    } catch (error) {
      showNotification({
        notificationType: NotificationType.Error,
        title: "Error",
        message: error.response ? error.response.data.message : error.message,
        displayTime: NOTIFICATION_DISPLAY_TIME,
      });
      onClose();
    }
  };

  const handleRemove = (workExperience: WorkExperienceType) => {
    ConfirmationAlertModal({
      title: "Remove Work Experience",
      message: "Are you sure you want to remove this work experience?",
      action: "Remove",
      onAction: () => deleteWorkExperience(workExperience),
    });
  };

  const RemoveButton = () => {
    if (data)
      return (
        <button
          type="button"
          onClick={() => handleRemove(data)}
          className="inline-flex items-center justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500"
        >
          Remove
        </button>
      );
    return null;
  };

  return (
    <>
      <SuggestRoleSkillsModal
        show={showSuggestRoleSkillsModal}
        role={selectedRole.name}
        onClose={suggestedSkillsOnClose}
        showSuccess={true}
        profileUserId={workExperienceProps.profileUserId}
      />
      <Modal show={show} onClose={onClose} title={modalTitle}>
        <div className="mt-2">
          <p className="block text-sm text-gray-500 ">{modalBody}</p>
          <form onSubmit={onSubmit} className="space-y-2">
            <div>
              <label htmlFor="companyName" className="block py-2 text-sm font-medium leading-6 text-gray-900">
                Company Name
              </label>
              <div className="autocomplete-wrapper">
                <Autocomplete
                  inputProps={{ name: "companyName", id: "companyName" }}
                  {...register("companyName")}
                  value={selectedCompany.name}
                  items={companies}
                  getItemValue={(company) => company.name}
                  shouldItemRender={matchValue}
                  renderMenu={(company) => <div className="dropdown">{company}</div>}
                  renderItem={(company, isHighlighted) => renderCompanyRow(company, isHighlighted)}
                  onChange={(event, name) => {
                    setSelectedCompany({ name });
                  }}
                  onSelect={(name) => {
                    setSelectedCompany({ name });
                  }}
                />
                {formErrors.companyName && <div className="mt-2 block w-full py-1 text-sm text-red-700">{formErrors.companyName}</div>}
              </div>
            </div>
            <div>
              <label htmlFor="roleName" className="block py-2 text-sm font-medium leading-6 text-gray-900">
                Role
              </label>
              <div className="autocomplete-wrapper">
                <Autocomplete
                  inputProps={{ name: "roleName", id: "roleName" }}
                  value={selectedRole.name}
                  items={roles}
                  getItemValue={(role) => role.name}
                  shouldItemRender={matchValue}
                  renderMenu={(role) => <div className="dropdown">{role}</div>}
                  renderItem={(role, isHighlighted) => (
                    <div className={`item ${isHighlighted ? "selected-item" : ""}`} key={role.id}>
                      {role.name}
                    </div>
                  )}
                  onChange={(event, name) => {
                    setSelectedRole({ name });
                  }}
                  onSelect={(name) => {
                    setSelectedRole({ name });
                  }}
                />
                {formErrors.roleName && <div className="mt-2 block w-full py-1 text-sm text-red-700">{formErrors.roleName}</div>}
              </div>
            </div>
            <div>
              <label htmlFor="about" className="block py-2 text-sm font-medium leading-6 text-gray-900">
                About your role
              </label>
              <textarea
                id="about"
                {...register("about")}
                rows={4}
                maxLength={500}
                tabIndex={1}
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-violet-600 sm:text-sm sm:leading-6"
              />
            </div>
            <div className="py-2 ">
              <label htmlFor="period" className="block py-2 text-sm font-medium leading-6 text-gray-900">
                Period
              </label>
              <div className="flex justify-between">
                <div>
                  <label htmlFor="startDate" className="block py-2 text-sm font-medium leading-6 text-gray-900">
                    Start Date
                  </label>
                  <DatePicker
                    name="startDate"
                    id="startDate"
                    dateFormat={"yyyy/MM/dd"}
                    showIcon
                    selected={startDate}
                    onChange={(date) => setStartDate(date!)}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-violet-600 sm:text-sm sm:leading-6"
                  />
                </div>
                <div>
                  <label htmlFor="endDate" className="block py-2 text-sm font-medium leading-6 text-gray-900">
                    End Date
                  </label>
                  <DatePicker
                    id="endDate"
                    name="endDate"
                    dateFormat={"yyyy/MM/dd"}
                    showIcon
                    selected={endDate}
                    onChange={(date) => setEndDate(date)}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-violet-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
              {formErrors.startDate && <div className="mt-2 block w-full py-1 text-sm text-red-700">{formErrors.startDate}</div>}
              {formErrors.endDate && <div className="mt-2 block w-full py-1 text-sm text-red-700">{formErrors.endDate}</div>}
            </div>
            <div className={`mt-6 flex items-center ${actionButtonsContainerClassNames} gap-x-6`}>
              <RemoveButton />
              <div>
                <button
                  type="button"
                  className="mr-3 rounded-md border-2 px-3 py-2 text-sm font-semibold text-black shadow-sm hover:bg-gray-200"
                  onClick={() => {
                    onClose();
                  }}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="inline-flex items-center justify-center rounded-md bg-violet-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-violet-500"
                  disabled={isSubmitting}
                >
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      </Modal>
    </>
  );
};

export default WorkExperienceModal;
