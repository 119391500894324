import { yupResolver } from "@hookform/resolvers/yup";
import { useContext } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import AppContext, { NotificationType } from "AppContext";
import AuthService from "api/AuthService";
import { ResetPasswordFormType } from "types/ResetPassword.types";
import { NOTIFICATION_DISPLAY_TIME } from "utils/constants";
import { PasswordResetValidate } from "utils/validateForm";

const Logo = "/images/v2.png";

const InvalidRequest = () => (
  <div className="bg-white">
    <div className="px-6 py-24 sm:px-6 sm:py-32 lg:px-8">
      <div className="mx-auto max-w-2xl text-center">
        <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Reset password error!</h2>
        <p className="mx-auto mt-6 max-w-xl text-lg leading-8 text-gray-600">
          The reset password link is not valid.
          <br />
          Please contact Tech Support.
        </p>
      </div>
    </div>
  </div>
);

export const ResetPasswordPage: React.FC<{}> = () => {
  const [searchParams] = useSearchParams();
  const email = searchParams.get("email")!;
  const code = searchParams.get("code")!;
  const navigate = useNavigate();
  const {
    notification: { showNotification },
  } = useContext(AppContext);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ResetPasswordFormType>({
    resolver: yupResolver(PasswordResetValidate),
  });

  const onSubmit = handleSubmit(async (formData) => {
    const resetPasswordData = {
      email,
      code,
      password: formData.password,
    };

    try {
      await AuthService.resetPassword(resetPasswordData);
      showNotification({
        notificationType: NotificationType.Success,
        title: "Success",
        message: "Password reset successfully.",
        displayTime: NOTIFICATION_DISPLAY_TIME,
      });
      navigate("/login");
    } catch (error) {
      showNotification({
        notificationType: NotificationType.Error,
        title: "Error resetting password",
        message: error.message,
        displayTime: NOTIFICATION_DISPLAY_TIME,
      });
    }
  });

  if (!email || !code) {
    return <InvalidRequest />;
  }

  return (
    <div className="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <img className="mx-auto h-12 w-auto" src={Logo} alt="Varla" />
        <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">Reset your password</h2>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white px-4 py-8 shadow sm:rounded-lg sm:px-10">
          <form onSubmit={onSubmit} className="space-y-6">
            <div>
              <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                Create a new password for your account.
              </label>
              <div className="mt-2">
                <input
                  id="email"
                  value={email}
                  type="text"
                  disabled
                  className="mt-1 block w-full rounded-md border border-slate-300 bg-white px-3 py-2 text-sm lowercase placeholder-slate-400 shadow-sm
                  focus:border-sky-500 focus:outline-none focus:ring-1 focus:ring-sky-500
                  disabled:border-slate-200 disabled:bg-slate-50 disabled:text-slate-500 disabled:shadow-none"
                />
              </div>
            </div>
            <div className="space-y-1">
              <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                Password
              </label>
              <div className="mt-2">
                <input
                  id="password"
                  {...register("password")}
                  type="password"
                  className="placeholder: block w-full rounded-md border-0 py-1.5 text-gray-500 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-violet-600 sm:text-sm sm:leading-6"
                />
                {errors?.password && <div className="mt-2 block w-full py-1 text-sm text-red-700">{errors.password.message}</div>}
              </div>
            </div>
            <div className="space-y-1">
              <label htmlFor="confirmPassword" className="block text-sm font-medium leading-6 text-gray-900">
                Confirm Password
              </label>
              <div className="mt-2">
                <input
                  id="confirmPassword"
                  {...register("confirmPassword")}
                  type="password"
                  className="placeholder: block w-full rounded-md border-0 py-1.5 text-gray-500 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-violet-600 sm:text-sm sm:leading-6"
                />
                {errors?.confirmPassword && (
                  <div className="mt-2 block w-full py-1 text-sm text-red-700">{errors.confirmPassword.message}</div>
                )}
              </div>
            </div>
            <div>
              <button
                type="submit"
                className="flex w-full justify-center rounded-md bg-violet-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-violet-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-violet-600"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ResetPasswordPage;
