import { AxiosResponse } from "axios";
import { InsertPersonalityRequest, PersonalityQuestion } from "types/WorkingGenius.types";
import { instance } from "./base.api";

export const getPersonalityQuestions = async (): Promise<AxiosResponse<Array<PersonalityQuestion>>> => {
  return instance.get(`personality/questions`);
};

export const insertPersonality = async (params: InsertPersonalityRequest): Promise<AxiosResponse> => {
  return instance.post(`personality`, params);
};

const WorkingGeniusService = {
  getPersonalityQuestions,
  insertPersonality,
};

export default WorkingGeniusService;
