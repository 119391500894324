import { AxiosResponse, HttpStatusCode } from "axios";
import { EMAIL_DUPLICATED_ERROR, EMAIL_USER_NOT_INVITED_ERROR, GENERIC_ERROR } from "utils/constants";
import { AuthCredentialsType } from "types/UserLogin.types";
import { APIResult } from "types/Api.types";
import { instance } from "./base.api";
import { ResetPasswordType } from "types/ResetPassword.types";

type User = {
  id: number;
  email: string;
};

const registration = async (params: AuthCredentialsType): Promise<APIResult<User>> => {
  try {
    const { data } = await instance.post("users", params);
    return { data, error: null };
  } catch (error) {
    const status = error.response?.status;
    let errorMessage = GENERIC_ERROR;
    if (status === HttpStatusCode.Conflict) {
      errorMessage = EMAIL_DUPLICATED_ERROR;
    } else if (status === HttpStatusCode.Forbidden) {
      errorMessage = EMAIL_USER_NOT_INVITED_ERROR;
    }
    return { data: null, error: { message: errorMessage, code: status } };
  }
};

export const verification = (email: string, code: string): Promise<AxiosResponse> => {
  const patchBody = [{ op: "replace", path: "email_verified", value: "true" }];
  return instance.patch(`cognito/users/${email}`, patchBody, { headers: { confirmationCode: code } });
};

export const login = async (data: AuthCredentialsType): Promise<AxiosResponse> => {
  const response = await instance.post("auth/login", data);
  const token = response.headers.varlalogintoken;
  if (token) {
    localStorage.setItem("varlaLoginToken", token);
  }

  return response;
};

export const resetPasswordRequest = (email: string): Promise<AxiosResponse> => {
  return instance.post(`cognito/reset-password/${email}`);
};

export const resetPassword = (data: ResetPasswordType): Promise<AxiosResponse> => {
  const patchBody = [{ op: "replace", path: "password", value: data.password }];
  return instance.patch(`cognito/users/${data.email}`, patchBody, { headers: { confirmationCode: data.code } });
};

const authService = {
  registration,
  verification,
  login,
  resetPasswordRequest,
  resetPassword,
};

export default authService;
