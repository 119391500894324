import { UserProfileContextProvider } from "pages/home/user-profile/UserProfileContext";
import React, { useCallback } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { useAppContext } from "./AppContext";
import { PrivateRoute } from "./components/PrivateRoute/PrivateRoute";
import {
  CreateCompanyProfilePage,
  DirectoryPage,
  HomePage,
  ProfilePage,
  ResetPasswordPage,
  ResetPasswordRequestPage,
  SuggestRoleSkillsPage,
} from "./pages";
import NotFoundPage from "./pages/errors/not-found/NotFoundPage";
import CompanyContainerPage from "./pages/home/companies/CompanyContainerPage";
import { CompanyProfilePage } from "./pages/home/companies/company-profile/CompanyProfilePage";
import EditCompanyProfilePage from "./pages/home/companies/company-profile/EditCompanyProfile";
import CompanyDashboardContainerPage from "./pages/home/companies/dashboard/CompanyDashboardContainerPage";
import { CompanyDirectoryPage } from "./pages/home/companies/dashboard/directory/CompanyDirectoryPage";
import JobGeneratorPage from "./pages/home/companies/dashboard/job-description-generator/JobGeneratorPage";
import { JobDescriptionListPage } from "./pages/home/companies/dashboard/job-descriptions/JobDescriptionListPage";
import TeamGeneratorPage from "./pages/home/companies/dashboard/team-generator/TeamGeneratorPage";
import TeamListPage from "./pages/home/companies/dashboard/team-list/TeamListPage";
import CompaniesPage from "./pages/home/companies/user-companies/CompaniesPage";
import ConnectionsPage from "./pages/home/connections/ConnectionsPage";
import { GuidePage } from "./pages/home/guide/GuidePage";
import EditUserProfilePage from "./pages/home/user-profile/EditUserProfilePage";
import { WelcomePage } from "./pages/home/welcome/Welcome";
import LoginPage from "./pages/login/LoginPage";
import { RegistrationPage } from "./pages/registration/RegistrationPage";
import VerificationPage from "./pages/verification/VerificationPage";
import JobPostingGeneratorPage from "./pages/home/companies/dashboard/job-posting-generator/JobPostingGeneratorPage";
import JobPostingListPage from "./pages/home/companies/dashboard/job-postings/JobPostingListPage";
import { AddNpcSkillsPage } from "pages/npcs/AddNpcSkillsPage";

export const AppRouter: React.FC<{}> = () => {
  const {
    session: { authenticated, profileCompleted },
  } = useAppContext();
  const guards = useCallback(
    () => ({
      loggedGuard: {
        check: authenticated,
        onFailedCheck: {
          redirect: "/login",
          errorMessage: "Please log in to continue.",
          errorTitle: "Log In",
        },
      },
      profileCompletedGuard: {
        check: profileCompleted,
        onFailedCheck: {
          redirect: "/edit-profile",
          errorMessage: "Please complete your profile to continue.",
          errorTitle: "Complete your profile",
        },
      },
    }),
    [authenticated, profileCompleted]
  );
  const { loggedGuard, profileCompletedGuard } = guards();

  return (
    <Routes>
      <Route path="/" element={<PrivateRoute conditions={[loggedGuard]} outlet={<HomePage />} />}>
        <Route path="companies" element={<PrivateRoute conditions={[profileCompletedGuard]} outlet={<CompaniesPage />} />} />
        <Route path="companies/create-profile" element={<CreateCompanyProfilePage />} />
        <Route path="companies/:companyId/profile" element={<CompanyProfilePage />} />
        <Route path="companies/:companyId" element={<CompanyContainerPage />}>
          <Route path="" element={<Navigate to="dashboard" />} />
          <Route path="edit-profile" element={<EditCompanyProfilePage />} />
          <Route path="dashboard" element={<CompanyDashboardContainerPage />}>
            <Route path="" element={<Navigate to="directory" />} />
            <Route path="job-descriptions" element={<JobDescriptionListPage />} />
            <Route path="job-description-generator/:jobDescriptionId?" element={<JobGeneratorPage />} />
            <Route path="team-generator" element={<TeamGeneratorPage />} />
            <Route path="directory" element={<CompanyDirectoryPage />} />
            <Route path="teams" element={<TeamListPage />} />
            <Route path="job-postings" element={<JobPostingListPage />} />
            <Route path="job-posting-generator/:jobPostingId?" element={<JobPostingGeneratorPage />} />
          </Route>
        </Route>
        <Route path="directory" element={<PrivateRoute conditions={[profileCompletedGuard]} outlet={<DirectoryPage />} />} />
        <Route path="connections" element={<PrivateRoute conditions={[profileCompletedGuard]} outlet={<ConnectionsPage />} />} />
        <Route
          path="profile/:prettyUrl?"
          element={
            <UserProfileContextProvider>
              <ProfilePage />
            </UserProfileContextProvider>
          }
        />
        <Route path="edit-profile" element={<EditUserProfilePage />} />
        <Route path="/" element={<PrivateRoute conditions={[profileCompletedGuard]} outlet={<Navigate to="/welcome" />} />} />
        <Route path="guide" element={<GuidePage />} />
        <Route path="welcome" element={<WelcomePage />} />
      </Route>
      <Route path="login" element={<LoginPage />} />
      <Route path="registration" element={<RegistrationPage />} />
      <Route path="verification" element={<VerificationPage />} />
      <Route path="reset-password-request" element={<ResetPasswordRequestPage />} />
      <Route path="reset-password" element={<ResetPasswordPage />} />
      {/* POC Suggest Role Skills */}
      <Route path="suggest-role-skills" element={<SuggestRoleSkillsPage />} />
      <Route path="add-npc-skills" element={<AddNpcSkillsPage />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};
