import { PatchDocument } from "types/PatchDocument.types";

export const formPatchDocument = <T extends object>(partialFormData: T): PatchDocument<T> => {
  const patchDocument: PatchDocument<T> = [];
  const paths = Object.keys(partialFormData) as Array<keyof T>;
  for (const path of paths) {
    const value = partialFormData[path];
    patchDocument.push({ op: "replace", path, value });
  }
  return patchDocument;
};
