import { ArrowRightCircleIcon, PlusCircleIcon } from "@heroicons/react/20/solid";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useAppContext } from "AppContext";
import { JobDescriptionForm } from "types/JobDescription.types";
import RichTextInput from "components/Inputs/RichTextInput";

export const StepDescriptionPage: React.FC<{}> = () => {
  const {
    paginator: { nextStep, stepData },
  } = useAppContext();

  const { handleSubmit, reset, control, setValue, getValues } = useForm<JobDescriptionForm>({
    defaultValues: { overview: "", responsibilities: "", qualifications: "" },
  });

  const [skills, setSkills] = useState<string[]>([]);
  const [addSkill, setAddSkill] = useState("");

  const [key, setKey] = useState<number>(0);

  useEffect(() => {
    const responsibilityList = stepData.jobDescription?.responsibilityList!;
    const qualificationList = stepData.jobDescription?.qualificationList!;
    setSkills(stepData.jobDescription?.skills || []);

    reset({
      overview: stepData.jobDescription?.overview,
      responsibilities: `<ul>${responsibilityList.map((x: string) => `<li>${x}</li>`).join("")}</ul>`,
      qualifications: `<ul>${qualificationList.map((x: string) => `<li>${x}</li>`).join("")}</ul>`,
    });
    setKey(1);
    return;
  }, [reset, stepData]);

  const onSubmit = (data: any) => {
    stepData.jobDescription!.qualifications = data.qualifications;
    stepData.jobDescription!.responsibilities = data.responsibilities;
    stepData.jobDescription!.skills = skills;
    nextStep(2, stepData);
  };

  return (
    <div className="lg:full flex flex-1 flex-col px-4 py-8 sm:px-6 lg:px-20 xl:px-24">
      <div className="flex items-center gap-1 text-lg">
        <div className="font-medium text-varla-violet-100">Role:</div>
        <div className="font-medium capitalize">{stepData.jobDescription!.role}</div>
      </div>
      <form onSubmit={handleSubmit(onSubmit)} className="mt-4">
        <div className="mb-6">
          <label htmlFor="overview" className="mb-2 block font-medium leading-6 text-gray-900">
            Overview
          </label>
          <RichTextInput key={key} name="overview" control={control} setValue={setValue} getValues={getValues} />
        </div>

        <div className="mb-6">
          <label htmlFor="responsibilities" className="mb-2 block font-medium leading-6 text-gray-900">
            Responsibilities
          </label>
          <RichTextInput key={key} name="responsibilities" control={control} setValue={setValue} getValues={getValues} />
        </div>

        <div className="mb-6">
          <label htmlFor="qualifications" className="mb-2 block font-medium leading-6 text-gray-900">
            Qualifications
          </label>
          <RichTextInput key={key} name="qualifications" control={control} setValue={setValue} getValues={getValues} />
        </div>

        <div className="mb-6">
          <label htmlFor="qualifications" className="mb-2 block font-medium leading-6 text-gray-900">
            Skills
          </label>
          <div className="flex px-4">
            <input
              onChange={(e) => setAddSkill(e.target.value)}
              id="add-skill"
              className="mr-2 block flex-grow rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              name="addSkill"
            />
            <button
              data-testid="add-skill-btn"
              className="flex rounded-md bg-violet-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-violet-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-violet-500"
              onClick={(e) => {
                e.preventDefault();
                setSkills([...skills, addSkill]);
              }}
            >
              <span className="flex ">
                Add skill
                <PlusCircleIcon className="ml-2 h-5 w-5" aria-hidden="true" />
              </span>
            </button>
          </div>
          <div className="mt-4 flex flex-wrap gap-x-3">
            {skills.map((skill) => (
              <span
                key={skill}
                className="mt-4 inline-flex items-center gap-x-0.5 rounded-full bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10"
              >
                {skill}
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    // missing dataset property
                    if (!(e.target instanceof HTMLSpanElement)) {
                      return;
                    }
                    const removeSkill = e.target.dataset["skill"];
                    setSkills((current) =>
                      current.filter((skill) => {
                        return skill !== removeSkill;
                      })
                    );
                  }}
                  type="button"
                  className="group relative -mr-1 h-3.5 w-3.5 rounded-sm hover:bg-gray-500/20"
                >
                  <span className="sr-only">Remove</span>
                  <svg viewBox="0 0 14 14" className="h-3.5 w-3.5 stroke-gray-600/50 group-hover:stroke-gray-600/75">
                    <path d="M4 4l6 6m0-6l-6 6" />
                  </svg>
                  <span data-testid={skill} data-skill={skill} className="absolute -inset-1" />
                </button>
              </span>
            ))}
          </div>
        </div>

        <div className="flex flex-row-reverse items-end justify-between space-x-3 border-t border-gray-200 px-2 py-2 sm:px-3">
          <button
            type="submit"
            data-testid="next-step"
            className="flex rounded-md bg-violet-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-violet-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-violet-500"
          >
            <span className="flex ">
              Next step
              <ArrowRightCircleIcon className="ml-2 h-5 w-5" aria-hidden="true" />
            </span>
          </button>
        </div>
      </form>
    </div>
  );
};

export default StepDescriptionPage;
