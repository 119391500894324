import { useCallback, useEffect, useState } from "react";
import Autocomplete from "react-autocomplete";
import { useForm } from "react-hook-form";
import { NotificationType, useAppContext } from "AppContext";
import RoleService from "api/RoleService";
import { useLoader } from "hooks/useLoader";
import { AddRoleFormType, Role } from "types/Role.types";
import { NOTIFICATION_DISPLAY_TIME } from "utils/constants";
import AddRole, { AddRoleErrors } from "utils/form-validations/addRole";
import { TeamRole } from "utils/form-validations/team-generator/teamDetailsForm";
import Modal, { ModalAction, ModalOnCloseFunction } from "./Modal";

export type AddRoleProps = {
  show: boolean;
  onClose: ModalOnCloseFunction<TeamRole>;
};

const AddRoleModal = (addRoleModalProps: AddRoleProps): JSX.Element => {
  const {
    notification: { showNotification },
  } = useAppContext();
  const { applyLoader } = useLoader();
  const [roles, setRoles] = useState<Array<Role>>([]);
  const [selectedRole, setSelectedRole] = useState<{ name: string }>({ name: "" });
  const [openDropDown, setOpenDropDown] = useState(false);
  const [formErrors, setFormErrors] = useState<AddRoleErrors>({});
  const modalTitle = "Add a Role";
  const modalBody = "Enter a role and number of team members to add your team.";
  const {
    register,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<AddRoleFormType>({});

  const resetModal = useCallback(() => {
    setFormErrors({});
    setSelectedRole({ name: "" });
    setOpenDropDown(false);
  }, []);

  useEffect(() => {
    if (addRoleModalProps.show) {
      const fetchRoles = async (): Promise<void> => {
        const { data, error } = await RoleService.listRoles();
        if (data) {
          setRoles(data);
        } else {
          showNotification({
            notificationType: NotificationType.Error,
            title: "Error fetching roles.",
            message: error.message,
            displayTime: NOTIFICATION_DISPLAY_TIME,
          });
        }
      };
      fetchRoles();
    } else {
      resetModal();
    }
  }, [addRoleModalProps.show, resetModal, showNotification]);

  const onSubmit = handleSubmit(async (formData) => {
    formData = { ...formData, roleName: selectedRole.name };
    const validationErrors = await AddRole.formValidation(formData);
    if (Object.keys(validationErrors).length === 0) {
      try {
        await applyLoader(RoleService.insertRole(formData.roleName));
        const newRole = { roleName: formData.roleName, description: "", amount: Number(formData.numOfMembers) };

        addRoleModalProps.onClose({ action: ModalAction.Add, data: newRole });
        setOpenDropDown(false);
      } catch (error) {
        showNotification({
          notificationType: NotificationType.Error,
          title: "Error creating new role",
          message: error.message,
          displayTime: NOTIFICATION_DISPLAY_TIME,
        });
      } finally {
        setOpenDropDown(false);
      }
    } else {
      setFormErrors(validationErrors);
    }
  });

  const matchValue = (object: Role, value: string) => {
    return object.name.toLowerCase().indexOf(value.toLowerCase()) !== -1;
  };

  return (
    <>
      <Modal show={addRoleModalProps.show} onClose={addRoleModalProps.onClose} title={modalTitle}>
        <div className="mt-2">
          <p className="block h-full text-sm text-gray-500 ">{modalBody}</p>
          <form onSubmit={onSubmit} className="space-y-2">
            <div className="pb-2 sm:max-w-md">
              <label htmlFor="roleName" className="block py-2 text-sm font-medium leading-6 text-gray-900">
                Role
              </label>
              <div className="autocomplete-wrapper">
                <Autocomplete
                  inputProps={{ name: "roleName", id: "roleName" }}
                  value={selectedRole.name}
                  items={roles}
                  getItemValue={(role) => role.name}
                  shouldItemRender={matchValue}
                  renderMenu={(role) => <div className="dropdown">{role}</div>}
                  renderItem={(role, isHighlighted) => (
                    <div className={`item ${isHighlighted ? "selected-item" : ""}`} key={role.id}>
                      {role.name}
                    </div>
                  )}
                  onChange={(event, name) => {
                    setOpenDropDown(true);
                    setSelectedRole({ name });
                  }}
                  onSelect={(name) => {
                    setOpenDropDown(false);
                    setSelectedRole({ name });
                  }}
                  open={openDropDown}
                />
                {formErrors.roleName && <div className="mt-2 block w-full py-1 text-sm text-red-700">{formErrors.roleName}</div>}
              </div>
            </div>
            <div className="pt-2 sm:max-w-md">
              <label htmlFor="numOfMembers" className="block text-sm font-medium leading-6 text-gray-900">
                Number of Team Members
              </label>
              <div className="relative mt-2 rounded-md pb-6 shadow-sm">
                <input
                  type="number"
                  id="numOfMembers"
                  {...register("numOfMembers")}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-violet-600 sm:text-sm sm:leading-6"
                  placeholder="Ex: 6"
                  min="1"
                />
                {formErrors?.numOfMembers && <div className="mt-2 w-full py-1 text-sm text-red-700">{formErrors.numOfMembers}</div>}
              </div>
            </div>
            <div className="mt-6 flex items-center justify-end gap-x-6">
              <button
                type="button"
                className="mr-3 rounded-md border-2 px-3 py-2 text-sm font-semibold text-black shadow-sm hover:bg-gray-200"
                onClick={() => {
                  addRoleModalProps.onClose();
                }}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="mr-3 inline-flex items-center justify-center rounded-md bg-violet-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-violet-500"
                disabled={isSubmitting}
              >
                Add
              </button>
            </div>
          </form>
        </div>
      </Modal>
    </>
  );
};

export default AddRoleModal;
