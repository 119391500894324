import { ArrowRightCircleIcon } from "@heroicons/react/20/solid";
import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { NotificationType, useAppContext } from "AppContext";
import JobGeneratorService from "api/JobGeneratorService";
import RoleService from "api/RoleService";
import AutoCompleteCombobox from "components/Dropdown/AutoCompleteCombobox";
import { useLoader } from "hooks/useLoader";
import { JobDescriptionGeneratorForm, JobDescriptionGeneratorFormSchema } from "types/JobDescription.types";
import { Role } from "types/Role.types";
import { NOTIFICATION_DISPLAY_TIME } from "utils/constants";

export const StepRolesPage: React.FC<{}> = () => {
  const { applyLoader } = useLoader();
  const {
    notification: { showNotification },
    paginator: { nextStep, stepData },
  } = useAppContext();

  const [roles, setRoles] = useState<Array<Role>>([]);
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setValue,
    reset,
    watch,
  } = useForm<JobDescriptionGeneratorForm>({
    resolver: yupResolver(JobDescriptionGeneratorFormSchema),
    defaultValues: { role: "" },
  });

  useEffect(() => {
    if (stepData.jobDescription?.role && stepData.jobDescription?.skills) {
      reset({ role: stepData.jobDescription?.role });
      return;
    }
  }, [reset, stepData]);

  const onSubmit = handleSubmit(async (formData) => {
    const roleId =
      roles.find((role) => role.name.localeCompare(formData.role, undefined, { sensitivity: "base" }) === 0)?.id ??
      (await applyLoader(RoleService.insertRole(formData.role))).data.roleId;
    const jobDescription = await applyLoader(JobGeneratorService.generateJobDescription(formData));
    if (jobDescription) {
      stepData.jobDescription!.roleId = roleId;
      stepData.jobDescription!.role = formData.role;
      stepData.jobDescription!.skills = jobDescription.skills!;
      stepData.jobDescription!.overview = jobDescription.overview!;
      stepData.jobDescription!.qualificationList = jobDescription.qualifications!;
      stepData.jobDescription!.responsibilityList = jobDescription.responsibilities!;
      nextStep(1, stepData);
    }
  });

  useEffect(() => {
    const fetchRoles = async (): Promise<void> => {
      const { data, error } = await RoleService.listRoles();
      if (data) {
        setRoles(data);
      } else {
        showNotification({
          notificationType: NotificationType.Error,
          title: "Error fetching roles.",
          message: error.message,
          displayTime: NOTIFICATION_DISPLAY_TIME,
        });
      }
    };
    fetchRoles();
  }, [showNotification]);

  const onChange = (value: string, key: "role") => {
    setValue(key, value);
  };

  return (
    <div className="lg:full flex flex-1 flex-col px-4 py-8 sm:px-6 lg:px-20 xl:px-24">
      <form onSubmit={onSubmit}>
        <div className="mb-5">
          <label htmlFor="role" className="block text-sm font-medium leading-6 text-gray-900">
            Role
          </label>
          <input type="hidden" {...register("role")} className="capitalize" autoComplete="off" />
          <AutoCompleteCombobox
            options={roles.map((role) => ({
              id: role.id.toString(),
              value: role.name,
            }))}
            selected={stepData.jobDescription!.role !== "" ? stepData.jobDescription!.role : watch("role")}
            fieldKey={"role"}
            onChange={onChange}
            error={errors?.role}
          />
          <p className="mt-2 text-sm text-gray-500" id="email-description">
            Select or Enter the Role you're looking for. Ex: Software Developer.
          </p>
        </div>
        <div className="flex flex-row-reverse items-end justify-between space-x-3 border-t border-gray-200 px-2 py-2 sm:px-3">
          <button
            type="submit"
            data-testid="next-step"
            className="flex rounded-md bg-violet-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-violet-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-violet-500"
            disabled={isSubmitting}
          >
            <span className="flex ">
              Next step
              <ArrowRightCircleIcon className="ml-2 h-5 w-5" aria-hidden="true" />
            </span>
          </button>
        </div>
      </form>
    </div>
  );
};

export default StepRolesPage;
