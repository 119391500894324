import { ArrowLongLeftIcon, ArrowLongRightIcon } from "@heroicons/react/24/outline";
import { Dispatch, SetStateAction } from "react";
import ReactPaginate from "react-paginate";

export type PaginationProps = {
  itemsLength: number;
  itemsPerPage: number;
  currentPage: number;
  setCurrentPage: Dispatch<SetStateAction<number>>;
};

const Pagination = (props: PaginationProps): JSX.Element => {
  const onPageChange = ({ selected }: { selected: number }): void => {
    props.setCurrentPage(selected + 1);
  };

  return (
    <ReactPaginate
      onPageChange={onPageChange}
      forcePage={props.currentPage - 1}
      pageCount={Math.ceil(props.itemsLength / props.itemsPerPage) || 1}
      containerClassName="hidden md:-mt-px md:flex justify-center items-center w-full h-9 py-4"
      activeClassName="text-violet-600 border-t-2 border-violet-500"
      pageLinkClassName="px-4 py-2 text-sm font-medium hover:border-gray-300 hover:text-gray-700 items-center flex"
      breakLinkClassName="px-4 py-2 text-sm font-medium hover:border-gray-300 hover:text-gray-700"
      previousLabel={
        <>
          <ArrowLongLeftIcon className="mr-3 h-5 w-5 text-gray-400" aria-hidden="true" />
          Previous
        </>
      }
      previousClassName="mr-auto h-full items-center flex"
      previousLinkClassName="flex items-center"
      nextLabel={
        <>
          Next
          <ArrowLongRightIcon className="ml-3 h-5 w-5 text-gray-400" aria-hidden="true" />
        </>
      }
      nextClassName="ml-auto items-center flex"
      nextLinkClassName="flex items-center"
    />
  );
};

export default Pagination;
