import React, { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import { EditorState, ContentState, convertFromHTML, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

interface RichTextInputProps {
  name: string;
  control: any;
  setValue: any;
  getValues: any;
  key: number;
}

const toolbar = {
  options: ["inline", "blockType", "fontSize", "fontFamily", "list", "textAlign", "colorPicker", "emoji", "remove", "history"],
  inline: {
    options: ["bold", "italic", "underline", "strikethrough", "monospace"],
  },
  list: {
    options: ["unordered", "ordered"],
  },
};

const RichTextInput: React.FC<RichTextInputProps> = ({ key, name, control, setValue, getValues }) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  useEffect(() => {
    const fieldValue = getValues(name);
    setEditorState(createEditorStateFromHTML(fieldValue));
  }, [getValues, name, key]);

  const createEditorStateFromHTML = (html: string) => {
    const blocksFromHTML = convertFromHTML(html);
    const contentState = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap);
    return EditorState.createWithContent(contentState);
  };

  const convertEditorStateToHTML = (state: EditorState) => {
    const contentState = state.getCurrentContent();
    return draftToHtml(convertToRaw(contentState));
  };

  const handleEditorChange = (state: EditorState) => {
    const htmlValue = convertEditorStateToHTML(state);
    setValue(name, htmlValue);
    setEditorState(state);
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => <Editor toolbar={toolbar} editorState={editorState} onEditorStateChange={handleEditorChange} />}
    />
  );
};

export default RichTextInput;
