import { yupResolver } from "@hookform/resolvers/yup";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { NotificationType, useAppContext } from "AppContext";
import UserService from "api/UserService";
import { useLoader } from "hooks/useLoader";
import { InviteUserFormType, InvitedUser } from "types/InviteUser.types";
import { NOTIFICATION_DISPLAY_TIME, NOTIFICATION_DISPLAY_TIME_LONG } from "utils/constants";
import { errorMessage } from "utils/errors";
import { InviteUserValidate } from "utils/validateForm";
import UserInviteDrowpdown from "../Dropdown/UserInviteDrowpdown";
import ConfirmationAlertModal from "./ConfirmationAlertModal";
import Modal from "./Modal";
const USER_INVITATION_REMOVED_SUCCESSFULLY = "User Invitation Removed Successfully";

type Props = {
  show: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
};

const calculateTimeDifference = (date1: Date, date2: Date) => {
  let diffInMilliSeconds = Math.abs(date2.getTime() - date1.getTime());

  let days = Math.floor(diffInMilliSeconds / (1000 * 60 * 60 * 24));
  diffInMilliSeconds -= days * (1000 * 60 * 60 * 24);

  let hours = Math.floor(diffInMilliSeconds / (1000 * 60 * 60));

  return `${days} day(s) and ${hours} hour(s)`;
};

const InviteUserModal = (props: Props): JSX.Element => {
  const { show, setOpen } = props;
  const [invitedUsers, setInvitedUsers] = useState<Array<InvitedUser>>([]);

  const {
    session: { loggedUser },
    notification: { showNotification },
  } = useAppContext();
  const { applyLoader } = useLoader();

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
  } = useForm<InviteUserFormType>({
    resolver: yupResolver(InviteUserValidate),
  });

  useEffect(() => {
    reset({ invitedBy: loggedUser.id, inviterName: loggedUser.firstName || "" });
  }, [loggedUser, reset]);

  useEffect(() => {
    const fetchInvitedUsers = async (): Promise<void> => {
      const { data, error } = await UserService.listInvitedUsers(loggedUser.id);
      if (data) {
        setInvitedUsers(data);
      } else {
        showNotification({
          notificationType: NotificationType.Error,
          title: "Error fetching invited users.",
          message: error.message,
          displayTime: NOTIFICATION_DISPLAY_TIME,
        });
      }
    };
    fetchInvitedUsers();
  }, [loggedUser.id, showNotification]);

  const onSubmit = handleSubmit(async (formData) => {
    try {
      const result = await applyLoader(UserService.inviteUser(formData));
      showNotification({
        notificationType: NotificationType.Success,
        title: "Invite User",
        message: result.data.message,
        displayTime: NOTIFICATION_DISPLAY_TIME_LONG,
      });

      setOpen(false);
    } catch (error) {
      showNotification({
        notificationType: NotificationType.Error,
        title: "Error sending user invite.",
        message: errorMessage(error),
        displayTime: NOTIFICATION_DISPLAY_TIME,
      });
    }
  });

  const deleteInvitation = async (invitation: InvitedUser, index: number) => {
    try {
      await applyLoader(UserService.deleteUserInvitation(loggedUser.id, invitation.id));
      showNotification({
        notificationType: NotificationType.Success,
        title: "Success",
        message: USER_INVITATION_REMOVED_SUCCESSFULLY,
        displayTime: NOTIFICATION_DISPLAY_TIME,
      });
      invitedUsers.splice(index, 1);
      setInvitedUsers(invitedUsers);
    } catch (error) {
      showNotification({
        notificationType: NotificationType.Error,
        title: "Error",
        message: errorMessage(error),
        displayTime: NOTIFICATION_DISPLAY_TIME,
      });
    }
  };

  const handleRemove = (invitation: InvitedUser, index: number) => () => {
    ConfirmationAlertModal({
      title: "Remove User Invitation",
      message: "Are you sure you want to remove this user invitation?",
      action: "Remove",
      onAction: () => deleteInvitation(invitation, index),
    });
  };

  return (
    <Modal show={show} onClose={() => setOpen(false)} title={"Invite a new user"}>
      <div className="mt-2">
        <p className="text-sm text-gray-500">Enter an email of a user you would like to invite to Varla.</p>
        <form onSubmit={onSubmit} className="mt-5 items-start sm:flex">
          <div className="w-full sm:max-w-xs">
            <label htmlFor="invitedEmail" className="sr-only">
              Email
            </label>
            <input
              id="invitedEmail"
              {...register("invitedEmail")}
              type="text"
              className="block w-full rounded-md border-0 py-1.5 lowercase text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-violet-600 sm:text-sm sm:leading-6"
            />
            {errors?.invitedEmail && <div className="mt-2 block w-full py-1 text-sm text-red-700">{errors.invitedEmail.message}</div>}
          </div>
          <button
            disabled={isSubmitting}
            type="submit"
            className="mt-3 inline-flex w-full items-center justify-center rounded-md bg-violet-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-violet-500 disabled:opacity-25 sm:ml-3 sm:mt-0 sm:w-auto"
          >
            Invite
          </button>
        </form>
      </div>

      {/* invited users */}
      <div className="mt-4 border-t-2 border-gray-100 py-2">
        {invitedUsers.length === 0 ? (
          <div className="text-center text-sm text-gray-500">Invite new users to Varla!</div>
        ) : (
          <div>
            <p className="font-semibold text-gray-900">Your invited users</p>
            <p className="text-sm text-gray-500">Here's a list of users that you invited.</p>
            <ul className="mx-6 mt-6 space-y-4">
              {invitedUsers.map((invitedUser, index) => (
                <li key={invitedUser.id}>
                  <div className="flex flex-row items-center">
                    <div className="mr-auto flex flex-col">
                      <p className="text-sm font-bold">
                        <svg
                          className={`mr-3 inline h-1.5 w-1.5 ${invitedUser.acceptedOn ? "fill-green-500" : "fill-gray-500"}`}
                          viewBox="0 0 6 6"
                          aria-hidden="true"
                        >
                          <circle cx="3" cy="3" r="3" />
                        </svg>
                        {invitedUser.invitedEmail}
                      </p>
                      <p className="mt-2 text-xs text-gray-500">Invited {calculateTimeDifference(invitedUser.invitedOn, new Date())} ago</p>
                    </div>
                    {invitedUser.acceptedOn ? (
                      <div className="mr-4 inline-flex h-fit items-center rounded-full bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                        Accepted
                      </div>
                    ) : (
                      <>
                        <div className="mr-4 inline-flex h-fit items-center rounded-full bg-gray-50 px-2 py-1 text-xs font-medium text-gray-500 ring-1 ring-inset ring-gray-300">
                          Pending
                        </div>
                        <UserInviteDrowpdown onUninvite={handleRemove(invitedUser, index)} />
                      </>
                    )}
                  </div>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
      {/* end of invited users */}
      <div className="mt-5 grid justify-items-end sm:mt-6">
        <button
          type="button"
          className="rounded-md border-2 px-3 py-2 text-sm font-semibold text-black shadow-sm hover:bg-gray-200"
          onClick={() => setOpen(false)}
        >
          Cancel
        </button>
      </div>
    </Modal>
  );
};

export default InviteUserModal;
