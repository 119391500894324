import { PhotoIcon, UserCircleIcon } from "@heroicons/react/24/solid";
import { yupResolver } from "@hookform/resolvers/yup";
import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useOutletContext } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import AppContext, { NotificationType } from "AppContext";
import CompanyService from "api/CompanyService";
import IndustryService from "api/IndustryService";
import S3Service from "api/S3Service";
import ImageCropperModal from "components/Modal/ImageCropperModal";
import { ModalAction, ModalOnCloseFunction, handleModalOnCloseResult } from "components/Modal/Modal";
import { useMaxLength } from "hooks/InputValidations";
import { useLoader } from "hooks/useLoader";
import { IndustryType } from "types/Industry.types";
import { MAX_LENGTH_120, MAX_LENGTH_255, NOTIFICATION_DISPLAY_TIME } from "utils/constants";
import { CompanyProfileForm, ImageCropper, companyProfileSchema } from "utils/form-validations/companyProfileForm";
import { CompanyContainerOutletContextType } from "../CompanyContainerPage";
import { errorMessage } from "utils/errors";

export const EditCompanyProfilePage: React.FC = () => {
  const {
    notification: { showNotification },
  } = useContext(AppContext);
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
  } = useForm<CompanyProfileForm>({ resolver: yupResolver(companyProfileSchema) });
  const { applyLoader } = useLoader();
  const { company } = useOutletContext<CompanyContainerOutletContextType>();
  const [profileImage, setProfileImage] = useState("");
  const [profilePresignUrl, setProfilePresignUrl] = useState("");
  const [bannerImage, setBannerImage] = useState("");
  const [bannerPresignUrl, setBannerPresignUrl] = useState("");
  const [industries, setIndustries] = useState<Array<IndustryType>>([]);
  const [showImageCropper, setShowImageCropper] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalDescription, setModalDescription] = useState("");
  const [cropDescription, setCropDescription] = useState("");
  const [aspectRadioImage, setAspectRadioImage] = useState(0);
  const [classNameImagePreview, setClassNameImagePreview] = useState("");

  useEffect(() => {
    const fetchIndustriesList = async (): Promise<void> => {
      try {
        const { data } = await applyLoader(IndustryService.industries());
        setIndustries(data);
      } catch (error) {
        showNotification({
          notificationType: NotificationType.Error,
          title: "Failed to load industries",
          message: error.message,
          displayTime: NOTIFICATION_DISPLAY_TIME,
        });
      }
    };

    fetchIndustriesList();
  }, [applyLoader, showNotification]);

  useEffect(() => {
    reset({
      name: company.name,
      website: company.website || "",
      about: company.about,
      publicUrl: company.publicUrl,
      industryId: company.industryId,
      logoUrl: company.logoUrl,
      bannerUrl: company.bannerUrl,
    });
  }, [company, reset]);

  const onSubmit = handleSubmit(async (formData) => {
    try {
      formData.logoUrl = profilePresignUrl;
      formData.bannerUrl = bannerPresignUrl;

      const controller = new AbortController();
      await Promise.all([
        applyLoader(CompanyService.updateCompanyProfile(Number(company.id), formData)),
        applyLoader(S3Service.upload(profilePresignUrl, profileImage)),
        applyLoader(S3Service.upload(bannerPresignUrl, bannerImage)),
      ]);
      controller.abort();

      showNotification({
        notificationType: NotificationType.Success,
        title: "Success",
        message: "Company profile updated successfully!",
        displayTime: NOTIFICATION_DISPLAY_TIME,
      });
    } catch (error) {
      showNotification({
        notificationType: NotificationType.Error,
        title: "Failed to update company profile",
        message: errorMessage(error),
        displayTime: NOTIFICATION_DISPLAY_TIME,
      });
    }
  });

  const inputRefName = useRef<HTMLInputElement | null>(null);
  const inputRefWebsite = useRef<HTMLInputElement | null>(null);
  const inputRefAbout = useRef<HTMLTextAreaElement | null>(null);
  const inputRefPublicUrl = useRef<HTMLInputElement | null>(null);
  const inputRefIndustryId = useRef<HTMLSelectElement | null>(null);

  const { ref: refName, ...restName } = register("name");
  const { ref: refWebsite, ...restWebsite } = register("website");
  const { ref: refAbout, ...restAbout } = register("about");
  const { ref: refPublicUrl, ...restPublicUrl } = register("publicUrl");
  const { ref: refIndustryId, ...restIndustryId } = register("industryId");

  const maxLengthName = useMaxLength({
    inputRef: inputRefName,
    maxLength: MAX_LENGTH_120,
  });

  const maxLengthWebsite = useMaxLength({
    inputRef: inputRefWebsite,
    maxLength: MAX_LENGTH_255,
  });

  const maxLengthAbout = useMaxLength({
    inputRef: inputRefAbout,
    maxLength: MAX_LENGTH_255,
  });

  const maxLengthPublicUrl = useMaxLength({
    inputRef: inputRefPublicUrl,
    maxLength: MAX_LENGTH_255,
  });

  const setProfileModalProps = () => {
    setShowImageCropper(true);
    setModalTitle("Profile Image");
    setModalDescription("Upload your profile picture to personalize your account.");
    setCropDescription("Please crop your profile picture to ensure it fits perfectly within the designated area.");
    setAspectRadioImage(1);
    setClassNameImagePreview("h-24 w-24 rounded-full ring-4 ring-white sm:h-24 sm:w-24 overflow-hidden");
  };

  const setBannerModalProps = () => {
    setShowImageCropper(true);
    setModalTitle("Banner Image");
    setModalDescription("Upload your banner picture to personalize your account.");
    setCropDescription("Please crop your banner picture to ensure it fits perfectly within the designated area.");
    setAspectRadioImage(16 / 9);
    setClassNameImagePreview("h-14 w-28 ring-4 ring-white sm:h-14 sm:w-28 overflow-hidden");
  };

  const onCloseModal = useCallback<ModalOnCloseFunction<ImageCropper>>(
    (onCloseResult) => {
      handleModalOnCloseResult(onCloseResult, {
        [ModalAction.Add]: async (imageCropper) => {
          const fileName = S3Service.getFileName(imageCropper.imageBase64);
          const presignUrl = await applyLoader(S3Service.presignCompanyAvatar(fileName));
          if (imageCropper.modalTitle.includes("Profile")) {
            setProfileImage(imageCropper.imageBase64);
            setProfilePresignUrl(S3Service.getUrl(presignUrl.data.url));
          }

          if (imageCropper.modalTitle.includes("Banner")) {
            setBannerImage(imageCropper.imageBase64);
            setBannerPresignUrl(S3Service.getUrl(presignUrl.data.url));
          }
        },
      });
      setShowImageCropper(false);
    },
    [applyLoader]
  );

  return (
    <div className="flex flex-1 flex-col px-4 py-12 sm:px-6 lg:w-full lg:px-20 xl:px-24">
      <div className="mx-auto w-full">
        <p className="text-base font-semibold leading-7 text-gray-900">Company</p>
        <p className="mt-1 text-sm leading-6 text-gray-600">Enter your company's information to be displayed publicly.</p>
        <div className="mt-4">
          <form onSubmit={onSubmit}>
            <div className="grid grid-cols-1 gap-x-6 gap-y-8 pb-6 sm:grid-cols-6">
              <div className="col-span-full sm:col-span-3">
                <label htmlFor="logoBase64" className="block text-sm font-medium leading-6 text-gray-900">
                  Photo
                </label>

                <div className="mt-2 flex flex-col items-center gap-4 rounded-lg px-6 py-10">
                  {!company.logoUrl && !profileImage ? (
                    <UserCircleIcon className="h-32 w-32 text-gray-300" aria-hidden="true" />
                  ) : (
                    <img className="h-32 w-32 rounded-full ring-4 ring-white" src={profileImage || company.logoUrl!} alt="profileImage" />
                  )}
                  <button
                    type="button"
                    className="mt-1 rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                    onClick={setProfileModalProps}
                  >
                    Change
                  </button>
                </div>
              </div>

              <div className="col-span-full sm:col-span-3">
                <label htmlFor="cover-photo" className="block text-sm font-medium leading-6 text-gray-900">
                  Cover photo
                </label>

                <div className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
                  <div className="justify-center text-center">
                    {!company.bannerUrl && !bannerImage ? (
                      <PhotoIcon className="mx-auto h-32 w-32 text-gray-300" aria-hidden="true" />
                    ) : (
                      <img className="full inline h-32 w-64 ring-4 ring-white" src={bannerImage || company.bannerUrl!} alt="bannerImage" />
                    )}

                    <div className="mt-4 flex justify-center text-sm leading-6 text-gray-600">
                      <label
                        htmlFor="file-upload"
                        className="relative cursor-default rounded-md bg-white font-semibold text-violet-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-violet-600 focus-within:ring-offset-2 hover:text-violet-500"
                      >
                        <span data-testid="bannerUploadImage" className="cursor-pointer" onClick={setBannerModalProps}>
                          Upload a file
                        </span>
                        <input id="file-upload" name="file-upload" type="file" className="sr-only" disabled />
                      </label>
                    </div>

                    <p className="text-xs leading-5 text-gray-600">PNG, JPG, GIF up to 10MB</p>
                  </div>
                </div>
              </div>

              <ImageCropperModal
                show={showImageCropper}
                setOpen={setShowImageCropper}
                modalTitle={modalTitle}
                modalDescription={modalDescription}
                cropDescription={cropDescription}
                aspectRatio={aspectRadioImage}
                classNameImagePreview={classNameImagePreview}
                onClose={onCloseModal}
              />
            </div>
            <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div className="sm:col-span-3">
                <label htmlFor="name" className="block text-sm font-medium leading-6 text-gray-900">
                  Company name
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    id="name"
                    {...restName}
                    ref={(e) => {
                      refName(e);
                      inputRefName.current = e;
                    }}
                    maxLength={MAX_LENGTH_120}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-violet-600 sm:text-sm sm:leading-6"
                  />
                  {errors?.name && <div className="mt-2 block w-full py-1 text-sm text-red-700">{errors.name.message}</div>}
                  {maxLengthName && <div className="mt-2 block w-full py-1 text-sm text-orange-400">{maxLengthName}</div>}
                </div>
              </div>
              <div className="sm:col-span-3">
                <label htmlFor="publicUrl" className="block text-sm font-medium leading-6 text-gray-900">
                  Public URL
                </label>
                <div className="mt-2">
                  <div className="flex w-full rounded-md bg-[#CDCDCD] shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-violet-600">
                    <span className="flex select-none items-center pl-3 text-gray-500 sm:text-sm">varla.app/</span>
                    <span data-tooltip-id="public-url" data-tooltip-content="Coming soon" className="focus:outline-none">
                      <input
                        type="text"
                        id="publicUrl"
                        {...restPublicUrl}
                        ref={(e) => {
                          refPublicUrl(e);
                          inputRefPublicUrl.current = e;
                        }}
                        className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                        autoComplete="off"
                        disabled
                      />
                      {errors?.publicUrl && <div className="mt-2 block w-full py-1 text-sm text-red-700">{errors.publicUrl.message}</div>}
                      {maxLengthPublicUrl && <div className="mt-2 block w-full py-1 text-sm text-orange-400">{maxLengthPublicUrl}</div>}
                    </span>
                    <Tooltip id="public-url" />
                  </div>
                </div>
              </div>
              <div className="sm:col-span-3">
                <label htmlFor="website" className="block text-sm font-medium leading-6 text-gray-900">
                  Website
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    id="website"
                    {...restWebsite}
                    ref={(e) => {
                      refWebsite(e);
                      inputRefWebsite.current = e;
                    }}
                    maxLength={MAX_LENGTH_255}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-violet-600 sm:text-sm sm:leading-6"
                  />
                  {errors?.website && <div className="mt-2 block w-full py-1 text-sm text-red-700">{errors.website.message}</div>}
                  {maxLengthWebsite && <div className="mt-2 block w-full py-1 text-sm text-orange-400">{maxLengthWebsite}</div>}
                </div>
              </div>

              <div className="sm:col-span-3">
                <label htmlFor="industryId" className="block text-sm font-medium leading-6 text-gray-900">
                  Industry
                </label>
                <select
                  id="industryId"
                  {...restIndustryId}
                  ref={(e) => {
                    refIndustryId(e);
                    inputRefIndustryId.current = e;
                  }}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-violet-600 sm:text-sm sm:leading-6"
                >
                  <option value="">Select an Industry</option>
                  {industries.map((industry) => (
                    <option key={industry.id} value={industry.id}>
                      {industry.industry}
                    </option>
                  ))}
                </select>
                {errors?.industryId && <div className="mt-2 block w-full py-1 text-sm text-red-700">{errors.industryId.message}</div>}
              </div>
              <div className="sm:col-span-6">
                <label htmlFor="about" className="block text-sm font-medium leading-6 text-gray-900">
                  About
                </label>
                <div className="mt-2">
                  <textarea
                    id="about"
                    {...restAbout}
                    ref={(e) => {
                      refAbout(e);
                      inputRefAbout.current = e;
                    }}
                    maxLength={MAX_LENGTH_255}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-violet-600 sm:text-sm sm:leading-6"
                  />
                  {errors?.about && <div className="mt-2 block w-full py-1 text-sm text-red-700">{errors.about.message}</div>}
                  {maxLengthAbout && <div className="mt-2 block w-full py-1 text-sm text-orange-400">{maxLengthAbout}</div>}
                </div>
              </div>
            </div>
            <div className="mt-6 flex items-center justify-end gap-x-6">
              <Link
                to="/companies"
                className="rounded-md px-3 py-2 text-sm font-semibold shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-700"
              >
                Cancel
              </Link>
              <button
                type="submit"
                className="rounded-md bg-violet-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-violet-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-violet-600 disabled:opacity-25"
                disabled={isSubmitting}
              >
                Save
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditCompanyProfilePage;
