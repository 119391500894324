import { useContext, useEffect } from "react";
import AppContext from "AppContext";
import Step from "components/Steps/Step";
import Steps from "components/Steps/Steps";
import StepPreviewPage from "./steps/StepPreviewPage";
import StepTeamCompositionPage from "./steps/StepTeamCompositionPage";
import StepTeamDetailsPage from "./steps/StepTeamDetailsPage";
import StepTeamMembersPage from "./steps/StepTeamMembersPage";

export const TeamGeneratorPage: React.FC<{}> = () => {
  const {
    paginator: { stepData, nextStep },
  } = useContext(AppContext);

  useEffect(() => {
    nextStep(0, stepData);
  }, [nextStep, stepData]);

  return (
    <div className="lg:full flex flex-1 flex-col px-4 py-4 sm:px-6 md:pt-2 lg:px-20 xl:px-24">
      <Steps>
        <Step title="Team Details">
          <StepTeamDetailsPage />
        </Step>
        <Step title="Team Composition" isDisabled={!stepData.teamGeneratorData?.projectDescription}>
          <StepTeamCompositionPage />
        </Step>
        <Step title="Team Members" isDisabled={!stepData.teamGeneratorData?.roles.length}>
          <StepTeamMembersPage />
        </Step>
        <Step title="Preview" isDisabled={!stepData.teamGeneratorData?.employees.length}>
          <StepPreviewPage />
        </Step>
      </Steps>
    </div>
  );
};

export default TeamGeneratorPage;
