import { yupResolver } from "@hookform/resolvers/yup";
import { Dispatch, SetStateAction, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { NotificationType, useAppContext } from "AppContext";
import UserService from "api/UserService";
import { useLoader } from "hooks/useLoader";
import { NOTIFICATION_DISPLAY_TIME, NOTIFICATION_DISPLAY_TIME_LONG } from "utils/constants";
import { errorMessage } from "utils/errors";
import Modal from "./Modal";
import { dateRangeFilter, JobPreferenceForm, jobPreferenceSchema, officeLocation } from "utils/form-validations/jobPreference";
import { Switch } from "@headlessui/react";
import { classNames } from "utils/helpers";

type Props = {
  show: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
};

const JobPreferenceModal = (props: Props): JSX.Element => {
  const { show, setOpen } = props;

  const {
    session: { loggedUser },
    notification: { showNotification },
  } = useAppContext();
  const { applyLoader } = useLoader();

  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
    reset,
  } = useForm<JobPreferenceForm>({
    resolver: yupResolver(jobPreferenceSchema),
    defaultValues: {
      available: false,
      minimumSalary: 0,
      officeLocation: [],
    },
  });

  useEffect(() => {
    const fetchJobPreference = async () => {
      const { data } = await applyLoader(UserService.getJobPreference(loggedUser.id));
      if (data) {
        reset(data);
      }
    };
    reset({ userId: loggedUser.id, available: false, minimumSalary: 0, officeLocation: [] });
    fetchJobPreference();
  }, [loggedUser, reset, applyLoader]);

  const onSubmit = handleSubmit(async (formData) => {
    try {
      const result = await applyLoader(UserService.upsertJobPreference(formData));
      showNotification({
        notificationType: NotificationType.Success,
        title: "Job preferences saved",
        message: result.data.message,
        displayTime: NOTIFICATION_DISPLAY_TIME_LONG,
      });

      setOpen(false);
    } catch (error) {
      showNotification({
        notificationType: NotificationType.Error,
        title: "Error saving user preference.",
        message: errorMessage(error),
        displayTime: NOTIFICATION_DISPLAY_TIME,
      });
    }
  });

  return (
    <Modal show={show} onClose={() => setOpen(false)} title={"Job Preferences"}>
      <div className="mt-2">
        <p className="text-sm text-gray-500">Select your job preferences to get the best matching job for you!</p>
        <form onSubmit={onSubmit} className="items-start">
          <div className="flex items-center justify-between py-6">
            <div className="flex flex-grow flex-col">
              <span className="text-sm font-medium leading-6 text-gray-900" id="availability-label">
                Available to hire
              </span>
              <span className="text-sm text-gray-500" id="availability-description">
                Are you currently looking for a job?
              </span>
            </div>
            <Controller
              name="available"
              control={control}
              render={({ field }) => (
                <Switch
                  checked={field.value}
                  onChange={field.onChange}
                  className={classNames(
                    field.value ? "bg-indigo-600" : "bg-gray-200",
                    "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2"
                  )}
                >
                  <span className="sr-only">Use setting</span>
                  <span
                    aria-hidden="true"
                    className={classNames(
                      field.value ? "translate-x-5" : "translate-x-0",
                      "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                    )}
                  />
                </Switch>
              )}
            />
          </div>
          {errors?.available && <div className="mb-6 block w-full text-sm text-red-700">{errors.available.message}</div>}

          <div className="flex items-center border-t py-6">
            <div className="w-1/2">
              <label htmlFor="minimumSalary" className="block text-sm font-medium leading-6 text-gray-900">
                Minimum Yearly Salary
              </label>
            </div>
            <div className="relative mt-2 w-1/2 rounded-md shadow-sm">
              <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                <span className="text-gray-500 sm:text-sm">$</span>
              </div>
              <input
                {...register("minimumSalary")}
                type="number"
                name="minimumSalary"
                id="minimumSalary"
                className="block w-full rounded-md border-0 py-1.5 pl-7 pr-12 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholder="0.00"
                aria-describedby="price-currency"
              />
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                <span className="text-gray-500 sm:text-sm" id="price-currency">
                  USD
                </span>
              </div>
            </div>
          </div>
          {errors?.minimumSalary && <div className="mb-6 block w-full text-sm text-red-700">{errors.minimumSalary.message}</div>}

          <div className="flex items-center border-t py-6">
            <div className="block w-1/2 text-sm font-medium leading-6 text-gray-900">Location</div>
            <fieldset className="w-1/2">
              <div className="space-y-1">
                {officeLocation.map((location) => (
                  <div key={location} className="relative flex items-start">
                    <div className="flex h-6 items-center">
                      <input
                        {...register("officeLocation")}
                        value={location}
                        id={location.toLowerCase().split(" ").join("-")}
                        type="checkbox"
                        className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                      />
                    </div>
                    <div className="ml-3 text-sm leading-6">
                      <label htmlFor={location.toLowerCase().split(" ").join("-")} className="font-medium text-gray-900">
                        {location}
                      </label>
                    </div>
                  </div>
                ))}
              </div>
            </fieldset>
          </div>
          {errors?.officeLocation && <div className="mb-6 block w-full text-sm text-red-700">{errors.officeLocation.message}</div>}

          <div className="flex items-center border-t py-6">
            <div className="block w-1/2 text-sm font-medium leading-6 text-gray-900">Date Range Filter</div>
            <fieldset>
              <legend className="sr-only">Notification method</legend>
              <div className="space-y-1">
                {dateRangeFilter.map((dateRange) => (
                  <div key={dateRange} className="flex items-center">
                    <input
                      {...register("dateRangeFilter")}
                      value={dateRange}
                      id={dateRange.toLowerCase().split(" ").join("-")}
                      type="radio"
                      className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                    />
                    <label
                      htmlFor={dateRange.toLowerCase().split(" ").join("-")}
                      className="ml-3 block text-sm font-medium leading-6 text-gray-900"
                    >
                      {dateRange}
                    </label>
                  </div>
                ))}
              </div>
            </fieldset>
          </div>
          {errors?.dateRangeFilter && <div className="mb-6 block w-full text-sm text-red-700">{errors.dateRangeFilter.message}</div>}
        </form>
      </div>

      <div className="mt-5 flex justify-items-end sm:mt-6">
        <button
          type="button"
          className="ml-auto rounded-md border-2 px-3 py-2 text-sm font-semibold text-black shadow-sm hover:bg-gray-200"
          onClick={() => setOpen(false)}
        >
          Cancel
        </button>
        <button
          disabled={isSubmitting}
          onClick={() => onSubmit()}
          type="submit"
          className="mt-3 inline-flex w-full items-center justify-center rounded-md bg-violet-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-violet-500 disabled:opacity-25 sm:ml-3 sm:mt-0 sm:w-auto"
        >
          Save
        </button>
      </div>
    </Modal>
  );
};

export default JobPreferenceModal;
