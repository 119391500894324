import { QuestionMarkCircleIcon, BugAntIcon, UserIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
import "./Welcome.css";

const features = [
  {
    name: "Guide Center",
    description:
      "Varla's Guide Center: Your one-stop resource for FAQs and How-To guides, making your Varla experience effortless and efficient.",
    href: "/guide",
    icon: QuestionMarkCircleIcon,
    button: "Learn More",
    target: "",
  },
  {
    name: "Report An Issue",
    description:
      "Varla's Issue Reporting Form: Report any issues promptly, helping us improve your Varla experience. Your feedback drives our excellence.",
    href: "https://forms.gle/oxMqCyxrmc8ZqTir5",
    icon: BugAntIcon,
    button: "Help Us Improve",
    target: "_blank",
  },
  {
    name: "Update Your Profile",
    description:
      "Elevate your profile on Varla by updating your professional skills and work history. Showcase your expertise and stand out in your field.",
    href: "/profile",
    icon: UserIcon,
    button: "Go To Profile",
    target: "",
  },
];

export const WelcomePage: React.FC = () => {
  return (
    <div>
      <div>
        <img className="h-32 w-full object-cover lg:h-48" src="/images/default_banner.png" alt="" />
      </div>
      <div className="bg-white py-8">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-2xl lg:mx-0">
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Welcome to Varla!</h2>
            <p className="mt-6 text-lg leading-8 text-gray-600">
              Thank you for choosing Varla! We appreciate your support. We're actively refining our features to offer you the best
              experience. Stay tuned for more features and updates!
            </p>
          </div>
          <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-none">
            <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-3">
              {features.map((feature) => (
                <div key={feature.name} className="flex flex-col">
                  <dt className="text-base font-semibold leading-7 text-gray-900">
                    <div className="mb-6 flex h-10 w-10 items-center justify-center rounded-lg bg-violet-600">
                      <feature.icon className="h-6 w-6 text-white" aria-hidden="true" />
                    </div>
                    {feature.name}
                  </dt>
                  <dd className="mt-1 flex flex-auto flex-col text-base leading-7 text-gray-600">
                    <p className="flex-auto">{feature.description}</p>
                    <p className="mt-6">
                      <Link to={feature.href} target={feature.target} className="text-sm font-semibold text-violet-600">
                        {feature.button} <span aria-hidden="true">→</span>
                      </Link>
                    </p>
                  </dd>
                </div>
              ))}
            </dl>
          </div>
        </div>
        <div className="mx-auto max-w-7xl px-6 pb-8 pt-8 ">
          <div className="border-violet/10 border-t pt-8 md:flex md:items-center md:justify-between"></div>
        </div>
      </div>
      <div className="fixed bottom-0 w-full">
        <footer>
          <div className="marquee flex items-center gap-x-6 bg-violet-900 px-6 py-2.5 sm:px-3.5 sm:before:flex-1">
            <p className="marquee__inner whitespace-nowrap text-sm text-white">
              <strong className="font-semibold">Attention • </strong>
              There's a known issue with image upload on User & Company Profiles. If you have issues with it, try a smaller size image.
            </p>
          </div>
        </footer>
      </div>
    </div>
  );
};

export default WelcomePage;
