import { InferType, array, number, object, string } from "yup";
import { MAX_LENGTH_125, MAX_LENGTH_255 } from "../../constants";

export const teamDetailsSchema = object().shape({
  teamName: string().strict().required("Team name is required").max(MAX_LENGTH_125),
  clientName: string().strict().max(MAX_LENGTH_125),
  projectDescription: string().strict().required("Project description is required").max(MAX_LENGTH_255),
  minNumOfMembers: number()
    .typeError("Value must be a number")
    .required()
    .min(1, "Value must be at least 1")
    .max(10, "Value must be at most 10"),
});
export type TeamDetailsForm = InferType<typeof teamDetailsSchema>;

export const teamRoleSchema = object().shape({
  roleName: string().strict().required(),
  description: string().strict().required(),
  amount: number().strict().required("Amount is required").min(1),
});

export type TeamRole = InferType<typeof teamRoleSchema>;

export const teamRoleRequestSchema = teamRoleSchema.shape({
  employeesIds: array(number().strict().required()),
});

export type TeamRoleRequest = InferType<typeof teamRoleRequestSchema>;
