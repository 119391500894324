import { ArrowRightCircleIcon } from "@heroicons/react/20/solid";
import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { NotificationType, useAppContext } from "AppContext";
import RoleService from "api/RoleService";
import AutoCompleteCombobox from "components/Dropdown/AutoCompleteCombobox";
import { Role } from "types/Role.types";
import { COUNTRIES, EXPIRATION_DATE_OPTIONS, NOTIFICATION_DISPLAY_TIME } from "utils/constants";
import CompanyService from "api/CompanyService";
import { useOutletContext } from "react-router-dom";
import { CompanyContainerOutletContextType } from "pages/home/companies/CompanyContainerPage";
import { Company, Employee } from "types/Company.types";
import { JobPostingGeneratorForm, JobPostingGeneratorFormSchema } from "types/JobPosting.types";
import CompaniesDropdown from "components/Dropdown/CompaniesDropdown";
import MultiRangeSlider from "components/Inputs/MultiRangeSlider";
import UserDropdown from "components/Dropdown/UserDropdown";
import { useLoader } from "hooks/useLoader";
import JobGeneratorService from "api/JobGeneratorService";

export const StepJobPostingPage: React.FC<{}> = () => {
  const { applyLoader } = useLoader();
  const minSalary = 0;
  const maxSalary = 1_000_000;
  const {
    notification: { showNotification },
    paginator: { nextStep, stepData },
  } = useAppContext();
  const { company } = useOutletContext<CompanyContainerOutletContextType>();

  const locations = ["Remote", "Hybrid", "On-site"];
  const [roles, setRoles] = useState<Array<Role>>([]);
  const [hiringManagers, setHiringManagers] = useState<Array<Employee>>([]);
  const [companies, setCompanies] = useState<Array<Company>>([]);
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setValue,
    reset,
    watch,
  } = useForm<JobPostingGeneratorForm>({
    resolver: yupResolver(JobPostingGeneratorFormSchema),
    defaultValues: {
      role: "",
      locations: [],
      companyId: company.id.toString(),
      hiringManagerId: "",
      country: "",
      minSalary,
      maxSalary,
      expirationDate: "6 months",
    },
  });

  useEffect(() => {
    if (stepData.jobPosting) {
      reset(stepData.jobPosting);
    }
  }, [reset, stepData]);

  const onSubmit = handleSubmit(async (formData) => {
    const roleId =
      roles.find((role) => role.name.localeCompare(formData.role, undefined, { sensitivity: "base" }) === 0)?.id ??
      (await applyLoader(RoleService.insertRole(formData.role))).data.roleId;
    stepData.jobPosting = {
      roleId: roleId,
      role: formData.role,
      companyId: formData.companyId,
      company,
      locations: formData.locations,
      country: formData.locations?.includes("On-site") ? formData.country : "",
      minSalary: formData.minSalary,
      maxSalary: formData.maxSalary,
      expirationDate: formData.expirationDate,
      hiringManagerId: formData.hiringManagerId,
      hiringManager: hiringManagers.find((manager) => Number(manager.id) === Number(formData.hiringManagerId))!,
      requirements: formData.requirements,
    };

    const jobDescription = await applyLoader(JobGeneratorService.generateJobDescription({ role: formData.role }));
    if (jobDescription) {
      stepData.jobDescription!.roleId = roleId;
      stepData.jobDescription!.role = formData.role;
      stepData.jobDescription!.skills = jobDescription.skills!;
      stepData.jobDescription!.overview = jobDescription.overview!;
      stepData.jobDescription!.qualificationList = jobDescription.qualifications!;
      stepData.jobDescription!.responsibilityList = jobDescription.responsibilities!;
    }
    nextStep(1, stepData);
  });

  useEffect(() => {
    const fetchRoles = async (): Promise<void> => {
      const { data, error } = await RoleService.listRoles();
      if (data) {
        setRoles(data);
      } else {
        showNotification({
          notificationType: NotificationType.Error,
          title: "Error fetching roles.",
          message: error.message,
          displayTime: NOTIFICATION_DISPLAY_TIME,
        });
      }
    };

    const fetchCompanies = async (): Promise<void> => {
      const { data, error } = await CompanyService.listCompanies();
      if (data) {
        setCompanies(data);
      } else {
        showNotification({
          notificationType: NotificationType.Error,
          title: "Error fetching companies.",
          message: error.message,
          displayTime: NOTIFICATION_DISPLAY_TIME,
        });
      }
    };

    fetchRoles();
    fetchCompanies();
  }, [showNotification]);

  useEffect(() => {
    const fetchHiringManagers = async (): Promise<void> => {
      const { data, error } = await CompanyService.getCompanyEmployees(company.id);
      if (data) {
        setHiringManagers(data);
      } else {
        showNotification({
          notificationType: NotificationType.Error,
          title: "Error fetching hiring managers.",
          message: error.message,
          displayTime: NOTIFICATION_DISPLAY_TIME,
        });
      }
    };

    fetchHiringManagers();
  }, [company, showNotification]);

  const selectedLocations = watch("locations") || [];

  return (
    <div className="lg:full flex flex-1 flex-col px-4 py-8 sm:px-6 lg:px-20 xl:px-24">
      <form onSubmit={onSubmit}>
        <div className="mb-5">
          <label htmlFor="role" className="block text-sm font-medium leading-6 text-gray-900">
            Role
          </label>
          <input type="hidden" {...register("role")} className="capitalize" autoComplete="off" />
          <AutoCompleteCombobox
            options={roles.map((role) => ({
              id: role.id.toString(),
              value: role.name,
            }))}
            selected={watch("role")}
            fieldKey={"role"}
            onChange={(role: string) => setValue("role", role)}
            error={errors?.role}
          />
          <p className="mt-2 text-sm text-gray-500" id="email-description">
            Select or Enter the Role you're looking for. Ex: Software Developer.
          </p>
        </div>
        <div className="mb-5">
          <label htmlFor="company" className="block text-sm font-medium leading-6 text-gray-900">
            Company
          </label>
          <CompaniesDropdown
            options={companies}
            selected={watch("companyId")}
            fieldKey={"companyId"}
            onChange={(companyId: string) => setValue("companyId", companyId)}
            disabled={!!company}
          />
        </div>
        <div className="mb-5">
          <label htmlFor="location" className="block text-sm font-medium leading-6 text-gray-900">
            Location
          </label>
          {locations.map((location) => {
            return (
              <div className="flex flex-row items-center" key={location}>
                <input
                  id={location}
                  type="checkbox"
                  value={location}
                  {...register("locations")}
                  className="mx-3 h-4 w-4 rounded border-gray-300 text-violet-600 focus:ring-violet-600"
                />
                <label htmlFor={location} className="block text-sm font-medium leading-6 text-gray-900">
                  {location}
                </label>
              </div>
            );
          })}
          {selectedLocations.includes("On-site") && (
            <AutoCompleteCombobox
              options={COUNTRIES.map((country) => ({
                id: country.code,
                value: country.name,
              }))}
              selected={watch("country")}
              fieldKey={"country"}
              onChange={(country: string) => setValue("country", country)}
              error={errors?.country}
              disableCreation={true}
            />
          )}
          {errors.locations && <div className="mt-2 block w-full py-1 text-sm text-red-700">{errors.locations.message}</div>}
        </div>
        <div className="mb-5">
          <label htmlFor="salary" className="block text-sm font-medium leading-6 text-gray-900">
            Salary
          </label>
          <div className="relative w-full p-4">
            <MultiRangeSlider
              min={minSalary}
              max={maxSalary}
              minValue={watch("minSalary")}
              maxValue={watch("maxSalary")}
              onMinChange={(value: number) => setValue("minSalary", value)}
              onMaxChange={(value: number) => setValue("maxSalary", value)}
            />
          </div>

          <p className="mt-2 text-sm text-gray-500">Select a range for the proposed yearly salary.</p>
        </div>
        <div className="mb-5">
          <label htmlFor="expirationDate" className="block text-sm font-medium leading-6 text-gray-900">
            Posting expiration date
          </label>
          <select
            {...register("expirationDate")}
            className="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-violet-600 sm:text-sm sm:leading-6"
          >
            {EXPIRATION_DATE_OPTIONS.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        </div>
        <div className="mb-5">
          <label htmlFor="hiringManager" className="block text-sm font-medium leading-6 text-gray-900">
            Hiring Manager
          </label>
          <UserDropdown
            options={hiringManagers}
            selected={watch("hiringManagerId")}
            fieldKey={"hiringManager"}
            onChange={(hiringManagerId: string) => setValue("hiringManagerId", hiringManagerId)}
            error={errors?.hiringManagerId}
          />
        </div>
        <div className="mb-5">
          <label htmlFor="requirements" className="block py-2 text-sm font-medium leading-6 text-gray-900">
            Special Requirements
          </label>
          <textarea
            {...register("requirements")}
            rows={4}
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-violet-600 sm:text-sm sm:leading-6"
          />

          <p className="mt-2 text-sm text-gray-500">
            Specify any special requirements for the job, such as certifications or qualifications
          </p>
        </div>
        <div className="flex flex-row-reverse items-end justify-between space-x-3 border-t border-gray-200 px-2 py-2 sm:px-3">
          <button
            type="submit"
            data-testid="next-step"
            className="flex rounded-md bg-violet-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-violet-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-violet-500"
            disabled={isSubmitting}
          >
            <span className="flex ">
              Next step
              <ArrowRightCircleIcon className="ml-2 h-5 w-5" aria-hidden="true" />
            </span>
          </button>
        </div>
      </form>
    </div>
  );
};

export default StepJobPostingPage;
