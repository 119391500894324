import { InferType, date, number, object, string } from "yup";
import { WorkExperienceType } from "./WorkExperience.types";

export enum WorkingGroup {
  Passion = "Passion",
  Mastery = "Mastery",
  Apathy = "Apathy",
}

export enum WorkingType {
  Elephant = "Elephant",
  Raven = "Raven",
  Owl = "Owl",
  Eagle = "Eagle",
  Horse = "Horse",
  Wolverine = "Wolverine",
}

const uniqueWorkingGeniusMessage = `Must be one of the following values: ${Object.values(WorkingType).join(", ")}`;
export const workingGeniusSchema = object({
  id: number(),
  workingGroup: string().strict().required().oneOf(Object.values(WorkingGroup)),
  workingTypeA: string().strict().required().oneOf(Object.values(WorkingType), uniqueWorkingGeniusMessage),
  workingTypeB: string().strict().required().oneOf(Object.values(WorkingType), uniqueWorkingGeniusMessage),
  createdOn: date(),
});
export type WorkingGenius = InferType<typeof workingGeniusSchema>;

export interface User {
  id: number;
  email: string;
  firstName: string | null;
  lastName: string | null;
  title: string | null;
  profilePictureUrl: string | null;
  bannerUrl: string | null;
  prettyUrl: string;
  location: string | null;
  aboutMe: string | null;
}

export interface UserProfile extends User {
  workingGenius: WorkingGenius[];
  workExperience: WorkExperienceType[];
  resume: string | null;
  workingTypeA: string | null;
  workingTypeB: string | null;
  personalitySummary: string;
}

export interface Personality {
  Elephant: string | undefined;
  Raven: string | undefined;
  Wolverine: string | undefined;
  Eagle: string | undefined;
  Owl: string | undefined;
  Horse: string | undefined;
}
export interface DirectoryUser {
  id: number;
  firstName: string | null;
  lastName: string | null;
  profilePictureUrl: string | null;
  personality: Personality;
}

export interface MatchingSkills {
  name: string;
  rate: number;
}
export interface MatchingEmployee extends DirectoryUser {
  hasRole: boolean;
  skills: MatchingSkills[];
  checked?: boolean;
  manuallyAdded?: boolean;
}

export interface MatchingRoleEmployee {
  roleName: string;
  roleSkillsEmployees: MatchingEmployee[];
}

export interface CompanyEmployee extends DirectoryUser {
  employeeId: number;
}
