import { AxiosResponse } from "axios";
import { PatchDocument } from "types/PatchDocument.types";
import { WorkExperienceFormType, WorkExperienceType } from "types/WorkExperience.types";
import { instance } from "./base.api";

export const insertWorkExperience = async (params: WorkExperienceFormType, userId: number): Promise<AxiosResponse<WorkExperienceType>> => {
  return instance.post<WorkExperienceType>(`users/${userId}/work-experience`, params).then((apiResponse) => {
    apiResponse.data.startDate = new Date(apiResponse.data.startDate);
    if (apiResponse.data.endDate) apiResponse.data.endDate = new Date(apiResponse.data.endDate);
    return apiResponse;
  });
};

export const updateWorkExperience = async (
  patchBody: PatchDocument<Partial<WorkExperienceFormType>>,
  workExperienceId: number,
  userId: number
): Promise<AxiosResponse> => {
  return instance.patch(`users/${userId}/work-experience/${workExperienceId}`, patchBody);
};

export const removeWorkExperience = async (workExperienceId: number, userId: number): Promise<AxiosResponse> => {
  return instance.delete(`users/${userId}/work-experience/${workExperienceId}`);
};

const WorkExperienceService = {
  insertWorkExperience,
  updateWorkExperience,
  removeWorkExperience,
};

export default WorkExperienceService;
