import React, { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { ENV_VARS } from "../../env";

type SkillsFromContent = {
  completedSkills: Array<string>;
  incompleteContent: string;
};

// TODO: tests once fully implemented
export const SuggestRoleSkillsPage: React.FC = () => {
  const [roleSkills, setRoleSkills] = useState<Array<string>>([]);
  const [streamingSkills, setStreamingSkills] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm();
  const inputRefRole = useRef<HTMLInputElement | null>(null);
  const { ref: refRole, ...restRole } = register("role");
  const completedSkillRegEx = /^([0-9]+\.\s?.+)(?=([1-9]|10)+\.).*$/s;
  const listNumberRegEx = /\d+\.\s?/;

  /**
   * Recursively searches content from right to left for completed skills.
   * @param content accum content from stream chunks
   * @param completedSkills recursive variable. array of verified completed skills
   * @param mightBeComplete recursive variable. possible completed skill
   * @returns {SkillsFromContent} {@link SkillsFromContent}
   */
  const extractSkillsFromContent = (
    content: string,
    completedSkills: Array<string> = [],
    mightBeComplete: string = ""
  ): SkillsFromContent => {
    const completedMatch = content.match(completedSkillRegEx);
    if (completedMatch) {
      const [, contentToProcess] = completedMatch;
      const contentToProcessIndex = content.indexOf(contentToProcess);
      const skill = content.substring(contentToProcessIndex + contentToProcess.length);
      if (!mightBeComplete) {
        return extractSkillsFromContent(contentToProcess, completedSkills, skill);
      }
      completedSkills.push(skill.replace(listNumberRegEx, "").trim());
      return extractSkillsFromContent(contentToProcess, completedSkills, mightBeComplete);
    }
    if (mightBeComplete) {
      completedSkills.push(content.replace(listNumberRegEx, "").trim());
      return {
        completedSkills: completedSkills,
        incompleteContent: mightBeComplete,
      };
    }
    return {
      completedSkills: completedSkills,
      incompleteContent: content,
    };
  };

  const streamSkills = async (role: string) => {
    const lambdaStreamUrl = ENV_VARS.StreamRoleSkillSuggestions;
    const { body: streamResponse } = await fetch(lambdaStreamUrl, { method: "POST", body: JSON.stringify({ role }) });
    if (!streamResponse) {
      return;
    }
    const reader = streamResponse.getReader();
    const decoder = new TextDecoder();
    let done = false;
    let content = "";
    console.clear();
    while (!done) {
      const { value, done: doneReading } = await reader.read();
      done = doneReading;
      const chunkValue = decoder.decode(value);
      content += chunkValue;
      const { completedSkills: completed, incompleteContent: incomplete } = extractSkillsFromContent(content);
      if (completed.length > 0) {
        setRoleSkills((previous) => [...previous, ...completed]);
      }
      content = incomplete;
    }
    if (content.trim()) {
      setRoleSkills((previous) => [...previous, content.replace(/\d+\.\s?/, "").trim()]);
    }
  };

  const onSubmit = handleSubmit(async ({ role }) => {
    setStreamingSkills(true);
    setRoleSkills([]);
    try {
      await streamSkills(role);
    } catch (error) {
      console.error(error);
    } finally {
      setStreamingSkills(false);
    }
  });

  return (
    <div className="p-12">
      <form onSubmit={onSubmit}>
        <div>
          <div className="mb-4 border-b border-gray-900/10 pb-6">
            <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div className="sm:col-span-4">
                <label htmlFor="role" className="block text-sm font-medium leading-6 text-gray-900">
                  Role
                </label>
                <div className="mt-2 flex gap-2">
                  <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-violet-600 sm:max-w-md">
                    <input
                      type="text"
                      id="role"
                      autoComplete="role"
                      className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                      placeholder="Type your role"
                      {...restRole}
                      ref={(e) => {
                        refRole(e);
                        inputRefRole.current = e;
                      }}
                    />
                  </div>
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className="rounded-md bg-violet-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-violet-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-violet-600"
                  >
                    Submit
                  </button>
                  {streamingSkills ? <div>streaming skills...</div> : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      <div className="flex flex-col gap-y-1">
        {roleSkills.map((skill, index) => (
          <div key={skill}>
            {index + 1}. {skill}
          </div>
        ))}
      </div>
    </div>
  );
};
